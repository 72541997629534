import styled from 'styled-components'

import { lessThan } from 'src/styles/utils'
import defaultTheme from 'src/styles/theme'

import { GridProps } from 'src/interfaces/styles'

import Container from 'src/styles/styled-components/Container'

const defaultProps = {
  theme: defaultTheme,
  noPadding: false,
  noGrow: false,
}

const sizes = [
  '0%',
  '8.33333333%',
  '16.66666667%',
  '25%',
  '33.33333333%',
  '41.66666667%',
  '50%',
  '58.33333333%',
  '66.66666667%',
  '75%',
  '83.33333333%',
  '91.66666667%',
  '100%',
]

const getSize = (sizeNumber: number, noGrow?: boolean): string =>
  sizeNumber
    ? `
  flex-basis: ${sizes[sizeNumber]};
  max-width: ${sizes[sizeNumber]};
`
    : `
  flex-grow: ${noGrow ? 0 : 1};
  flex-basis: 0;
  max-width: 100%;
`

const getOffset = (offset: number, right: boolean) =>
  offset
    ? `
  ${
    right
      ? `
    margin-right: ${offset < 12 ? sizes[offset] : sizes[11]};
  `
      : `
    margin-left: ${offset < 12 ? sizes[offset] : sizes[11]};
  `
  }
`
    : ''

const xs = ({ xs, offset, right, noGrow }: GridProps) => `
  ${getSize(xs, noGrow)}
  ${getOffset(offset, right)}
`

const sm = ({ theme, xs, sm, noGrow }: GridProps) =>
  lessThan(theme.breakpoints.small)(getSize(sm || xs, noGrow))

const md = ({ theme, xs, sm, md, noGrow }: GridProps) =>
  lessThan(theme.breakpoints.medium)(getSize(md || sm || xs, noGrow))

const lg = ({ theme, xs, sm, md, lg, noGrow }: GridProps) =>
  lessThan(theme.breakpoints.large)(getSize(lg || md || sm || xs, noGrow))

const padding = ({ noPadding }: GridProps) => `
  ${!noPadding ? 'padding: 0 .5rem;' : ''}
`

const Col = styled(Container)`
  box-sizing: border-box;
  flex: 0 0 auto;
  ${padding} ${lg} ${md} ${sm} ${xs}
`

Col.displayName = 'Col'
Col.defaultProps = defaultProps

export default Col
