import styled from 'styled-components'
import { Container } from 'src/styles/styled-components'
import defaultTheme from 'src/styles/theme'

interface optionStyledProps {
  background: string
  height: number|string
  padding: string
}

const dynamicStyles = ({ background, height, padding }: optionStyledProps) => `
  background-color: ${background};
  height: ${height}px;
  padding: ${padding};
`

// TASK: WOZ-623
const OptionStyled = styled(Container)`
  padding: 8px 14px;
  height: 37px;
  border-top: 1px solid #e2e7ee;

  &:hover {
    background-color: #f1f4f8;
    > span {
      color: ${defaultTheme.colors.main};
    }
  }

  ${dynamicStyles}
`

OptionStyled.displayName = 'OptionStyled'

export default OptionStyled
