import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useHistory, useParams } from 'react-router-dom'

import MainLayout from 'src/layouts/Main'
import { Col, Container, Row, Text } from 'src/styles/styled-components'

// components
import {
  Button,
  Icon,
  Segment,
  FormElement,
  Table,
  Modal,
  EditButton,
  Wrapper,
} from 'src/components'
import { ThemeContext } from 'styled-components'
import { ClientRequestInfo } from 'src/interfaces/app'
import {
  createToken,
  editClient,
  getClientInfoById,
  // WOZ-693 WOZ-694 WOZ-695
  isAuthUserExist,
  isUserExist,
  sendEmail,
  uploadFiles,
} from 'src/services/api'
import Item from 'src/helpers/Item'
import { CLIENT_INFO_KEY_MAP, STATUSES } from 'src/constants/clientInfo'
import { generateString, hasKey, isEmail } from 'src/utils/misc'
import { useAuth } from 'src/hooks/useAuth'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/user'
import ManagerActions from 'src/pages/clientDetail/components/ManagerActions'
import StopFile from 'src/pages/clientDetail/components/ActionViews/StopFile'
import ClientInfoAdditionalForm from 'src/pages/clientDetail/components/ClientInfoAdditionalForm'
import UploadDocuments from 'src/pages/clientDetail/components/UploadDocuments'
import ClientActions from 'src/pages/clientDetail/components/ClientActions'
import { useDocument } from 'src/hooks/useDocument'
import { useAlert } from 'react-alert'
import { useScreenSize } from 'src/hooks/useScreenSize'
import { BASE_URL, SELECT_OPTIONS } from 'src/constants'
import { generateSignatureExample } from 'src/services/pdfGenerator'
import { UPDATE_DOCUMENT_EMAIL } from 'src/constants/emailContents'

interface ClientDetailProps {}

function ClientDetail(props: ClientDetailProps) {
  const theme = useContext(ThemeContext)
  const params = useParams<{ id: string }>()
  const auth = useAuth()
  const alert = useAlert()

  const history = useHistory()
  const { getMunicipalities, getDocumentStopCaseList } = useActions(actions)
  const [isLoading, setIsLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isModalShow, setIsModalShow] = useState(false)
  const [data, setData] = useState(null)
  const { createNewUser } = useDocument(data)
  const [isStopFileModalShow, setIsStopFileModalShow] = useState(false)
  const [updateClientInfo, setUpdatedClientInfo] = useState<any>({})

  const isMobile = useScreenSize()

  useEffect(() => {
    ;(async function () {
      if (data?.signature && !data.authorization && data?.onWebsite) {
        setIsLoading(true)
        const signatureDoc = await generateSignatureExample(data)
        await uploadFiles(
          signatureDoc.file,
          'authorization',
          'pending',
          params.id,
          { onWebsite: false }
        )
        await getClientData()
        setIsLoading(false)
      }
    })()
  }, [data])

  useEffect(() => {
    getDocumentStopCaseList()
    getMunicipalities()
    ;(async function () {
      setIsLoading(true)
      await getClientData()
      setIsLoading(false)
    })()
  }, [])

  function goBack() {
    history.goBack()
  }

  function getInputType(name: string) {
    switch (name) {
      case 'reasonOfObjection':
        return 'textarea'
      case 'assessmentDate':
        return 'datepicker'
      case 'salutation':
      case 'property':
      case 'howFindUs':
        return 'select'
      default:
        return 'input'
    }
  }

  const columns = [
    {
      name: 'Naam',
      selector: 'label',
    },
    {
      name: 'Value',
      selector: 'value',
      cell: (row: Record<string, any>) => {
        const availableRows = ['phone', 'email']
        const value = row.name === 'municipality' ? row.value.name : row.value
        const formType = getInputType(row.name)

        return auth.isAdmin || availableRows.includes(row.name) ? (
          <FormElement
            name={row.name}
            value={updateClientInfo[row.name] || value}
            edit={isEdit}
            formType={formType}
            onChange={formType !== 'input' && onDataEdit}
            inlineUpdate={onDataEdit}
            withPortal
            // @ts-ignore
            options={SELECT_OPTIONS[row.name]}
          >
            <Text color={theme.colors.gray5}>
              {/* @ts-ignore */}
              {row.name === 'reasonOfObjection' ? row.value.split("\n").map((text, index) => (
                <>
                  {index !== 0 && <br />}
                  {text}
                </>
              )) : value}
            </Text>
          </FormElement>
        ) : (
          <Text color={theme.colors.gray5}>{value}</Text>
        )
      },
    },
  ]

  async function getClientData() {
    const response = await getClientInfoById(params.id)
    if (!response) {
      return history.replace('/')
    }
    const userRegistered = await isAuthUserExist(response?.email)
    setData({
      ...response,
      id: params.id,
      userRegistered,
    })
  }

  function onDataEdit(value: string, name: string) {
    setUpdatedClientInfo((oldData: any) => {
      return { ...oldData, [name]: value }
    })
  }

  function showStopFileModal() {
    setIsStopFileModalShow(true)
  }

  function onFileStop() {
    setIsStopFileModalShow(false)
  }

  function hideEditor() {
    setIsEdit(false)
    setUpdatedClientInfo({})
  }

  function showEditor() {
    setIsEdit(true)
  }

  function hideModal() {
    setIsModalShow(false)
    setUpdatedClientInfo({})
  }

  async function showModal() {
    if (!!updateClientInfo.email && updateClientInfo !== data.email) {
      setIsModalShow(true)
    } else {
      await updateData()
    }
  }

  async function updateData() {
    if (updateClientInfo.email && !isEmail(updateClientInfo.email)) {
      alert.error('EMAIL_ERROR_TEXT')
      setIsEdit(false)
      hideModal()
    } else {
      try {
        setIsLoading(true)
        const token = generateString(64)
        const url = `${BASE_URL}verify/${token}`
        const hasUser = updateClientInfo.email
          ? await isUserExist(updateClientInfo.email)
          : null
        console.log('LOG ::::::> updateClientInfo <::::::',updateClientInfo)
        const firstName = updateClientInfo?.firstName || data.firstName
        const lastName = updateClientInfo?.lastName || data.lastName
        if (
          !!updateClientInfo.email &&
          updateClientInfo.email !== data.email &&
          hasUser
        ) {
          await createToken({
            token,
            hasUser,
            clientInfoId: params.id,
            email: updateClientInfo.email,
            type: 'change_email_client_info',
          })
          await sendEmail({
            to: updateClientInfo.email,
            body: UPDATE_DOCUMENT_EMAIL(data, url),
            subject: 'Wijziging e-mailadres',
          })
          await editClient(params.id, {
            ...updateClientInfo,
            email: data.email,
            fullName: firstName + ' ' + lastName,
          })
        } else {
          await editClient(params.id, {
            ...updateClientInfo,
            email: data.email,
            fullName: firstName + ' ' + lastName,
          })
        }

        if (
          !!updateClientInfo.email &&
          updateClientInfo.email !== data.email &&
          !hasUser
        ) {
          await createNewUser(
            params.id,
            { ...data, ...updateClientInfo },
            false
          )
        }

        await getClientData()
        hideEditor()
        hideModal()
        alert.success('Success')
        setIsLoading(false)
      } catch (e) {
        await getClientData()
        hideEditor()
        hideModal()
        setIsLoading(false)
      }
    }
  }

  const parseData = useCallback(
    (_data: ClientRequestInfo, type: 'realEstateData' | 'taxpayerDetails') => {
      const result: Record<string, any>[] = []

      _data &&
        Object.keys(CLIENT_INFO_KEY_MAP[type]).forEach((i) => {
          if (hasKey(_data, i)) {
            result.push({
              name: i,
              value: _data[i],
              label: CLIENT_INFO_KEY_MAP[type][i],
            })
          }
        })

      return result
    },
    []
  )

  return (
    <MainLayout hideLeftPanel={isMobile}>
      <Wrapper isBusy={isLoading} padding={16}>
        {!isMobile && (
          <Container margin={{ bottom: 24 }}>
            {/* TASK: WOZ-628 */}
            {/* TASK: WOZ-629 */}
            <Item
              flex
              crossAxis="center"
              onClick={goBack}
              hoverDecoration={theme.decorations.main}
            >
              <Icon iconName="ChevronLeft" size={12} color={theme.colors.main} />
              <Text margin={{ left: 5 }} size={14} color={theme.colors.main}>
                Terug naar het overzicht
              </Text>
            </Item>
          </Container>
        )}
        <Container
          flex
          margin={{ bottom: 24 }}
          mainAxis="space-between"
          crossAxis="center"
        >
          <Text size={24}>
            {data?.salutation} {data?.firstName} {data?.lastName}
          </Text>
          {auth?.isAdmin && !data?.isStop && (
            <Item
              onClick={showStopFileModal}
              flex
              noGrow
              crossAxis="center"
              hoverColor={theme.colors.red0}
              hoverDecoration={theme.decorations.danger}
            >
              <Icon
                iconName="StopCircleFill"
                color={theme.colors.red1}
                size={18}
              />
              <Text
                margin={{ left: 8 }}
                color={theme.colors.red1}
                hoverColor={theme.colors.red0}
              >
                Dossier stopzetten
              </Text>
            </Item>
          )}
        </Container>
        <Container>
          <Segment
            editable={false}
            steps={STATUSES}
            currentIndex={data?.status}
          />
          {data?.isStop ? (
            <Container
              padding={16}
              margin={{ top: 24 }}
              background={theme.colors.orange}
            >
              <Text color={theme.colors.white}>
                Dit dossier is stopgezet op {data?.documentStoppedDate}
              </Text>
            </Container>
          ) : auth.isAdmin ? (
            <ManagerActions
              clientInfo={data}
              updateData={getClientData}
              status={data?.status}
              email={data?.email}
            />
          ) : (
            !isMobile && <ClientActions clientInfo={data} status={data?.status} />
          )}
          {auth.isAdmin && (
            <Container margin={{ top: 24 }} flex column noGrow>
              {data?.documentReminder ? (
                <Text>Documenten: herinnerd op {data?.documentReminder}</Text>
              ) : null}
              {data?.photoReminder ? (
                <Text>Foto's: herinnerd op {data?.photoReminder}</Text>
              ) : data?.photoRequest ? (
                <Text>Foto's: opgevraagd op {data?.photoRequest}</Text>
              ) : null}
            </Container>
          )}
        </Container>
        {!isMobile && (
          <Container
            padding={{ top: 24 }}
            borderTop="1px solid black"
            flex
            margin={{ top: 24, bottom: 56 }}
          >
            <Row noPadding>
              <Col xs={10} noPadding>
                <Container padding={{ right: 76 }}>
                  <Container>
                    {/* TASK: WOZ-632 */}
                    <Row mainAxis="space-between">
                      <Col xs={6}>
                        <Container padding={{ right: 50 }}>
                          <Container margin={{ bottom: 16 }}>
                            {/* TASK: WOZ-630 */}
                            <Text size={18} weight="bold">Objectgegevens</Text>
                          </Container>
                          <Table
                            noTableHead
                            data={parseData(data, 'realEstateData')}
                            columns={columns}
                          />
                        </Container>
                      </Col>
                      <Col xs={6}>
                        <Container padding={{ left: 50 }}>
                          <Container margin={{ bottom: 16 }}>
                            <Text size={18} weight="bold">Gegevens belastingplichtige</Text>
                          </Container>
                          <Table
                            noTableHead
                            data={parseData(data, 'taxpayerDetails')}
                            columns={columns}
                          />
                        </Container>
                      </Col>
                    </Row>
                    <Container
                      margin={{ top: 15 }}
                      flex
                      noShrink
                      mainAxis="flex-end"
                    >
                      <EditButton
                        data={data}
                        isEdit={isEdit}
                        onCancel={hideEditor}
                        onSubmit={showModal}
                        toggleEditButton={showEditor}
                      />
                    </Container>
                  </Container>
                  {auth.isAdmin && (
                    <ClientInfoAdditionalForm update={getClientData} data={data} />
                  )}
                </Container>
              </Col>
              <Col xs={2} noPadding>
                <UploadDocuments
                  data={data}
                  isAdmin={auth?.isAdmin}
                  updateData={getClientData}
                />
              </Col>
            </Row>
          </Container>
        )}
      </Wrapper>
      <Modal
        isShow={isStopFileModalShow}
        title="Dossier stopzetten"
        onRequestClose={setIsStopFileModalShow}
      >
        <StopFile
          clientInfo={data}
          email={data?.email}
          updateData={getClientData}
          onRequestSent={onFileStop}
        />
      </Modal>
      <Modal modalSize="small" isShow={isModalShow} onRequestClose={hideModal}>
        <Container
          column
          flex
          crossAxis="center"
          mainAxis="space-between"
          padding={{ horizontal: 16 }}
        >
          <Text align="center">
            Weet u het zeker dat u het e-mailadres wilt aanpassen? Er zal een
            e-mail worden verstuurd naar het nieuwe e-mailadres om dit te
            bevestigen. Na bevestiging zal het e-mailadres definitief aangepast
            worden.
          </Text>
          <Container flex noGrow margin={{ bottom: 24 }}>
            <Container margin={{ right: 16 }}>
              <Button styleType="remove" label="Cancel" onClick={hideModal} />
            </Container>
            <Container>
              <Button label="Change" onClick={updateData} />
            </Container>
          </Container>
        </Container>
      </Modal>
    </MainLayout>
  )
}

ClientDetail.defaultProps = {}

export default {
  component: ClientDetail,
  exact: false,
  public: false,
  path: '/client_detail/:id',
}
