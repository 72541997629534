import React, { memo, useContext, useEffect, useState } from 'react'

import { Button, Form, Input, Wrapper } from 'src/components'
import { Container, Text } from 'src/styles/styled-components'

import { useForm } from 'src/hooks/useForm'
import { useActions } from 'redux-exodus'
import { useAlert } from 'react-alert'

import { actions } from 'src/redux/authorization'
// data
import { REGISTRATION_FORM } from 'src/forms/authorization/formData'
import { useSelector } from 'react-redux'
import { signUpHandlerSelector } from 'src/selectors'
import {
  changePassword,
  expireToken,
  getClients,
  getProfile,
} from 'src/services/api'
import { useHistory, useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
// TASK: WOZ-571

import { useAlertOptions } from 'src/hooks/useAlertOptions'

type TokenData = {
  id: string
  token: string
  email: string
  redirectLink?: string
  clientId: string
  subpath?: 'documents' | 'wizard' | 'photos'
}

interface RegistrationFormProps {
  isRegistration?: boolean
  tokenData: TokenData
}

function RegistrationForm(props: RegistrationFormProps) {
  const theme = useContext(ThemeContext)
  const { tokenData, isRegistration = true } = props
  const history = useHistory()
  const alert = useAlert()
  const alertOptions = useAlertOptions()
  const location = useLocation<{ from: any }>()
  const urlParams = new URLSearchParams(location.search)
  const clientId = urlParams.get('clientId')
  const details = urlParams.get('details')

  const { updateFormData, formData, formValues, onSubmit } =
    useForm(REGISTRATION_FORM)
  const { signUp, signIn } = useActions(actions)
  const { error } = useSelector(signUpHandlerSelector)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (error?.message) {
      alert.error(error?.message, alertOptions)
    }
  }, [error])

  function handleSubmit() {
    onSubmit(async () => {
      setIsLoading(true)
      if (isRegistration) {
        await signUp(tokenData.email, formValues.password).then(async () => {
          if (details === 'true' && clientId) {
            history.replace(`/client_detail/${clientId}`)
          } else if (tokenData?.redirectLink) {
            setTimeout(() => {
              history.replace(
                tokenData?.subpath
                  ? `${tokenData?.redirectLink}/${tokenData.subpath}`
                  : tokenData?.redirectLink
              )
            }, 1200)
          } else {
            history.replace('/')
          }
          await expireToken(tokenData.id)
        })
        alert.success('Wachtwoord succesvol aangemaakt.', alertOptions)
      } else {
        const result = await changePassword(
          tokenData.email,
          formValues.password
        )

        if (!result.success) {
          alert.error('Error', alertOptions)
        } else {
          signIn(tokenData.email, formValues.password).then(async () => {
            if (tokenData?.redirectLink) {
              setTimeout(() => {
                history.replace(
                  tokenData?.subpath
                    ? `${tokenData?.redirectLink}/${tokenData.subpath}`
                    : tokenData?.redirectLink
                )
              }, 1200)
            } else {
              alert.success('Wachtwoord succesvol aangepast', alertOptions)
              const user = await getProfile()
              if (user.size && user.docs[0].data().role === 'customer') {
                const firstTwoClient = await getClients(
                  user.docs[0].data().email,
                  2
                )
                if (firstTwoClient.size === 1) {
                  history.replace(`/client_detail/${firstTwoClient.docs[0].id}`)
                } else {
                  history.replace(`/`)
                }
              }
            }
            await expireToken(tokenData.id)
          })
        }
        setIsLoading(false)
      }
    })
  }

  return (
    <Wrapper isBusy={isLoading}>
      {isRegistration ? (
        <>
          <Container flex mainAxis="center" margin={{ bottom: 8 }}>
            <Text size={25} color={theme.colors.gray5} mobile={{ size: 24 }}>
              Wachtwoord aanmaken
            </Text>
          </Container>
          <Container flex mainAxis="center" margin={{ bottom: 20 }}>
            <Text size={16} color={theme.colors.gray5} mobile={{ size: 14 }}>
              Voer hieronder 2 keer uw gewenste wachtwoord in.
            </Text>
          </Container>
        </>
      ) : (
        <>
          <Container flex mainAxis="center" margin={{ bottom: 8 }}>
            <Text size={25} color={theme.colors.gray5} mobile={{ size: 24 }}>
              Wachtwoord aanpassen
            </Text>
          </Container>
          <Container flex mainAxis="center" margin={{ bottom: 20 }}>
            <Text size={16} color={theme.colors.gray5} mobile={{ size: 14 }}>
              Voer hieronder 2 keer uw nieuwe wachtwoord in om deze aan te
              passen.
            </Text>
          </Container>
        </>
      )}

      <Form onSubmit={handleSubmit}>
        <Container margin={{ bottom: 5 }}>
          <Input
            name="password"
            type="password"
            placeholder="Wachtwoord"
            onChange={updateFormData}
            value={formData.password.value}
          />
        </Container>
        <Container margin={{ bottom: 15 }}>
          <Text mobile={{ size: 14 }}>
            Het wachtwoord dient te bestaan uit minimaal 8 karakters, bestaande
            uit één hoofdletter, één kleine letter, één cijfer en één speciaal
            teken ( !$#%&*()-=+ )
          </Text>
        </Container>
        <Container margin={{ bottom: 15 }}>
          <Input
            type="password"
            name="confirm_password"
            placeholder="Herhaal wachtwoord"
            onChange={updateFormData}
            value={formData.confirm_password.value}
            errorText={formData.confirm_password.validationText}
          />
        </Container>
        <Container flex mainAxis="center" margin={{ top: 15, bottom: 20 }}>
          <Button
            type="submit"
            label={
              isRegistration ? 'Wachtwoord aanmaken' : 'Wijziging doorvoeren'
            }
            large
            big
          />
        </Container>
      </Form>
    </Wrapper>
  )
}

export default memo(RegistrationForm)
