import {
  MANAGER_ACTION_TYPES,
  TemplateCategoryIds,
  TemplateIds,
} from 'src/interfaces/app'

const isProd = false

export const KEEP_LOGGED_IN_TIME = 60 * 60 * 1000
export const MOBILE_MAX_SIZE = 768
// NOTE: can use IS_MOBILE for production (for better performance)
export const IS_MOBILE = document.body.clientWidth < MOBILE_MAX_SIZE

const BASE_URL_PROD = 'https://portal.wozbezwaar.biz/'
const BASE_URL_DEV = 'https://portal-staging-89613.web.app/'
export const BASE_URL = isProd ? BASE_URL_PROD : BASE_URL_DEV

export const DIRECTOR_SIGNATURE_URL =
  'https://firebasestorage.googleapis.com/v0/b/portal-41ffa.appspot.com/o/loc%2Fsignature_director.png?alt=media&token=0629cd71-9850-40ea-a47c-a23435bd1b10'
export const DOCUMENT_BG =
  'https://firebasestorage.googleapis.com/v0/b/portal-41ffa.appspot.com/o/loc%2Fpdf_bg.png?alt=media&token=a939d0f0-cb9f-479b-bf91-e7b1ab04eedb'
export const COPY_LETTER_BG =
  'https://firebasestorage.googleapis.com/v0/b/portal-41ffa.appspot.com/o/loc%2Fcopyletter.png?alt=media&token=89f2ed30-0c3a-4969-b367-5b003d319409'
export const APP_LOGO =
  'https://firebasestorage.googleapis.com/v0/b/portal-41ffa.appspot.com/o/loc%2Flogo.png?alt=media&token=1a361ed9-8c4b-44f0-b462-cc99e09bf2fa'

export const TEXT_BLOCKS_CATEGORIES = {
  COExport: 'Text blocks of CO export',
  decisionReceived: 'Decision received',
}
export const TEXT_BLOCKS_CATEGORIES_KEY = {
  COExport: 'Text blocks of CO export',
  decisionReceived: 'Decision received',
}

export enum ROLES {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
}

export const DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_TIME_FORMAT = 'DD-MM-YY HH:mm'
export const EMAIL_ERROR_TEXT =
  'Het e-mailadres is niet correct gespeld. Controleer het adres en probeer het opnieuw'
// quetsions
export const QUESTION4_LABEL =
  'Wat is de staat van onderhoud van uw woning? Indien er sprake is van gebreken en/of achterstallig onderhoud, kunt u dan aangeven waaruit dit bestaat?'
export const QUESTION5_LABEL =
  'Bevindt de woning zich in een gedateerde staat (bijvoorbeeld: een oude keuken, oude badkamer, oude CV-installatie, enkel glas, oud dak etc.)? Zo ja, kunt u dit toelichten?'

export const TEMPLATES_ID_MAP = {
  [TemplateCategoryIds.SEND_REMINDER]: 'Verstuur herinnering',
  [TemplateCategoryIds.REQUEST_PHOTO]: "Foto's opvragen",
  [TemplateCategoryIds.EMAIL_SIGNATURE]: 'E-mail handtekening',
  [TemplateCategoryIds.SEND_OBJECTION_TO_MUNICIPALITY]: 'Proforma ingediend',
  [TemplateCategoryIds.RECEIVED_DECISION]: 'Uitspraak ontvangen',
  [TemplateCategoryIds.CO_OBJECTION]: 'Inhoudelijk bezwaar ingediend',
  [TemplateCategoryIds.FILE_STOPPED]: 'Dossier stopgezet',
  [TemplateCategoryIds.PROFILE_CHANGES]: 'Profielwijzigingen',
  [TemplateCategoryIds.CHECKING]: 'Stukken controleren',
  [TemplateCategoryIds.WELCOME_MESSAGE]: 'Welkomstbericht',
}

export const TEMPLATES_TITLES_MAP = {
  [TemplateIds.PASSWORD_NOT_CREATED]: 'Password Not-created on website, signed',
  [TemplateIds.CREATE_ON_WEB_SITE]: 'Password created on website, signed',
  [TemplateIds.CREATE_ON_WEB_SITE_NOT_SIGNED]:
    'Password created on website, not signed',
  [TemplateIds.PASSWORD_NOT_CREATED_NOT_SIGNED]:
    'Password Not-created on website, not signed',
  [TemplateIds.PASSWORD_NOT_CREATED_NOT_SIGNED_PORTAL]: 'Portal welcome',
  [TemplateIds.EXISTING_USER_PORTAL]: 'Portal welcome Existing user',
  [TemplateIds.COPY_LETTER_NEW]: 'COPY_LETTER_NEW',
  [TemplateIds.SIGNATURE_NEW]: 'SIGNATURE_NEW',
  [TemplateIds.QUESTION_LIST_NEW]: 'QUESTION_LIST_NEW',
  [TemplateIds.RESET_PASSWORD]: 'RESET_PASSWORD',
  [TemplateIds.RESET_PASSWORD_SUCCESS]: 'RESET_PASSWORD_SUCCESS',
  [TemplateIds.CHANGE_EMAIL]: 'CHANGE_EMAIL',
  [TemplateIds.EMAIL_REMINDER]: 'EMAIL_REMINDER',
  [TemplateIds.PHOTO_REMINDER]: 'PHOTO_REMINDER',
  [TemplateIds.REQUEST_PHOTO]: 'REQUEST_PHOTO',
  [TemplateIds.SEND_REMINDER]: 'SEND_REMINDER',
  [TemplateIds.NEED_SOME_FILES]: 'NEED_SOME_FILES',
  [TemplateIds.ALL_FILES_OK]: 'ALL_FILES_OK',
  [TemplateIds.FO_IS_DELIVERY]: 'FO_IS_DELIVERY',
  [TemplateIds.CO_DELIVERY_TO_CLIENT]: 'CO_DELIVERY_TO_CLIENT',
  [TemplateIds.CO_DELIVERY_TO_MUNICIPALITY]: 'CO_DELIVERY_TO_MUNICIPALITY',
  [TemplateIds.DECISION]: 'DECISION',
  [TemplateIds.REASON1]: 'WOZ klopt onderzoek',
  [TemplateIds.REASON2]: 'Geen stukken',
  [TemplateIds.REASON3]: 'Afmelding klant',
  [TemplateIds.REASON4]: 'WOZ klopt EA',
  [TemplateIds.SEND_REASON_TO_MUNICIPALITY]: 'SEND_REASON_TO_MUNICIPALITY',
  [TemplateIds.COPY_LETTER]: 'COPY_LETTER',
  [TemplateIds.SIGNATURE]: 'SIGNATURE',
  [TemplateIds.QUESTION_LIST]: 'QUESTION_LIST',
  [TemplateIds.EMAIL_SIGNATURE]: 'EMAIL_SIGNATURE',
}

export const TemplateKeysMap: any = {
  PASSWORD_NOT_CREATED: 'password_not_created', // 8
  CREATE_ON_WEB_SITE: 'create_on_web_site', // 8
  RESET_PASSWORD: 'reset_password', // 6
  RESET_PASSWORD_SUCCESS: 'reset_password_success', // 6
  CHANGE_EMAIL: 'change_email',
  EMAIL_REMINDER: 'email_reminder',
  PHOTO_REMINDER: 'photo_reminder',
  REQUEST_PHOTO: 'request_photo',
  ASK_REMINDER: 'ask_reminder',
  NEED_SOME_FILES: 'need_some_files',
  EMAIL_SIGNATURE: 'email_signature',
  ALL_FILES_OK: 'all_files_ok',
  FO_IS_DELIVERY: 'fo_is_delivery',
  CO_DELIVERY_TO_CLIENT: 'co_delivery_to_client',
  CO_DELIVERY_TO_MUNICIPALITY: 'co_delivery_to_municipality',
  DECISION: 'decision',
  REASON1: 'reason1',
  REASON2: 'reason2',
  REASON3: 'reason3',
  REASON4: 'reason4',
  SEND_REASON_TO_MUNICIPALITY: 'send_reason_to_municipality',
  SIGNATURE: 'signature',
  QUESTION_LIST: 'question_list',
  COPY_LETTER: 'copy_letter',
  SIGNATURE_NEW: 'signature_new',
  QUESTION_LIST_NEW: 'question_list_new',
  COPY_LETTER_NEW: 'copy_letter_new',
  PASSWORD_NOT_CREATED_NOT_SIGNED: 'password_not_created_not_signed',
  PASSWORD_NOT_CREATED_NOT_SIGNED_PORTAL:
    'password_not_created_not_signed_portal',
  EXISTING_USER_PORTAL: 'existing_user_portal',
  CREATE_ON_WEB_SITE_NOT_SIGNED: 'create_on_web_site_not_signed',
}

export const ACTIONS_TEMPLATE_MAP: Record<
  string,
  [TemplateCategoryIds, TemplateIds]
> = {
  [MANAGER_ACTION_TYPES.CHECKING]: [
    TemplateCategoryIds.CHECKING,
    TemplateIds.NEED_SOME_FILES,
  ],
  [MANAGER_ACTION_TYPES.FO_OBJECTION]: [
    TemplateCategoryIds.SEND_OBJECTION_TO_MUNICIPALITY,
    TemplateIds.FO_IS_DELIVERY,
  ],
  [MANAGER_ACTION_TYPES.RECEIVED_DECISION]: [
    TemplateCategoryIds.RECEIVED_DECISION,
    TemplateIds.DECISION,
  ],
  //[MANAGER_ACTION_TYPES.RECEIVED_DECISION]: [TemplateCategoryIds.RECEIVED_DECISION],
  // EXPORT_CO: [undefined, undefined],
  // EXPORT_FO: [undefined, undefined],
  // EXPORT_OBJECTION: [undefined, undefined],
  // FO_OBJECTION: [undefined, undefined],
  // RECEIVED_DECISION: [undefined, undefined],
  // SEND_OBJECTION_TO_MUNICIPALITY: [undefined, undefined],
  // VIEW_DECISION: [undefined, undefined],
  // VIEW_OBJECTION: [undefined, undefined],
  [MANAGER_ACTION_TYPES.SEND_REMINDER]: [
    TemplateCategoryIds.SEND_REMINDER,
    TemplateIds.EMAIL_REMINDER,
  ],
  [MANAGER_ACTION_TYPES.REQUEST_PHOTO]: [
    TemplateCategoryIds.REQUEST_PHOTO,
    TemplateIds.REQUEST_PHOTO,
  ],
  [MANAGER_ACTION_TYPES.PHOTO_REMINDER]: [
    TemplateCategoryIds.REQUEST_PHOTO,
    TemplateIds.PHOTO_REMINDER,
  ],
}

export const SELECT_OPTIONS = {
  salutation: [
    { label: 'heer', value: 'heer' },
    { label: 'mevrouw', value: 'mevrouw' },
  ],
  property: [
    { label: 'woning in eigendom', value: 'woning in eigendom' },
    { label: 'gehuurde woning', value: 'gehuurde woning' },
    { label: 'bedrijfspand', value: 'bedrijfspand' },
  ],
  howFindUs: [
    {
      label: 'Via een zoekmachine (bv. Google)',
      value: 'Via een zoekmachine (bv. Google)',
    },
    { label: 'Via een flyer', value: 'Via een flyer' },
    { label: 'Doorverwezen door iemand', value: 'Doorverwezen door iemand' },
    {
      label: 'Via een advertentie in een dagblad',
      value: 'Via een advertentie in een dagblad',
    },
    {
      label: 'Wij hebben al eerder voor u bezwaar gemaakt',
      value: 'Wij hebben al eerder voor u bezwaar gemaakt',
    },
  ],
}
