import styled from 'styled-components'

import { size } from 'src/styles/helpers/size'
import { flex } from 'src/styles/helpers/flex'
import { font } from 'src/styles/helpers/font'
import { margin } from 'src/styles/helpers/margin'
import { padding } from 'src/styles/helpers/padding'
import { mobile } from 'src/styles/helpers/mobile'

const ListItem = styled.li`
  margin-bottom: 6px;
  ${size}
  ${flex}
  ${font}
  ${margin}
  ${padding}
  ${mobile}
`

export default ListItem;
