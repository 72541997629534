import React from "react";

const MainGradient = () => (
    <svg width="0px" height="0px" style={{ position: 'absolute' }}>
        <defs>
            <linearGradient id="mainGradient" x1="-10.35%" y1="24.38%" x2="110.35%" y2="75.62%">
                <stop stop-color="#0000ff" />
                <stop offset="1" stop-color="#2a9bf9" />
            </linearGradient>
        </defs>
    </svg>
);

export default function SvgGradients() {
    return (
        <>
            <MainGradient />
        </>
    );
}
