import React, { memo, useContext, useState } from 'react'

import { Container } from 'src/styles/styled-components'
import SideBarStyled from './styled/SideBarStyled'
import { Gradient } from 'src/components'
import Table from 'src/assets/images/Table'
import FileExport from 'src/assets/images/FileExport'
import { Link } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'
import { useAuth } from 'src/hooks/useAuth'
import Item from 'src/helpers/Item'

const HoverContainer = styled(Container)`
  &:hover {
    background-color: #ECF0F3;
  }
`

interface SideMenuProps {
  onOpen: (isOpen: boolean) => void
}

function SideMenu(props: SideMenuProps) {
  const theme = useContext(ThemeContext)
  const auth = useAuth()
  const [isOpen, setIsOpen] = useState(true)

  function toggle() {
    setIsOpen(!isOpen)
    props.onOpen(!isOpen)
  }

  return (
    <SideBarStyled isOpen={isOpen}>
      {isOpen && (
        <Container>
          <HoverContainer
            margin={{ top: 50, bottom: 4 }}
            flex
            mainAxis="center"
            crossAxis="center"
            height={38}
          >
            <Link
              to="/"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Table />
            </Link>
          </HoverContainer>
          {auth.isAdmin && (
            <HoverContainer flex mainAxis="center" crossAxis="center" height={38}>
              <Link
                to="/bulk_export"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <FileExport />
              </Link>
            </HoverContainer>
          )}
        </Container>
      )}
      {isOpen && (
        <Container
          flex
          mainAxis="center"
          crossAxis="flex-end"
        >
          <HoverContainer width="100%" flex height={38}>
            <Link
              to="/profile"
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Gradient.Cog size={24} />
            </Link>
          </HoverContainer>
        </Container>
      )}
      <Container
        flex
        noGrow={isOpen}
        mainAxis="flex-end"
        crossAxis="flex-end"
        margin={{ bottom: 64, top: 2 }}
        >
        <HoverContainer
          onClick={toggle}
          cursor="pointer"
          flex
          mainAxis="center"
          crossAxis="center"
          height={38}
          padding={{ left: isOpen ? 0 : 26 }}
        >
          {isOpen ? <Gradient.CaretLeft size={16} /> : <Gradient.CaretRight size={16} />}
        </HoverContainer>
      </Container>
    </SideBarStyled>
  )
}

SideMenu.defaultProps = {}

export default memo(SideMenu)
