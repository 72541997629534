import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'
import { font } from 'src/styles/helpers/font'
import { background } from 'src/styles/helpers/background'
import { mobile } from 'src/styles/helpers/mobile'

interface ButtonStyles {
  large?: boolean
  big?: boolean
  xxl?: boolean
  styleType?: string
  width?: number
  type?: 'submit' | 'reset' | 'button'
  height?: number
  active?: boolean
  disabled?: boolean
}

const secondary = () => `
  color: ${defaultTheme.colors.gray5};
  background-color: transparent;
  border: 1px solid ${defaultTheme.colors.opacityGrey};
  &:active {
    background-color: rgba(10, 10, 10, 0.08);
  };
`
const other = () => `
  background-image: linear-gradient(to bottom, #516173, #3b4857);
  &:hover {
    background-image: none;
  };
`

const remove = () => `
  background-color: ${defaultTheme.colors.red1};
  &:hover {
    background-color: ${defaultTheme.colors.darkRed};
  };
`

const light = ({ styleType }: ButtonStyles) => `
  ${
    styleType === 'light'
      ? `
    background-color: ${defaultTheme.colors.lightBlue};
    &:hover {
      background-image: none;
    };
  `
      : ''
  };
`

// TASK: WOZ-621
const type = (props: ButtonStyles) => {
  if (props.styleType === 'secondary') {
    return secondary()
  } else if (props.styleType === 'remove') {
    return remove()
  } else if (props.styleType === 'other') {
    return other()
  } else if (props.styleType === 'light') {
    return light(props)
  } else {
    return `
     &:hover {
      background-color: ${defaultTheme.colors.darkBlue};
     }
    `
  }
}

const size = ({ width, height, large, big, xxl }: ButtonStyles) => `
  ${width ? `width: ${width}px` : ''};
  ${height ? `height: ${height}px` : ''};
  ${large && !width ? `width: 100%` : ''};
  ${big ? `line-height: 34px;` : ''};
  ${xxl ? `padding: 11px 60px;` : ''};
`

const disabledStyles = ({ disabled }: ButtonStyles) => `
  ${
    disabled
      ? `
      cursor: not-allowed;
      border: none;
      background-image: none;
      color: ${defaultTheme.colors.white};
      background-color: ${defaultTheme.colors.gray6} !important;
      `
      : `

      `
  }
`

const ButtonStyled = styled.button`
  display: flex;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  flex-shrink: 0;
  min-width: 86px;
  padding: 8px 11px;
  border-radius: 5px;
  justify-content: center;
  color: ${defaultTheme.colors.white};
  background: ${defaultTheme.colors.main};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  font-family: ${defaultTheme.typography.openSans};

  ${type};
  ${size};
  ${font};
  ${background};
  ${disabledStyles};
  ${mobile};
`

ButtonStyled.displayName = 'ButtonStyled'

export default ButtonStyled
