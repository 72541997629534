import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'

import Container from 'src/styles/styled-components/Container'
import { GridProps } from 'src/interfaces/styles'

const defaultProps = {
  flex: true,
  flexWrap: true,
  theme: defaultTheme,
}

const dynamicStyles = ({ noPadding }: GridProps) => `
  ${
    noPadding
      ? `
    margin: 0
  `
      : `
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  `
  }
`

const Row = styled(Container)`
  ${dynamicStyles}
`

Row.displayName = 'Row'
Row.defaultProps = defaultProps

export default Row
