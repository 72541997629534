import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { generateZIP } from 'src/services/generateZip'

import {
  Input,
  Radio,
  Button,
  Wrapper,
  Checkbox,
  FormElement,
} from 'src/components'

import {
  Text,
  Container,
  TextAreaContainer,
} from 'src/styles/styled-components'

import { useAlert } from 'react-alert'
import { actions } from 'src/redux/user'
import { useActions } from 'redux-exodus'
import { useHistory } from 'react-router-dom'
import { createClient } from 'src/services/api'
import { useDocument } from 'src/hooks/useDocument'

import {
  generateIntroLetter,
  generateQuestionsListPDF,
  generateSignatureExample,
} from 'src/services/pdfGenerator'

interface Step4Props {
  wizard: any
  formData: any
}

function Step4(props: Step4Props) {
  const { formData } = props
  const history = useHistory()
  const alert = useAlert()
  const { getClients } = useActions(actions)
  const { createNewUser, getTemplate } = useDocument(formData)

  const [isSendEmail, setIsSendEmail] = useState(!!formData.email)
  const [isLoading, setIsLoading] = useState(false)
  const [isEmailPersonalize, setIsEmailPersonalize] = useState(false)

  const [_email, setEmail] = useState(formData.email)
  const [_subject, setSubject] = useState(getTemplate?.subject)
  const [_content, setContent] = useState(getTemplate?.content)

  useEffect(() => {
    if (getTemplate?.subject && !_subject) {
      setSubject(getTemplate?.subject)
      setContent(getTemplate?.content)
    }
  }, [getTemplate, _subject])

  async function downloadDocuments() {
    const introDoc = await generateIntroLetter(formData)
    const generator = await generateSignatureExample(formData)

    const address = `${formData.buildingStreet} ${
      formData.buildingHouseNumber
    }${formData.buildingAddition ? ` ${formData.buildingAddition}` : ''} te ${
      formData.buildingPlace
    }`

    const generatorQuestions = await generateQuestionsListPDF(formData, address)

    const zip = [
      { name: 'Klantenbrief.docx', file: await introDoc.base64() },
      { name: 'Machtiging.pdf', file: generator.base64() },
      { name: 'Vragenlijst.pdf', file: await generatorQuestions.base64() },
    ]

    await generateZIP(zip, address).download()
  }

  async function submit() {
    setIsLoading(true)
    const data = await createClient({
      ...formData,
      status: 0,
      fullName: `${formData.firstName} ${formData.lastName}`,
      created_date: moment().toISOString(),
      version: 2,
    })

    if (!formData.email || !isSendEmail) {
      await downloadDocuments()
    } else {
      await createNewUser(data.id, formData)
    }
    getClients()
    setIsLoading(false)
    alert.success('Success')
    history.push('/')
  }

  return (
    // TASK: WOZ-648
    <Wrapper maxWidth={820} isBusy={isLoading} padding={16}>
      <Text size={32}>Stap 4 van 4 - Aanmelding afronden</Text>
      <Container margin={{ bottom: 32 }}>
        <Container margin={{ top: 32, bottom: 10 }}>
          <Text>Machtiging, vragenlijst, aanslag</Text>
        </Container>
        <Container>
          <Container margin={{ bottom: 5 }}>
            <Radio
              value={true}
              disabled={!formData.email}
              label="Per mail versturen"
              name="isSendEmail"
              onCheck={setIsSendEmail}
              checked={isSendEmail}
            />
          </Container>
          <Radio
            value={false}
            name="isSendEmail"
            onCheck={setIsSendEmail}
            label="Uitprinten en versturen"
            checked={!isSendEmail}
          />
        </Container>
      </Container>
      <Container>
        <Container margin={{ bottom: 32 }} flex noGrow crossAxis="center">
          <Text margin={{ right: 16 }}>E-mail wordt verzonden naar:</Text>
          <Input
            name="email"
            size="default"
            value={_email}
            onChange={setEmail}
            disabled={!isEmailPersonalize}
          />
        </Container>
        <Container flex margin={{ bottom: 32 }}>
          <Input
            name="subject"
            value={_subject}
            onChange={setSubject}
            disabled={!isEmailPersonalize}
          />
        </Container>
        <Container flex margin={{ bottom: 32 }}>
          <FormElement
            isWysiwyg
            name="body"
            edit={isEmailPersonalize}
            value={_content}
            formType="textarea"
            onChange={setContent}
          >
            <TextAreaContainer>{ReactHtmlParser(_content)}</TextAreaContainer>
          </FormElement>
        </Container>
        <Container flex margin={{ bottom: 32 }}>
          <Checkbox
            name=""
            disabled={!_email}
            label="E-mail personaliseren"
            checked={isEmailPersonalize}
            onCheck={setIsEmailPersonalize}
          />
        </Container>
        <Container flex margin={{ bottom: 32 }}>
          <Button onClick={submit} large label="Aanmelding afronden" />
        </Container>
      </Container>
    </Wrapper>
  )
}

Step4.defaultProps = {}

export default memo(Step4)
