import React, { memo, useEffect, useState } from 'react'
import {
  Container,
  Text,
  TextAreaContainer,
} from 'src/styles/styled-components'
import {
  Button,
  Checkbox,
  FormElement,
  Input,
  Radio,
  Wrapper,
} from 'src/components'
import {
  ClientRequestInfo,
  EmailTemplate,
  TemplateIds,
} from 'src/interfaces/app'
import {
  editClient,
  getDocumentUrl,
  sendEmail,
} from 'src/services/api'

import { useAlert } from 'react-alert'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { DATE_FORMAT } from 'src/constants'
import ReactHtmlParser from 'react-html-parser'
import { useSelector } from 'react-redux'
import { municipalitiesSelector } from 'src/selectors'

interface SendObjectionProps {
  email: string
  clientInfo: ClientRequestInfo
  updateData: () => void
  templates: EmailTemplate[]
  onRequestSent: (redirectLink: string) => void
  token: string
}

function SendObjection(props: SendObjectionProps) {
  const { templates, email, updateData, onRequestSent, token } = props

  const alert = useAlert()
  const params = useParams<{ id: string }>()
  const municipalities = useSelector(municipalitiesSelector)
  const municipality = municipalities.find(
    (m) => m.id === props.clientInfo?.municipality?.id
  )

  const clientEmailTemplate = templates.find(
    (template) => template.key === TemplateIds.CO_DELIVERY_TO_CLIENT
  )
  const municipalityEmailTemplate = templates.find(
    (template) => template.key === TemplateIds.CO_DELIVERY_TO_MUNICIPALITY
  )

  const [municipalityError, setMunicipalityError] = useState('')
  const [_email, setEmail] = useState(email)
  const [_municipalityEmail, setMunicipalityEmail] = useState(
    municipality?.email
  )
  const [_municipalitySubject, setMunicipalitySubject] = useState(
    municipalityEmailTemplate.subject
  )
  const [_municipalityContent, setMunicipalityContent] = useState(
    municipalityEmailTemplate.content
  )
  const [subject, setSubject] = useState(clientEmailTemplate.subject)
  const [content, setContent] = useState(clientEmailTemplate.content)
  const [isLoading, setIsLoading] = useState(false)
  const [isPersonal, setIsPersonal] = useState(false)
  const [isPersonalClient, setIsPersonalClient] = useState(false)
  const [sendMailType, setSendMailType] = useState(0)

  useEffect(() => {
    updateData()
  }, [])

  useEffect(() => {
    const template = templates.find(
      (template) => template.key === TemplateIds.CO_DELIVERY_TO_CLIENT
    )
    setSubject(template.subject)
    setContent(template.content)
  }, [token, JSON.stringify(templates)])

  async function submit() {
    if (!municipality) {
      alert.error('Geen samenwerkingsverband geselecteerd.')
    } else {
      if ((sendMailType === 1 || sendMailType === 2) && !_municipalityEmail) {
        setMunicipalityError('Geen e-mailadres')
      } else {
        setIsLoading(true)
        if (sendMailType === 0 || sendMailType === 2) {
          await sendEmail({
            to: _email,
            subject,
            body: content,
          })
        }
        if (sendMailType === 1 || sendMailType === 2) {
          const attachments = []

          if (props.clientInfo.objection) {
            const a = await getDocumentUrl(props.clientInfo.objection.file)
            attachments.push({
              content: window.btoa(a),
              filename: 'Nadere motivering.pdf',
              type: 'application/pdf',
              disposition: 'attachment',
              encoding: 'base64',
            })
          }

          await sendEmail({
            to: _municipalityEmail,
            subject: _municipalitySubject,
            body: _municipalityContent,
            attachments,
          })
        }
        await editClient(params.id, {
          status: 4,
          municipality: municipality,
          coReceived: moment().format(DATE_FORMAT),
        })
        onRequestSent(params.id)
        setIsLoading(false)
        await updateData()

        if (sendMailType === 0) {
          alert.success('Bericht succesvol verstuurd.')
        } else if (sendMailType === 1) {
          alert.success(
            'Inhoudelijk bezwaar succesvol verstuurd naar de gemeente / samenwerkingsverband.'
          )
        } else if (sendMailType === 2) {
          alert.success(
            'Inhoudelijk bezwaar succesvol verstuurd naar de klant en gemeente / samenwerkingsverband.'
          )
        } else {
          alert.success('Case succesvol bijgewerkt.')
        }
      }
    }
  }

  const radioButtons = [
    { label: 'Klant', value: 0 },
    { label: 'Gemeente', value: 1 },
    { label: 'Beide', value: 2 },
    { label: 'Geen van beide', value: 3 },
  ]

  return (
    <Wrapper isBusy={isLoading} width="100%" padding={48} height="fit-content">
      <Container margin={{ bottom: 16 }}>
        <Text>(Wie) wil je mailen?</Text>
        <Container flex>
          {radioButtons.map((radioButton) => {
            return (
              <Container margin={{ right: 16 }} key={radioButton.value}>
                <Radio
                  onCheck={setSendMailType}
                  label={radioButton.label}
                  value={radioButton.value}
                  checked={radioButton.value === sendMailType}
                />
              </Container>
            )
          })}
        </Container>
      </Container>
      {(sendMailType === 0 || sendMailType === 2) && (
        <Container margin={{ top: 16 }}>
          <Text size={18} weight="bold">
            E-mail naar de klant
          </Text>
          <Container margin={{ bottom: 32 }} flex noGrow crossAxis="center">
            <Text margin={{ right: 16 }}>Email wordt verzonden naar: </Text>
            <Input
              name="email"
              size="default"
              value={_email}
              onChange={setEmail}
              disabled={!isPersonalClient}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Input
              name="subject"
              value={subject}
              onChange={setSubject}
              disabled={!isPersonalClient}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <FormElement
              isWysiwyg
              name="body"
              edit={isPersonalClient}
              value={content}
              formType="textarea"
              onChange={setContent}
            >
              <TextAreaContainer>{ReactHtmlParser(content)}</TextAreaContainer>
            </FormElement>
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Checkbox
              name="isPersonal"
              label="E-mail personaliseren"
              checked={isPersonalClient}
              onCheck={setIsPersonalClient}
            />
          </Container>
        </Container>
      )}
      {(sendMailType === 1 || sendMailType === 2) && (
        <Container margin={{ top: 24 }}>
          <Text size={18} weight="bold">
            E-mail naar de gemeente
          </Text>
          <Container margin={{ bottom: 32 }} flex noGrow crossAxis="center">
            <Text margin={{ right: 16 }}>Email wordt verzonden naar: </Text>
            <Input
              name="email"
              size="default"
              disabled={!isPersonal}
              value={_municipalityEmail}
              errorText={municipalityError}
              onChange={setMunicipalityEmail}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Input
              name="subject"
              value={_municipalitySubject}
              onChange={setMunicipalitySubject}
              disabled={!isPersonal}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <FormElement
              isWysiwyg
              name="body"
              edit={isPersonal}
              value={_municipalityContent}
              formType="textarea"
              onChange={setMunicipalityContent}
            >
              <TextAreaContainer>
                {ReactHtmlParser(_municipalityContent)}
              </TextAreaContainer>
            </FormElement>
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Checkbox
              name="isPersonal"
              checked={isPersonal}
              onCheck={setIsPersonal}
              label="E-mail personaliseren"
            />
          </Container>
        </Container>
      )}
      <Container flex mainAxis="center">
        {/* TASK: WOZ-633 */}
        <Button big xxl onClick={submit} label={sendMailType === 3 ? 'Opslaan' : 'Versturen'} />
      </Container>
    </Wrapper>
  )
}

export default memo(SendObjection)
