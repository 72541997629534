import React, { memo, useEffect } from 'react'

import { Button, Table } from 'src/components'

import { Col, Container, Row, Text } from 'src/styles/styled-components'
import { ClientRequestInfo } from 'src/interfaces/app'
import { CLIENT_INFO_KEY_MAP } from 'src/constants/clientInfo'
import { hasKey } from 'src/utils/misc'

interface Step3Props {
  buildingInfoFormData: any
  personalInfoFormData: any
  wizard: any
}

function Step3(props: Step3Props) {
  //const { formData, updateFormData, isValid } = props

  useEffect(() => {
    props.wizard.current?.updateStepValidation(3, true)
  }, [])

  function submit() {
    props.wizard.current?.next()
  }

  const columns = [
    {
      name: 'Veld',
      selector: 'label',
    },
    {
      name: 'Waarde',
      selector: 'value',
      cell(row: { name: string; value: string; label: string }) {
        // NOTE: `reasonOfObjection` field is textarea
        // so it can not display data correctly if is just shown in `span` tag
        if (row.name === 'reasonOfObjection') {
          return row.value.split("\n").map((text, index) => (
            <>
              {index !== 0 && <br />}
              {text}
            </>
          ))
        }
        return row.value
      },
      wrap: true,
    },
  ]

  function parseData(
    _data: ClientRequestInfo,
    type: 'realEstateData' | 'taxpayerDetails'
  ) {
    const result: Record<string, any>[] = []

    _data &&
      Object.keys(_data).forEach((i) => {
        if (CLIENT_INFO_KEY_MAP[type][i] && hasKey(_data, i)) {
          result.push({
            name: i,
            value: _data[i],
            label: CLIENT_INFO_KEY_MAP[type][i],
          })
        }
      })
    return result
  }

  return (
    // TASK: WOZ-648
    <Container maxWidth={990} padding={16}>
      <Container>
        <Row>
          <Col>
            <Container margin={{ bottom: 16 }}>
              <Text>Objectgegevens</Text>
            </Container>
            <Table
              data={parseData(props.buildingInfoFormData, 'realEstateData')}
              columns={columns}
            />
          </Col>
          <Col>
            <Container margin={{ bottom: 16 }}>
              <Text>Gegevens belastingplichtige</Text>
            </Container>
            <Table
              data={parseData(props.personalInfoFormData, 'taxpayerDetails')}
              columns={columns}
            />
          </Col>
        </Row>
      </Container>
      <Container margin={{ top: 32 }}>
        <Button
          large
          onClick={submit}
          label="Naar Stap 4 - Aanmelding afronden"
        />
      </Container>
    </Container>
  )
}

Step3.defaultProps = {}

export default memo(Step3)
