import styled from 'styled-components'

type TabContainerProps = {
  fillHeight: boolean
}

const dynamicStyles = ({ fillHeight }: TabContainerProps) => `
  ${fillHeight ? 'height: 100%;' : ''}
`

const TabContainer = styled.div`
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  padding: 18px 0 20px;
  ${dynamicStyles}
`

TabContainer.displayName = 'TabContainer'

export default TabContainer
