import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'

//styled
// TASK: WOZ-571
import { Container } from 'src/styles/styled-components'
import logo from 'src/assets/images/logo.svg'
import { Wrapper } from 'src/components'
import { useSelector } from 'react-redux'
import { signUpHandlerSelector } from 'src/selectors'
import ResetPasswordForm from 'src/forms/authorization/RegistrationForm'
import { getToken } from 'src/services/api'
import { useHistory, useParams } from 'react-router-dom'

function ResetPassword() {
  const theme = useContext(ThemeContext)
  const history = useHistory()
  const params = useParams<{ token: string }>()

  const [isShow, setIsShow] = useState(false)
  const [tokenData, setTokenData] = useState(null)
  const { status } = useSelector(signUpHandlerSelector)

  useEffect(() => {
    getToken(params.token).then((r) => {
      if (!r.docs.length) {
        history.push('/')
      } else {
        setTokenData({ id: r.docs[0].id, ...r.docs[0].data() })
        setIsShow(true)
      }
    })
  }, [])

  return (
    isShow && (
      <Wrapper
        flex
        column
        height="100vh"
        isBusy={status}
        mainAxis="center"
        crossAxis="center"
        mobile={{ padding: { horizontal: 10 } }}
      >
        <Container margin={{ bottom: 32 }}>
          <img width={300} src={logo} alt="WOZBEZWAAR.BIZ" />
        </Container>
        <Container
          flex
          column
          noGrow
          radius={3}
          width={500}
          padding={{ horizontal: 30, vertical: 20 }}
          border={`1px solid ${theme.colors.gray0}`}
          background={theme.colors.gray2}
          mobile={{ maxWidth: 500, width: "unset" }}
        >
          <ResetPasswordForm isRegistration={false} tokenData={tokenData} />
        </Container>
      </Wrapper>
    )
  )
}

ResetPassword.defaultProps = {}

export default {
  component: ResetPassword,
  exact: true,
  public: null,
  path: '/reset_password/:token',
}
