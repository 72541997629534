import React, { memo, useEffect, useRef, useState } from 'react'

import { Segment } from 'src/components'
import { Container, Text } from 'src/styles/styled-components'

import MainLayout from 'src/layouts/Main'
import Step1 from 'src/forms/signInClient/Step1'
import { useForm } from 'src/hooks/useForm'
import { AUTH_FORM } from 'src/forms/authorization/formData'
import {
  SIGN_IN_CLIENT_BUILDING_INFO,
  SIGN_IN_CLIENT_PERSONAL_INFO,
} from 'src/forms/signInClient/formData'
import Step2 from 'src/forms/signInClient/Step2'
import Step3 from 'src/forms/signInClient/Step3'
import Step4 from 'src/forms/signInClient/Step4'

interface SignInClientProps {}

function SignInClient(props: SignInClientProps) {
  const wizard = useRef(null)
  const [stepIndex, setStepIndex] = useState(0)

  const buildingInfoForm = useForm(SIGN_IN_CLIENT_BUILDING_INFO)
  const personalInfoForm = useForm(SIGN_IN_CLIENT_PERSONAL_INFO)

  useEffect(() => {
    if (stepIndex === 1) {
      if (!personalInfoForm.formData.copyAddressFormStep1.value) {
        personalInfoForm.updateFormData('', 'personalPostCode')
        personalInfoForm.updateFormData('', 'personalHouseNumber')
        personalInfoForm.updateFormData('', 'personalAddition')
        personalInfoForm.updateFormData('', 'personalStreet')
        personalInfoForm.updateFormData('', 'personalPlace')
      } else {
        personalInfoForm.updateFormData(
          buildingInfoForm.formValues.buildingPostCode,
          'personalPostCode'
        )
        personalInfoForm.updateFormData(
          buildingInfoForm.formValues.buildingHouseNumber,
          'personalHouseNumber'
        )
        personalInfoForm.updateFormData(
          buildingInfoForm.formValues.buildingAddition,
          'personalAddition'
        )
        personalInfoForm.updateFormData(
          buildingInfoForm.formValues.buildingStreet,
          'personalStreet'
        )
        personalInfoForm.updateFormData(
          buildingInfoForm.formValues.buildingPlace,
          'personalPlace'
        )
      }
    }
  }, [
    personalInfoForm.formData.copyAddressFormStep1.value,
    buildingInfoForm.formValues,
    stepIndex,
  ])

  const steps = [
    { title: 'Gegevens onroerend goed', isValid: true },
    { title: 'Gegevens belastingplichtige' },
    { title: 'Controleren' },
    { title: 'Aanmelding afronden' },
  ]

  function onStepChange(index: number) {
    setStepIndex(index)
  }

  function getFormData() {
    return {
      ...buildingInfoForm.formValues,
      ...personalInfoForm.formValues,
    }
  }

  return (
    <MainLayout hideLeftPanel>
      <Container padding={24} flex column>
        <Segment onChange={onStepChange} isWizard steps={steps} ref={wizard}>
          <Step1
            wizard={wizard}
            isValid={buildingInfoForm.formConfigs.isFormValid}
            formData={buildingInfoForm.formData}
            onSubmit={buildingInfoForm.onSubmit}
            updateFormData={buildingInfoForm.updateFormData}
          />
          <Step2
            wizard={wizard}
            onSubmit={personalInfoForm.onSubmit}
            formData={personalInfoForm.formData}
            updateFormData={personalInfoForm.updateFormData}
            isValid={personalInfoForm.formConfigs.isFormValid}
          />
          <Step3
            wizard={wizard}
            buildingInfoFormData={buildingInfoForm.formValues}
            personalInfoFormData={personalInfoForm.formValues}
          />
          <Step4 wizard={wizard} formData={getFormData()} />
        </Segment>
      </Container>
    </MainLayout>
  )
}

SignInClient.defaultProps = {}

export default {
  component: SignInClient,
  exact: false,
  public: false,
  path: '/sign_in_client',
}
