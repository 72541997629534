import React, { memo, useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/user'
import { useSelector } from 'react-redux'
import { visibleColumnsFormSelector } from 'src/selectors'
import { Col, Container, Row, Text } from 'src/styles/styled-components'
import { Button, Checkbox } from 'src/components'
import {
  columns,
  columnsDefaultData,
} from 'src/pages/dashboard/components/constants'
import Item from 'src/helpers/Item'

interface ColumnFilterProps {
  onClose: () => void
}

function ColumnFilter(props: ColumnFilterProps) {
  const theme = useContext(ThemeContext)
  const { setVisibleColumns } = useActions(actions)
  const _visibleColumns = useSelector(visibleColumnsFormSelector)
  const [visibleColumns, updateFormData] = useState(_visibleColumns)

  function search() {
    props.onClose()
    setVisibleColumns(visibleColumns)
  }

  function selectAll() {
    const selected = {}

    Object.keys(visibleColumns).forEach((key) => {
      // @ts-ignore
      selected[key] = true
    })

    updateFormData(selected)
  }

  function reset() {
    updateFormData(columnsDefaultData())
  }

  function updateFilterForm(value: boolean, name: string) {
    updateFormData({
      ...visibleColumns,
      [name]: value,
    })
  }

  function generateColumns() {
    return columns.map((column) => {
      return (
        <Col xs={3} key={column.name} margin={{ bottom: 16 }}>
          <Checkbox
            name={column.name}
            label={column.label}
            onCheck={updateFilterForm}
            checked={visibleColumns[column.name]}
          />
        </Col>
      )
    })
  }

  return (
    <Container
      border={`1px solid ${theme.colors.gray6}`}
      padding={16}
      margin={{ bottom: 32, horizontal: 16 }}
    >
      <Container>
        <Row>{generateColumns()}</Row>
      </Container>
      <Container
        flex
        crossAxis="center"
        mainAxis="flex-end"
        margin={{ top: 24 }}
      >
        <Item onClick={selectAll} margin={{ right: 16 }}>
          {/* TASK: WOZ-641 */}
          <Text color={theme.colors.main} hoverDecoration="underline">Alles selecteren</Text>
        </Item>
        <Item onClick={reset} margin={{ right: 16 }}>
          <Text color={theme.colors.main} hoverDecoration="underline">Alles deselecteren</Text>
        </Item>
        <Button big label="Toepassen" onClick={search} />
      </Container>
    </Container>
  )
}

ColumnFilter.defaultProps = {}

export default memo(ColumnFilter)
