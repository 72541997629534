import React, { memo, useCallback, useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'

//components
import {
  Input,
  Button,
  Textarea,
  Tab,
  Table,
  Modal,
  Wrapper,
} from 'src/components'

//styled
import { Col, Container, Text } from 'src/styles/styled-components'
import { useSelector } from 'react-redux'
import { textBlocksSelector } from 'src/selectors'
import Item from 'src/helpers/Item'
import { Municipality, TextBlock } from 'src/interfaces/app'
import {
  createTextBlock,
  deleteTextBlock,
  updateTextBlock,
} from 'src/services/api';
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/user'
import { useAlert } from 'react-alert'

interface TekstblokkenProps {}

// TASK: WOZ-636
function TextBlocks(props: TekstblokkenProps) {
  const theme = useContext(ThemeContext)
  const alert = useAlert()
  const textBlocks = useSelector(textBlocksSelector)
  const { getTextBlocks } = useActions(actions)

  //form
  const [currentBlock, setCurrentBlock] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [shortTitle, setShortTitle] = useState('')
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  function showForm() {
    hideEditor()
    setShowCreateForm(true)
  }

  function hideForm() {
    hideEditor()
    setShowCreateForm(false)
  }

  function hideEditor() {
    setShortTitle('')
    setTitle('')
    setContent('')
    setIsEdit(false)
    setCurrentBlock(null)
    setShowCreateForm(false)
  }

  async function _deleteTextBlock({ data }: { data: Municipality }) {
    setIsLoading(true)
    await deleteTextBlock(data.id)
    await getTextBlocks()
    setIsLoading(false)
  }

  function showEditor({ data }: { data: TextBlock }) {
    setShowCreateForm(true)
    setCurrentBlock(data)
    setShortTitle(data?.short_title)
    setTitle(data?.title)
    setContent(data?.content)
    setIsEdit(true)
  }

  async function submit({ data }: { data: TextBlock }) {
    setIsLoading(true)
    const { id, ...otherData } = data

    await updateTextBlock(id, {
      ...otherData,
      title,
      content,
      short_title: shortTitle,
    })
    alert.success('Tekstblok succesvol aangemaakt')
    await getTextBlocks()
    setIsLoading(false)
    hideEditor()
  }

  async function createNewTextBlock({ data }: { data: string }) {
    setIsLoading(true)
    await createTextBlock({
      title,
      content,
      short_title: shortTitle,
      category: data,
    })
    alert.success('Tekstblok succesvol aangemaakt')
    await getTextBlocks()
    setIsLoading(false)
    hideForm()
  }

  const getDataByCategory = useCallback(
    (category: string) => {
      return textBlocks.filter((i) => i.category === category)
    },
    [textBlocks]
  )

  const renderForm = useCallback(
    (category: string) => {
      return (
        <Col xs={12}>
          <Container flex margin={{ bottom: 10 }}>
            <Container margin={{ right: 10 }} grow="unset" crossAxis="center">
              <Text color={theme.colors.gray5}>Korte titel:</Text>
            </Container>
            <Input
              name="short_title"
              value={shortTitle}
              onChange={setShortTitle}
            />
          </Container>
          <Container flex margin={{ bottom: 20 }}>
            <Container margin={{ right: 52 }} grow="unset" crossAxis="center">
              <Text color={theme.colors.gray5}>Titel:</Text>
            </Container>
            <Input name="title" value={title} onChange={setTitle} />
          </Container>
          <Container flex margin={{ bottom: 20 }}>
            <Container margin={{ right: 30 }}>
              <Text color={theme.colors.gray5}>Inhoud:</Text>
            </Container>
            <Textarea
              isWysiwyg
              name="content"
              value={content}
              onChange={setContent}
            />
          </Container>
          <Container flex crossAxis="center" mainAxis="flex-end">
            <Item
              margin={{ right: 16 }}
              onClick={hideForm}
              hoverColor={theme.colors.red0}
            >
              <Text color={theme.colors.red1} hoverColor={theme.colors.red0}>
                Annuleren
              </Text>
            </Item>
            <Button
              big
              label="Opslaan"
              data={isEdit ? currentBlock : category}
              onClick={isEdit ? submit : createNewTextBlock}
            />
          </Container>
        </Col>
      )
    },
    [showCreateForm, title, shortTitle, content]
  )

  function renderTextBlocks(category: string) {
    const data = getDataByCategory(category)

    const columns = [
      {
        name: 'Korte titel',
        selector: 'short_title',
      },
      {
        name: 'Aanpassen',
        cell: (raw: TextBlock) => (
          <Container flex mainAxis="flex-end">
            <Button
              large
              width={150}
              data={raw}
              height={28}
              label="Aanpassen"
              icon="PencilSquare"
              onClick={showEditor}
              iconProps={{ size: 16, color: theme.colors.white }}
            />
          </Container>
        ),
      },
      {
        name: 'Verwijderen',
        cell: (raw: TextBlock) => (
          <Button
            large
            data={raw}
            height={28}
            icon="Trash"
            styleType="remove"
            label="Verwijderen"
            onClick={_deleteTextBlock}
            iconProps={{ size: 16, color: theme.colors.white }}
          />
        ),
      },
    ]

    return (
      <Container flex>
        <Container minWidth={540} maxWidth={780}>
          {/* TASK: WOZ-640 */}
          <Table title={null} data={data} columns={columns} highlightOnHover={false} />
        </Container>
      </Container>
    )
  }

  const tabs = [
    { text: 'Tekstblokken beslissing ontvangen' },
    { text: 'Tekstblokken inhoudelijke bezwaar export' },
  ]

  return (
    <Wrapper isBusy={isLoading}>
      <Container
        flex
        mainAxis="space-between"
        crossAxis="flex-start"
        margin={{ bottom: 30 }}
      >
        <Tab fillHeight titles={tabs} onTabChange={setCurrentTabIndex}>
          {renderTextBlocks('COExport')}
          {renderTextBlocks('FOExport')}
        </Tab>
        <Button
          big
          // TASK: WOZ-639
          name="name"
          icon="Plus"
          iconProps={{ iconPack: "faSolid", color: "white", size: 16 }}
          onClick={showForm}
          label="Nieuwe tekstblok aanmaken"
        />
      </Container>
      <Modal
        isShow={showCreateForm}
        title={currentBlock?.title}
        titleProps={{ padding: { top: 24, right: 28, left: 32 } }}
        onRequestClose={setShowCreateForm}
      >
        <Container
          margin={{ bottom: 16 }}
          padding={{ horizontal: 24, vertical: 16 }}
        >
          {renderForm(currentTabIndex === 0 ? 'COExport' : 'FOExport')}
        </Container>
      </Modal>
    </Wrapper>
  )
}

export default memo(TextBlocks)
