import { useEffect, useRef, useState } from 'react'
import {
  createAuthUser,
  createToken,
  createUser,
  isAuthUserExist,
  isUserExist,
  sendEmail,
} from 'src/services/api'
import { generateString } from 'src/utils/misc'
import {
  ClientRequestInfo,
  TemplateCategoryIds,
  TemplateIds,
} from 'src/interfaces/app'
import { useEmailTemplate } from 'src/email'
import { EMAIL_VARIABLES } from 'src/email/misc'
import { BASE_URL } from 'src/constants'
import {
  generateQuestionsListPDF,
  generateSignatureExample,
} from 'src/services/pdfGenerator'
import { DOCUMENT_NEW_USER } from 'src/constants/emailContents'

interface UseDocumentProps {
  getTemplate: {
    subject: string
    content: string
  }
  createNewUser: (
    docId: string,
    formData: ClientRequestInfo,
    isDefined?: boolean
  ) => void
}

export function useDocument(_data?: ClientRequestInfo): UseDocumentProps {
  const token = useRef(generateString(64)).current
  const verifyToken = useRef(generateString(64)).current

  const { getTemplate } = useEmailTemplate()
  const [clientInfo, setClientInfo] = useState(_data)
  const [template, setTemplate] = useState(null)

  useEffect(() => {
    if (!clientInfo) {
      setClientInfo(_data)
    }
  }, [_data])

  useEffect(() => {
    ;(async function () {
      const _hasAccount = await isAuthUserExist(clientInfo?.email)
      if (!template?.subject) {
        setTemplate(getTemplateData(null, _hasAccount))
      }
    })()
  }, [clientInfo, template])

  function getTemplateData(docId: string = null, hasAccount: boolean) {
    return getTemplate(
      TemplateCategoryIds.WELCOME_MESSAGE,
      hasAccount
        ? TemplateIds.EXISTING_USER_PORTAL
        : TemplateIds.PASSWORD_NOT_CREATED_NOT_SIGNED_PORTAL,
      EMAIL_VARIABLES(
        { id: docId, ...clientInfo },
        {
          RESET_PASSWORD_LINK: `${BASE_URL}registration/${token}`,
        }
      )
    )
  }

  async function createNewUser(
    docId: string,
    formData: ClientRequestInfo,
    isDefined = true
  ) {
    const isExist = await isUserExist(formData.email)
    const isAuthExist = await isAuthUserExist(formData.email)

    const allFilesOk = clientInfo?.assessment?.status === "approved" &&
      clientInfo?.authorization?.status === "approved" &&
      clientInfo?.questionsDoc?.status === "approved"

    const { content, subject } = getTemplate(
      TemplateCategoryIds.WELCOME_MESSAGE,
      isAuthExist
        ? TemplateIds.EXISTING_USER_PORTAL
        : TemplateIds.PASSWORD_NOT_CREATED_NOT_SIGNED_PORTAL,
      EMAIL_VARIABLES(
        { id: docId, ...clientInfo },
        {
          RESET_PASSWORD_LINK: `${BASE_URL}registration/${token}?clientId=${
            docId}&allFilesOk=${allFilesOk}`,
        }
      )
    )
    const attachments = []

    if (!clientInfo?.questionsDoc) {
      const address = `${clientInfo.buildingStreet} ${
        clientInfo.buildingHouseNumber
      }${
        clientInfo.buildingAddition ? ` ${clientInfo.buildingAddition}` : ''
      } te ${clientInfo.buildingPlace}`

      const generatorQuestions = await generateQuestionsListPDF(
        clientInfo,
        address
      )
      const generatorQuestionsDOCX = await generateQuestionsListPDF(
        clientInfo,
        address,
        null,
        'DOCX'
      )

      attachments.push({
        content: generatorQuestions.base64(),
        filename: 'Vragenlijst.pdf',
        type: 'application/pdf',
        disposition: 'attachment',
        encoding: 'base64',
      })

      attachments.push({
        content: await generatorQuestionsDOCX.base64(),
        filename: 'Vragenlijst.docx',
        type:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        disposition: 'attachment',
        encoding: 'base64',
      })
    }

    if (!clientInfo?.authorization) {
      const generatorSignature = await generateSignatureExample(clientInfo)

      attachments.push({
        content: generatorSignature.base64(),
        filename: 'Machtiging.pdf',
        type: 'application/pdf',
        disposition: 'attachment',
        encoding: 'base64',
      })
    }

    if (!isAuthExist) {
      if (!isExist) {
        await createUser({
          first_name: formData.firstName,
          last_name: formData.lastName,
          role: 'customer',
          salutation: formData.salutation,
          email: formData?.email ?? '',
          version: 2,
        })
      }
      //await createAuthUser(formData?.email)

      if (formData?.email) {
        if (isDefined) {
          await createToken({
            token,
            email: formData.email,
            type: 'reset_password',
            redirectLink: `/client_detail/${docId}/upload_files/wizard`,
          })

          await sendEmail({
            subject,
            body: content,
            to: formData.email,
            attachments,
          })
        } else {
          await createToken({
            token,
            email: formData.email,
            type: 'change_email_client_info',
            redirectLink: `/client_detail/${docId}`,
          })
          await createToken({
            token: verifyToken,
            hasUser: false,
            clientInfoId: docId,
            email: formData.email,
            type: 'change_email_client_info',
            redirectLink: `/registration/${token}`,
          })

          await sendEmail({
            subject: 'Emailadres wijzigen',
            body: DOCUMENT_NEW_USER(
              clientInfo,
              `${BASE_URL}verify/${verifyToken}`
            ),
            to: formData.email,
          })
        }
      }
    } else {
      if (formData?.email) {
        await sendEmail({
          subject,
          body: content,
          to: formData.email,
          attachments,
        })
      }
    }
  }

  return {
    createNewUser,
    getTemplate: template,
  }
}
