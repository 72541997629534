import React, { memo } from 'react'
import * as bootstrap from '@styled-icons/bootstrap'
import * as faSolid from '@styled-icons/fa-solid'
import { hasKey } from 'src/utils/misc'
import { StyledIcon, StyledIconProps } from '@styled-icons/styled-icon'

const iconPacks = {
  bootstrap,
  faSolid,
}

type IconPack = keyof typeof iconPacks;
export interface IconProps extends StyledIconProps {
  iconName: string
  iconPack?: IconPack
}

const Icon = (props: IconProps) => {
  const { iconName, iconPack = 'bootstrap', ...otherProps } = props
  const icons = iconPacks[iconPack];
  const Component: StyledIcon = hasKey(icons, iconName) ? icons[iconName] : null

  return Component && <Component {...otherProps} />
}

export default memo(Icon)
