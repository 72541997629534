import { createAction, createReducer } from 'redux-exodus'
import Storage from 'src/services/storage'
import {
  resetPassword,
  signIn,
  signOut,
  signUp,
} from 'src/services/api'
import { setUser } from '@sentry/minimal'

const defaultState = {
  signInHandler: {},
  signUpHandler: {},
  resetPasswordHandler: {},
  isLoggedIn: Storage.get('isLoggedIn'),
}

function onSignSuccess({ result }: { result: any }) {
  if (result?.user) {
    Storage.set('isLoggedIn', true)
    setUser({
      id: result.user?.uid,
      email: result.user?.email,
      username: result.user?.displayName,
    })
  }
}

const signInAction = createAction({
  name: 'SIGN_IN',
  apiCall: signIn,
  key: 'signInHandler',
  handleResponse: (result) => result,
  onSuccess: onSignSuccess,
})

const signUpAction = createAction({
  name: 'SIGN_UP',
  apiCall: signUp,
  key: 'signUpHandler',
  handleResponse: (result) => result,
  onSuccess: onSignSuccess,
})

const setIsLoggedInAction = createAction({
  key: 'isLoggedIn',
  name: 'SET_IS_LOGGED_IN',
})

const resetPasswordAction = createAction({
  name: 'RESET_PASSWORD',
  apiCall: resetPassword,
  key: 'resetPasswordHandler',
  handleResponse: (result) => {
    return result
  },
})

const signOutAction = createAction({
  name: 'SIGN_OUT',
  key: 'isLoggedIn',
  apiCall: signOut,
  onSuccess: () => {
    Storage.remove('isLoggedIn')
    setUser(null)
  },
  handleResponse: () => false,
})

export const actions = {
  signIn: signInAction,
  signUp: signUpAction,
  signOut: signOutAction,
  setIsLoggedIn: setIsLoggedInAction,
  resetPassword: resetPasswordAction,
}

export default createReducer(actions, defaultState)
