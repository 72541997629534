import styled, { CSSProperties } from 'styled-components'

const dynamicStyles = (props: CSSProperties) => ({ ...props })

const Image = styled.img`
  ${dynamicStyles}
`

Image.displayName = 'Image'

export default Image
