import styled from 'styled-components'
import DeleteContainer from './DeleteContainer'
import defaultTheme from 'src/styles/theme'

//mediaQuery
import media from 'src/styles/utils/media'

// TASK: WOZ-574
const ImageContainer = styled.div`
  width: 75px;
  height: 100px;
  position: relative;
  overflow: hidden;
  &:hover ${DeleteContainer} {
    opacity: 1;
    bottom: 0;
  }
  ${media.lessThan(defaultTheme.breakpoints.small)`
    width: 75px;
    height: 90px;
    padding: 10px
  `};
`

ImageContainer.displayName = 'ImageContainer'

export default ImageContainer
