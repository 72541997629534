import React, { memo, useContext, useState, useMemo } from 'react'
import { ClientRequestInfo, FileData } from 'src/interfaces/app'
import { Checkbox, Icon, Uploader, Wrapper } from 'src/components'
import { Container, Text } from 'src/styles/styled-components'
import { deleteFile, editClient, uploadFiles } from 'src/services/api'
import { ThemeContext } from 'styled-components'
import { useAuth } from 'src/hooks/useAuth'
import { isArray } from 'src/utils/misc'
import AlertModal from 'src/components/AlertModal'

interface FileUploaderProps {
  name?: string
  label: string
  icon?: string
  isView?: boolean
  multiple?: boolean
  maxFiles?: number
  data?: ClientRequestInfo
  fileData?: FileData
  clientInfoId?: string
  onUpdate?: () => void
}

function FileUploader(props: FileUploaderProps) {
  const theme = useContext(ThemeContext)
  const {
    label,
    data,
    fileData,
    isView,
    clientInfoId,
    name,
    multiple,
    maxFiles,
    onUpdate,
    icon = 'CloudArrowUp',
  } = props
  const auth = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isModalShow, setIsModalShow] = useState(false)

  async function deleteDocument() {
    setIsLoading(true)

    if (name === 'photos') {
      await editClient(clientInfoId, {
        [name]:
          data.photos.length === 1
            ? null
            : data.photos.filter((i) => i.name !== fileData.name),
      })
    } else {
      if (name === 'authorization') {
        await editClient(clientInfoId, {
          [name]: null,
          signature: null,
        })
        try {
          await deleteFile(data.signature.name)
        } catch (e) {}
      } else {
        await editClient(clientInfoId, {
          [name]: null,
        })
      }
    }
    await deleteFile(fileData.name)
    await onUpdate()
    setIsLoading(false)
    setIsModalShow(false)
  }

  async function onDropFile(file: File | File[], name: string) {
    setIsLoading(true)

    if (isArray(file)) {
      const imageUploads: any[] = []
      for (const f of file) {
        imageUploads.push(uploadFiles(f, name, 'approved'))
      }
      Promise.all(imageUploads).then(async (result) => {
        const oldData = data?.photos || null

        const updatedData: any = {}

        if (result.length) {
          result.forEach((item) => {
            if (updatedData[item.name] && isArray(updatedData[item.name])) {
              updatedData[item.name].push(item.file)
            } else if (item.name === 'photos') {
              updatedData[item.name] = [item.file]
            }
          })
        }
        const _photos =
          updatedData?.photos && oldData
            ? [...oldData, ...updatedData?.photos]
            : updatedData?.photos && !oldData
            ? updatedData?.photos
            : oldData

        await editClient(clientInfoId, {
          photos: _photos,
        })
        setTimeout(async () => {
          await onUpdate()
          setIsLoading(false)
        }, 800)
      })
    } else {
      if (name === 'photos') {
        const _d = !!data?.photos ? data.photos : []
        const photo = await uploadFiles(file, name, 'approved')
        await editClient(clientInfoId, {
          photos: [..._d, photo.file],
        })
      } else {
        await uploadFiles(file, name, 'approved', clientInfoId)
        setTimeout(async () => {
          await onUpdate()
          setIsLoading(false)
        }, 800)
      }
    }
  }

  const color =
    fileData?.status === 'approved' ? theme.colors.green1 : theme.colors.red1
  const prefixIcon = useMemo(() => !isView && !!label && (
    <Checkbox
      isCircle
      indeterminate={fileData?.status !== 'approved'}
      checked={fileData?.status === 'approved'}
      color={color}
      padding={{ right: 10 }}
    />
  ), [isView, label, fileData?.status])
  return (
    <Wrapper
      noShrink
      column={false}
      isBusy={isLoading}
      maxWidth={216}
      margin={{ bottom: 5 }}
      // TASK: WOZ-631
      mainAxis="space-between"
      crossAxis="center"
      cursor={!!fileData?.file ? 'pointer' : 'default'}
      hoverColor={!!fileData?.file && theme.colors.main}
    >
      {fileData?.file ? (
        // TASK: WOZ-633
        <Container flex crossAxis="center">
          {prefixIcon}
          <Text target="_blank" as="a" hoverDecoration="underline" href={fileData.file}>
            {label}
          </Text>
        </Container>
      ) : (
        <Container flex crossAxis="center">
          {prefixIcon}
          <Text>{label}</Text>
        </Container>
      )}
      {auth.isAdmin && !isView ? (
        fileData ? (
          <Container padding={{ left: 8 }}>
            <Icon
              size={24}
              iconName="X"
              cursor="pointer"
              onClick={() => {
                setIsModalShow(true)
              }}
            />
          </Container>
        ) : (
          <Uploader multiple={multiple} name={name} maxFiles={maxFiles} onDrop={onDropFile}>
            {(open) => (
              <Container padding={{ left: 10 }}>
                <Icon
                  size={24}
                  onClick={open}
                  cursor="pointer"
                  iconName={icon}
                  color={theme.colors.gray5}
                />
              </Container>
            )}
          </Uploader>
        )
      ) : (
        <Container />
      )}
      <AlertModal
        onRemove={deleteDocument}
        onCancel={setIsModalShow}
        isModalShow={isModalShow}
        text="Wilt u het bestand verwijderen?"
      />
    </Wrapper>
  )
}

export default memo(FileUploader)
