import React, { memo, useEffect, useState } from 'react'

import Select from '../select'
import Item from 'src/helpers/Item'
import { Container, Text } from 'src/styles/styled-components'
import Input from 'src/components/input'
import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'
import Checkbox from 'src/components/checkbox'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/user'
import { useSelector } from 'react-redux'
import { tableFiltersSelector } from 'src/selectors'
import Icon from 'src/components/icon'
import { hasColumnFilter, isObject } from 'src/utils/misc'
import { DatePicker } from 'src/components'

export type FilterItem = {
  label: string
  value: string
}

export interface TableTitleProps {
  title: string
  name: string
  isDate?: boolean
  hideSearch?: boolean
  filters?: string[] | FilterItem[]
}

// TASK: WOZ-625
const DropDownContainer = styled(Container)`
  box-shadow: 0px 4px 20px rgba(68, 74, 81, 0.5);
  background: ${defaultTheme.colors.white};
`

const DateWrapper = styled(Container)`
  input {
    color: ${defaultTheme.colors.main} !important;
  }
`

const MenuItem = styled(Container)`
  height: 36px;
  cursor: pointer;
  padding: 6px 14px;
  background: ${(props) => {
    return `${props?.odd ? defaultTheme.colors.white : '#f1f4f8'}`
  }};
  &:hover {
    background-color: #c5d0de;

    > div > span {
      color: ${defaultTheme.colors.main};
    }
  }
`

function TableTitle(props: TableTitleProps) {
  const { filters } = props
  const [isOpen, setIsOpen] = useState(false)
  const { setTableFilters } = useActions(actions)
  const tableFilters = useSelector(tableFiltersSelector)
  const [_tableFilters, _setTableFilters] = useState(tableFilters)

  useEffect(() => {
    if (!isOpen) {
      setTableFilters(_tableFilters)
    }
  }, [isOpen])

  function selectAll() {
    const _data = {}

    if (filters) {
      filters.forEach((i: string | FilterItem) => {
        const key = isObject(i) ? i.value : i
        // @ts-ignore
        _data[key] = true
      })
    }

    _setTableFilters({
      ..._tableFilters,
      [props.name]: {
        ..._tableFilters[props.name],
        filters: _data,
      },
    })
  }

  function deSelectAll() {
    _setTableFilters({
      ..._tableFilters,
      [props.name]: {
        ..._tableFilters[props.name],
        filters: {},
      },
    })
  }

  function updateSorting(data: 'asc' | 'desc') {
    _setTableFilters({
      ..._tableFilters,
      [props.name]: {
        ..._tableFilters[props.name],
        sorting: data,
      },
    })
  }

  function updateFilterData(value: boolean, name: string) {
    if (!value) {
      const _newData = { ..._tableFilters }
      const _f = { ..._tableFilters[props.name]?.filters }
      delete _f[name]

      if (!Object.keys(_f).length) {
        delete _newData[props.name]
      } else {
        _newData[props.name] = {
          ..._newData[props.name],
          filters: _f,
        }
      }

      _setTableFilters(_newData)
    } else {
      _setTableFilters({
        ..._tableFilters,
        [props.name]: {
          ..._tableFilters[props.name],
          filters: {
            ..._tableFilters[props.name]?.filters,
            [name]: value,
          },
        },
      })
    }
  }

  function handleSearch(text: string) {
    if (!text) {
      const _newData = { ..._tableFilters }
      delete _newData[props.name]

      _setTableFilters(_newData)
    } else {
      _setTableFilters({
        ..._tableFilters,
        [props.name]: {
          ..._tableFilters[props.name],
          searchText: text,
        },
      })
    }
  }

  function renderFilters() {
    const _data = _tableFilters[props.name]?.filters || {}

    if (filters) {
      // @ts-ignore
      return filters.map((filter: string | FilterItem, index: number) => {
        const [itemHover, setItemHover] = useState(false);
        const key = isObject(filter) ? filter.value : filter
        const label = isObject(filter) ? filter.label : filter

        return (
          <Item onHover={setItemHover}>
          <MenuItem key={key} odd={index % 2}>
            <Checkbox
              onCheck={updateFilterData}
              name={key}
              label={label}
              checked={_data[key]}
              selected={_data[key] || itemHover}
            />
          </MenuItem>
          </Item>
        )
      })
    }

    return null
  }

  function renderCustomOptions() {
    const isAsc = _tableFilters[props.name]?.sorting === 'asc'
    const isDesc = _tableFilters[props.name]?.sorting === 'desc'
    return (
      <DropDownContainer width={280}>
        <MenuItem odd>
          <Item
            flex
            mainAxis="space-between"
            crossAxis="center"
            data="asc"
            onClick={updateSorting}
          >
            <Text
              weight="bold"
              color={
                isAsc ? defaultTheme.colors.main : defaultTheme.colors.gray5
              }
            >
              Sorteren A {'<'} Z
            </Text>
            {isAsc && (
              <Icon
                iconName="Check"
                color={defaultTheme.colors.main}
                size={20}
              />
            )}
          </Item>
        </MenuItem>
        <MenuItem>
          <Item
            flex
            mainAxis="space-between"
            crossAxis="center"
            data="desc"
            onClick={updateSorting}
          >
            <Text
              weight="bold"
              color={
                isDesc ? defaultTheme.colors.main : defaultTheme.colors.gray5
              }
            >
              Sorteren Z {'>'} A
            </Text>
            {isDesc && (
              <Icon
                iconName="Check"
                color={defaultTheme.colors.main}
                size={20}
              />
            )}
          </Item>
        </MenuItem>
        {!!props.filters && (
          <MenuItem odd>
            <Container flex mainAxis="space-between">
              <Item onClick={selectAll}>
                {/* TASK: WOZ-625 */}
                <Text
                  size={13}
                  weight="bold"
                  hoverColor={defaultTheme.colors.darkBlue}
                  color={defaultTheme.colors.main}
                >
                  Selecteer alles
                </Text>
              </Item>
              <Item onClick={deSelectAll}>
                <Text
                  size={13}
                  weight="bold"
                  hoverColor={defaultTheme.colors.darkBlue}
                  color={defaultTheme.colors.main}
                >
                  Deselecteer alles
                </Text>
              </Item>
            </Container>
          </MenuItem>
        )}
        {!props.hideSearch && (
          <Container padding={4}>
            {props.isDate ? (
              <DateWrapper>
                <DatePicker
                  name={props.name}
                  onChange={handleSearch}
                  placeholder="Datum"
                  value={_tableFilters[props.name]?.searchText}
                />
              </DateWrapper>
            ) : (
              <Input
                name={props.name}
                onChange={handleSearch}
                placeholder="Search"
                color={defaultTheme.colors.main}
                value={_tableFilters[props.name]?.searchText}
              />
            )}
          </Container>
        )}
        <Container maxHeight={288} overflowY="auto">
          {renderFilters()}
        </Container>
      </DropDownContainer>
    )
  }
  const _hasFilter = hasColumnFilter(tableFilters, props.name)
  return (
    <Select onShow={setIsOpen} renderCustomOption={renderCustomOptions}>
      {({ showOptions }) => (
        <Item onClick={showOptions}>
          <Text margin={{ right: 6 }}>{props.title}</Text>
          {/* TASK: WOZ-626 */}
          <Container height={12} width={12} style={{ display: "inline-block" }}>
            {(_hasFilter || tableFilters[props.name]?.sorting) ? (
              <Icon
                size={12}
                color="yellow"
                iconPack="faSolid"
                iconName="ChevronCircleDown"
              />
            ) : (
              <Icon
                size={10}
                iconPack="faSolid"
                iconName="ChevronDown"
              />
            )}
          </Container>
        </Item>
      )}
    </Select>
  )
}

TableTitle.defaultProps = {}

export default memo(TableTitle)
