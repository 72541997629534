import React, { useContext, useEffect, useState } from 'react'
import 'react-dates/initialize'
import { OpenDirectionShape, SingleDatePicker } from 'react-dates';
import moment from 'moment'
import 'react-dates/lib/css/_datepicker.css'
import DatePickerWrapper from './styled/DatePickerWrapper'
import { Container, Text } from 'src/styles/styled-components'
import { ThemeContext } from 'styled-components'
import { Icon } from 'src/components'
import InputContainer from 'src/components/datePicker/styled/InputContainer'
import Item from 'src/helpers/Item'

interface DatePickerProps {
  name: string
  label?: string
  errorText?: string
  placeholder?: string
  format?: string
  openDirection?: OpenDirectionShape
  disabled?: boolean
  clearable?: boolean
  value: string | moment.Moment
  size?: 'default' | 'large' | 'full'
  onChange?: (value: string, name: string) => void
  isOutsideRange?: () => boolean
  withPortal?: boolean
  /** WARN: this function was added to avoid a bug: remove if possible */
  handleFocusChange?: (
    focused: boolean,
    setFocused: (focused: boolean) => void,
  ) => void
}

export default function DatePicker(props: DatePickerProps) {
  const theme = useContext(ThemeContext)
  const {
    value,
    onChange,
    name,
    label,
    errorText,
    disabled,
    clearable,
    size = 'full',
    openDirection = 'up',
    format = 'DD-MM-YYYY',
    handleFocusChange,
    ...otherProps
  } = props
  const [focused, setFocused] = useState(false)
  const [date, setDate] = useState(getDefaultDate())

  useEffect(() => {
    setDate(value ? moment(value, format) : null)
  }, [value, disabled])

  function getDefaultDate() {
    if (typeof value === 'string') {
      return moment(value, format)
    } else {
      return value
    }
  }

  function handleChange(date: moment.Moment) {
    if (onChange) {
      onChange(date?.format(format), name)
    }
    setDate(date)
  }

  function clearChange() {
    if (onChange) {
      onChange(undefined, name)
    }
    setDate(undefined)
  }

  return (
    <Container flex column>
      {label ? (
        <Container margin={{ bottom: 5 }}>
          <Text
            size={14}
            weight="bold"
            lineHeight={21}
            color={theme.colors.label1}
            font={theme.typography.openSans}
          >
            {label}
          </Text>
        </Container>
      ) : null}
      <InputContainer disabled={disabled} isFocus={focused} size={size}>
        <Item
          margin={{ right: 12 }}
          onClick={() => {
            if (!disabled) {
              setFocused(true)
            }
          }}
        >
          <Icon iconName="Calendar3" size={20} color={theme.colors.gray5} />
        </Item>
        <DatePickerWrapper>
          <SingleDatePicker
            id={name}
            date={date}
            disabled={disabled}
            focused={focused}
            numberOfMonths={1}
            displayFormat={format}
            openDirection={openDirection}
            onDateChange={handleChange}
            isOutsideRange={() => false}
            placeholder="Datum"
            onFocusChange={({ focused }) => handleFocusChange
              ? handleFocusChange(focused, setFocused)
              : setFocused(focused)
            }
            {...otherProps}
          />
        </DatePickerWrapper>
        {date && clearable && (
          <Item onClick={clearChange}>
            <Icon iconName="X" size={20} color={theme.colors.gray5} cursor="pointer" />
          </Item>
        )}
      </InputContainer>
      {errorText ? (
        <Container margin={{ top: 5 }}>
          <Text size={14} lineHeight={21} color={theme.colors.red1}>
            {errorText}
          </Text>
        </Container>
      ) : null}
    </Container>
  )
}
