// TASK: WOZ-571
import { useMemo } from 'react'
import { useScreenSize } from 'src/hooks/useScreenSize'

import type { AlertCustomOptions } from 'react-alert'

export function useAlertOptions(timeout = 15000) {
    const isMobile = useScreenSize()
    const alertOptions: AlertCustomOptions = useMemo(() => isMobile ? {
        timeout
    } : {}, [isMobile])

    return alertOptions;
}
