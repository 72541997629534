// TASK: WOZ-571
import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

//styled
import { Container, Text } from 'src/styles/styled-components'
import logo from 'src/assets/images/logo.svg'
import ForgotPasswordForm from 'src/forms/authorization/ForgotPasswordForm'

function ForgotPassword() {
  const theme = useContext(ThemeContext)
  const { state } = useLocation<{ title?: string; desc?: string }>()
  const [isRequestSent, setIsRequestSent] = useState(false)

  return (
    <Container
      column
      flex
      mainAxis="center"
      crossAxis="center"
      height="100vh"
      mobile={{ padding: { horizontal: 10 } }}
    >
      <Container margin={{ bottom: 32 }}>
        <img width={300} src={logo} alt="WOZBEZWAAR.BIZ" />
      </Container>
      <Container
        flex
        column
        noGrow
        radius={3}
        width={500}
        padding={{ horizontal: 30, vertical: 20 }}
        mobile={{ maxWidth: 500, width: "unset" }}
        border={`1px solid ${theme.colors.gray0}`}
        background={theme.colors.gray2}
      >
        <Container flex mainAxis="center" margin={{ bottom: 8 }}>
          <Text size={25} color={theme.colors.gray5} mobile={{ size: 24 }}>
            {state?.title ? state?.title : 'Wachtwoord wijzigen'}
          </Text>
        </Container>
        <Container flex mainAxis="center" margin={{ bottom: 20 }}>
          <Text size={16} color={theme.colors.gray5} mobile={{ size: 14 }}>
            {state?.desc
              ? state?.desc
              : 'Voer hieronder uw e-mailadres in om uw wachtwoord opnieuw in te stellen en klik op de knop hieronder. Vervolgens ontvangt u een e-mail van ons. Volg de aanwijzingen in de e-mail om uw wachtwoord aan te passen.'}
          </Text>
        </Container>
        <ForgotPasswordForm
          isRequestSent={isRequestSent}
          setIsRequestSent={setIsRequestSent}
        />
      </Container>
    </Container>
  )
}

export default {
  component: ForgotPassword,
  exact: false,
  public: null,
  path: '/forgot-password',
}
