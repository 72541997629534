import React, { memo, ReactNode, useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { ThemeContext } from 'styled-components'

// components
import Icon from '../icon'

// styled
import { Text, Container } from 'src/styles/styled-components'
import BackdropStyled from './styled/BackdropStyled'
import ModalContainer from './styled/ModalContainer'
import { ContainerStyles } from 'src/interfaces/styles'

// TASK: WOZ-574
interface ModalTitleProps {
  size?: number | string
  color?: string
  weight?: string
  textAlign?: string
  hideClose?: boolean
  padding?: ContainerStyles["padding"]
}

interface ModalProps {
  title?: string
  isShow: boolean
  modalSize?: 'xs' | 'small' | 'medium' | 'large'
  children: ReactNode | ReactNode[]
  onRequestClose: (isShow: boolean) => void
  renderFooter?: () => ReactNode
  background?: string
  boxShadow?: string
  titleProps?: ModalTitleProps
}

const Modal = (props: ModalProps) => {
  const theme = useContext(ThemeContext)
  const [container, setContainer] = useState<HTMLElement | null>(null)
  const [isRender, setIsRender] = useState(false)

  const { isShow, onRequestClose, title, modalSize = 'large', renderFooter } = props

  useEffect(() => {
    if (isShow) {
      document.body.style.cssText = 'overflow: hidden'
      createContainer()
    } else {
      document.body.style.cssText = ''
      removeContainer()
    }
  }, [isShow])

  useEffect(() => {
    if (container) {
      container.setAttribute('id', 'modal')
      document.body.appendChild(container)
    }
  }, [container])

  function onClose() {
    onRequestClose(false)
  }

  function createContainer() {
    if (!isRender) {
      setContainer(document.createElement('div'))

      setIsRender(true)
    }
  }

  function removeContainer() {
    if (container) {
      document.body.removeChild(container)
      setContainer(null)
      setIsRender(false)
    }
  }

  function _renderHeader(titleProps: ModalTitleProps) {
    return (
      <Container
        flex
        noGrow
        mainAxis={titleProps.hideClose ? "center" : "space-between"}
        padding={titleProps.padding || { top: 24, right: 28, left: 48 }}
      >
        <Text
          size={titleProps.size || 27}
          color={titleProps.color || theme.colors.black}
          weight={titleProps.weight}
          textAlign={titleProps.textAlign}
        >
          {title}
        </Text>
        {!titleProps.hideClose && <Icon
          iconName="X"
          onClick={onClose}
          cursor="pointer"
          size={36}
          color={theme.colors.black}
        />}
      </Container>
    )
  }

  function _renderFooter() {
    if (renderFooter) {
      return renderFooter()
    }
    return null
  }

  function renderContainer() {
    return (
      <BackdropStyled background={props.background}>
        <Container
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          onClick={onClose}
          position="absolute"
        />
        <ModalContainer modalSize={modalSize} boxShadow={props.boxShadow}>
          {_renderHeader(props.titleProps || {})}
          <Container flex position="relative" maxHeight="90vh" overflowY="auto">
            {props.children}
          </Container>
          {_renderFooter()}
        </ModalContainer>
      </BackdropStyled>
    )
  }

  return isRender && container
    ? ReactDOM.createPortal(renderContainer(), container)
    : null
}

Modal.defaultProps = {
  // TASK: WOZ-633
  boxShadow: '0px 5px 25px rgba(0, 0, 0, 0.3)'
}

export default memo(Modal)
