import React, { useEffect, memo, useRef, useState } from 'react'
import { Wrapper } from 'src/components'
import { Text } from 'src/styles/styled-components'
import { getClients } from 'src/services/api'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { profileSelector } from 'src/selectors'
import { useScreenSize } from 'src/hooks/useScreenSize'

const cancelable = (func: any, time = 500) => {
  const timeout = useRef<NodeJS.Timeout>();

  const execute = () => {
    timeout.current = setTimeout(func, time)
  }

  const cancel = () => {
    clearTimeout(timeout.current)
  }

  return [execute, cancel]
}

const Redirecter = () => {
  const [warning, setWarning] = useState(false);
  const history = useHistory()
  const profile = useSelector(profileSelector)

  const [enableWarning, cancelWarning] = cancelable(() => setWarning(true), 1500);

  const [redirect, cancel] = cancelable(() => {
    if (!profile?.payload?.email) {
      return;
    }
    if (
      history.location.pathname.includes('client_details')
      && !history.location.pathname.includes('upload_files')
    ) {
      history.replace(`${history.location.pathname}/upload_files/wizard`)
    } else if (!history.location.pathname.includes('client_details')) {
      getClients(profile?.payload?.email, 1).then(firstClient => {
        if (!firstClient?.docs?.[0]?.id) {
          return;
        }
        history.replace(`/client_detail/${firstClient.docs[0].id}/upload_files/wizard`)
      })
    }
  })

  useEffect(() => {
    redirect()
    return cancel
  }, [profile?.payload?.email, history.location.pathname])

  useEffect(() => {
    enableWarning()
    return cancelWarning
  }, [])

  return (
    <Wrapper
      isBusy={!warning}
      mainAxis="center"
      height="90vh"
      padding={{ horizontal: 24 }}
    >
      {warning && (
        <Text as="h2" textAlign="center">
          Draai de tablet naar "landscape mode" voor betere gebruikerservaring
        </Text>
      )}
    </Wrapper>
  )
}

const Layout = memo<{ Component: React.FC }>(({ Component }) => {
  const isMobile = useScreenSize()

  return isMobile ? <Redirecter /> : <Component />
}, () => true)

export default Layout
