import React, { memo, useEffect, useState } from 'react'

import { Button, DatePicker, Input, Radio, Select } from 'src/components'

import { Col, Container, Row, Text } from 'src/styles/styled-components'
import { DATE_FORMAT, SELECT_OPTIONS } from 'src/constants';
import moment from 'moment'
import { getAddress } from 'src/services/api'

interface Step1Props {
  formData: any
  wizard: any
  isValid: boolean
  onSubmit: (callback: () => void, errorCallback: () => void) => void
  updateFormData: (value: any, name?: string) => void
}

function Step1(props: Step1Props) {
  const { formData, updateFormData, isValid, onSubmit } = props

  useEffect(() => {
    if (!isValid) {
      props.wizard.current?.updateStepValidation(1, isValid)
      props.wizard.current?.updateStepValidation(2, isValid)
    }
  }, [isValid, props.wizard])

  useEffect(() => {
    const postCode = formData.buildingPostCode.value
    const houseNumber = formData.buildingHouseNumber.value

    if (postCode && houseNumber) {
      ;(async function () {
        const data = await getAddress(postCode, houseNumber)
        if (data?.street) {
          updateFormData(data.street, 'buildingStreet')
          updateFormData(data.city, 'buildingPlace')
          updateFormData(data.municipality, 'town')
        }
      })()
    }
  }, [formData.buildingPostCode.value, formData.buildingHouseNumber.value])

  useEffect(() => {
    if (!formData.assessment.value) {
      updateFormData('', 'wozValue')
      updateFormData('', 'assessmentDate')
      updateFormData('', 'assessmentNumber')
    } else {
     // updateFormData('', 'assessmentDate')
    }
  }, [formData.assessment.value])

  function submit() {
    onSubmit(
      () => {
        props.wizard.current?.updateStepValidation(1, true, true)
      },
      () => {
        props.wizard.current?.updateStepValidation(1, false)
        props.wizard.current?.updateStepValidation(2, false)
      }
    )
  }

  return (
    <Container padding={16}>
      <Text size={32}>Stap 1 van 4 - Gegevens onroerend goed</Text>
      {/* TASK: WOZ-573 */}
      {/* TASK: WOZ-648 */}
      <Container maxWidth={820} mobile={{ width: "unset" }}>
        <Container margin={{ bottom: 32, top: 20 }}>
          <Text>Bezwaar maken voor 1 of meerdere adressen?*</Text>
          <Container flex margin={{ top: 10 }}>
            <Container margin={{ right: 25 }}>
              <Radio
                value="1"
                label="1 adres"
                name="addressCount"
                onCheck={updateFormData}
                checked={formData.addressCount.value === '1'}
              />
            </Container>
            <Radio
              value="other"
              name="addressCount"
              onCheck={updateFormData}
              label="meerdere adressen"
              checked={formData.addressCount.value === 'other'}
            />
          </Container>
        </Container>
        <Container margin={{ bottom: 32 }}>
          <Container margin={{ bottom: 10 }}>
            <Text>Soort pand*</Text>
          </Container>
          <Select
            name="property"
            onSelect={updateFormData}
            value={formData.property.value}
            options={SELECT_OPTIONS.property}
          />
        </Container>
        <Container margin={{ bottom: 32 }}>
          <Row>
            <Col>
              <Input
                name="buildingPostCode"
                placeholder="Postcode*"
                onChange={updateFormData}
                value={formData.buildingPostCode.value}
                errorText={formData.buildingPostCode.validationText}
              />
            </Col>
            <Col>
              <Input
                name="buildingHouseNumber"
                onChange={updateFormData}
                placeholder="Huisnummer*"
                value={formData.buildingHouseNumber.value}
                errorText={formData.buildingHouseNumber.validationText}
              />
            </Col>
            <Col>
              <Input
                name="buildingAddition"
                placeholder="Toevoeging"
                onChange={updateFormData}
                value={formData.buildingAddition.value}
                errorText={formData.buildingAddition.validationText}
              />
            </Col>
          </Row>
        </Container>
        <Container margin={{ bottom: 32 }}>
          <Input
            name="buildingStreet"
            placeholder="Straat*"
            onChange={updateFormData}
            value={formData.buildingStreet.value}
            errorText={formData.buildingStreet.validationText}
          />
        </Container>
        <Container margin={{ bottom: 32 }}>
          <Input
            name="buildingPlace"
            placeholder="Plaats*"
            onChange={updateFormData}
            value={formData.buildingPlace.value}
            errorText={formData.buildingPlace.validationText}
          />
        </Container>
        <Container margin={{ bottom: 32 }}>
          <Input
            name="town"
            placeholder="Gemeente*"
            onChange={updateFormData}
            value={formData.town.value}
            errorText={formData.town.validationText}
          />
        </Container>
        <Container margin={{ bottom: 32 }}>
          <Text>Heeft u het aanslagbiljet bij de hand?*</Text>
          <Container flex margin={{ top: 10 }}>
            <Container margin={{ right: 25 }}>
              <Radio
                label="Ja"
                value={true}
                name="assessment"
                onCheck={updateFormData}
                checked={formData.assessment.value}
              />
            </Container>
            <Radio
              label="Nee"
              value={false}
              name="assessment"
              onCheck={updateFormData}
              checked={!formData.assessment.value}
            />
          </Container>
        </Container>
        {formData.assessment.value && (
          <Container>
            <Container margin={{ bottom: 32 }}>
              <Input
                name="wozValue"
                onChange={updateFormData}
                placeholder="WOZ-waarde *"
                value={formData.wozValue.value}
                errorText={formData.wozValue.validationText}
              />
            </Container>
            <Container margin={{ bottom: 32 }}>
              <DatePicker
                name="assessmentDate"
                onChange={updateFormData}
                value={formData.assessmentDate.value}
                errorText={formData.assessmentDate.validationText}
                placeholder="Dagtekening (zie datum op aanslagbiljet) *"
              />
            </Container>
            <Container margin={{ bottom: 32 }}>
              <Input
                name="assessmentNumber"
                onChange={updateFormData}
                placeholder="Aanslagnummer *"
                errorText={formData.assessmentNumber.validationText}
                value={formData.assessmentNumber.value}
              />
            </Container>
          </Container>
        )}
        <Container>
          <Button
            large
            onClick={submit}
            label="Naar Stap 2 - Gegevens belastingplichtige"
          />
        </Container>
      </Container>
    </Container>
  )
}

Step1.defaultProps = {}

export default memo(Step1)
