import { useSelector } from 'react-redux'
import { templatesSelector } from 'src/selectors'
import {
  EmailTemplate,
  TemplateCategoryIds,
  TemplateIds,
} from 'src/interfaces/app'
import { TEMPLATES_ID_MAP } from 'src/constants'
import { parseTemplate, ValidationData } from 'src/email/misc'

interface useEmailTemplateProps {
  getTemplate: (
    categoryId: TemplateCategoryIds,
    key: TemplateIds,
    validation?: ValidationData
  ) => EmailTemplate
  getTemplates: (
    categoryId: TemplateCategoryIds,
    validation?: ValidationData
  ) => EmailTemplate[]
  templates: EmailTemplate[]
}

export function useEmailTemplate(): useEmailTemplateProps {
  const templates = useSelector(templatesSelector)
  let _allTemplates: any = []

  Object.values(templates).forEach((i) => {
    _allTemplates = [..._allTemplates, ...i]
  })

  function getTemplate(
    categoryId: TemplateCategoryIds,
    key: TemplateIds,
    validation: ValidationData
  ) {
    if (categoryId !== undefined && key) {
      const t = templates[TEMPLATES_ID_MAP[categoryId]]?.find(
        (template) => template.key === key
      )

      if (!t) {
        return null
      }

      return {
        ...t,
        subject: parseTemplate(t.subject, validation),
        content: parseTemplate(t.content, validation, _allTemplates),
      }
    }
    return null
  }

  function getTemplates(
    categoryId: TemplateCategoryIds,
    validation?: ValidationData
  ) {
    return categoryId
      ? validation
        ? templates[TEMPLATES_ID_MAP[categoryId]].map((i) => ({
            ...i,
            subject: parseTemplate(i.subject, validation),
            content: parseTemplate(i.content, validation, _allTemplates),
          }))
        : templates[TEMPLATES_ID_MAP[categoryId]]
      : []
  }

  return { getTemplate, getTemplates, templates: _allTemplates }
}
