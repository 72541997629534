import React, { memo, useContext, useState } from 'react'
import { Container, Text } from 'src/styles/styled-components'
import { Button, Select, Wrapper } from 'src/components'
import { ClientRequestInfo } from 'src/interfaces/app'
import { generateFO } from 'src/services/api'
import { useAlert } from 'react-alert'
import { ThemeContext } from 'styled-components'
import Item from 'src/helpers/Item'
import { generateSignatureExample } from 'src/services/pdfGenerator'

interface ExportFOProps {
  data: ClientRequestInfo
  updateData: () => void
  onRequestSent: () => void
}

function ExportFO(props: ExportFOProps) {
  const { updateData, onRequestSent, data } = props

  const theme = useContext(ThemeContext)
  const alert = useAlert()

  const [isLoading, setIsLoading] = useState(false)
  const [exportFormat, setExportFormat] = useState<
    'PDF'
    | 'WORD'
    | 'WORD_EXCLUDE'
  >('PDF')

  const exportFormats = [
    { label: 'Word', value: 'WORD' },
    { label: 'Word exclude layout', value: 'WORD_EXCLUDE' },
    { label: 'PDF', value: 'PDF' },
  ]

  async function downloadSignature() {
    if (data?.signature?.status === 'approved') {
      const signature = await generateSignatureExample(data)
      signature.download()
    } else if (data?.authorization?.status === 'approved') {
      const signature = await generateFO({
        signatureUrl: data?.authorization?.file,
        type: 'PDF',
      });
      signature.download();
    }
  }

  async function submit() {
    setIsLoading(true)
    const doc = await generateFO({
      clientInfo: data,
      type: exportFormat,
    })
    doc.download()
    if (exportFormat !== "PDF") {
      await downloadSignature()
    }
    onRequestSent()
    await updateData()
    setIsLoading(false)
    alert.success('Proforma bezwaar succesvol geëxporteerd.')
  }

  async function exportDocument() {
    setIsLoading(true)
    try {
      const word = await generateFO({
        clientInfo: data,
        type: 'WORD',
      })
      await downloadSignature()
      word.download()
      const wordExclude = await generateFO({
        clientInfo: data,
        type: 'WORD_EXCLUDE',
        withoutSignature: true,
      })
      wordExclude.download()
      const pdf = await generateFO({
        clientInfo: data,
        type: 'PDF',
      })
      pdf.download()

      onRequestSent()
    } catch (err) {} finally {
      setIsLoading(false)
    }
  }

  return (
    <Wrapper isBusy={isLoading} width="100%" padding={48} height="fit-content">
      <Container>
        {/*<Container width="50%">*/}
        {/*  <Text>Selecteer de samenwerkingsverband</Text>*/}
        {/*  <Container margin={{ top: 16 }}>*/}
        {/*    <Row>*/}
        {/*      {municipalities.map((municipality) => {*/}
        {/*        return (*/}
        {/*          <Col margin={{ bottom: 16 }} xs="6" key={municipality.id}>*/}
        {/*            <Radio*/}
        {/*              name="municipality"*/}
        {/*              label={municipality.name}*/}
        {/*              value={municipality}*/}
        {/*              onCheck={selectMunicipality}*/}
        {/*              checked={municipality.name === _municipality?.name}*/}
        {/*            />*/}
        {/*          </Col>*/}
        {/*        )*/}
        {/*      })}*/}
        {/*    </Row>*/}
        {/*  </Container>*/}
        {/*  {!!error && <Text color={theme.colors.red1}>{error}</Text>}*/}
        {/*</Container>*/}
        <Container margin={{ vertical: 36 }}>
          <Text>Selecteer de bestandstype en opmaak voor de export</Text>
          <Container margin={{ top: 16 }} flex crossAxis="center">
            <Container>
              <Select
                value={exportFormat}
                options={exportFormats}
                onChange={setExportFormat}
              />
            </Container>
            <Container margin={{ left: 60 }}>
              <Item onClick={exportDocument}>
                {/* TASK: WOZ-633 */}
                <Text color={theme.colors.main} hoverDecoration="underline">
                  Download in alle formaten en stylen
                </Text>
              </Item>
            </Container>
          </Container>
        </Container>
        {data?.authorization?.status === 'approved' && (
          <Container margin={{ bottom: 16 }}>
            <Text>Dit bestand wordt bijgevoegd bij de Proforma export</Text>
            <Container margin={{ top: 16 }}>
              <Text
                as="a"
                hoverDecoration="underline"
                target="_blank"
                color={theme.colors.main}
                href={data.authorization.file}
              >
                Machtiging.pdf
              </Text>
            </Container>
          </Container>
        )}
      </Container>
      <Container flex mainAxis="center">
        <Button big xxl onClick={submit} label="Exporteren" />
      </Container>
    </Wrapper>
  )
}

export default memo(ExportFO)
