import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'

const TextareaStyled = styled.textarea`
  width: 100%;
  max-width: 100%;
  height: 100%;
  font-size: 14px;
  border-radius: 5px;
  padding: 8px 12px 0 12px;
  min-height: 138px;
  min-width: 100%;
  line-height: 21px;
  resize: vertical;
  color: ${defaultTheme.colors.gray5};
  border: 1px solid ${defaultTheme.colors.gray1};
  font-family: ${defaultTheme.typography.lato};

  &:disabled {
    background: rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(0, 0, 0, 0.1);
  }
`

TextareaStyled.displayName = 'TextareaStyled'

export default TextareaStyled
