import React, { memo, useContext, useMemo, useEffect, useState } from 'react'
import {
  Container,
  Text,
  TextAreaContainer,
} from 'src/styles/styled-components'
import { Button, Checkbox, FormElement, Input, Textarea } from 'src/components'
import {
  ClientRequestInfo,
  EmailTemplate,
  MANAGER_ACTION_TYPES,
} from 'src/interfaces/app'
import { editClient, sendEmail } from 'src/services/api'
import moment from 'moment'
import { useAlert } from 'react-alert'
import { useParams } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import {
  generateIntroLetter,
  generateQuestionsListPDF,
  generateSignatureExample,
} from 'src/services/pdfGenerator'

import { ThemeContext } from 'styled-components'
import { generateZIP } from 'src/services/generateZip'

interface RequestDocumentsProps {
  email: string
  token?: string
  updateData: () => void
  template: EmailTemplate
  onRequestSent: () => void
  clientInfo: ClientRequestInfo
  actionName:
    | MANAGER_ACTION_TYPES.SEND_REMINDER
    | MANAGER_ACTION_TYPES.REQUEST_PHOTO
    | MANAGER_ACTION_TYPES.PHOTO_REMINDER
}

function RequestDocuments(props: RequestDocumentsProps) {
  const theme = useContext(ThemeContext)
  const {
    template,
    email,
    clientInfo,
    updateData,
    actionName,
    onRequestSent,
    token,
  } = props

  const alert = useAlert()
  const params = useParams<{ id: string }>()

  const [_email, setEmail] = useState(email)
  const [subject, setSubject] = useState(template.subject)
  const [content, setContent] = useState(template.content)
  const [isPersonal, setIsPersonal] = useState(false)

  const successMessage = useMemo(() => {
    switch (actionName) {
      case MANAGER_ACTION_TYPES.SEND_REMINDER: {
        return "Herinnering documenten verstuurd."
      }
      case MANAGER_ACTION_TYPES.REQUEST_PHOTO: {
        return "Foto's opvragen."
      }
      case MANAGER_ACTION_TYPES.PHOTO_REMINDER: {
        return "Herinnering foto's verstuurd."
      }
    }
  }, [actionName])

  useEffect(() => {
    updateData()
  }, []);

  useEffect(() => {
    // NOTE: es `useEffect`@ glxarad a arac,
    // en case-n a vor user@ grancvum a orinak photo-i mail-ov
    // heto file-i mail a stanum u mej@ pti registration link chlini
    setSubject(template.subject)
    setContent(template.content)
  }, [token]);

  async function submit() {
    const reminder =
      actionName === MANAGER_ACTION_TYPES.SEND_REMINDER
        ? 'documentReminder'
        : !clientInfo.photoRequest
        ? 'photoRequest'
        : 'photoReminder'
    const attachments = []
    await editClient(params.id, {
      [reminder]: moment().format('DD-MM-YYYY'),
    })
    onRequestSent()

    let generatorQuestions
    let generatorSignature
    let generatorQuestionsDOCX

    const address = `${clientInfo.buildingStreet} ${
      clientInfo.buildingHouseNumber
    }${
      clientInfo.buildingAddition ? ` ${clientInfo.buildingAddition}` : ''
    } te ${clientInfo.buildingPlace}`

    if (!clientInfo?.authorization && reminder === 'documentReminder') {
      generatorSignature = await generateSignatureExample(clientInfo)
      attachments.push({
        content: generatorSignature.base64(),
        filename: 'Machtiging.pdf',
        type: 'application/pdf',
        disposition: 'attachment',
        encoding: 'base64',
      })
    }

    if (!clientInfo?.questionsDoc && reminder === 'documentReminder') {
      generatorQuestions = await generateQuestionsListPDF(clientInfo, address)
      generatorQuestionsDOCX = await generateQuestionsListPDF(
        clientInfo,
        address,
        null,
        'DOCX'
      )

      attachments.push({
        content: generatorQuestions.base64(),
        filename: 'Vragenlijst.pdf',
        type: 'application/pdf',
        disposition: 'attachment',
        encoding: 'base64',
      })

      attachments.push({
        content: await generatorQuestionsDOCX.base64(),
        filename: 'Vragenlijst.docx',
        type:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        disposition: 'attachment',
        encoding: 'base64',
      })
    }

    let didNotSend = true

    if (_email) {
      if (reminder === 'photoRequest') {
        const res = await sendEmail({
          to: _email,
          subject,
          body: content,
          attachments,
          bcc: 'info@wozbezwaar.biz',
        })
        didNotSend = !res?.success
      } else {
        const res = await sendEmail({
          to: _email,
          subject,
          body: content,
          attachments,
        })
        didNotSend = !res?.success
      }
    } else if (reminder === 'documentReminder') {
      const introLetter = await generateIntroLetter(clientInfo)
      const zip = [
        { name: 'Klantenbrief.docx', file: await introLetter.base64() },
      ]

      if (generatorQuestions) {
        zip.push({
          name: 'Vragenlijst.pdf',
          file: await generatorQuestions.base64(),
        })
        zip.push({
          name: 'Vragenlijst.docx',
          file: await generatorQuestionsDOCX.base64(),
        })
      }

      if (generatorSignature) {
        zip.push({ name: 'Machtiging.pdf', file: generatorSignature.base64() })
      }
      await generateZIP(zip, address).download()
    }
    if (didNotSend) {
      alert.success("Case bijgewerkt.")
    } else {
      alert.success(successMessage)
    }
    await updateData()
  }

  return (
    <Container width="100%" padding={48} height="fit-content">
      <Container margin={{ bottom: 32 }} flex noGrow crossAxis="center">
        <Text margin={{ right: 16 }}>Email wordt verzonden naar: </Text>
        {!!clientInfo?.email ? (
          <Input
            size="default"
            name="email"
            value={_email}
            onChange={setEmail}
            disabled={!isPersonal}
          />
        ) : (
          <>
            <Input
              flex={false}
              size="default"
              name="email"
              value={_email}
              onChange={setEmail}
              disabled={!isPersonal}
            />
            <Text padding={{ left: 16 }} color={theme.colors.red1}>
              {' '}
              geen e-mailadres
            </Text>
          </>
        )}
      </Container>
      <Container flex margin={{ bottom: 32 }}>
        <Input
          disabled={!isPersonal}
          name="subject"
          value={subject}
          onChange={setSubject}
        />
      </Container>
      <Container flex margin={{ bottom: 32 }} minHeight={300}>
        <FormElement
          isWysiwyg
          name="body"
          edit={isPersonal}
          value={content}
          formType="textarea"
          onChange={setContent}
        >
          <TextAreaContainer>{ReactHtmlParser(content)}</TextAreaContainer>
        </FormElement>
      </Container>
      <Container flex margin={{ bottom: 32 }}>
        <Checkbox
          name="isPersonal"
          checked={isPersonal}
          onCheck={setIsPersonal}
          label="E-mail personaliseren"
        />
      </Container>
      <Container flex mainAxis="center">
        <Button big xxl onClick={submit} label="Versturen" />
      </Container>
    </Container>
  )
}

export default memo(RequestDocuments)
