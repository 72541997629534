import React from 'react'
import {
  Route,
  Switch,
  Redirect,
  RouteProps,
  useLocation,
} from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { isNull } from 'src/utils/misc'

interface RoutesI extends RouteProps {
  public: boolean
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
}

interface PropsI {
  routes: RoutesI[]
  isSignIn: boolean
}

export const RouteBuilder = (props: PropsI) => {
  const { routes, isSignIn } = props

  const renderRoute = (route: RoutesI, type: string) => {
    const { component, ...props } = route
    const Elem = component

    return (
      <Route key={`${type}${route.path}`} {...props}>
        {/*// @ts-ignore*/}
        <Elem />
      </Route>
    )
  }

  const renderPublicRoutes = () => {
    const location = useLocation()

    return (
      <Switch>
        {routes.map(
          (route) =>
            (route.public || isNull(route.public)) &&
            renderRoute(route, 'public')
        )}
        <Redirect to={{ pathname: '/', state: { from: location } }} />
      </Switch>
    )
  }

  const renderPrivateRoutes = () => {
    const r = routes
      .filter((route) => !route.public || isNull(route.public))
      .map((route) => renderRoute(route, 'private'))

    return (
      <Switch>
        {r}
        <Redirect to="/" />
      </Switch>
    )
  }

  return isSignIn ? renderPrivateRoutes() : renderPublicRoutes()
}
