import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'

//components
import { Tab } from 'src/components'

//styled
import { Container, Text } from 'src/styles/styled-components'
import MainLayout from 'src/layouts/Main'

import UserInfo from './components/UserInfo'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/user'

import EmailTemplates from 'src/pages/profile/components/EmailTemplates'
import TextBlock from 'src/pages/profile/components/TextBlock'
import Municipality from 'src/pages/profile/components/Municipality'
import StopCaseList from 'src/pages/profile/components/StopCaseList'
import { useAuth } from 'src/hooks/useAuth'
import { useScreenSize } from 'src/hooks/useScreenSize'

interface ProfileProps {}

function Profile(props: ProfileProps) {
  const theme = useContext(ThemeContext)
  const auth = useAuth()
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const {
    getEmailTemplates,
    getTextBlocks,
    getMunicipalities,
    getDocumentStopCaseList,
  } = useActions(actions)
  const isMobile = useScreenSize()

  useEffect(() => {
    getEmailTemplates()
    getTextBlocks()
    getMunicipalities()
    getDocumentStopCaseList()
  }, [])

  const tabs = useMemo(() => {
    const value = [{ text: 'Mijn profiel' }]
    if (auth.isAdmin) {
      value.push(
        { text: 'E-mail templates' },
        { text: 'Tekstblokken' },
        { text: 'Samenwerkingsverbanden' },
        { text: 'Redenen om een case stop te zetten' },
      )
    }
    return value
  }, [auth.isAdmin])

  return (
    <MainLayout hideLeftPanel={isMobile}>
      <Container padding={24} flex column>
        <Container margin={{ bottom: 35 }}>
          <Text size={25} color={theme.colors.gray5}>
            {tabs[currentTabIndex].text}
          </Text>
        </Container>
        <Tab fillHeight onTabChange={setCurrentTabIndex} titles={tabs}>
          <UserInfo />
          <EmailTemplates />
          <TextBlock />
          <Municipality />
          <StopCaseList />
        </Tab>
      </Container>
    </MainLayout>
  )
}

Profile.defaultProps = {}

export default {
  component: Profile,
  exact: false,
  public: false,
  path: '/profile',
}
