import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'
import { Text } from 'src/styles/styled-components'

interface TabItemProps {
  isActive: boolean
}

const dynamicStyles = ({ isActive }: TabItemProps) => `
  ${
    isActive
      ? `
    ${Text} {
      color: ${defaultTheme.colors.gray5};
    }
    border-bottom: 3px solid ${defaultTheme.colors.main};
  `
      : `
    ${Text} {
       color: #b0b0b1;
    }
    border-bottom: 3px solid transparent;
  `
  }
`

const TabItem = styled.div`
  margin: 0 15px;
  padding-bottom: 18px;
  ${dynamicStyles}
`

TabItem.displayName = 'TabItem'

export default TabItem
