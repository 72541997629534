import styled from 'styled-components'

import { font } from 'src/styles/helpers/font'
import { flex } from 'src/styles/helpers/flex'
import { margin } from 'src/styles/helpers/margin'
import { padding } from 'src/styles/helpers/padding'
// TASK: WOZ-571
import { mobile } from 'src/styles/helpers/mobile'

const defaultProps = {
  // some styles here
}
const mediaQuery = ({ mediaQuery }: any) => `
${mediaQuery ? mediaQuery : ''};
`

const Text = styled.span`
  ${font}
  ${flex}
  ${margin}
  ${padding}
  ${mediaQuery}
  ${mobile}
`

Text.displayName = 'Text'
Text.defaultProps = defaultProps

export default Text
