import styled from 'styled-components'

const DeleteContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: -100%;
  opacity: 0;
  cursor: pointer;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.25s linear;
`

DeleteContainer.displayName = 'DeleteContainer'

export default DeleteContainer
