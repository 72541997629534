import React, {
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react'
import SignatureCanvas from 'react-signature-canvas'

// TASK: WOZ-573
import {
  Button,
  Uploader,
  Radio,
  Icon,
  Wrapper,
  List,
  ListItem,
  ShowMore,
} from 'src/components'

import { Container, Text } from 'src/styles/styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import {
  editClient,
  getClientInfoById,
  sendEmail,
  uploadFiles,
} from 'src/services/api'
import Item from 'src/helpers/Item'
import {
  dataURItoBlob,
  isArray,
  isBoolean,
} from 'src/utils/misc'
import { ClientRequestInfo } from 'src/interfaces/app'
import { ThemeContext } from 'styled-components'
import {
  generateQuestionsListPDF,
  generateSignatureExample,
} from 'src/services/pdfGenerator'
import {
  DELIVERY_FILES_CONTENT,
} from 'src/constants/emailContents'

//mediaquery
import { step1 } from 'src/styles/mediaQuery/step1'
import moment from 'moment'
import { DATE_FORMAT } from 'src/constants'
import { useScreenSize } from 'src/hooks/useScreenSize'
import { generateAssessmentLetter } from 'src/services/docxGenerator'

interface Step3Props {
  wizard: any
  formData: any
  questions: boolean
  isDocuments?: boolean
  isWizard?: boolean
  questionsListFile: any
  updateData: () => void
  data: ClientRequestInfo
  showSuccesPage?: () => void
}

function Step3(props: Step3Props) {
  const {
    data,
    formData,
    updateData,
    questions,
    questionsListFile,
    isDocuments,
    isWizard,
    showSuccesPage
  } = props

  const theme = useContext(ThemeContext)
  const history = useHistory()
  const alert = useAlert()
  const params = useParams<{ id: string }>()
  const signature = useRef<SignatureCanvas>(null)
  const isMobile = useScreenSize()

  useEffect(() => {
    if (signature.current && !!data?.authorization) {
      signature.current.off();
    }
  }, [!!data?.authorization]);

  const [hasSignature, setHaseSignature] = useState(
    data?.signature ? null : true
  )
  const [uploadCopyLetter, setUploadCopyLetter] = useState(!data?.assessment)
  const [isLoading, setIsLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [files, setFiles] = useState<Record<string, File>>({
    ...questionsListFile,
  })

  const assessmentDocument =
    !data?.assessment && uploadCopyLetter && !files?.assessment
  const _signature =
    isBoolean(hasSignature)
    && !files?.signature
    && !files?.authorization
    && !data?.authorization

  useEffect(() => {
    if (data && !data?.questionsDoc && questions) {
      const address = `${data.buildingStreet} ${data.buildingHouseNumber}${
        data.buildingAddition ? ` ${data.buildingAddition}` : ''
      } te ${data.buildingPlace}`

      // @ts-ignore
      ;(async function () {
        const _d = await generateQuestionsListPDF(data, address, formData)

        // @ts-ignore
        onDropFiles(_d.file, 'questionsDoc')
      })()
    }
  }, [])

  const clearSignature = useCallback(() => {
    setFiles((prevState) => {
      const cloneData = { ...prevState }
      delete cloneData.signature
      signature.current.clear()
      return cloneData
    })
  }, [])

  const onDropFiles = useCallback((file: File, name: string) => {
    setFiles((prevState) => {
      return {
        ...prevState,
        [name]: file,
      }
    })
  }, [])

  async function submit() {
    const showUploadPhoto = !!data?.photoReminder || !!data?.photoRequest
    if (assessmentDocument || _signature) {
      setShowError(true)
    } else {
      setShowError(false)
      if (!Object.keys(files).length) {
        if (isDocuments || isWizard || !showUploadPhoto) {
          if (isMobile) {
            showSuccesPage();
          } else {
            history.replace(`/client_detail/${params.id}`)
          }
        } else {
          props.wizard.current?.updateStepValidation(2, true, true)
        }
      } else {
        setIsLoading(true)

        const imageUploads: any[] = []
        for (const key of Object.keys(files)) {
          const dataFile: File | Array<File> = files[key]
          if (Array.isArray(dataFile) && key === 'assessment') {
            const assessmentFile = await generateAssessmentLetter(dataFile)
            imageUploads.push(uploadFiles(assessmentFile, key, 'pending'))
          } else {
            imageUploads.push(uploadFiles(dataFile, key, 'pending'))
          }
        }

        Promise.all(imageUploads).then(async (result) => {
          const updatedData: any = {}

          if (result.length) {
            result.forEach((item) => {
              if (updatedData[item.name] && isArray(updatedData[item.name])) {
                updatedData[item.name].push(item.file)
              } else {
                updatedData[item.name] = item.file
              }
            })
          }

          // WOZ-687
          // const status =
          //   !!Object.keys(files).length &&
          //   Object.keys(files).length === 1 &&
          //   !files.photos
          //     ? 1
          //     : data.status
          const status =
            !!Object.keys(files).length &&
            !files.photos
              ? 1
              : data.status

          await editClient(params.id, {
            status,
            ...updatedData,
            questions: !!formData.question1 ? formData : null,
          })

          if (Object.keys(files).length) {
            try {
              const buildingAddress = `${data.buildingStreet} ${data.buildingHouseNumber} te ${data.buildingPlace}`
              await sendEmail({
                to: 'info@wozbezwaar.biz',
                subject: `Document(en) aangeleverd voor ${buildingAddress}`,
                body: DELIVERY_FILES_CONTENT(data),
              })

              alert.success(
                'Bedankt voor het aanleveren van één of meerdere documenten! Wij gaan deze controleren en laten u per mail weten of wij hiermee verder kunnen.',
                {
                  timeout: 10000,
                }
              )
              setIsLoading(false)
            } catch (e) {}
          }
          const snapshotData = await getClientInfoById(params.id)
          if (snapshotData?.signature && !snapshotData.authorization) {
            const signatureDoc = await generateSignatureExample(snapshotData)
            await uploadFiles(
              signatureDoc.file,
              'authorization',
              'pending',
              params.id
            )
            await editClient(params.id, {
              status: 1,
            })
          }
          setIsLoading(false)
          if (isDocuments || isWizard) {
            if (isMobile) {
              showSuccesPage();
            } else {
              history.replace(`/client_detail/${params.id}`)
            }
          } else {
            props.wizard.current?.updateStepValidation(2, true, true)
          }
        })
      }
    }
  }

  async function downloadAuthorizationDoc() {
    const generator = await generateSignatureExample(data)
    generator.download()
  }

  const canvasProps = useMemo<React.CanvasHTMLAttributes<HTMLCanvasElement>>(() => ({
    width: isMobile ? document.body.clientWidth - 48 : 500,
    height: 200,
    className: `sigCanvas${!!data?.authorization ? " sigCanvasDisabled" : ""}`,
  }), [isMobile, !!data?.authorization])

  return (
    <Wrapper isBusy={isLoading} padding={16} mobile={{ padding: { horizontal: "unset" } }}>
      <Text size={32} mediaQuery={step1.mainTitle}>
        {questions
          ? 'Stap 3 van 3 - Uploaden en versturen'
          : 'Uploaden en versturen'}
      </Text>
      {/* TASK: WOZ-646 */}
      <Container width={820} mediaQuery={step1.content}>
        <Container margin={{ bottom: 36 }}>
          <Text as="h2" mediaQuery={step1.secondaryTitle}>
            Kopie aanslag gemeentelijke belastingen
          </Text>
          <Container margin={{ bottom: 32 }}>
            <Container flex mediaQuery={step1.radioContainer}>
              <Radio
                value={true}
                label={!isMobile ? "Nu uploaden" : "Nu scannen"}
                name="question1"
                disabled={!!data?.assessment}
                onCheck={setUploadCopyLetter}
                checked={uploadCopyLetter}
              />
              <Radio
                margin={{ left: 16 }}
                label="Later aanleveren"
                value={false}
                name="question1"
                disabled={!!data?.assessment}
                onCheck={setUploadCopyLetter}
                checked={!uploadCopyLetter}
                mediaQuery={step1.radio}
              />
            </Container>
            <Container margin={{ top: 24 }}>
              {isMobile ? (
                <List ordered>
                  <ListItem>Klik op de knop hieronder om de camera op uw telefoon of tablet op te starten.</ListItem>
                  <ListItem>Maak een foto van de brief aanslag gemeentelijke belastingen (alle pagina's van de aanslag, behalve de algemene toelichting op de aanslag).</ListItem>
                  <ListItem>Druk op OK.</ListItem>
                  <ListItem>De foto(s) die u zojuist aangemaakt hebt wordt naar ons toegestuurd nadat u drukt op de knop “Versturen” onderaan deze pagina.</ListItem>
                </List>
              ) : (
                <Text>
                  Upload de aanslag gemeentelijke belastingen door op de knop
                  hieronder te drukken of het bestand te slepen.
                </Text>
              )}
            </Container>
            {showError && assessmentDocument && (
              <Container margin={{ top: 16 }}>
                <Text color={theme.colors.red1}>
                  Upload eerst het document(en) om verder te gaan
                </Text>
              </Container>
            )}
          </Container>
          {uploadCopyLetter && (
            <Container>
              {data?.assessment ? (
                <Container flex>
                  {/* TASK: WOZ-633 */}
                  <Text target="_blank" as="a" hoverDecoration="underline" href={data?.assessment.file}>
                    {data?.assessment?.label}
                  </Text>
                </Container>
              ) : (
                <Uploader
                  name="assessment"
                  multiple={isMobile}
                  onDrop={onDropFiles}
                  // TASK: WOZ-668
                  maxFilesError="U kunt maximaal één aanslag document uploaden."
                />
              )}
            </Container>
          )}
        </Container>
        <Container margin={{ bottom: 36 }}>
          <Text as="h2" mediaQuery={step1.secondaryTitle}>
            Ondertekende machtiging
          </Text>
          <Container margin={{ bottom: 32 }}>
            <Container flex mediaQuery={step1.radioContainer}>
              <Radio
                margin={{ right: 16 }}
                label="Nu digitaal ondertekenen"
                value={true}
                name="question1"
                disabled={!!data?.authorization}
                onCheck={setHaseSignature}
                checked={hasSignature}
              />
              {!isMobile && <Radio
                margin={{ right: 16 }}
                value={false}
                label="Nu uploaden"
                name="question1"
                disabled={!!data?.authorization}
                onCheck={setHaseSignature}
                checked={hasSignature === false}
              />}
              <Radio
                label="Later aanleveren"
                value={null}
                name="question1"
                onCheck={setHaseSignature}
                checked={hasSignature === null}
                disabled={!!data?.authorization}
              />
            </Container>
            {showError && _signature && (
              <Container margin={{ top: 16 }}>
                <Text color={theme.colors.red1}>
                  Plaats eerst hieronder uw handtekening om verder te gaan
                </Text>
              </Container>
            )}
            {!isMobile && (
              <ShowMore margin={{ top: 32 }}>
                {/* WOZ-688 */}
                Ondergetekende machtigt hierbij de heer mr. S. Mrosek en mevrouw N. Yonekura, verbonden aan advieskantoor
                WOZBEZWAAR.BIZ (KVK-nummer: 59801964, BTW-nummer: NL8536.49.777B01, IBAN: NL29 ABNA 0571 7359 83),
                kantoor houdend aan Jan Campertstraat 5 te Heerlen, om hem/haar zowel in als buiten rechte te vertegenwoordigen in
                alle aangelegenheden aangaande de aanslagen lokale belastingen alsmede de daarop vermelde WOZ-beschikking(-en).
                Deze volmacht houdt onder andere in het in ontvangst nemen van besluiten, al dan niet op grond van een bezwaarschrift
                genomen, daartegen bezwaar of beroep in te dienen of daartegen, al dan niet bij wege van een voorlopige voorziening
                beroep aan te tekenen, waaronder mede begrepen administratief beroep of hoger beroep. Tevens kan op basis van deze
                volmacht bestuursorganen verzocht worden om een besluit te nemen tot het vergoeden van door volmachtgever geleden
                schade, om hoorzittingen bij te wonen en daarin namens volmachtgever het woord te voeren, om een aanhangig bezwaar
                of (hoger) beroep in te trekken en voorts al datgene te doen wat de gevolmachtigde in het belang van volmachtgever
                gewenst, nuttig of noodzakelijk acht en de volmachtgever zelf tegenwoordig zijnde zou kunnen, mogen of moeten doen.
                <br />
                <br />
                <Text weight="bold" size={18}>Cessie:</Text>
                <br />
                <br />
                Wanneer wij namens u succesvol bezwaar maken bepaalt de wet dat wij recht hebben op een vergoeding van de door ons
                gemaakte kosten welke met bovenvermelde werkzaamheden gepaard gaat. Dit recht wordt uitgewerkt in de artikelen 7:15
                en 8:75 Awb en het BPB. Ondergetekende draagt bij deze dan ook alle bestaande en toekomstige vorderingen uit hoofde
                van bovenvermelde proceskostenvergoeding, waarbij WOZBEZWAAR.BIZ als gemachtigde is opgetreden, over aan
                WOZBEZWAAR.BIZ en gelast zijn/haar gemeente om deze vergoeding rechtstreeks aan WOZBEZWAAR.BIZ over te
                maken op rekening IBAN NL29 ABNA 0571 7359 83. Indien het bezwaar ongegrond is neemt WOZBEZWAAR.BIZ alle
                kosten voor eigen rekening.
                <br />
                <br />
                Het teveel betaalde aan lokale heffingen dient rechtstreeks op rekeningnummer van volmachtgever te worden
                overgemaakt.
                <br />
                <br />
                Door dit document te tekenen geeft u aan kennis genomen te hebben van de algemene voorwaarden zoals vermeld op
                <a href="https://www.wozbezwaar.biz/algemene-voorwaarden/" target="__blank">https://www.wozbezwaar.biz/algemene-voorwaarden/</a> en daarmee akkoord te gaan. Deze algemene voorwaarden zijn ook
                gedeponeerd bij de Kamer van Koophandel.
                <br />
                <br />
                <Text weight="bold" size={18}>Alle correspondentie inzake deze procedure richten aan:</Text>
                <br />
                <br />
                WOZBEZWAAR.BIZ, Jan Campertstraat 5, 6416 SG Heerlen

              </ShowMore>
            )}
            {hasSignature !== null && !data?.authorization && (
              <Container margin={{ vertical: 16 }}>
                <Text>
                  Ondertekend te {data?.personalPlace}, op{' '}
                  {moment().format(DATE_FORMAT)}
                </Text>
              </Container>
            )}
          </Container>
          {isMobile ? (
            <>
              <Text as="h1">Uw digitale handtekening.</Text>
              <Text as="p">Zet hieronder uw handtekening. Druk op het handtekeningveld en houd deze ingedrukt totdat u klaar bent.</Text>
            </>
          ) : (
            <Text>Teken uw handtekening hieronder m.b.v. uw muisaanwijzer.</Text>
          )}
          {hasSignature !== null && (
            <Container margin={{ top: 24 }}>
              {hasSignature ? (
                data?.signature?.file ? (
                  <Container flex noShrink mainAxis="space-between">
                    <img src={data?.signature?.file} alt="" />
                  </Container>
                ) : (
                  <Container>
                    <Container flex noShrink column={isMobile}>
                      <SignatureCanvas
                        onEnd={() => {
                          const file = dataURItoBlob(
                            signature.current
                              .getTrimmedCanvas()
                              .toDataURL('image/png'),
                            `signature.png`
                          )

                          onDropFiles(file, 'signature')
                        }}
                        ref={signature}
                        canvasProps={canvasProps}
                      />
                      {!data?.authorization && <Container margin={{ left: 16 }}>
                        <Item flex noGrow onClick={clearSignature}>
                          <Text>Wissen</Text>
                          <Container>
                            <Icon iconName="X" size={24} cursor="pointer" />
                          </Container>
                        </Item>
                      </Container>}
                    </Container>
                  </Container>
                )
              ) : !!data?.authorization ? (
                <Container flex>
                  <Text target="_blank" as="a" hoverDecoration="underline" href={data?.authorization.file}>
                    {data?.authorization.label}
                  </Text>
                </Container>
              ) : (
                <Container>
                  <Container margin={{ bottom: 16 }}>
                    <Item onClick={downloadAuthorizationDoc}>
                      <Text color={theme.colors.main}>Machtiging.pdf</Text>
                    </Item>
                  </Container>
                  <Container margin={{ bottom: 16 }}>
                    <Text>
                      Download het bovenstaand document, print deze uit,
                      onderteken het. Vervolgens kun je deze uploaden door op
                      het knop hieronder te drukken.
                    </Text>
                  </Container>
                  <Uploader name="authorization" onDrop={onDropFiles} />
                </Container>
              )}
            </Container>
          )}
        </Container>
        {questions && (
          <Container margin={{ bottom: 36 }}>
            <Text as="h2" mediaQuery={step1.secondaryTitle}>
              Vragenlijst
            </Text>
            <Container width={isMobile ? "100%" : "60%"} margin={{ vertical: 24 }}>
              <Text>
                Klik op de link hieronder om de vragen en de antwoorden te
                bekijken die opgestuurd zullen worden.
              </Text>
            </Container>
            <Item
              flex
              noGrow
              onClick={() => {
                props.wizard.current?.goTo(1)
              }}
            >
              <Text color={theme.colors.main}>Vragen en antwoorden</Text>
            </Item>
          </Container>
        )}
        <Button
          onClick={submit}
          large
          big
          label="Versturen"
          // TASK: WOZ-671
          mobile={{
            boxShadow: "0 3px 6px 0 rgb(0 0 0 / 10%), 0 1px 4px 0 rgb(0 0 0 / 16%)",
            weight: "bold",
            height: 64,
            size: 16,
          }}
        />
      </Container>
    </Wrapper>
  )
}

Step3.defaultProps = {}

export default memo(Step3)
