export type ReduxItemState<T> = {
  payload: T
  status?: number
  error?: any | null
}

export type EmailTemplate = {
  id: number
  name: string
  label?: string
  docId: string
  key: TemplateIds
  sort: string
  subject: string
  content: string
}

export type TextBlock = {
  id: string
  title: string
  content: string
  short_title: string
  category: 'COExport' | 'FOExport'
}

export type Municipality = {
  id: string
  name: string
  postal_code: string
  city: string
  email: string
  post_bus: string
}
export type StopCaseItem = {
  id: string
  text: string
}

export interface AppState {
  authorization: {
    isLoggedIn: ReduxItemState<boolean>
    signInHandler: ReduxItemState<unknown>
    signUpHandler: ReduxItemState<unknown>
    resetPasswordHandler: ReduxItemState<unknown>
  }
  user: {
    profile: ReduxItemState<any>
    filterForm: ReduxItemState<any>
    emptySearchFields: ReduxItemState<any>
    tableFilters: ReduxItemState<any>
    visibleColumns: ReduxItemState<any>
    templates: ReduxItemState<Record<string, EmailTemplate[]>>
    textBlocks: ReduxItemState<TextBlock[]>
    municipalities: ReduxItemState<Municipality[]>
    stopCaseList: ReduxItemState<StopCaseItem[]>
    clients: ReduxItemState<any[]>
    isCheckModalShow: ReduxItemState<boolean>
  }
}

export enum ClientRequestInfoStatus {
  'PENDING',
  'CHECKING',
  'HANDLING',
  'OBJECTION',
  'CONTENT_OBJECTION',
  'DISMISSED',
}

export enum MANAGER_ACTION_TYPES {
  'SEND_REMINDER' = 'SEND_REMINDER',
  'PHOTO_REMINDER' = 'PHOTO_REMINDER',
  'REQUEST_PHOTO' = 'REQUEST_PHOTO',
  'CHECKING' = 'CHECKING',
  'EXPORT_FO' = 'EXPORT_FO',
  'FO_OBJECTION' = 'FO_OBJECTION',
  'EXPORT_OBJECTION' = 'EXPORT_OBJECTION',
  'CO_OBJECTION' = 'CO_OBJECTION',

  'RECEIVED_DECISION' = 'RECEIVED_DECISION',
  'EXPORT_CO' = 'EXPORT_CO',
  'VIEW_OBJECTION' = 'VIEW_OBJECTION',
  'VIEW_DECISION' = 'VIEW_DECISION',
}

export enum CLIENT_ACTIONS {
  'DELIVER_DOCUMENTS' = 'DELIVER_DOCUMENTS',
  'SHOW_CO' = 'SHOW_CO',
  'SHOW_DECISION' = 'SHOW_DECISION',
}

export enum TemplateCategoryIds {
  'SEND_REMINDER', // Verstuur herinnering
  'REQUEST_PHOTO', // Foto's opvragen
  'SEND_OBJECTION_TO_MUNICIPALITY', // Proforma ingediend
  'RECEIVED_DECISION', // Uitspraak ontvangen
  'CO_OBJECTION', // Inhoudelijk bezwaar ingediend
  'FILE_STOPPED', // Dossier stopgezet
  'PROFILE_CHANGES', // Profielwijzigingen
  'CHECKING', // Stukken controleren
  'WELCOME_MESSAGE', // Welkomstbericht
  'EMAIL_SIGNATURE', // E-mail handtekening
}

export enum TemplateIds {
  'PASSWORD_NOT_CREATED' = 'password_not_created', // 8
  'CREATE_ON_WEB_SITE' = 'create_on_web_site', // 8
  'CREATE_ON_WEB_SITE_NOT_SIGNED' = 'create_on_web_site_not_signed', // 8
  'PASSWORD_NOT_CREATED_NOT_SIGNED' = 'password_not_created_not_signed', // 8
  'PASSWORD_NOT_CREATED_NOT_SIGNED_PORTAL' = 'password_not_created_not_signed_portal', // 8
  'EXISTING_USER_PORTAL' = 'existing_user_portal', // 8
  'RESET_PASSWORD' = 'reset_password', // 6
  'RESET_PASSWORD_SUCCESS' = 'reset_password_success', // 6
  'CHANGE_EMAIL' = 'change_email',
  'EMAIL_REMINDER' = 'email_reminder',
  'PHOTO_REMINDER' = 'photo_reminder',
  'REQUEST_PHOTO' = 'request_photo',
  'SEND_REMINDER' = 'send_reminder',
  'NEED_SOME_FILES' = 'need_some_files',
  'ALL_FILES_OK' = 'all_files_ok',
  'FO_IS_DELIVERY' = 'fo_is_delivery',
  'CO_DELIVERY_TO_CLIENT' = 'co_delivery_to_client',
  'CO_DELIVERY_TO_MUNICIPALITY' = 'co_delivery_to_municipality',
  'DECISION' = 'decision',
  'REASON1' = 'reason1',
  'REASON2' = 'reason2',
  'REASON3' = 'reason3',
  'REASON4' = 'reason4',
  'SEND_REASON_TO_MUNICIPALITY' = 'send_reason_to_municipality',
  'SIGNATURE' = 'signature',
  'QUESTION_LIST' = 'question_list',
  'COPY_LETTER' = 'copy_letter',
  'SIGNATURE_NEW' = 'signature_new',
  'QUESTION_LIST_NEW' = 'question_list_new',
  'COPY_LETTER_NEW' = 'copy_letter_new',
  'EMAIL_SIGNATURE' = 'email_signature',
}

export type FileData = {
  file: string
  name: string
  label: string
  status: 'approved' | 'pending' | 'reject'
}

export interface ClientRequestInfo {
  fullName: string
  id: string
  property: string
  onWebsite?: boolean
  created_date: string
  salutation: string
  objectNumber: string
  wozValue?: number
  lastName?: string
  firstName?: string
  caseNumber: string
  town: string
  municipality: Municipality
  foReceived: string
  assessmentNumber?: number
  phone: string
  buildingHouseNumber: string
  buildingPostCode: string
  buildingPlace: string
  buildingStreet: string
  buildingAddition: string
  personalHouseNumber: string
  personalPostCode: string
  personalPlace: string
  personalStreet: string
  personalAddition: string
  paymentReceived: string
  amount: string
  comment: string
  assessmentDate: string
  coReceived: string
  documentStoppedDate?: string
  isStop?: boolean
  decision?: 'true' | 'false' | 'null'
  authorization: FileData
  authorizationImage?: FileData
  decisionDocument: FileData
  objection: FileData
  taxDoc: FileData
  assessment: FileData
  photos: FileData[]
  questionsDoc: FileData
  signature: FileData
  municipalityEmail?: string
  foDelivery: string
  email: string
  questions?: {
    question1: boolean
    question2: string
    question3: string
    question4: string
    question5: string
  }
  photoReminder: string
  photoRequest: string
  documentReminder: string
  status: ClientRequestInfoStatus
  userRegistered?: boolean
}
