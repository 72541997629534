export const EMPTY_SELECT_VALUE = "EMPTY";

export const formValues = {
  assessment: [
    { label: 'Ja', value: true },
    { label: 'Nee', value: false },
  ],
  authorization: [
    { label: 'Ja', value: true },
    { label: 'Nee', value: false },
  ],
  questionsDoc: [
    { label: 'Ja', value: true },
    { label: 'Nee', value: false },
  ],
  taxDoc: [
    { label: 'Ja', value: true },
    { label: 'Nee', value: false },
  ],
  coReceived: [
    { label: 'Ja', value: true },
    { label: 'Nee', value: false },
  ],
  addressCount: [
    { label: '1 adres', value: '1' },
    { label: 'meerdere adressen', value: 'other' },
  ],
  property: [
    { label: 'woning in eigendom', value: 'woning in eigendom' },
    { label: 'gehuurde woning', value: 'gehuurde woning' },
    { label: 'bedrijfspand', value: 'bedrijfspand' },
  ],
  decision: [
    { label: 'Leeg', value: EMPTY_SELECT_VALUE },
    { label: 'Gegrond', value: 'true' },
    { label: 'Ongegrond', value: 'false' },
    { label: 'Gestopt', value: 'null' },
  ],
  howFindUs: [
    {
      label: 'Via een zoekmachine (bv. Google)',
      value: 'Via een zoekmachine (bv. Google)',
    },
    { label: 'Via een flyer', value: 'Via een flyer' },
    { label: 'Doorverwezen door iemand', value: 'Doorverwezen door iemand' },
    {
      label: 'Via een advertentie in een dagblad',
      value: 'Via een advertentie in een dagblad',
    },
    {
      label: 'Wij hebben al eerder voor u bezwaar gemaakt',
      value: 'Wij hebben al eerder voor u bezwaar gemaakt',
    },
  ],
}

type ColumnItem = {
  name: string
  label: string
  value: boolean
}

export const columns: ColumnItem[] = [
  { name: 'property', label: 'Soort pand', value: false },
  { name: 'objectNumber', label: 'Objectnummer', value: false },
  {
    name: 'foDelivery',
    label: 'Pro Forma Bezwaarschrift ingediend',
    value: false,
  },
  { name: 'decision', label: 'Uitslag', value: false },
  { name: 'reasonOfObjection', label: 'Reden bezwaar', value: false },
  { name: 'taxDoc', label: 'Taxatieverslag', value: false },
  { name: 'foReceived', label: 'Ontvangstbevestiging', value: false },
  { name: 'paymentReceived', label: 'Betaling ontvangen', value: false },
  //{ name: 'property', label: 'Dig ondertekend', value: false },
  { name: 'assessment', label: 'Aanslag', value: false },
  { name: 'caseNumber', label: 'Dossiernummer', value: false },
  { name: 'amount', label: 'Ontvangen bedrag', value: false },
  { name: 'municipality', label: 'Samenwerkingsverband', value: false },
  { name: 'questionsDoc', label: 'Vragenlijst', value: false },
  {
    name: 'coReceived',
    label: 'Inhoudelijk Bezwaarschrift ingediend',
    value: false,
  },
  { name: 'comment', label: 'Opmerkingen', value: false },
  { name: 'addressCount', label: 'Aantal adressen', value: false },
  { name: 'authorization', label: 'Machtiging', value: false },
  { name: 'howFindUs', label: 'Hoe is de klant binnengekomen?', value: false },
]

export const columnsDefaultData = () => {
  const data: any = {}

  columns.forEach((column) => {
    data[column.name] = column.value
  })
  return data
}

const decisionValue = (value: string) => {
  switch (value) {
    case 'true':
      return 'Gegrond'
    case 'false':
      return 'Ongegrond'
    case 'null':
      return 'Gestopt'
    default:
      return undefined
  }
}

const fileData = {
  authorization: true,
  decisionDocument: true,
  objection: true,
  taxDoc: true,
  assessment: true,
  questionsDoc: true,
}

export function additionalColumns(visibleColumns: Record<string, boolean>) {
  const col: any = []

  columns.forEach((column) => {
    col.push({
      name: column.label,
      sortable: true,
      selector: column.name,
      // @ts-ignore
      omit: !visibleColumns[column.name],
      cell: (row: Record<string, any>) => {
        // @ts-ignore
        if (fileData[column.name]) {
          return row[column.name] && row[column.name].status === 'approved'
            ? 'Ja'
            : 'Nee'
        } else {
          if (column.name === 'decision') {
            return decisionValue(row[column.name])
          } else if (column.name === 'addressCount') {
            return row[column.name] === '1' ? '1 adres' : 'meerdere adressen'
          } else if (column.name === 'municipality') {
            return row[column.name] ? row[column.name].name : ''
          } else {
            return row[column.name]
          }
        }
      },
    })
  })

  return col
}
