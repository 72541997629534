import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'

interface InputContainerProps {
  width?: number
  isFocus: boolean
  disabled: boolean
  size: 'default' | 'large' | 'full'
}

const _size = (props: InputContainerProps) => {
  if (props.width) {
    return ''
  }

  switch (props.size) {
    case 'large':
      return 'width: 500px;'
    case 'full':
      return 'width: 100%'
    default:
      return 'width: 250px;'
  }
}

const dynamicStyles = (props: InputContainerProps) => `
  ${
    props.isFocus
      ? `
    border: 1px solid ${defaultTheme.colors.secondary};
  `
      : ''
  }
  ${
    props.width
      ? `
    width: ${props.width}px;
  `
      : ''
  }
  ${
    props.disabled
      ? `
    background: rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(0, 0, 0, 0.1);
  `
      : ''
  }
`

const InputContainer = styled.div`
  height: 50px;
  display: flex;
  flex-grow: 1;
  border-radius: 5px;
  align-items: center;
  padding: 0 10px 0 12px;
  background: ${defaultTheme.colors.white};
  border: 1px solid ${defaultTheme.colors.gray1};

  ${_size};
  ${dynamicStyles}
`

InputContainer.displayName = 'InputContainer'

export default InputContainer
