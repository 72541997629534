import { ClientRequestInfo } from 'src/interfaces/app'
import { isBoolean, isObject } from 'src/utils/misc'
import moment from 'moment';

export function filterCondition(
  item: ClientRequestInfo,
  filterValues: any,
  emptyFilters: { [key: string]: boolean },
) {
  const conditions: any = {}

  Object.keys(filterValues).forEach((key) => {
    if (emptyFilters[key]) {
      // @ts-ignore
      if (item[key]) {
        conditions[key] = false
      }
    } else if (isBoolean(filterValues[key])) {
      // @ts-ignore
      if (filterValues[key]) {
        // @ts-ignore
        if (item[key]?.status !== 'approved') {
          conditions[key] = false
        }
      } else {
        // @ts-ignore
        if (item[key]?.status === 'approved') {
          conditions[key] = false
        }
      }
    } else if (
      // @ts-ignore
      !item[key] ||
      // @ts-ignore
      (typeof item[key] === 'string' &&
        // @ts-ignore
        !item[key]?.toLowerCase().includes(filterValues[key]?.toLowerCase()))
    ) {
      conditions[key] = false
    } else if (key === 'municipality') {
      if (!item[key] || (!!item[key] && item[key].id !== filterValues[key])) {
        conditions[key] = false
      }
    }
  })

  return !Object.keys(conditions).length
}

export function tableFilterCondition(
  item: ClientRequestInfo,
  filterValues: any
) {
  const conditions: any = {}

  Object.keys(filterValues).forEach((key) => {
    const searchText = filterValues[key]?.searchText
    // @ts-ignore
    const filters = filterValues[key]?.filters
      ? Object.keys(filterValues[key]?.filters)
          .filter((_key) => filterValues[key]?.filters[_key])
          .map((i) => i)
      : []

    if (!filters.length && searchText) {
      // @ts-ignore
      if (key === 'created_date') {
        const val = moment(item[key]).format('DD-MM-YYYY')
        if (!val.includes(searchText)) {
          conditions[key] = false
        }
      } else {
        // @ts-ignore
        if (!item[key]?.toLowerCase().includes(searchText?.toLowerCase())) {
          conditions[key] = false
        }
      }
    } else {
      // @ts-ignore
      if (key === 'status') {
        if (filters.length && !filters.includes(`${item[key]}`)) {
          conditions[key] = false
        }
      } else {
        // @ts-ignore
        if (filters.length && !filters.includes(item[key])) {
          conditions[key] = false
        }
      }
    }
  })
  return !Object.keys(conditions).length
}
