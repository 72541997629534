import React, { memo, ReactChild } from 'react'
import { Container, Loader, Spinner } from 'src/styles/styled-components'
import { isBoolean } from 'src/utils/misc'
import { ContainerStyles } from 'src/interfaces/styles'

interface WrapperProps extends ContainerStyles {
  isBusy?: boolean | number
  children: ReactChild | ReactChild[]
  // TASK: WOZ-571
  mobile?: Omit<ContainerStyles, "mobile">
}

function Wrapper(props: WrapperProps) {
  const { isBusy, ...otherProps } = props

  function needToShowIndicator(status: number | boolean) {
    if (isBoolean(status)) {
      return status
    } else {
      return status === 1
    }
  }

  return (
    <Container flex column position="relative" {...otherProps}>
      {props.children}
      {needToShowIndicator(isBusy) && (
        <Loader>
          <Spinner viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </Spinner>
        </Loader>
      )}
    </Container>
  )
}

Wrapper.defaultProps = {}

export default memo(Wrapper)
