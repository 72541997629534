import React, {
  useState,
  ReactNode,
  RefObject,
  useEffect,
  useCallback,
} from 'react'

import ReactDOM from 'react-dom'

// components
import { Container } from 'src/styles/styled-components'

interface PortalProps {
  isShow: boolean
  zIndex?: number
  children: ReactNode
  containerId: string
  parent: RefObject<HTMLElement>
  position?: 'absolute' | 'fixed'
  // methods
  onRequestClose?: (isShow: boolean) => void
}

export default function Portal(props: PortalProps) {
  const [container, setContainer] = useState<HTMLElement | null>(null)

  const {
    isShow,
    containerId,
    parent,
    zIndex = 1000,
    onRequestClose,
    position = 'absolute',
  } = props
  const [isRender, setIsRender] = useState(false)

  useEffect(() => {
    if (container) {
      container.setAttribute('id', containerId)
      document.body.appendChild(container)
      document.addEventListener('click', handleDocumentClick)
    }
  }, [container])

  useEffect(() => {
    if (isShow) {
      createContainer()
    } else {
      removeContainer()
    }
  }, [isShow])

  const handleDocumentClick = useCallback(
    (e: Event) => {
      if (
        onRequestClose &&
        !parent?.current?.contains(e.target as Node) &&
        !container?.contains(e.target as Node)
      ) {
        onRequestClose(false)
      }
    },
    [parent, container]
  )

  function getPosition() {
    if (parent?.current) {
      const size: DOMRect = parent.current.getBoundingClientRect()
      const offset = position === 'fixed' ? 0 : window.pageYOffset

      return {
        width: size.width,
        height: size.height,
        left: size.left,
        top: size.top + size.height + offset,
      }
    }

    return {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
    }
  }

  function createContainer() {
    if (!isRender) {
      setContainer(document.createElement('div'))

      setIsRender(true)
    }
  }

  function removeContainer() {
    if (container) {
      document.removeEventListener('click', handleDocumentClick)
      document.body.removeChild(container)
      setContainer(null)
      setIsRender(false)
    }
  }

  function renderContainer() {
    if (typeof props.children === 'function') {
      return props.children(getPosition())
    }

    const size = getPosition()
    let pr = {
      left: size.left,
    }
    // @ts-ignore
    if (!props.customSize) {
      pr = {
        // @ts-ignore
        width: size.width,
        left: size.left,
      }
    }
    return (
      <Container zIndex={zIndex} top={size.top} {...pr} position={position}>
        {typeof props.children === 'function'
          ? props.children(getPosition())
          : props.children}
      </Container>
    )
  }

  return isRender && container
    ? ReactDOM.createPortal(renderContainer(), container)
    : null
}
