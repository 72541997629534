import { FormData } from 'src/hooks/useForm'
import { isPasswordValid } from 'src/utils/misc'
import {
  Question,
  QuestionData,
} from 'src/services/questions/generateQuestions'

export const QUESTION_LIST_FORM: (data: any[]) => FormData = (
  data: QuestionData[]
) => {
  const fromData: FormData = {}

  data.forEach((item: QuestionData) => {
    const { questions } = item

    questions.forEach((q: Question, index: number) => {
      const name = `${item.title.toLowerCase()}_${index}`
      const dependencies = q?.condition
        ? q.condition.questions.map((_, i) => `${name}_${i}`)
        : null
      fromData[name] = {
        name,
        value: '',
        label: q.question,
        exclude: true,
        validationType: q.required ? 'required' : null,
        dependencies,
        validationText:
          'De bovenstaande vraag is verplicht. Geef a.u.b. uw antwoord.',
      }

      if (q.condition) {
        q.condition.questions.forEach((_q: Question, i: number) => {
          const _name = `${name}_${i}`
          fromData[_name] = {
            name: _name,
            value: '',
            exclude: true,
            label: _q.question,
            validationText:
              'De bovenstaande vraag is verplicht. Geef a.u.b. uw antwoord.',
            validation: (value: string, d: FormData) => {
              return d[name].value === q?.condition?.isNext && _q.required
                ? !!value.trim().length
                : true
            },
          }
        })
      }
    })
  })

  return fromData
}
