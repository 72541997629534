import React, {
  memo,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react'

import Input, { InputProps } from './../input'
import TextArea, { TextAreaProps } from './../textarea'
import { Container } from 'src/styles/styled-components'
import { DatePicker, Select } from 'src/components';

interface FormElementProps extends InputProps, TextAreaProps {
  edit: boolean
  withPortal?: boolean,
  children?: ReactNode
  formType?: 'input' | 'textarea' | 'datepicker' | 'select'
  inlineUpdate?: (value: string, name: string) => void
  onChange?: (value: string, name: string, e?: SyntheticEvent) => void
}

function FormElement(props: FormElementProps) {
  const {
    children,
    inlineUpdate,
    onChange,
    formType = 'input',
    edit = false,
    value,
    ...otherProps
  } = props

  const container = useRef(null)

  const [height, setHeight] = useState('auto')
  const [_value, setValue] = useState(value)

  useEffect(() => {
    if (container.current) {
      const _height =
        container.current.clientHeight > 138
          ? container.current.clientHeight
          : 138
      setHeight(_height)
    }
  }, [container])

  function handleOnChange(value: string, name: string, e?: SyntheticEvent) {
    if (inlineUpdate && formType === 'input') {
      setValue(value)
    } else {
      return onChange(value, name, e)
    }
  }

  function handleOnBlur() {
    if (inlineUpdate) {
      inlineUpdate(_value, otherProps.name)
    }
  }

  function renderFormElement() {
    switch (formType) {
      case 'textarea':
        return (
          <TextArea
            {...otherProps}
            height={height}
            value={inlineUpdate ? _value : value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
        )
      case 'select':
        return (
          <Select
            {...otherProps}
            onSelect={handleOnChange}
            value={inlineUpdate ? _value : value}
          />
        )
      case 'datepicker':
        return (
          <DatePicker
            {...otherProps}
            onChange={handleOnChange}
            value={inlineUpdate ? _value : value}
          />
        )
      default:
        return (
          <Input
            {...otherProps}
            value={inlineUpdate ? _value : value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
        )
    }
  }

  return (
    <Container width="100%" flex={!otherProps.isWysiwyg} ref={container}>
      {edit ? renderFormElement() : children || null}
    </Container>
  )
}

FormElement.defaultProps = {}

export default memo(FormElement)
