import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'

//styled
// TASK: WOZ-571
import { Container } from 'src/styles/styled-components'
import logo from 'src/assets/images/logo.svg'
import { Wrapper } from 'src/components'
import { useSelector } from 'react-redux'
import { signUpHandlerSelector } from 'src/selectors'
import RegistrationForm from 'src/forms/authorization/RegistrationForm'
import { getToken } from 'src/services/api'
import { useHistory, useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { useAlertOptions } from 'src/hooks/useAlertOptions'

function Registration() {
  const theme = useContext(ThemeContext)

  const history = useHistory()
  const params = useParams<{ token: string, subpath?: 'documents'|'wizard'|'photos' }>()

  const [isShow, setIsShow] = useState(false)
  const [tokenData, setTokenData] = useState(null)
  const { status } = useSelector(signUpHandlerSelector)
  const alert = useAlert()
  const alertOptions = useAlertOptions()

  useEffect(() => {
    getToken(params.token).then((r) => {
      if (!r.docs.length) {
        history.push('/')
      } else {
        const item: any = {
          id: r.docs[0].id,
          ...r.docs[0].data()
        }
        if (item.expired) {
          history.push('/')
          return
        }
        if (item.type === 'change_email_client_info') {
          alert.success('Dossier succesvol overgenomen', alertOptions)
        }
        setTokenData({ ...item, subpath: params.subpath })
        setIsShow(true)
      }
    })
  }, [])

  return (
    isShow && (
      <Wrapper
        flex
        column
        height="100vh"
        isBusy={status}
        mainAxis="center"
        crossAxis="center"
        mobile={{ padding: { horizontal: 10 } }}
      >
        <Container margin={{ bottom: 32 }}>
          <img width={300} src={logo} alt="WOZBEZWAAR.BIZ" />
        </Container>
        <Container
          flex
          column
          noGrow
          radius={3}
          width={500}
          mobile={{ maxWidth: 500, width: "unset" }}
          padding={{ horizontal: 30, vertical: 20 }}
          border={`1px solid ${theme.colors.gray0}`}
          background={theme.colors.gray2}
        >
          <RegistrationForm tokenData={tokenData} />
        </Container>
      </Wrapper>
    )
  )
}

Registration.defaultProps = {}

export { default as RedirectFromRegistration } from "./RedirectFromRegistration"

export default {
  component: Registration,
  exact: true,
  public: true,
  path: '/registration/:token/:subpath?',
}
