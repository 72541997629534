import { Action, combineReducers } from 'redux'

import authorizationReducer from './authorization'
import userReducer from './user'
import { AppState } from 'src/interfaces/app'

const appReducer = combineReducers({
  user: userReducer,
  authorization: authorizationReducer,
})

const rootReducer = (state: AppState, action: Action) => {
  return appReducer(state, action)
}

export default rootReducer
