import { createGlobalStyle } from 'styled-components'
import defaultTheme from 'src/styles/theme'
import { MOBILE_MAX_SIZE } from "src/constants";

// Prompt-Regular
import OpenSansTTF from 'src/assets/fonts/OpenSans-Regular.ttf'
import OpenSansWOFF from 'src/assets/fonts/OpenSans-Regular.woff'
import LatoRegularTTF from 'src/assets/fonts/Lato-Regular.ttf'

// TASK: WOZ-634
// TASK: WOZ-633
export const GlobalStyles = createGlobalStyle`
    /* Fonts */
    @font-face {
      font-family: 'OpenSans';
      src: url(${OpenSansTTF}), url(${OpenSansWOFF});
    }
    @font-face {
      font-family: 'Lato-Regular';
      src: url(${LatoRegularTTF});
    }
    /* Fonts */
    figure {
      margin-left: 0 !important;
    }
    body {
      margin: 0;
      padding: 0;
      color: #333F52;
    }
    .SingleDatePicker_picker {
      z-index: 1100;
    }
    .DayPickerKeyboardShortcuts_show__bottomRight {
      display: none;
    }
    .CalendarDay__default:hover {
      background-color: ${defaultTheme.colors.main};
      color: white;
    }
    td.CalendarDay__selected,
    td.CalendarDay__selected:active,
    td.CalendarDay__selected:hover {
      background: ${defaultTheme.colors.darkBlue};
      border: none;
    }
    .DayPickerNavigation.DayPickerNavigation__horizontal > div:hover {
      background-color: ${defaultTheme.colors.main};
    }
    .DayPickerNavigation.DayPickerNavigation__horizontal > div:active {
      background-color: ${defaultTheme.colors.darkBlue};
    }
    .DayPickerNavigation.DayPickerNavigation__horizontal > div:hover > svg {
      fill: white;
    }
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video, input, textarea {
      //margin: 0;
      //padding: 0;
      border: 0;
      outline: none;
      text-decoration: none;
      font-weight: normal;
      font-family: OpenSans, serif;
    };
    a:hover {
      text-decoration: underline;
    }
    button {
      outline: none;
    }
    *, ::after, ::before {
      box-sizing: border-box;
    };

    ::-webkit-scrollbar {
      width: 10px;
      border-radius: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
       border-radius: 20px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 20px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    .rdt_TableHeader {
      display: none !important;
    }
    .sigCanvas {
      border: 1px solid gray;
    }
    @media (max-width: ${MOBILE_MAX_SIZE}px) {
      .sigCanvas {
        max-width: -webkit-fill-available;
      }
    }
    .sigCanvasDisabled {
      background-color: #dedede;
    }
    .demo-wrapper {
      border-radius: 5px;
      margin-bottom: 16px;
      border: 1px solid ${defaultTheme.colors.gray1};
    }
    .demo-editor {
      padding: 0 16px;
      height: auto;
    }
    .rdt_Pagination {
      justify-content: center;
    }
    .rdw-image-center, .rdw-image-right {
      display: flex;
      justify-content: flex-start !important;
    }
    .rdw-image-alignment-options-popup {
      display: none;
    }


    .isStopped {
      background-color: ${defaultTheme.colors.gray6};
    }
    .hasDecision {
      background-color: ${defaultTheme.colors.green1};
      color: ${defaultTheme.colors.white};
    }
    .hasNotDecision {
      background-color: ${defaultTheme.colors.orange};
      color: ${defaultTheme.colors.white};
    }
    .ant-table-thead > tr > th {
      background: ${defaultTheme.colors.blue0};
      color: ${defaultTheme.colors.white};
      font-size: 12px;
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover {
      background: ${defaultTheme.colors.blue0};
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
      background: ${defaultTheme.colors.blue0};
    }
    .ant-table-filter-trigger-container-open, .ant-table-filter-trigger-container:hover, .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
      background: ${defaultTheme.colors.blue0};
    }
`
