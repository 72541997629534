import React, { memo, useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Container, Text } from 'src/styles/styled-components'
import { Button, Checkbox, Icon } from 'src/components'
import { ClientRequestInfo } from 'src/interfaces/app'
import FileUploader from 'src/pages/clientDetail/components/FileUploader'
import { useParams } from 'react-router-dom'
import { editClient, getClientInfoById } from 'src/services/api'
import Item from 'src/helpers/Item'
import {
  generateCopyLetter,
  generateEtiket,
  generateIntroLetter,
  generateQuestionsListPDF,
  generateSignatureExample,
} from 'src/services/pdfGenerator'
import { useActions } from 'redux-exodus';
import { actions } from 'src/redux/user';

interface UploadDocumentsProps {
  isAdmin: boolean
  updateData: () => void
  data: ClientRequestInfo
}

function UploadDocuments(props: UploadDocumentsProps) {
  const { data, isAdmin, updateData } = props

  const theme = useContext(ThemeContext)
  const [expand, setExpand] = useState(false)
  const params = useParams<{ id: string }>()
  const { showCheckModal } = useActions(actions)
  async function handleUpdate() {
    await updateData()
    const _data = await getClientInfoById(params.id)
    if (isAdmin) {
      const condition =
        !!_data?.assessment?.file ||
        !!_data?.authorization?.file ||
        !!_data?.questionsDoc?.file
      const allFilesOk =
        !!_data?.assessment?.file &&
        !!_data?.authorization?.file &&
        !!_data?.questionsDoc?.file
      const isNotExist =
        !_data?.assessment?.file &&
        !_data?.authorization?.file &&
        !_data?.questionsDoc?.file

      if (allFilesOk) {
        showCheckModal(true)
        updateData()
      }
      if (_data.status < 1 && condition) {
        await editClient(params.id, {
          status: 1,
        })
        updateData()
      } else if (_data.status === 1 && isNotExist) {
        await editClient(params.id, {
          status: 0,
        })
        updateData()
      }
    }
  }

  function expandPhotos() {
    setExpand(!expand)
  }

  async function exportCopyLetter() {
    const doc = await generateCopyLetter(data)
    await doc.download()
  }

  async function exportIntroLetter() {
    const doc = await generateIntroLetter(data)
    await doc.download()
  }

  async function exportEtiket() {
    generateEtiket(data).download()
  }

  async function exportSignature() {
    const doc = await generateSignatureExample(data)
    doc.download()
  }

  async function exportQuestions() {
    if (data?.questionsDoc) {
      window.open(data.questionsDoc.file, '_blank')
    } else {
      const address = `${data.buildingStreet} ${data.buildingHouseNumber}${
        data.buildingAddition ? ` ${data.buildingAddition}` : ''
      } te ${data.buildingPlace}`

      await (await generateQuestionsListPDF(data, address)).download()
    }
  }

  return (
    <Container padding={{ left: 24 }} margin={{ bottom: 36 }}>
      <Container>
        {/* TASK: WOZ-630 */}
        <Text size={18} weight="bold">Documenten</Text>
        <Container margin={{ top: 16 }}>
          <FileUploader
            label="Machtiging"
            name="authorization"
            clientInfoId={params.id}
            onUpdate={handleUpdate}
            fileData={data?.authorization}
          />
          {data?.authorizationImage?.file && <FileUploader
            label="Machtiging (pdf/jpeg)"
            name="authorizationImage"
            clientInfoId={params.id}
            onUpdate={handleUpdate}
            fileData={data?.authorizationImage}
          />}
          <FileUploader
            label="Aanslag"
            name="assessment"
            clientInfoId={params.id}
            onUpdate={handleUpdate}
            fileData={data?.assessment}
          />
          <FileUploader
            label="Vragenlijst"
            name="questionsDoc"
            clientInfoId={params.id}
            onUpdate={handleUpdate}
            fileData={data?.questionsDoc}
          />
        </Container>
      </Container>
      <Container margin={{ top: 32 }}>
        <Container margin={{ bottom: 16 }}>
          <Text size={18} weight="bold">Overige bijlagen</Text>
        </Container>
        <FileUploader
          label="Taxatieverslag"
          name="taxDoc"
          clientInfoId={params.id}
          onUpdate={updateData}
          fileData={data?.taxDoc}
        />
        <FileUploader
          label="Inhoudelijk bezwaar"
          name="objection"
          clientInfoId={params.id}
          onUpdate={updateData}
          fileData={data?.objection}
        />
        <FileUploader
          label="Uitslag"
          onUpdate={updateData}
          name="decisionDocument"
          clientInfoId={params.id}
          fileData={data?.decisionDocument}
        />
        {data?.photos ? (
          <>
            {data.photos.slice(0, 3).map((photo, i) => {
              return (
                <FileUploader
                  data={data}
                  label={`Foto ${i + 1}`}
                  name="photos"
                  key={photo.file}
                  clientInfoId={params.id}
                  onUpdate={updateData}
                  fileData={photo}
                />
              )
            })}
            {data.photos.length > 3 && (
              <Container>
                {expand &&
                  data.photos.slice(3).map((photo, i) => {
                    return (
                      <FileUploader
                        data={data}
                        multiple
                        label={`Foto ${i + 4}`}
                        name="photos"
                        key={photo.file}
                        clientInfoId={params.id}
                        onUpdate={updateData}
                        fileData={photo}
                      />
                    )
                  })}
                <Item
                  flex
                  mainAxis="center"
                  margin={{ vertical: 8 }}
                  onClick={expandPhotos}
                >
                  <Text color={theme.colors.main}>
                    {expand ? 'Toon minder' : 'Toon alles'}
                  </Text>
                </Item>
              </Container>
            )}
            <FileUploader
              label=""
              multiple
              maxFiles={40 - (data?.photos || []).length}
              data={data}
              name="photos"
              icon="Plus"
              clientInfoId={params.id}
              onUpdate={updateData}
            />
          </>
        ) : (
          <FileUploader
            multiple
            maxFiles={40 - (data?.photos || []).length}
            label="Foto"
            name="photos"
            data={data}
            clientInfoId={params.id}
            onUpdate={updateData}
          />
        )}
      </Container>
      {isAdmin && (
        // TASK: WOZ-631
        <Container borderTop="1px solid black" padding={{ top: 50 }}>
          <Container margin={{ bottom: 16 }}>
            <Button
              large
              label="Exporteer voorblad"
              onClick={exportCopyLetter}
            />
          </Container>
          <Container margin={{ bottom: 16 }}>
            <Button
              large
              label="Print etiket"
              onClick={exportEtiket}
            />
          </Container>
          <Container margin={{ bottom: 16 }}>
            <Button
              large
              label="Exporteer machtiging"
              onClick={exportSignature}
            />
          </Container>
          <Container margin={{ bottom: 16 }}>
            <Button
              large
              label="Exporteer vragenlijst"
              onClick={exportQuestions}
            />
          </Container>
          <Container margin={{ bottom: 16 }}>
            <Button
              large
              label="Exporteer klantenbrief"
              onClick={exportIntroLetter}
            />
          </Container>
        </Container>
      )}
    </Container>
  )
}

UploadDocuments.defaultProps = {}

export default memo(UploadDocuments)
