import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'
import Container from './Container'

const defaultProps = {
  flex: true,
  mainAxis: 'center',
  crossAxis: 'center',
  theme: defaultTheme,
}

const Loader = styled(Container)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
`

Loader.displayName = 'Loader'
Loader.defaultProps = defaultProps

export default Loader
