import React, { memo, useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { useHistory } from 'react-router-dom'

//components
import { Input, Button, Modal, Wrapper } from 'src/components'

//styled
import { Container, Text } from 'src/styles/styled-components'
import Item from 'src/helpers/Item'
import { getUser, updateUserEmail } from 'src/services/api'
import { useAlert } from 'react-alert'
import { EMAIL_ERROR_TEXT } from 'src/constants'
import { isEmail } from 'src/utils/misc'
import { useSelector } from 'react-redux'
import { profileSelector } from 'src/selectors'
import { useAuth } from 'src/hooks/useAuth'

function UserInfo() {
  const theme = useContext(ThemeContext)
  const alert = useAlert()
  const user = getUser()
  const auth = useAuth()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState(user?.email)
  const profile = useSelector(profileSelector)
  const [errorText, setErrorText] = useState('')

  function _showModal() {
    setShowModal(true)
  }
  function hideModal() {
    setShowModal(false)
  }

  async function updateEmail() {
    setIsLoading(true)
    if (!isEmail(email)) {
      setErrorText(EMAIL_ERROR_TEXT)
    } else {
      const name = `${profile.payload?.first_name} ${profile.payload?.last_name}`
      const result = await updateUserEmail(email, profile.payload?.email)
      if (!result.success) {
        alert.error(result.message)
      } else {
        alert.success('Success')
      }
    }
    hideModal()
    setEmail(user?.email)
    setIsLoading(false)
  }

  async function resetPassword() {
    history.push('/forgot-password', {
      title: 'Wachtwoord wijzigen',
      desc:
        'Voer hieronder uw e-mailadres in om uw wachtwoord opnieuw in te stellen en klik op de knop hieronder. Vervolgens ontvangt u een e-mail van ons. Volg de aanwijzingen in de e-mail om uw wachtwoord aan te passen.',
    })
  }

  function handleChangeEmail(value: string) {
    setErrorText('')
    setEmail(value)
  }

  return (
    <Wrapper isBusy={isLoading} width={375}>
      <Text color={theme.colors.gray5} margin={{ bottom: 15 }}>
        E-mail en wachtwoord
      </Text>
      {auth.isAdmin && (
        <Container flex noGrow crossAxis="center" margin={{ top: 15 }}>
          <Input
            name="email"
            value={email}
            errorText={errorText}
            onChange={handleChangeEmail}
          />
          <Container margin={{ left: 15 }}>
            <Item onClick={_showModal}>
              <Text color={theme.colors.main} hoverDecoration="underline">Wijzigen</Text>
            </Item>
          </Container>
        </Container>
      )}
      <Container flex noGrow crossAxis="center" margin={{ top: 15 }}>
        <Button label="Wachtwoord wijzigen" onClick={resetPassword} />
      </Container>
      <Modal modalSize="small" isShow={showModal} onRequestClose={hideModal}>
        <Container padding={{ horizontal: 16 }} column flex crossAxis="center" mainAxis="space-between">
          <Text>
            Weet u het zeker dat u het e-mailadres wilt aanpassen? Er zal een
            e-mail worden verstuurd naar het nieuwe e-mailadres om dit te
            bevestigen. Na bevestiging zal het e-mailadres definitief aangepast
            worden.
          </Text>
          <Container flex noGrow margin={{ bottom: 24 }}>
            <Container margin={{ right: 16 }}>
              <Button styleType="remove" label="Cancel" big onClick={hideModal} />
            </Container>
            <Container>
              <Button label="Change" onClick={updateEmail} big />
            </Container>
          </Container>
        </Container>
      </Modal>
    </Wrapper>
  )
}

export default memo(UserInfo)
