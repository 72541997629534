import React from "react";

const Table: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21">
        <g fill="none" fillRule="evenodd">
            <g fill="url(#mainGradient)" fillRule="nonzero" transform="translate(-13.000000, -153.000000)">
                <g>
                    <path d="M21.75 0H2.25C1.007 0 0 .992 0 2.217v16.256c0 1.224 1.007 2.217 2.25 2.217h19.5c1.243 0 2.25-.993 2.25-2.217V2.217C24 .992 22.993 0 21.75 0zM7.5 19.212H2.25c-.414 0-.75-.331-.75-.74v-3.694h6v4.434zm0-5.912h-6V8.867h6V13.3zm0-5.91h-6V2.955h6v4.433zM15 19.211H9v-4.434h6v4.434zm0-5.912H9V8.867h6V13.3zm0-5.91H9V2.955h6v4.433zm7.5 7.388v3.695c0 .408-.336.739-.75.739H16.5v-4.434h6zm0-1.478h-6V8.867h6V13.3zm0-5.91h-6V2.955h6v4.433z" transform="translate(13.000000, 153.000000)" />
                </g>
            </g>
        </g>
    </svg>
);

export default Table;
