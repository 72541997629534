import React, { CSSProperties, memo, useContext, useMemo } from 'react'
import DataTable, { IDataTableProps } from 'react-data-table-component'
import { ThemeContext } from 'styled-components'

import Icon from '../icon'
import { Container } from 'src/styles/styled-components'

// TASK: WOZ-573
export interface TableProps extends Omit<IDataTableProps, 'data'> {
  data: Record<string, any>[]
  rowStyle?: CSSProperties
}

function Table(props: TableProps) {
  const theme = useContext(ThemeContext)

  const customStyles = {
    rows: {
      style: {
        ...props.rowStyle,
        minHeight: '37px', // override the row height
        '&:not(:last-of-type)': {
          borderBottomStyle: 'none',
        },
      },
      stripedStyle: {
        color: theme.colors.gray5,
        backgroundColor: '#f1f4f8',
      },
      highlightOnHoverStyle: {
        backgroundColor: '#c5d0de',
      },
    },
    head: {
      style: {
        height: '37px',
      },
    },
    headCells: {
      style: {
        height: '37px',
        color: theme.colors.white,
        backgroundColor: theme.colors.secondary,
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
      activeSortStyle: {
        color: theme.colors.white,
        '& span.__rdt_custom_sort_icon__ div svg': {
          width: '10px !important',
        },
      },
      inactiveSortStyle: {
        color: theme.colors.white,
        '&:hover': {
          color: theme.colors.white,
        },
        '& span.__rdt_custom_sort_icon__ div svg': {
          width: '10px !important',
        },
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  }

  const renderSortIcon = useMemo(() => {
    return (
      <Container margin={{ left: 5 }}>
        <Icon iconName="ChevronUp" size={10} />
      </Container>
    )
  }, [])

  return (
    <DataTable
      striped
      highlightOnHover
      //sortIcon={renderSortIcon}
      customStyles={customStyles}
      {...props}
    />
  )
}

Table.defaultProps = {}

export default memo(Table)
