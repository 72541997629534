import React, { memo, ReactNode, useMemo, useState } from 'react'
import { Container, Text } from 'src/styles/styled-components'
import Item from 'src/helpers/Item'

// styled
import TabContainer from './styled/TabContainer'
import TabItem from './styled/TabItem'

type Title = {
  text: string
}

// TASK: WOZ-637
const TitleComponent = (
  { isActive, index, onTabClick, title }:
  {
    isActive: boolean
    index: number
    onTabClick: (data: any) => void
    title: { text: string }
  }
) => {
  const [hover, setHover] = useState(false)

  return (
    <TabItem isActive={isActive || hover}>
      <Item
        data={index}
        onClick={onTabClick}
        onHover={setHover}
      >
        <Text size={15}>{title.text}</Text>
      </Item>
    </TabItem>
  )
}

interface TabProps {
  titles: Title[]
  fillHeight?: boolean
  currentIndex?: number
  children: ReactNode[]
  onTabChange?: (index: number) => void
}

function Tab(props: TabProps) {
  const { titles, currentIndex = 0, fillHeight = false, onTabChange } = props

  const [tabIndex, setTabIndex] = useState(currentIndex)

  const renderTitles = useMemo(() => {
    function onTabClick(i: number) {
      setTabIndex(i)
      onTabChange && onTabChange(i)
    }

    return (
      <Container flex padding={{ horizontal: 15 }}>
        {titles?.map((title: Title, index: number) => {
          const isActive = index === tabIndex

          return (
            <TitleComponent {...{ index, isActive, onTabClick, title, key: index }} />
          )
        })}
      </Container>
    )
  }, [titles, onTabChange, tabIndex])

  return (
    <TabContainer fillHeight={fillHeight}>
      {renderTitles}
      <Container
        borderTop="1px solid #dfe2e5"
        padding={{ horizontal: 30, top: 15 }}
      >
        {props.children[tabIndex]}
      </Container>
    </TabContainer>
  )
}

Tab.defaultProps = {}

export default memo(Tab)
