import React, { memo, useEffect, useState } from 'react'

import { Button, Uploader, Radio, Wrapper } from 'src/components'

import { Col, Container, Row, Text, Image } from 'src/styles/styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import {
  editClient,
  sendEmail,
  uploadFiles,
} from 'src/services/api'
import {
  isArray,
} from 'src/utils/misc'
import { ClientRequestInfo } from 'src/interfaces/app'
import {
  DELIVERY_PHOTOS_CONTENT,
} from 'src/constants/emailContents'

//mediaquery
import { step1 } from 'src/styles/mediaQuery/step1'
import { useScreenSize } from 'src/hooks/useScreenSize'

interface Step3Props {
  wizard: any
  formData: any
  questions: any
  questionsListFile: any
  updateData: () => void
  data: ClientRequestInfo
  showSuccesPage?: () => void
}

function Step4(props: Step3Props) {
  const { data, questions, questionsListFile, showSuccesPage } = props

  const history = useHistory()
  const alert = useAlert()
  const params = useParams<{ id: string }>()
  const isMobile = useScreenSize()

  const [uploadPhotos, setUploadPhotos] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState<Record<string, File | File[]>>({
    ...questionsListFile,
  })

  useEffect(() => {
    props.wizard.current?.updateStepValidation(2, true)
  }, [])

  function onDropFiles(file: File | File[], name: string) {
    setFiles((prevState) => {
      return {
        ...prevState,
        [name]: file,
      }
    })
  }

  async function submit() {
    const _photos = !data.photos && uploadPhotos && !files.photos
    if (_photos) {
      // TASK: WOZ-671
      alert.error("Upload eerst foto’s om verder te gaan.")
    } else {
      if (uploadPhotos) {
        setIsLoading(true)

        const imageUploads: any[] = []

        for (const key of Object.keys(files)) {
          const data: File | File[] = files[key]
          // @ts-ignore
          for (const f of data) {
            imageUploads.push(uploadFiles(f, key, 'approved'))
          }
        }

        Promise.all(imageUploads).then(async (result) => {
          const updatedData: any = {}

          if (result.length) {
            result.forEach((item) => {
              if (updatedData[item.name] && isArray(updatedData[item.name])) {
                updatedData[item.name].push(item.file)
              } else if (item.name === 'photos') {
                updatedData[item.name] = [item.file]
              } else {
                updatedData[item.name] = item.file
              }
            })
          }

          const oldData = data?.photos || null

          const _photos =
            updatedData?.photos && oldData
              ? [...oldData, ...updatedData?.photos]
              : updatedData?.photos && !oldData
              ? updatedData?.photos
              : oldData

          await editClient(params.id, {
            ...updatedData,
            photos: _photos,
          })

          if (Object.keys(files).length) {
            try {
              const buildingAddress = `${data.buildingStreet} ${data.buildingHouseNumber} te ${data.buildingPlace}`
              if (files.photos) {
                await sendEmail({
                  to: 'info@wozbezwaar.biz',
                  //to: 'bayazetyan@gmail.com',
                  subject: `Foto's aangeleverd voor ${buildingAddress}`,
                  body: DELIVERY_PHOTOS_CONTENT(data),
                })
              }
            } catch (e) {}
          }
          setIsLoading(false)
          alert.success(
            'De foto\'s zijn succesvol geüpload. We gaan ze toevoegen aan het het inhoudelijke bezwaarschrift.',
            {
              timeout: 10000,
            }
          )
          if (isMobile) {
            showSuccesPage()
          } else {
            history.replace(`/client_detail/${params.id}`)
          }
        })
      } else {
        if (isMobile) {
          showSuccesPage()
        } else {
          history.replace(`/client_detail/${params.id}`)
        }
      }
    }
  }

  return (
    <Wrapper isBusy={isLoading} padding={16} mobile={{ padding: { horizontal: "unset" } }}>
      <Text size={32} mediaQuery={step1.mainTitle}>
        {questions ? "Stap 4 van 4 - Foto's uploaden" : "Foto's uploaden"}
      </Text>
      {/* TASK: WOZ-646 */}
      <Container width={820} mediaQuery={step1.content}>
        <Container margin={{ bottom: 36 }}>
          {!isMobile && (<Container flex mediaQuery={step1.radioContainer}>
            <Radio
              value={true}
              label="Nu uploaden"
              name="question1"
              onCheck={setUploadPhotos}
              checked={uploadPhotos}
            />
            <Radio
              margin={{ left: 16 }}
              label="Later aanleveren"
              value={false}
              name="question1"
              onCheck={setUploadPhotos}
              checked={!uploadPhotos}
              mediaQuery={step1.radio}
            />
          </Container>)}
          <Container margin={{ top: 24 }}>
            <Text>
              {isMobile ?
                'Klik hieronder om de gevraagde foto\'s te uploaden en aan te leveren.' :
                'Upload hieronder uw foto\'s om deze met ons te delen.'
              }
            </Text>
          </Container>
          {uploadPhotos && isMobile && (
            <Container margin={{ top: 24 }}>
              <Uploader
                maxFiles={40 - (data?.photos || []).length}
                multiple
                name="photos"
                onDrop={onDropFiles}
              />
            </Container>
          )}
          <Container margin={{ top: 24 }}>
            <Container>
              <Row margin={{ bottom: 24 }} mobile={{ grid: 2 }}>
                {data?.photos?.map((photo) => {
                  return (
                    <Col key={photo.file}>
                      <Container>
                        <Container
                          flex
                          noGrow
                          as="a"
                          // TASK: WOZ-633
                          hoverDecoration="underline"
                          href={photo.file}
                          target="_blank"
                        >
                          <Image
                            src={photo.file}
                            width={150}
                            style={isMobile
                              ? { width: 'unset', maxWidth: 136, objectFit: 'cover' }
                              : {}
                            }
                            height={200}
                          />
                        </Container>
                        <Text
                          as="a"
                          target="_blank"
                          href={photo.file}
                          hoverDecoration="underline"
                          mobile={{ width: 136, ellipsis: true }}
                        >
                          {photo.label}
                        </Text>
                      </Container>
                    </Col>
                  )
                })}
              </Row>
              {uploadPhotos && !isMobile && (
                <Uploader
                  maxFiles={40 - (data?.photos || []).length}
                  multiple
                  name="photos"
                  onDrop={onDropFiles}
                />
              )}
            </Container>
          </Container>
        </Container>
        <Button
          onClick={submit}
          large
          big
          label="Versturen"
          mobile={{
            boxShadow: "0 3px 6px 0 rgb(0 0 0 / 10%), 0 1px 4px 0 rgb(0 0 0 / 16%)",
            weight: "bold",
            height: 64,
            size: 16,
          }}
        />
      </Container>
    </Wrapper>
  )
}

Step4.defaultProps = {}

export default memo(Step4)
