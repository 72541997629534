// TASK: WOZ-573
import React, { useContext, useMemo } from "react";
import { Container, Text } from 'src/styles/styled-components'
import Item from "src/helpers/Item";
import { useActions } from "redux-exodus";
import { actions } from 'src/redux/authorization'
import { ThemeContext } from "styled-components";

export interface SuccessPageProps {
  type: 'documents'|'photos';
  onLogout?: () => void
}

const SuccessPage = ({ type, onLogout }: SuccessPageProps) => {
  const { signOut } = useActions(actions)
  const theme = useContext(ThemeContext)
  const { title, info, show } = useMemo(() => {
    switch (type) {
      case "documents": {
        return {
          title: "Document(en) succesvol verstuurd!",
          info: "Hartelijk dank voor het aanleveren van één of meerdere documenten. Klik op de link hieronder om uit te loggen.",
          show: true,
        }
      }
      case "photos": {
        return {
          title: "Foto(s) succesvol verstuurd!",
          info: "Bedankt voor het aanleveren van één of meerdere foto’s. Klik op de link hieronder om uit te loggen.",
          show: true,
        }
      }
      default: {
        return {
          show: false,
        }
      }
    }
  }, [type])

  if (!show) {
    return null
  }

  return (
    <Container textAlign="center">
      <Text as="h1">{title}</Text>
      <Text as="p">{info}</Text>
      <Container margin={{ top: 40 }}>
        <Item onClick={() => {
          onLogout && onLogout()
          signOut();
        }}>
          <Text size={14} color={theme.colors.main}>
            Uitloggen
          </Text>
        </Item>
      </Container>
    </Container>
  )
}

export default SuccessPage
