import React, { memo, useState } from 'react'
import { Col, Container, Row, Text } from 'src/styles/styled-components'
import { Button, Checkbox, Wrapper } from 'src/components'
import { editClient } from 'src/services/api'
import { useAlert } from 'react-alert'
import { useParams } from 'react-router-dom'
import { ClientRequestInfo, TextBlock } from 'src/interfaces/app'
import { generateCO, generateFO } from 'src/services/pdfGenerator'
import { hasKey } from 'src/utils/misc'

interface ExportCOProps {
  data: TextBlock[]
  updateData: () => void
  onRequestSent: () => void
  clientInfo: ClientRequestInfo
}

function ExportCO(props: ExportCOProps) {
  const { updateData, onRequestSent, data, clientInfo } = props

  const alert = useAlert()
  const [isLoading, setIsLoading] = useState(false)

  const [selectedBlocks, setSelectedBlocks] = useState({})

  async function submit() {
    setIsLoading(true)
    const doc = await generateCO(clientInfo, {
      municipality: clientInfo?.municipality,
      selectedBlocks,
    })
    await doc.download()
    onRequestSent()
    await updateData()
    setIsLoading(false)
    alert.success('Export success')
  }

  function selectBlocks(value: boolean, name: string) {
    const _data = data.find((i: TextBlock) => i.id === name)
    const cloneData: Record<string, TextBlock> = { ...selectedBlocks }

    if (hasKey(cloneData, name)) {
      delete cloneData[name]
    } else {
      cloneData[name] = _data
    }

    setSelectedBlocks(cloneData)
  }

  return (
    <Wrapper isBusy={isLoading} width="100%" padding={48} height="fit-content">
      <Container width="100%">
        <Text>
          Selecteer de tekstblokken die je wilt toevoegen aan de export
        </Text>
        <Container margin={{ top: 32 }}>
          <Row>
            {data.map((block) => {
              const selected =
                hasKey(selectedBlocks, block.id) && selectedBlocks[block.id]
              return (
                <Col key={block.id} xs={4} margin={{ bottom: 16 }}>
                  <Checkbox
                    name={block.id}
                    label={block.title}
                    onCheck={selectBlocks}
                    checked={selected}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </Container>
      <Container flex mainAxis="center">
        <Button big xxl onClick={submit} label="Versturen" />
      </Container>
    </Wrapper>
  )
}

export default memo(ExportCO)
