import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'
import { MOBILE_MAX_SIZE } from 'src/constants';

const HeaderStyled = styled.header`
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 78px;
  display: flex;
  position: fixed;
  align-items: center;
  padding: 0 26px 0 10px;
  justify-content: space-between;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: ${defaultTheme.colors.white};
  @media (max-width: ${MOBILE_MAX_SIZE}px) {
    padding: 0 10px 0 10px;
  }
`

HeaderStyled.displayName = 'HeaderStyled'

export default HeaderStyled
