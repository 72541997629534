import media from '../utils/media'
import defaultTheme from '../theme'

export const step1 = {
  main: `
    ${media.lessThan(defaultTheme.breakpoints.large)`
      width: 100%;
    `}
  `,
  radioContainer: `
    ${media.lessThan(defaultTheme.breakpoints.small)`
      flex-direction: column;
      margin-top: 16px;
    `}
  `,
  radio: `
    ${media.lessThan(defaultTheme.breakpoints.small)`
      margin-left: 0;
    `}
  `,
  mainTitle: `
    ${media.lessThan(defaultTheme.breakpoints.small)`
      font-size: 22px;
    `}
  `,
  secondaryTitle: `
    ${media.lessThan(defaultTheme.breakpoints.small)`
      font-size: 18px;
    `}
  `,

  //segment 
  segmentContainer: `
    ${media.lessThan(defaultTheme.breakpoints.small)`
      width: 100%;
      flex-direction: column;
    `}
  `,
  hideElement: `
  ${media.lessThan(defaultTheme.breakpoints.small)`
    width: 0;
    height: 0;
  `}
  `,
  item: `
    ${media.lessThan(defaultTheme.breakpoints.small)`
      border-right: none;
      justify-content: flex-start;
      padding-left: 16px;
    `}
  `,

  //step3
  content: `
    ${media.lessThan(defaultTheme.breakpoints.small)`
      width: 100%;
    `}
  `,
}
