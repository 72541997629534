import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import {
  Col,
  Container,
  Row,
  Text,
  TextAreaContainer,
} from 'src/styles/styled-components'

import {
  Button,
  Checkbox,
  FormElement,
  Input,
  Radio,
  Select,
  Uploader,
} from 'src/components'

import { ClientRequestInfo, EmailTemplate, TextBlock } from 'src/interfaces/app'
import {
  editClient,
  getDocumentUrl,
  sendEmail,
  uploadFiles,
} from 'src/services/api'
import { useAlert } from 'react-alert'
import { useParams } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { EMAIL_VARIABLES, parseTemplate } from 'src/email/misc'
import { ThemeContext } from 'styled-components'
import { useEmailTemplate } from 'src/email'
import { splitNameAndExtension } from 'src/utils/misc'

interface ReceivedDecisionProps {
  email: string
  data: TextBlock[]
  clientInfo: ClientRequestInfo
  updateData: () => void
  template: EmailTemplate
  onRequestSent: () => void
}

function ReceivedDecision(props: ReceivedDecisionProps) {
  const { template, email, clientInfo, updateData, onRequestSent, data } = props
  const { templates } = useEmailTemplate()
  const theme = useContext(ThemeContext)
  const alert = useAlert()
  const params = useParams<{ id: string }>()
  const container = useRef(null)
  const [_email, setEmail] = useState(email)
  const [subject, setSubject] = useState(template.subject)
  const [content, setContent] = useState(template.content)
  const [isPersonal, setIsPersonal] = useState(false)
  const [isEmailSend, setIsEmailSend] = useState(true)
  const [selectedBlock, setSelectedBlock] = useState(null)
  const [decision, setDecision] = useState('true')
  const [amount, setAmount] = useState('')
  const [isError, setIsError] = useState(false)
  const [file, setFile] = useState<File>(null)

  const decisionOptions = [
    { label: 'Gegrond', value: 'true' },
    { label: 'Ongegrond ', value: 'false' },
  ]

  useEffect(() => {
    if (decision === 'false') {
      setAmount('')
    }
  }, [decision])

  async function submit() {
    if (!file && !clientInfo?.decisionDocument) {
      setIsError(true)
      container.current.scrollIntoView({ behavior: 'smooth' })
    } else {
      await editClient(params.id, {
        status: 5,
        decision,
        amount,
      })
      let _file = null
      if (file) {
        _file = await uploadFiles(
          file,
          'decisionDocument',
          'approved',
          params.id
        )
      }

      onRequestSent()
      let didNotSend = true
      if (isEmailSend) {
        const attachments = []
        if (clientInfo?.decisionDocument || _file?.file) {
          const _doc = _file || clientInfo.decisionDocument
          const a = await getDocumentUrl(_doc?.file)
          const [, extension] = splitNameAndExtension(_doc?.name)
          attachments.push({
            content: window.btoa(a),
            filename: `Uitspraak gemeente.${extension}`,
            type:
              extension === 'pdf'
                ? 'application/pdf'
                : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            disposition: 'attachment',
            encoding: 'base64',
          })
        }

        const res = await sendEmail({
          to: _email,
          subject,
          body: content,
          attachments,
        })
        didNotSend = !res.success
      }
      await updateData()
      if (didNotSend) {
        alert.success('Case bijgewerkt.')
      } else {
        alert.success('Uitspraak succesvol verstuurd naar de klant.')
      }
    }
  }

  function onDropFiles(file: File) {
    setIsError(false)
    setFile(file)
  }

  function selectTextBlock(value: TextBlock) {
    setSelectedBlock(value)
    setContent(
      `${template.content}\n\n${parseTemplate(
        value.content,
        EMAIL_VARIABLES(clientInfo),
        templates
      )}`
    )
  }

  return (
    <Container ref={container} width="100%" padding={48} height="fit-content">
      <Container>
        <Text>Klant mailen?</Text>
        <Container margin={{ top: 16 }} flex noGrow crossAxis="center">
          <Radio
            checked={isEmailSend}
            onCheck={setIsEmailSend}
            name="show"
            label="Ja"
            value={true}
          />
          <Container margin={{ left: 40 }}>
            <Radio
              checked={!isEmailSend}
              onCheck={setIsEmailSend}
              name="hide"
              label="Nee"
              value={false}
            />
          </Container>
        </Container>
      </Container>
      <Container margin={{ vertical: 32 }}>
        <Text>Upload de beslissing van de gemeente</Text>
        {clientInfo?.decisionDocument ? (
          <Container flex>
            <Text
              target="_blank"
              as="a"
              // TASK: WOZ-633
              hoverDecoration="underline"
              href={clientInfo?.decisionDocument.file}
            >
              Uitspraak gemeente.{splitNameAndExtension(clientInfo?.decisionDocument.name)[1]}
            </Text>
          </Container>
        ) : (
          <Container width={500}>
            <Uploader name="decisionDocument" onDrop={onDropFiles} />
          </Container>
        )}
        {isError && (
          <Text color={theme.colors.red1}>Upload eerst de uitslag</Text>
        )}
      </Container>
      <Container width="100%">
        {/* TASK: WOZ-633 */}
        {isEmailSend && (<>
          <Container margin={{ top: 16 }}>
            <Text>
              Kies het tekstblok die je wilt toevoegen aan de e-mailbericht
            </Text>
          </Container>
          <Container margin={{ top: 32 }}>
            <Row>
              {data.map((block) => {
                return (
                  <Col key={block.id} xs={4} margin={{ bottom: 16 }}>
                    <Radio
                      name="block"
                      value={block}
                      label={block.short_title}
                      onCheck={selectTextBlock}
                      checked={selectedBlock?.id === block.id}
                    />
                  </Col>
                )
              })}
            </Row>
          </Container>
        </>)}
      </Container>
      {/* TASK: WOZ-633 */}
      <Container margin={{ bottom: 32, top: 16 }} width="40%">
        <Select
          name="decision"
          value={decision}
          onChange={setDecision}
          options={decisionOptions}
          label="Resultaat uitspraak"
        />
      </Container>
      {decision === 'true' && (
        <Container margin={{ bottom: 32 }} width="40%">
          <Input
            name="amount"
            onChange={setAmount}
            value={amount}
            label="Te ontvangen bedrag"
          />
        </Container>
      )}
      {isEmailSend && (
        <>
          <Container margin={{ bottom: 32 }} flex noGrow crossAxis="center">
            <Text margin={{ right: 16 }}>Email wordt verzonden naar: </Text>
            <Input
              name="email"
              size="default"
              value={_email}
              onChange={setEmail}
              disabled={!isPersonal}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Input
              disabled={!isPersonal}
              name="subject"
              value={subject}
              onChange={setSubject}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <FormElement
              isWysiwyg
              name="body"
              edit={isPersonal}
              value={content}
              formType="textarea"
              onChange={setContent}
            >
              <TextAreaContainer>{ReactHtmlParser(content)}</TextAreaContainer>
            </FormElement>
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Checkbox
              name="isPersonal"
              checked={isPersonal}
              onCheck={setIsPersonal}
              label="E-mail personaliseren"
            />
          </Container>
        </>
      )}
      <Container flex mainAxis="center">
        {/* TASK: WOZ-633 */}
        <Button big xxl onClick={submit} label={isEmailSend ? 'Versturen' : 'Opslaan'} />
      </Container>
    </Container>
  )
}

export default memo(ReceivedDecision)
