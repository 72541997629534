import React from "react";

const FileExport: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21">
        <g fill="none" fillRule="evenodd">
            <g fill="url(#mainGradient)" fillRule="nonzero" transform="translate(-13.000000, -197.000000)">
                <g>
                    <path d="M23.845 11.446l-2.99-2.966c-.315-.312-.855-.093-.855.347v2.004h-4V5.413c0-.52-.215-1.025-.59-1.394L11.914.58C11.54.208 11.031 0 10.502 0H2C.896.004 0 .886 0 1.972v17.06C0 20.118.896 21 2 21h11.997C15.101 21 16 20.118 16 19.032v-4.264h-2v4.264H2V1.972h6.665v4.265c0 .546.446.984 1 .984H14v3.61H7.167c-.277 0-.5.22-.5.492v.985c0 .271.223.492.5.492H20v2.005c0 .439.54.658.855.346l2.99-2.966c.207-.204.207-.535 0-.74zm-13.18-6.193v-3.12l3.17 3.12h-3.17z" transform="translate(13.000000, 197.000000)" />
                </g>
            </g>
        </g>
    </svg>
);

export default FileExport;
