import React, { memo } from 'react'
import SwitchStyled from './styled/SwitchStyled'

type SwitchProps = {
  value?: any
  name?: string
  checked: boolean
  disabled?: boolean
  onCheck: (value: any, name?: string) => void
}

const Switch = (props: SwitchProps) => {
  const { name, checked, onCheck, disabled = false } = props

  function handleChecked() {
    if (onCheck && !disabled) {
      onCheck(!checked, name)
    }
  }

  return (
    <SwitchStyled
      checked={checked}
      disabled={disabled}
      onClick={handleChecked}
    />
  )
}

Switch.defaultProps = {}

export default memo(Switch)
