import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'
import Container from 'src/styles/styled-components/Container'
import { ContainerStyles, StyledElement } from 'src/interfaces/styles'

const defaultProps: StyledElement & ContainerStyles = {
  theme: defaultTheme,
  width: '100vw',
  height: '100vh',
  zIndex: 1001,
  top: 0,
  left: 0,
  background: '#464c5380',
}

const BackdropStyled = styled(Container)`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
`

BackdropStyled.displayName = 'BackdropStyled'
BackdropStyled.defaultProps = defaultProps

export default BackdropStyled
