// TASK: WOZ-574
import React, { memo } from 'react'
import { Container, Text } from 'src/styles/styled-components'
import { Button, Modal } from 'src/components'

export { default as CustomAlert } from './Custom'

interface AlertModalProps {
  data?: any
  text: string
  isModalShow: boolean
  onRemove: (data: any) => void
  onCancel: (isOpen: boolean) => void
}

function AlertModal(props: AlertModalProps) {
  const { text, data, onRemove, isModalShow, onCancel } = props

  function hideModal() {
    onCancel(false)
  }

  function handleRemove() {
    onRemove(data)
  }

  return (
    <Modal modalSize="small" isShow={isModalShow} onRequestClose={hideModal}>
      <Container
        column
        flex
        crossAxis="center"
        mainAxis="space-between"
        padding={{ horizontal: 16 }}
      >
        <Text align="center">{text}</Text>
        <Container flex noGrow margin={{ bottom: 24 }}>
          <Container margin={{ right: 16 }}>
            <Button big styleType="remove" label="Nee" onClick={hideModal} />
          </Container>
          <Container>
            <Button big label="Ja" onClick={handleRemove} />
          </Container>
        </Container>
      </Container>
    </Modal>
  )
}

AlertModal.defaultProps = {}

export default memo(AlertModal)
