import { FormData } from 'src/hooks/useForm'
import { isPasswordValid } from 'src/utils/misc'

export const AUTH_FORM: FormData = {
  email: {
    value: '',
    name: 'email',
    validationType: 'email',
    validationText:
      'Het e-mailadres is niet correct gespeld. Controleer het adres en probeer het opnieuw',
  },
  password: {
    value: '',
    name: 'password',
    validationText: 'Voer uw wachtwoord in om door te gaan',
    validationType: 'required',
  },
}

export const REGISTRATION_FORM: FormData = {
  password: {
    value: '',
    name: 'password',
    validationText: 'Wachtwoord voldoet niet aan de voorwaarden.',
    validation: (value: string) => isPasswordValid(value),
  },
  confirm_password: {
    value: '',
    name: 'confirm_password',
    validationText: 'Wachtwoord voldoet niet aan de voorwaarden.',
    validation: (value: string, data: FormData) =>
      !!value.trim() && value === data.password.value && isPasswordValid(value),
  },
}

export const FORGOT_PASSWORD_FORM: FormData = {
  email: {
    value: '',
    name: 'email',
    validationType: 'email',
  },
}
