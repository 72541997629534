import React, { memo, useContext, useEffect, useMemo, useState } from 'react'

import { CLIENT_ACTIONS, ClientRequestInfo } from 'src/interfaces/app'

import { Col, Container, Row, Text } from 'src/styles/styled-components'
import { CUSTOMER_ACTIONS } from 'src/constants/clientInfo'
import Item from 'src/helpers/Item'
import { ThemeContext } from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'

type ClientAction = {
  title: string
  status: number
  actionName: string
}

interface ClientActionsProps {
  status: number
  clientInfo: ClientRequestInfo
}

function ClientActions(props: ClientActionsProps) {
  const { clientInfo } = props
  const theme = useContext(ThemeContext)
  const history = useHistory()
  const params = useParams<{ id: string }>()

  const renderActions = useMemo(() => {
    function handleActions(action: ClientAction) {
      if (action.actionName === CLIENT_ACTIONS.DELIVER_DOCUMENTS) {
        history.push(`${params.id}/upload_files/wizard`, { data: clientInfo })
      } else if (
        action.actionName === CLIENT_ACTIONS.SHOW_CO &&
        props.clientInfo?.objection
      ) {
        window.open(props.clientInfo.objection.file, '_blank')
      } else if (
        action.actionName === CLIENT_ACTIONS.SHOW_DECISION &&
        props.clientInfo?.decisionDocument
      ) {
        window.open(props.clientInfo.decisionDocument.file, '_blank')
      }
    }

    return CUSTOMER_ACTIONS.map((item: { actions: ClientAction[] }, index) => {
      const offset = index === 1 ? 6 : 0

      return (
        <Col offset={offset} key={`actions_${index}`} noPadding>
          <Container padding={{ left: 8 }}>
            {item.actions.map((action: ClientAction) => {
              const additionalStatus =
                action.actionName === 'SHOW_CO' ? !!clientInfo?.objection : true
              const isActive =
                action?.status === undefined ||
                (additionalStatus && action?.status <= props.status)
              return (
                <Item
                  margin={{ bottom: 16 }}
                  key={`action_${action.actionName}`}
                  data={action}
                  onClick={handleActions}
                >
                  <Text
                    cursor={isActive ? 'pointer' : 'not-allowed'}
                    color={isActive ? theme.colors.main : theme.colors.gray0}
                  >
                    {action.title}
                  </Text>
                </Item>
              )
            })}
          </Container>
        </Col>
      )
    })
  }, [clientInfo])

  return (
    <Container margin={{ top: 16 }}>
      <Row noPadding>{renderActions}</Row>
    </Container>
  )
}

ClientActions.defaultProps = {}

export default memo(ClientActions)
