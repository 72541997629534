import React, { memo, useContext, useEffect, useState } from 'react'
import { Col, Container, Row, Text } from 'src/styles/styled-components'
import {
  Button,
  DatePicker,
  Input,
  Select,
  Textarea,
  EditButton,
  Wrapper,
} from 'src/components'
import Item from 'src/helpers/Item'
import { useForm } from 'src/hooks/useForm'
import { CLIENT_INFO_ADDITIONAL_FORM } from 'src/forms/clientInfo/formData'
import { useSelector } from 'react-redux'
import { municipalitiesSelector } from 'src/selectors'
import { ThemeContext } from 'styled-components'
import { ClientRequestInfo } from 'src/interfaces/app'
import { editClient, uploadFiles } from 'src/services/api'
import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'

interface ClientInfoAdditionalFormProps {
  data: ClientRequestInfo
  update: () => void
}

function ClientInfoAdditionalForm(props: ClientInfoAdditionalFormProps) {
  const { data, update } = props

  const alert = useAlert()
  const params = useParams<{ id: string }>()
  const theme = useContext(ThemeContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const municipalities = useSelector(municipalitiesSelector)

  const { formData, updateFormData, formValues, updateDefaultData } = useForm(
    CLIENT_INFO_ADDITIONAL_FORM(data)
  )

  useEffect(() => {
    updateDefaultData(CLIENT_INFO_ADDITIONAL_FORM(data))
  }, [data])

  const municipalityOptions = municipalities.map((item) => ({
    value: item,
    label: item.name,
  }))

  function hide() {
    setIsDisabled(true)
  }

  function show() {
    setIsDisabled(false)
  }

  async function updateClientInfoData() {
    setIsLoading(true)
    const firstName = formValues?.firstName || data.firstName
    const lastName = formValues?.lastName || data.lastName
    try {
      await editClient(params.id, {
        ...formValues,
        firstName,
        lastName,
      })
      alert.success('Success')
    } catch (e) {
      alert.error(e)
    }
    await update()
    hide()
    setIsLoading(false)
  }

  const decisionValue = () => {
    switch (data?.decision) {
      case 'true':
        return 'Gegrond'
      case 'false':
        return 'Ongegrond'
      case 'null':
        return 'Gestopt'
      default:
        return undefined
    }
  }

  return (
    <Wrapper
      isBusy={isLoading}
      borderTop="1px solid black"
      margin={{ top: 32 }}
    >
      <Row margin={{ top: 32 }}>
        <Col>
          <Container margin={{ bottom: 32 }}>
            <Select
              fullSize
              clearable
              disabled={isDisabled}
              name="municipality"
              onSelect={updateFormData}
              label="Samenwerkingsverband"
              options={municipalityOptions}
              value={formData.municipality.value}
            />
          </Container>
          <Container>
            <Input
              disabled={isDisabled}
              name="objectNumber"
              label="Objectnummer"
              onChange={updateFormData}
              value={formData.objectNumber.value}
            />
          </Container>
        </Col>
        <Col>
          <Container margin={{ bottom: 32 }}>
            <DatePicker
              disabled
              name="foDelivery"
              value={data?.foDelivery}
              label="Pro Forma Bezwaarschrift ingediend"
            />
          </Container>
          <Container margin={{ bottom: 32 }}>
            <DatePicker
              disabled={isDisabled}
              onChange={updateFormData}
              label="Ontvangstbevestiging"
              name="foReceived"
              value={formData.foReceived.value}
            />
          </Container>
          <Container margin={{ bottom: 32 }}>
            <Input
              disabled={isDisabled}
              name="caseNumber"
              label="Dossiernummer"
              onChange={updateFormData}
              value={formData.caseNumber.value}
            />
          </Container>
          <Container>
            <DatePicker
              disabled
              name="coReceived"
              value={data?.coReceived}
              label="Inhoudelijk bezwaarschrift ingediend"
            />
          </Container>
        </Col>
        <Col>
          <Container margin={{ bottom: 32 }}>
            <Input
              disabled
              label="Uitslag"
              name="decision"
              value={decisionValue()}
            />
          </Container>
          <Container margin={{ bottom: 32 }}>
            <DatePicker
              disabled={isDisabled}
              name="paymentReceived"
              onChange={updateFormData}
              label="Betaling ontvangen"
              value={formData.paymentReceived.value}
            />
          </Container>
          <Container margin={{ bottom: 32 }}>
            <Input
              name="amount"
              disabled={isDisabled}
              onChange={updateFormData}
              value={formData.amount.value}
              label="Ontvangen bedrag"
            />
          </Container>
          <Container>
            <Textarea
              name="comment"
              disabled={isDisabled}
              label="Opmerkingen"
              onChange={updateFormData}
              value={formData.comment.value}
            />
          </Container>
        </Col>
      </Row>
      <Container margin={{ top: 32 }}>
        <EditButton
          toggleEditButton={show}
          isEdit={!isDisabled}
          onCancel={hide}
          onSubmit={updateClientInfoData}
        />
      </Container>
    </Wrapper>
  )
}

ClientInfoAdditionalForm.defaultProps = {}

export default memo(ClientInfoAdditionalForm)
