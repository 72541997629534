import { AppState, EmailTemplate } from 'src/interfaces/app'

export const authSelector = (state: AppState) => state.authorization
export const signInHandlerSelector = (state: AppState) =>
  authSelector(state).signInHandler
export const signUpHandlerSelector = (state: AppState) =>
  authSelector(state).signUpHandler
export const resetPasswordSelector = (state: AppState) =>
  authSelector(state).resetPasswordHandler
export const loggedInSelector = (state: AppState) =>
  authSelector(state).isLoggedIn.payload

export const userSelector = (state: AppState) => state.user
export const profileSelector = (state: AppState) => userSelector(state).profile
export const visibleColumnsFormSelector = (state: AppState) =>
  userSelector(state).visibleColumns.payload
export const filterFormSelector = (state: AppState) =>
  userSelector(state).filterForm.payload
export const emptySearchFieldsSelector = (state: AppState) =>
  userSelector(state).emptySearchFields.payload
export const tableFiltersSelector = (state: AppState) =>
  userSelector(state).tableFilters.payload
export const isCheckModalSelector = (state: AppState) =>
  userSelector(state).isCheckModalShow.payload
export const templatesSelector = (state: AppState) =>
  userSelector(state).templates.payload
export const textBlocksSelector = (state: AppState) =>
  userSelector(state).textBlocks.payload
export const municipalitiesSelector = (state: AppState) =>
  userSelector(state).municipalities.payload
export const stopCaseListSelector = (state: AppState) =>
  userSelector(state).stopCaseList.payload
export const clientsSelector = (state: AppState) => userSelector(state).clients
