import React, { useContext, useEffect, useMemo, useState } from 'react'
import MainLayout from 'src/layouts/Main'
import orderBy from 'lodash.orderby'

import { Container, Text } from 'src/styles/styled-components'
import { Button, DatePicker, Input, Table, TableTitle } from 'src/components'
import MobileRedirecter from 'src/helpers/MobileRedirecter'
import { useSelector } from 'react-redux'
import {
  clientsSelector,
  emptySearchFieldsSelector,
  filterFormSelector,
  profileSelector,
  tableFiltersSelector,
  visibleColumnsFormSelector,
} from 'src/selectors'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/user'
import { actions as authActions } from 'src/redux/authorization'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { DATE_FORMAT, DATE_TIME_FORMAT, ROLES } from 'src/constants'
import { ThemeContext } from 'styled-components'
import { ClientRequestInfo } from 'src/interfaces/app'
import { verifyEmail } from 'src/services/api'
import { useAlert } from 'react-alert'
import Item from 'src/helpers/Item'
import { generateCopyLetterBulkExport } from 'src/services/pdfGenerator'
import { STATUSES } from 'src/constants/clientInfo'
import moment from 'moment'
import { useAuth } from 'src/hooks/useAuth'

import Filters from './components/Filters'
import ColumnFilter from 'src/pages/dashboard/components/ColumnFilter'
import { filterCondition, tableFilterCondition } from 'src/utils/dataFilter'
import {
  additionalColumns,
  columnsDefaultData,
} from 'src/pages/dashboard/components/constants'
import { hasTableFilters } from 'src/utils/misc'
import { useScreenSize } from 'src/hooks/useScreenSize'

function Dashboard() {
  const theme = useContext(ThemeContext)
  const [searchValue, setSearchValue] = useState('')
  const history = useHistory()
  const location = useLocation<{ from: any }>()
  const [showFilters, setShowFilters] = useState(false)
  const [showColumnFilter, setShowColumnFilter] = useState(false)
  const alert = useAlert()
  const auth = useAuth()

  const filters = useSelector(filterFormSelector)
  const emptyFilters = useSelector(emptySearchFieldsSelector)
  const tableFilters = useSelector(tableFiltersSelector)
  const _visibleColumns = useSelector(visibleColumnsFormSelector)
  const _hasTableFilters = hasTableFilters(tableFilters)

  const clients = useSelector(clientsSelector)
  const profile = useSelector(profileSelector)
  const {
    getClients,
    setVisibleColumns,
    setFilterForm,
    setTableFilters,
  } = useActions(actions)
  const { signOut } = useActions(authActions)
  const params = useParams<{ token: string }>()
  const isBulkExport = location.pathname === '/bulk_export'
  const urlParams = new URLSearchParams(location.search)
  const page = urlParams.get('page') || '1'
  const dateParam = urlParams.get('date') || undefined

  const [filterDate, setFilterDate] = useState(dateParam)
  const [rows, setRows] = useState<any>({})
  const isMobile = useScreenSize()

  useEffect(() => {
    if (location?.state?.from?.pathname) {
      history.replace(location?.state?.from?.pathname)
    }
  }, [])

  useEffect(() => {
    ;(async function () {
      if (params.token) {
        const result = await verifyEmail(params.token)
        if (result.error) {
          alert.error(result.error)
        } else if (result.type === 'change_email_client_info') {
          history.replace(`/`)
          alert.success('Approved')
        } else {
          signOut()
          alert.success('Approved')
          history.replace(`/reset_password/${params.token}`)
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (!!profile.payload.role) {
      if (profile.payload.role === 'customer') {
        getClients(profile.payload?.email)
      } else {
        getClients()
      }
    }
  }, [profile.payload])

  function handleChangeDate(date: string) {
    if (date) {
      history.push(`${location.pathname}?date=${date}`)
    } else {
      history.push(`${location.pathname}`)
    }
    setFilterDate(date)
  }

  const _data = getData(clients.payload)
  const sortedData = getSortedData(_data)
  const pageIndex = useMemo(() => {
    return parseInt(page) > 1 ? 25 * (parseInt(page) - 1) : 0
  }, [page])

  const statusFilters: any = [
    { label: 'In afwachting van de stukken', value: '0' },
    { label: 'Stukken worden gecontroleerd', value: '1' },
    { label: 'In behandeling', value: '2' },
    { label: 'Proforma bezwaar ingediend', value: '3' },
    { label: 'Inhoudelijk bezwaar ingediend', value: '4' },
    { label: 'Beslissing ontvangen', value: '5' },
  ]

  const columns = [
    {
      name: 'Rijnr.',
      width: '50px',
      cell: (row: Record<string, any>, index: number) => {
        return index + 1 + pageIndex
      },
    },
    {
      name: <TableTitle title="Aangemeld op" name="created_date" isDate />,
      selector: 'created_date',
      cell: (row: Record<string, any>) => {
        return moment(row.created_date).format(DATE_TIME_FORMAT)
      },
    },
    {
      name: (
        <TableTitle
          hideSearch
          name="status"
          title="Status dos."
          filters={statusFilters}
        />
      ),
      selector: 'status',
      cell: (row: Record<string, any>) => {
        return STATUSES[row.status]?.title || 'Stopgezet'
      },
    },
    {
      name: (
        <TableTitle
          hideSearch
          title="Aanhef"
          name="salutation"
          filters={['heer', 'mevrouw']}
        />
      ),
      grow: 0,
      selector: 'salutation',
    },
    {
      name: <TableTitle title="Naam" name="fullName" />,
      selector: 'fullName',
    },
    {
      name: <TableTitle title="Telefoon" name="phone" />,
      selector: 'phone',
    },
    {
      name: <TableTitle title="E-mail" name="email" />,
      selector: 'email',
    },
    {
      name: <TableTitle title="Adres" name="buildingStreet" />,
      selector: 'buildingStreet',
    },
    {
      name: <TableTitle title="Huisnummer" name="buildingHouseNumber" />,
      selector: 'buildingHouseNumber',
    },
    {
      name: <TableTitle title="Toevoeging" name="buildingAddition" />,
      selector: 'buildingAddition',
    },
    {
      name: <TableTitle title="Postcode" name="buildingPostCode" />,
      selector: 'buildingPostCode',
    },
    {
      name: <TableTitle title="Plaats" name="buildingPlace" />,
      selector: 'buildingPlace',
    },
    {
      name: 'Gemeente',
      selector: 'town',
    },
    {
      name: <TableTitle title="WOZ-waarde" name="wozValue" />,
      selector: 'wozValue',
    },
    {
      name: <TableTitle title="Dagtekening a." name="assessmentDate" isDate />,
      selector: 'assessmentDate',
    },
    {
      name: <TableTitle title="Aanslagnr" name="assessmentNumber" />,
      selector: 'assessmentNumber',
    },
    ...additionalColumns(_visibleColumns),
  ]

  const conditionalRowStyles = [
    {
      when: (row: ClientRequestInfo) => row.isStop && auth.isAdmin,
      style: {
        backgroundColor: theme.colors.gray6,
      },
    },
    {
      when: (row: ClientRequestInfo) => row.decision === 'true' && auth.isAdmin,
      style: {
        backgroundColor: theme.colors.green1,
        color: theme.colors.white,
      },
    },
    {
      when: (row: ClientRequestInfo) =>
        row.decision === 'false' && auth.isAdmin,
      style: {
        backgroundColor: theme.colors.orange,
        color: theme.colors.white,
      },
    },
  ]

  const conditionalRowStylesAnt = (row: ClientRequestInfo) => {
    if (row.isStop && auth.isAdmin) {
      return 'isStopped'
    } else if (row.decision === 'true' && auth.isAdmin) {
      return 'hasDecision'
    } else if (row.decision === 'false' && auth.isAdmin) {
      return 'hasNotDecision'
    }
  }

  function openFilters() {
    setShowFilters(!showFilters)
  }

  function openColumnFilter() {
    setShowColumnFilter(!showColumnFilter)
  }

  function hideFilters() {
    setShowFilters(false)
  }

  function hideColumnFilter() {
    setShowColumnFilter(false)
  }

  function openClientCreateScreen() {
    history.push('sign_in_client')
  }

  function onPageChange(page: number) {
    history.push(`${location.pathname}?page=${page}`)
  }

  function onRowClicked(data: any) {
    history.push(`/client_detail/${data.id}`, { data })
  }

  async function exportData() {
    if (rows?.selectedRows?.length) {
      await (await generateCopyLetterBulkExport(rows?.selectedRows)).download()
    }
  }

  function reset() {
    setVisibleColumns(columnsDefaultData())
    setFilterForm({})
    setTableFilters({})
  }

  function getSortedData(data: ClientRequestInfo[]) {
    if (Object.keys(tableFilters).length) {
      const filterKeys = Object.keys(tableFilters)
      const filterColumnsSorting = filterKeys
        .filter((key) => tableFilters[key]?.sorting)
        .map((key) => tableFilters[key]?.sorting)
      if (filterColumnsSorting.length) {
        return orderBy(
          data,
          filterKeys.map((column: string) => {
            return (row: ClientRequestInfo) => {
              // @ts-ignore
              const value = row[column]
              return value != null ? value.toString().toLowerCase() : ''
            }
          }),
          filterColumnsSorting
        )
      }
    }

    return data
  }

  function getData(data: ClientRequestInfo[]) {
    if (
      searchValue ||
      filterDate ||
      Object.keys(filters).length ||
      _hasTableFilters
    ) {
      return data.filter((item) => {
        const phone = item?.phone
        const address = `${item.buildingStreet} ${item.buildingHouseNumber}`.toLowerCase()
        const email = item?.email
        const name = item?.fullName?.toLowerCase()
        const assessmentNumber = `${item?.assessmentNumber || ''}`

        const searchByText = searchValue
          ? phone?.includes(searchValue.toLowerCase()) ||
            address?.includes(searchValue.toLowerCase()) ||
            email?.includes(searchValue.toLowerCase()) ||
            name?.includes(searchValue.toLowerCase()) ||
            assessmentNumber?.includes(searchValue.toLowerCase())
          : true

        const byDate = filterDate
          ? filterDate === moment(item.created_date).format(DATE_FORMAT)
          : true

        return (
          filterCondition(item, filters, emptyFilters) &&
          searchByText &&
          byDate &&
          tableFilterCondition(item, tableFilters)
        )
      })
    }

    return data
  }

  const a = _data.filter(
    (i) => i.municipality && i.municipality.id === 'Z4b02CYmlh4rG4UVsvC3'
  )

  return (
    <MainLayout hideLeftPanel={isMobile}>
      {ROLES.ADMIN === profile.payload?.role && !isBulkExport && (
        <Container
          padding={16}
          flex
          noShrink
          mainAxis="space-between"
          crossAxis="flex-start"
        >
          <Container>
            <Button
              onClick={openColumnFilter}
              label="Weergave opties"
              big
              /* TASK: WOZ-627 */
              iconProps={{ size: 16, color: theme.colors.white, iconPack: "faSolid" }}
              icon={showColumnFilter ? "CaretUp" : "CaretDown"}
            />
          </Container>
          <Container column flex padding={{ horizontal: 120 }}>
            {!showFilters && (
              <Input
                value={searchValue}
                placeholder="Search"
                name="search"
                onChange={setSearchValue}
              />
            )}
            <Container flex mainAxis="flex-end" margin={{ top: 8 }}>
              <Item onClick={openFilters} hoverDecoration={theme.decorations.main}>
                <Text color={theme.colors.main}>
                  {showFilters
                    ? 'Uitgebreid zoeken sluiten'
                    : 'Uitgebreid zoeken'}
                </Text>
              </Item>
            </Container>
          </Container>
          <Container>
            <Button
              onClick={openClientCreateScreen}
              label="Aanmelding toevoegen"
              big
              iconProps={{ iconPack: "faSolid", color: "white", size: 16 }}
              icon="Plus"
            />
          </Container>
        </Container>
      )}
      {ROLES.ADMIN === profile.payload?.role && isBulkExport && (
        <Container padding={16} flex noShrink crossAxis="center">
          <Container margin={{ right: 16 }}>
            <DatePicker
              clearable
              openDirection="down"
              isOutsideRange={() => false}
              name="assessmentDate"
              onChange={handleChangeDate}
              value={filterDate}
            />
          </Container>
          {/*<Container margin={{ right: 16 }}>*/}
          {/*  <Item>*/}
          {/*    <Text>Alles selecteren</Text>*/}
          {/*  </Item>*/}
          {/*</Container>*/}
          {/*<Container margin={{ right: 16 }}>*/}
          {/*  <Item>*/}
          {/*    <Text>Alles deselecteren</Text>*/}
          {/*  </Item>*/}
          {/*</Container>*/}
          <Container margin={{ right: 16 }}>
            <Item>
              <Text>{rows?.selectedCount ?? 0} dossiers geselecteerd</Text>
            </Item>
          </Container>
          <Container>
            <Button big onClick={exportData} label="Exporteer voorblad(en)" />
          </Container>
        </Container>
      )}
      {showFilters && <Filters onClose={hideFilters} />}
      {showColumnFilter && <ColumnFilter onClose={hideColumnFilter} />}
      {(!!Object.keys(filters).length || _hasTableFilters) && (
        <Container
          flex
          border={`1px solid ${theme.colors.gray6}`}
          padding={{ horizontal: 16, vertical: 8 }}
          margin={{ horizontal: 16 }}
          mainAxis="space-between"
        >
          <Container>
            <Button
              label="Refresh filter"
              icon="ArrowRepeat"
              onClick={() => getClients()}
            />
          </Container>
          <Container flex noGrow crossAxis="center">
            <Text color={theme.colors.label}>Er is een filter actief</Text>
            <Item onClick={openFilters} margin={{ horizontal: 16 }}>
              {/* TASK: WOZ-641 */}
              <Text color={theme.colors.main} hoverDecoration="underline">Aanpassen</Text>
            </Item>
            <Item onClick={reset}>
              {/* TASK: WOZ-641 */}
              <Text color={theme.colors.main} hoverDecoration="underline">Uitzetten</Text>
            </Item>
          </Container>
        </Container>
      )}
      <Container
        padding={16}
        margin={{
          top: ROLES.ADMIN === profile.payload?.role && !isBulkExport ? 32 : 0,
        }}
      >
        {/*<AntTable*/}
        {/*  columns={antColumn}*/}
        {/*  dataSource={_data}*/}
        {/*  scroll={{ x: 400 }}*/}
        {/*  rowClassName={conditionalRowStylesAnt}*/}
        {/*/>*/}
        <Table
          pagination
          // TASK: WOZ-626
          rowStyle={{ height: 50 }}
          data={sortedData}
          columns={columns}
          paginationPerPage={25}
          onChangePage={onPageChange}
          onRowClicked={onRowClicked}
          onSelectedRowsChange={setRows}
          selectableRows={isBulkExport}
          paginationComponent={_data.length > 25 ? undefined : () => ''}
          noDataComponent="Geen dossiers gevonden."
          paginationComponentOptions={{ noRowsPerPage: true }}
          conditionalRowStyles={conditionalRowStyles}
          paginationDefaultPage={parseInt(page) || 1}
        />
      </Container>
    </MainLayout>
  )
}

Dashboard.defaultProps = {}

export default {
  component: () => <MobileRedirecter Component={Dashboard} />,
  exact: true,
  public: false,
  path: ['/', '/verify/:token', '/bulk_export'],
}
