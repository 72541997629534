// TASK: WOZ-573
import React, { memo, useContext, useEffect, useMemo } from 'react'

import { Button, Table } from 'src/components'
import Item from 'src/helpers/Item'

import { Container, Text } from 'src/styles/styled-components'

//mediaquery
import { step1 } from 'src/styles/mediaQuery/step1'
import { useScreenSize } from 'src/hooks/useScreenSize'

import type { IDataTableColumn } from 'react-data-table-component'
import { ThemeContext } from 'styled-components'

interface Step2Props {
  formData: any
  wizard: any
}

function getColumn(colDefault: IDataTableColumn, isMobile: boolean) {
  const col = { ...colDefault }
  if (isMobile) {
    col.cell = row => (
      <Container flex mainAxis="space-between">
        <Container alignSelf="center" textAlign="start" margin={4} minWidth="max-content">{col.name}</Container>
        <Container alignSelf="center" textAlign="end" margin={{ top: col.selector === "name" ? -32 : 4, bottom: 4, horizontal: 4 }}>{row[col.selector as string]}</Container>
      </Container>
    )
  }
  return col
}

function Step2(props: Step2Props) {
  const theme = useContext(ThemeContext)
  const isMobile = useScreenSize()

  useEffect(() => {
    props.wizard.current?.updateStepValidation(2, true)
  }, [])

  function goBack() {
    props.wizard.current?.prev()
  }

  function submit() {
    props.wizard.current?.next()
  }

  const columns = useMemo(() => [
    getColumn({
      name: '',
      width: '50px',
      // TASK: WOZ-572
      // @ts-ignore
      style: isMobile ? { "> div": { justifyContent: "flex-start" } } : {},
      selector: 'index',
    }, isMobile),
    getColumn({
      name: !isMobile && 'Onderdeel',
      selector: 'name',
    }, isMobile),
    {
      name: !isMobile && 'Vragen en antwoorden',
      selector: 'value',
      cell: (row: Record<string, any>) => {
        return (
          <Container margin={{ top: 8 }} mobile={{ padding: { left: 24 } }}>
            {row.value.map((ans: any) => {
              return (
                <Container key={ans.question} margin={{ bottom: 8 }}>
                  <Text size={13} weight="bold">
                    {ans?.question}
                  </Text>
                  {ans.answer.split('\n').map((i: string, ind: number) => {
                    return (
                      <Container margin={{ top: 4 }} key={`value_${ind}`}>
                        <Text>{i}</Text>
                      </Container>
                    )
                  })}
                </Container>
              )
            })}
          </Container>
        )
      },
    },
  ], [isMobile])

  function parseData(_data: any) {
    return Object.keys(_data).map((i, index: number) => {
      return { name: i, value: _data[i], index: index + 1 }
    })
  }

  return (
    <Container padding={16} mobile={{ padding: { horizontal: "unset" } }}>
      <Text size={32} mediaQuery={step1.mainTitle}>
        Stap 2 van 3 - Controleren
      </Text>
      <Container width="100%" margin={{ top: 20 }}>
        <Table
          data={parseData(props.formData)}
          columns={columns}
          noTableHead={isMobile}
          rowStyle={{ flexDirection: isMobile ? 'column' : 'row' }}
        />
        {isMobile && (
          <Container margin={{ bottom: 45, top: 16 }}>
            <Item onClick={goBack}>
              <Text margin={{ left: 5 }} size={14} color={theme.colors.main}>
                Vorige
              </Text>
            </Item>
          </Container>
        )}
        <Container>
          <Button
            onClick={submit}
            // TASK: WOZ-671
            mobile={{
              boxShadow: "0 3px 6px 0 rgb(0 0 0 / 10%), 0 1px 4px 0 rgb(0 0 0 / 16%)",
              weight: "bold",
              height: 64,
              size: 16,
            }}
            large
            big
            label="Naar Stap 3 - Uploaden en versturen"
          />
        </Container>
      </Container>
    </Container>
  )
}

Step2.defaultProps = {}

export default memo(Step2)
