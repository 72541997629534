import React, { SyntheticEvent, useMemo, useContext } from 'react'
import { ThemeContext } from 'styled-components'

//components
import Icon, { IconProps } from '../icon'
import { StyledIconProps } from '@styled-icons/styled-icon'
import ButtonStyled from './styled/ButtonStyled'

// styled
import { Container } from 'src/styles/styled-components'

import type { ContainerStyles } from 'src/interfaces/styles'

export interface ButtonProps {
  data?: any
  name?: string
  label: string
  icon?: string
  width?: number
  height?: number
  large?: boolean
  big?: boolean
  xxl?: boolean
  active?: boolean
  styleType?: string
  disabled?: boolean
  secondary?: boolean
  type?: 'submit' | 'reset' | 'button'
  iconProps?: StyledIconProps & { iconPack?: IconProps["iconPack"] }
  children?: React.ReactNode | string
  onClick?: (args: { data: any; e: SyntheticEvent; name?: string }) => void
  // TASK: WOZ-672
  backgroundImage?: string
  // TASK: WOZ-671
  boxShadow?: string
  weight?: string
  mobile?: Omit<ContainerStyles, "mobile">
}

export default function Button(props: ButtonProps) {
  const theme = useContext(ThemeContext)
  const {
    icon,
    width,
    height,
    label,
    disabled,
    secondary,
    large = false,
    big = false,
    xxl = false,
    active = false,
    styleType,
    iconProps = {
      size: 16,
      color: theme.colors.white,
    },
    type = 'button',
    backgroundImage,
    boxShadow,
    weight,
    mobile,
  } = props

  const handleClick = (e: SyntheticEvent) => {
    if (typeof props.onClick === 'function') {
      props.onClick({ data: props.data, e, name: props.name })
    }
  }

  const _renderIcon = useMemo(() => {
    return (
      !!icon && (
        <Container
          flex
          noGrow
          as="span"
          mainAxis="center"
          crossAxis="center"
          margin={{ right: 10 }}
        >
          <Icon iconName={icon} {...iconProps} />
        </Container>
      )
    )
  }, [icon])

  return (
    <ButtonStyled
      width={width}
      height={height}
      large={large}
      big={big}
      xxl={xxl}
      active={active}
      disabled={disabled}
      styleType={styleType}
      onClick={handleClick}
      type={type}
      backgroundImage={backgroundImage}
      boxShadow={boxShadow}
      weight={weight}
      mobile={mobile}
    >
      {_renderIcon}
      {label || props.children}
    </ButtonStyled>
  )
}

Button.defaultProps = {
  large: false,
}
