export const border = ({
  border,
  borderLeft,
  borderTop,
  borderBottom,
  borderRight,
  outline,
}: any) => `
  ${border ? `border: ${border}` : ''};
  ${borderLeft ? `border-left: ${borderLeft}` : ''};
  ${borderTop ? `border-top: ${borderTop}` : ''};
  ${borderBottom ? `border-bottom: ${borderBottom}` : ''};
  ${borderRight ? `border-right: ${borderRight}` : ''};
  ${outline ? `outline: ${outline}` : ''};
`
