import React, { memo, useContext, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
// TASK: WOZ-637
//components
import { Input, Button, Table, Modal } from 'src/components'

//styled
import { Container, Text } from 'src/styles/styled-components'
import { useSelector } from 'react-redux'
import { municipalitiesSelector } from 'src/selectors'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/user'
import Item from 'src/helpers/Item'
import { Municipality } from 'src/interfaces/app'
import {
  createMunicipality,
  deleteMunicipality,
  updateMunicipality,
} from 'src/services/api'

interface MunicipalityProps {}

function Municipality(props: MunicipalityProps) {
  const theme = useContext(ThemeContext)
  const municipalities = useSelector(municipalitiesSelector)
  const { getMunicipalities } = useActions(actions)

  //form
  const [isFormShow, setIsFormShow] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [city, setCity] = useState('')
  const [email, setEmail] = useState('')
  const [postCode, setPostCode] = useState('')
  const [postBus, setPostBus] = useState('')

  function showForm() {
    setIsFormShow(true)
  }

  function showEditForm({ data }: { data: Municipality }) {
    setIsFormShow(true)
    setIsEdit(true)
    setId(data.id)
    setName(data.name)
    setCity(data.city)
    setEmail(data.email)
    setPostCode(data.postal_code)
    setPostBus(data.post_bus)
  }

  function hideForm() {
    setIsFormShow(false)
    setIsEdit(false)
    setId('')
    setName('')
    setCity('')
    setEmail('')
    setPostCode('')
    setPostBus('')
  }

  async function _deleteMunicipality({ data }: { data: Municipality }) {
    await deleteMunicipality(data.id)
    getMunicipalities()
  }

  async function _createMunicipality() {
    await createMunicipality({
      name,
      city,
      email,
      post_bus: postBus,
      postal_code: postCode,
    })
    getMunicipalities()
    hideForm()
  }

  async function _updateMunicipality() {
    await updateMunicipality(id, {
      name,
      city,
      email,
      post_bus: postBus,
      postal_code: postCode,
    })
    getMunicipalities()
    hideForm()
  }

  const renderMunicipalities = useMemo(() => {
    const columns = [
      {
        name: 'Naam',
        selector: 'name',
      },
      {
        name: 'Aanpassen',
        cell: (raw: Municipality) => (
          <Button
            large
            data={raw}
            height={28}
            label="Aanpassen"
            icon="PencilSquare"
            onClick={showEditForm}
            iconProps={{ size: 16, color: theme.colors.white }}
          />
        ),
      },
      {
        name: 'Verwijderen',
        cell: (raw: Municipality) => (
          <Button
            large
            data={raw}
            height={28}
            icon="Trash"
            styleType="remove"
            label="Verwijderen"
            onClick={_deleteMunicipality}
            iconProps={{ size: 16, color: theme.colors.white }}
          />
        ),
      },
    ]
    return (
      <Container width={500}>
        {/* TASK: WOZ-640 */}
        <Table title={null} data={municipalities} columns={columns} highlightOnHover={false} />
      </Container>
    )
  }, [municipalities])

  function renderForm() {
    return (
      isFormShow && (
        <Container width={720} margin={{ top: 30 }}>
          <Container flex margin={{ bottom: 10 }} crossAxis="center">
            <Container width={120}>
              <Text color={theme.colors.gray5}>Naam*</Text>
            </Container>
            <Input name="name" value={name} onChange={setName} />
          </Container>
          <Container flex margin={{ bottom: 10 }} crossAxis="center">
            <Container width={120}>
              <Text color={theme.colors.gray5}>Postbus*</Text>
            </Container>
            <Input name="postBus" value={postBus} onChange={setPostBus} />
          </Container>
          <Container flex margin={{ bottom: 10 }} crossAxis="center">
            <Container width={120}>
              <Text color={theme.colors.gray5}>Postcode*</Text>
            </Container>
            <Input name="postCode" value={postCode} onChange={setPostCode} />
          </Container>
          <Container flex margin={{ bottom: 10 }} crossAxis="center">
            <Container width={120}>
              <Text color={theme.colors.gray5}>Plaats*</Text>
            </Container>
            <Input name="city" value={city} onChange={setCity} />
          </Container>
          <Container flex margin={{ bottom: 10 }} crossAxis="center">
            <Container width={120}>
              <Text color={theme.colors.gray5}>E-mailadres*</Text>
            </Container>
            <Input name="email" value={email} onChange={setEmail} />
          </Container>
          <Container flex crossAxis="center" mainAxis="flex-end">
            <Item
              margin={{ right: 16 }}
              onClick={hideForm}
              hoverColor={theme.colors.red0}
            >
              <Text color={theme.colors.red1} hoverColor={theme.colors.red0}>
                Annuleren
              </Text>
            </Item>
            <Button
              big
              label="Opslaan"
              onClick={isEdit ? _updateMunicipality : _createMunicipality}
            />
          </Container>
        </Container>
      )
    )
  }

  return (
    <Container>
      <Container flex mainAxis="space-between" crossAxis="flex-start">
        {renderMunicipalities}
        <Button
          big
          iconProps={{ iconPack: "faSolid", color: "white", size: 16 }}
          icon="Plus"
          onClick={showForm}
          label="Nieuwe tekstblok aanmaken"
        />
      </Container>
      {/* TASK: WOZ-637 */}
      <Modal
        isShow={isFormShow}
        modalSize="medium"
        // TODO: find out what shall title be
        // title="Title"
        onRequestClose={hideForm}
      >
        <Container
          margin={{ bottom: 16 }}
          padding={{ horizontal: 24, vertical: 16 }}
        >
          {renderForm()}
        </Container>
      </Modal>
    </Container>
  )
}

export default memo(Municipality)
