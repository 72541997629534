import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'
// TASK: WOZ-572

interface RadioStyled {
  disabled: boolean
  checked: boolean
}

const dynamicStyles = ({ disabled, checked }: RadioStyled) => `
  ${
    disabled
      ? `
    cursor: text;
    background-color: ${defaultTheme.colors.gray3}
    ${
      checked
        ? `
        background-color: ${defaultTheme.colors.gray3};
        border: 1px solid ${defaultTheme.colors.opacityGrey};
        `
        : ''
    }
  `
      : `
    ${
      checked
        ? `
      border: 1px solid ${defaultTheme.colors.main};
      background-color: ${defaultTheme.colors.main};
    `
        : ''
    }
  `
  }
`

const RadioStyled = styled.div`
  min-width: 16px;
  height: 16px;
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${defaultTheme.colors.opacityGrey};
  background-color: ${defaultTheme.colors.white};

  ${dynamicStyles}
`

RadioStyled.displayName = 'RadioStyled'

export default RadioStyled
