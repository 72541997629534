import { Theme } from 'src/interfaces/styles'

const theme: Theme = {
  colors: {
    main: '#0000FF',
    red0: '#ed1c24',
    red1: '#cc2029',
    darkRed: '#BD1E26',
    blue0: '#2a9bf9',
    black: '#000000',
    black1: '#333F52',
    white: '#FFFFFF',
    gray0: '#C5D0DE',
    gray1: '#dfe3e9',
    gray2: '#e9edf1',
    gray3: '#e0e1e2',
    gray4: '#f8fafb',
    gray5: '#5C5D5D',
    gray6: '#ced2db',
    gray7: '#bfbfbf',
    label: '#7f8fa4',
    label1: '#333F52',
    gray9: '#333F52',
    orange: '#F2A835',
    green0: '#1bb934',
    green1: '#2db84b',
    secondary: '#2A9BF9',
    lightBlue: '#2a9bf9',
    darkBlue: '#0000AB',
    opacityGrey: 'rgba(0, 0, 0, 0.1)',
  },
  gradient: {
    image: 'linear-gradient(134.72deg, #0000FF 0%, #2A9BF9 100%)',
  },
  typography: {
    openSans: 'OpenSans',
    lato: 'Lato-Regular',
  },
  breakpoints: {
    hug: '1440px',
    large: '1280px',
    medium: '992px',
    small: '768px',
  },
  decorations: {
    main: 'underline #0000FF',
    disabled: 'underline #C5D0DE',
    danger: 'underline #ed1c24',
  }
}

export default theme
