import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'

import { ContainerStyles } from 'src/interfaces/styles'

const defaultProps: ContainerStyles = {}

const _height = (props: any) => props.height ? `height: ${props.height}px;` : ''
const _color = (props: any) => props.color ? `color: ${props.color};` : ''

const InputStyled = styled.input`
  width: 100%;
  height: 48px;
  font-size: 16px;
  line-height: 21px;
  color: ${defaultTheme.colors.gray5};
  font-family: ${defaultTheme.typography.lato};

  ${_height}
  ${_color}
  &:disabled {
    background: transparent;
  }
`

InputStyled.displayName = 'InputStyled'
InputStyled.defaultProps = defaultProps

export default InputStyled
