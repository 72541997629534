import React, { memo, useContext } from 'react'
import { ThemeContext } from 'styled-components'

//styled components
import { Text, Container } from 'src/styles/styled-components'

// components
import { ContainerStyles } from 'src/interfaces/styles'
import RadioStyled from './styled/RadioStyled'

type RadioProps = ContainerStyles & {
  value?: any
  name?: string
  label?: string
  errorText?: string
  checked: boolean
  disabled?: boolean
  onCheck: (value: any, name: string) => void
}

const Radio = (props: RadioProps) => {
  const theme = useContext(ThemeContext)
  const {
    name,
    label,
    value,
    checked,
    onCheck,
    errorText,
    disabled = false,
    ...otherProps
  } = props

  function handleChecked() {
    if (onCheck && !disabled) {
      onCheck(value, name)
    }
  }

  function renderIcon() {
    if (!checked) {
      return null
    }

    return (
      <Container
        radius={3}
        width={6}
        height={6}
        background={disabled ? theme.colors.gray2 : theme.colors.white}
      />
    )
  }

  return (
    <Container>
      <Container
        flex
        noGrow
        noShrink
        onClick={handleChecked}
        cursor="pointer"
        crossAxis="center"
        mobile={{ margin: { vertical: 10 } }}
        {...otherProps}
      >
        <RadioStyled checked={checked} disabled={disabled}>
          {renderIcon()}
        </RadioStyled>
        {Boolean(label) && (
          <Text
            color={theme.colors.gray5}
            size={14}
            margin={{ left: 14 }}
            lineHeight={21}
          >
            {label}
          </Text>
        )}
      </Container>
      {errorText ? (
        <Container margin={{ top: 5 }}>
          <Text size={14} lineHeight={21} color={theme.colors.red1}>
            {errorText}
          </Text>
        </Container>
      ) : null}
    </Container>
  )
}

Radio.defaultProps = {}

export default memo(Radio)
