import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'
import { Container } from 'src/styles/styled-components'

const defaultProps = {
  theme: defaultTheme,
}

// TASK: WOZ-623
const OptionsContainer = styled(Container)`
  border: solid 1px #dededf;
  border-top: unset;
  box-shadow: 0px 4px 8px rgba(68, 74, 81, 0.08);
  background: ${defaultTheme.colors.white};
`

OptionsContainer.displayName = 'OptionsContainer'
OptionsContainer.defaultProps = defaultProps

export default OptionsContainer
