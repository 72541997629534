import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'

//styled
import { Container, Text } from 'src/styles/styled-components'
import logo from 'src/assets/images/logo.svg'
import AuthForm from 'src/forms/authorization/AuthForm'
import { Wrapper } from 'src/components'
import { useSelector } from 'react-redux'
import { signInHandlerSelector } from 'src/selectors'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { verifyEmail } from 'src/services/api'
import { useAlert } from 'react-alert'
// TASK: WOZ-571
import { useAlertOptions } from 'src/hooks/useAlertOptions'

function Login() {
  const theme = useContext(ThemeContext)
  const alert = useAlert()
  const { status } = useSelector(signInHandlerSelector)
  const location = useLocation<any>()
  const history = useHistory<any>()
  const params = useParams<{ token: string }>()
  const [isLoading, setIsLoading] = useState(false)
  const alertOptions = useAlertOptions()

  useEffect(() => {
    ;(async function () {
      if (params.token) {
        setIsLoading(true)
        const result = await verifyEmail(params.token)
        setIsLoading(false)
        if (result.error) {
          alert.error(result.error, alertOptions)
        } else if (result.type === 'change_email_client_info') {
          if (result.hasUser) {
            alert.success('Dossier succesvol overgenomen', alertOptions)
            history.replace('/')
          } else {
            history.replace(
              result?.redirectLink || `/registration/${params.token}`
            )
          }
        } else {
          alert.success('Approved', alertOptions)
          history.replace(`/reset_password/${params.token}`)
        }
      }
    })()
  }, [])

  return (
    <Wrapper
      flex
      column
      height="100vh"
      mainAxis="center"
      crossAxis="center"
      isBusy={status || isLoading}
      mobile={{ padding: { horizontal: 10 } }}
    >
      <Container margin={{ bottom: 32 }}>
        <img width={300} src={logo} alt="WOZBEZWAAR.BIZ" />
      </Container>
      <Container
        flex
        column
        noGrow
        radius={3}
        width={500}
        padding={{ horizontal: 30, vertical: 20 }}
        border={`1px solid ${theme.colors.gray0}`}
        background={theme.colors.gray2}
        mobile={{ maxWidth: 500, width: "unset" }}
      >
        <Container flex mainAxis="center" margin={{ bottom: 40 }}>
          <Text size={25} color={theme.colors.gray5}>
            Login Portal WOZBEZWAAR.BIZ
          </Text>
        </Container>
        <AuthForm redirectUrl={location?.state?.from?.pathname} />
      </Container>
    </Wrapper>
  )
}

Login.defaultProps = {}

export default {
  component: Login,
  exact: true,
  public: true,
  path: ['/', '/verify/:token'],
}
