import React, { memo, ReactChildren, ReactNode, useEffect, useState } from 'react';

import { Container } from 'src/styles/styled-components'

import Header from './components/Header'
import SideMenu from './components/SideMenu'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/user'
import Footer from 'src/layouts/components/Footer';

interface MainLayoutProps {
  hideLeftPanel?: boolean
  children: ReactNode | ReactNode[]
}

function MainLayout(props: MainLayoutProps) {
  const { getProfile } = useActions(actions)
  const [left, setLeft] = useState(!props.hideLeftPanel ? 50 : 0)
  useEffect(() => {
    getProfile()
  }, [])

  function handleSideBarOpen(isOpen: boolean) {
    setLeft(isOpen ? 50 : 20)
  }

  return (
    <Container minHeight="100vh">
      <Header />
      <Container flex>
        {!props.hideLeftPanel && <SideMenu onOpen={handleSideBarOpen} />}
        <Container
          width="100%"
          transition="all 0.5s linear"
          minHeight="calc(100vh - 40px)"
          padding={{ top: 78, left, bottom: 40 }}
        >
          {props.children}
        </Container>
      </Container>
      <Footer />
    </Container>
  )
}

MainLayout.defaultProps = {}

export default memo(MainLayout)
