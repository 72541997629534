import React, {
  memo,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useDropzone } from 'react-dropzone'

import UploaderStyled from './styled/UploaderStyled'
import ImageContainer from './styled/ImageContainer'
import DeleteContainer from './styled/DeleteContainer'
import DeleteContainerMobile from './styled/DeleteContainerMobile'
import { Container, Row, Col, Text } from 'src/styles/styled-components'

import Icon from '../icon'
import { ThemeContext } from 'styled-components'

import pdf from 'src/assets/images/pdf.png'
import doc from 'src/assets/images/doc.png'
import AlertModal, { CustomAlert } from 'src/components/AlertModal'
import { useScreenSize } from 'src/hooks/useScreenSize'
import { Button } from '..'
import { useAlert } from 'react-alert'

interface UploaderProps {
  name?: string
  multiple?: boolean
  maxFiles?: number
  children?: (open: () => void) => ReactElement
  onDrop?: (file: File | File[], name?: string) => void
  onUpload?: (url: string, fileName: string) => void
  // TASK: WOZ-668
  // TASK: WOZ-669
  maxFilesError?: string
}

function Uploader(props: UploaderProps) {
  const theme = useContext(ThemeContext)
  const { children, maxFiles = 40, multiple = false } = props
  const [files, setFiles] = useState([])
  const [isModalShow, setIsModalShow] = useState(false)
  const [selectedFileName, setSelectedFileName] = useState('')
  const isMobile = useScreenSize()
  const alert = useAlert();

  const onDrop = (acceptedFiles: any) => {
    if (multiple) {
      setFiles((oldValue) => {
        const newData = [...oldValue, ...acceptedFiles]
        if (newData.length > maxFiles) {
          alert.error(props.maxFilesError)
          return oldValue
        }
        props?.onDrop(newData, props.name)
        return !children ? newData : []
      })
    } else {
      setFiles(!children ? acceptedFiles : [])
      props?.onDrop(acceptedFiles[0], props.name)
    }
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxFiles: multiple ? maxFiles : 1,
    maxSize: 10000000, // 10MB
    accept:
      'image/jpeg, image/png, image/bmp, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    onDropRejected: (reject, event) => {
      if (reject.some(rej => rej.errors.some(error => error.code === 'file-too-large'))) {
        alert.error('Het maximum toegestane bestandsgrootte is 10MB')
      } else if (reject.some(rej => rej.errors.some(error => error.code === 'too-many-files'))) {
        alert.error(props.maxFilesError)
      } else if (reject?.[0]?.errors?.[0]?.message) {
        alert.error(reject[0].errors[0].message)
      }
    }
  })

  function getUrl(file: File | string) {
    return typeof file === 'string' ? file : URL.createObjectURL(file)
  }

  const renderDescription = useMemo(() => {
    const textStyles = {
      textAlign: 'center',
    }

    if (files.length && !multiple) {
      return null
    }

    return (
      <Container
        padding={{ horizontal: 38 }}
        flex={1}
        mainAxis="center"
        crossAxis="center"
      >
        {isMobile ? (
          <Text color="white" weight="bold">
            <Icon color="white" iconName="Camera" size={32} style={{ marginRight: 12, marginTop: -2, marginBottom: 2 }} />
            {!!files.length ? 'Nog één foto maken' : 'Foto maken'}
          </Text>
        ) : isDragActive ? (
          <Text {...textStyles}>
            Laat de muisklik los om bestand(en) te uploaden.
          </Text>
        ) : (
          <Container mainAxis="center" crossAxis="center" column flex>
            <Text {...textStyles}>
              Sleep uw bestand(en) hierheen óf klik op de onderstaande knop om
              bestanden te selecteren.
            </Text>
            <Icon
              iconName="CloudArrowUp"
              size={50}
              color={theme.colors.gray5}
            />
          </Container>
        )}
      </Container>
    )
  }, [isDragActive, files])

  function deleteImage(name: string) {
    setFiles((oldData) => {
      const newData = oldData.filter((file) => file.name !== name)
      setSelectedFileName('')
      if (multiple) {
        props?.onDrop(newData, props.name)
      } else {
        props?.onDrop(newData[0], props.name)
      }
      return newData
    })
    setIsModalShow(false)
  }

  const _renderFiles = () => {
    function getFilePreview(file: any) {
      switch (file.type) {
        case 'application/pdf':
          return pdf
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return doc
        default:
          return file
      }
    }

    return (
      <Row grid={4}>
        {!!files.length &&
          files.map((file, index) => {
            const preview = getFilePreview(file)

            return (
              <Col key={file} margin={{ bottom: 10 }} noGrow noPadding={isMobile} flex column>
                <ImageContainer>
                  <Container
                    height="100%"
                    style={{
                      background: `url(${getUrl(
                        preview
                      )}) no-repeat center/cover`,
                    }}
                    className="img"
                  />
                  {isMobile ? (
                    <DeleteContainerMobile>
                      <Button
                        label=""
                        onClick={() => {
                          setIsModalShow(true)
                          setSelectedFileName(file.name)
                        }}
                        height={22}
                        // TASK: WOZ-672
                        backgroundImage="linear-gradient(134.72deg, #0000FF 0%, #2A9BF9 100%)"
                        mobile={{
                          minWidth: 22,
                          maxWidth: 22,
                          radius: "11px",
                          padding: { horizontal: "unset", vertical: "unset" },
                        }}
                      >
                        <Icon iconName="Times" iconPack="faSolid" size={16} />
                      </Button>
                    </DeleteContainerMobile>
                  ) : (
                    <DeleteContainer onClick={() => {
                      setIsModalShow(true)
                      setSelectedFileName(file.name)
                    }}>
                      <Icon
                        iconName="Trash"
                        size={25}
                        color={theme.colors.white}
                      />
                    </DeleteContainer>
                  )}
                </ImageContainer>
                <Text mobile={{ width: 82, ellipsis: true }}>{file.name}</Text>
              </Col>
            )
          })}
      </Row>
    )
  }

  return children ? (
    <>
      <input {...getInputProps()} />
      {children(open)}
    </>
  ) : (
    <Container>
      {isMobile ? (
        <>
          <UploaderStyled isDragActive={isDragActive} {...getRootProps()}>
            {(multiple || !files.length) && <input {...getInputProps()} />}
            {!multiple && _renderFiles()}
            {renderDescription}
          </UploaderStyled>
          {multiple && _renderFiles()}
        </>
      ) : (
        <>
          {multiple && _renderFiles()}
          <UploaderStyled isDragActive={isDragActive} {...getRootProps()}>
            {!multiple && _renderFiles()}
            {(multiple || !files.length) && <input {...getInputProps()} />}
            {renderDescription}
          </UploaderStyled>
        </>
      )}
      {isMobile ? (
        <CustomAlert
          data={selectedFileName}
          onRemove={deleteImage}
          onCancel={setIsModalShow}
          isModalShow={isModalShow}
          text="Weet u zeker dat u deze foto wilt verwijderen?"
          title="Foto verwijderen"
        />
      ) : (
        <AlertModal
          data={selectedFileName}
          onRemove={deleteImage}
          onCancel={setIsModalShow}
          isModalShow={isModalShow}
          text="Bestand verwijderen?"
        />
      )}
    </Container>
  )
}

Uploader.defaultProps = {
  maxFilesError: 'U kunt maximaal 40 foto\'s aanleveren per dossier.'
}

export default memo(Uploader)
