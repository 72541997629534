import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'
// TASK: WOZ-571
import { MOBILE_MAX_SIZE } from 'src/constants';

const FooterStyled = styled.header`
  z-index: 10;
  height: 40px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  position: fixed;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: ${defaultTheme.colors.white};
  @media (max-width: ${MOBILE_MAX_SIZE}px) {
    position: relative;
  }
`

FooterStyled.displayName = 'FooterStyled'

export default FooterStyled
