import styled from 'styled-components'

const CheckStyled = styled.div`
  width: 8px;
  height: 4px;
  border-radius: 1px;
  margin-bottom: 2px;
  transform: rotate(-45deg);
  border-bottom: 2px solid white;
  border-left: 2px solid white;
`

CheckStyled.displayName = 'CheckStyled'

export default CheckStyled
