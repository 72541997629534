import styled from 'styled-components'
import * as faSolid from '@styled-icons/fa-solid'

export const Cog = styled(faSolid.Cog)`
  > path {
    fill: url(#mainGradient);
  }
`

export const CaretLeft = styled(faSolid.CaretLeft)`
  > path {
    fill: url(#mainGradient);
  }
`

export const CaretRight = styled(faSolid.CaretRight)`
  > path {
    fill: url(#mainGradient);
  }
`
