import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { hasKey, isBoolean, isEmail, isObject } from 'src/utils/misc'

export type FormItem = {
  value: any
  name: string
  label?: string
  exclude?: boolean
  isValid?: boolean
  placeholder?: string
  isFormItem?: boolean
  displayValue?: string
  dependencies?: string[]
  validationText?: string
  validationType?: 'required' | 'email'
  validation?: (value: any, data: FormData) => boolean
}

export type FormData = Record<string, FormItem>

type FormObjectValue = {
  value: any
  name: string
}

type FormAction = {
  type: string
  payload: any
}

export interface FormConfigs {
  isFormValid?: boolean
}

export interface useFormHook {
  updateFormData: (data: any | FormObjectValue, name?: string) => void
  updateDefaultData: (data: FormData) => void
  formData: FormData
  formConfigs: FormConfigs
  formValues: Record<string, any>
  onSubmit: (callback: () => void, errorCallback?: () => void) => void
}

const reducer = (state: FormData, action: FormAction): FormData => {
  if (action.type === 'CLEAR') {
    return { ...action.payload }
  } else if (action.type === 'DEFAULT_DATA') {
    return { ...state, ...action.payload }
  } else {
    return {
      ...state,
      ...action.payload.dependencies,
      [action.payload.name]: {
        ...state[action.payload.name],
        ...action.payload.value,
      },
    }
  }
}

export function useForm(
  defaultFormData: FormData,
  defaultConfigs: FormConfigs = {}
): useFormHook {
  const [formConfigs, setFormConfigs] = useState(defaultConfigs)
  const [formData, dispatch] = useReducer(
    reducer,
    getDefaultData(defaultFormData)
  )

  useEffect(() => {
    setFormConfigs((oldState) => {
      const isFormValid = !Object.values(formData).filter(
        (item) => !item?.isValid
      ).length
      return { ...oldState, isFormValid }
    })
  }, [formData])

  function updateDefaultData(data: FormData) {
    dispatch({
      type: 'DEFAULT_DATA',
      payload: getDefaultData(data),
    })
  }

  function handleValidationType(type: 'required' | 'email', value: string) {
    switch (type) {
      case 'required':
        return !!value?.trim()
      case 'email':
        return isEmail(value)
      default:
        return true
    }
  }

  function getDefaultData(data: FormData) {
    const _data = {}

    Object.keys(data).forEach((key) => {
      // @ts-ignore
      _data[key] = {
        ...data[key],
        isValid: true,
        validationText: '',
      }
    })

    return _data
  }

  function validateData(data: FormData): FormData {
    const _data = {}

    Object.keys(data).forEach((key) => {
      const hasValidation = data[key]?.validationType || data[key]?.validation
      const mainValidation =
        !!data[key]?.validationType &&
        handleValidationType(data[key]?.validationType, data[key].value)
      const customValidation =
        !!data[key]?.validation && data[key]?.validation(data[key].value, data)
      const isValid = !hasValidation ? true : mainValidation || customValidation

      // @ts-ignore
      _data[key] = {
        ...data[key],
        validationText: isValid ? '' : defaultFormData[key]?.validationText,
        isValid,
      }
    })

    return _data
  }

  const updateFormData = useCallback(
    (data: any | FormObjectValue, name: string) => {
      function _update(value: any, name: string) {
        const item: any = { value }
        const dependencies: any = {}

        if (formData[name]?.validation) {
          item.isValid = formData[name].validation(value, formData)
        } else if (formData[name]?.validationType) {
          item.isValid = handleValidationType(
            formData[name].validationType,
            value
          )
          item.validationText = ''
        } else {
          item.isValid = true
          item.validationText = ''
        }

        if (formData[name]?.dependencies) {
          formData[name].dependencies.forEach((depenedency) => {
            dependencies[depenedency] = {
              ...formData[depenedency],
              isValid: formData[name]?.validation
                ? formData[name]?.validation(item.value, formData)
                : !item.value,
            }
          })
        }

        dispatch({
          type: 'UPDATE',
          payload: { value: item, name, dependencies },
        })
      }

      if (isObject(data) && data.name && data.value) {
        // @ts-ignore
        if (hasKey(formData, data.name)) {
          _update(data.value, data.name)
        }
      } else {
        _update(data, name)
      }
    },
    [formData]
  )

  const formValues = useMemo(() => {
    const values: Record<string, any> = {}

    function excludeValue(value: any) {
      if (isBoolean(value)) {
        return false
      } else if (!!value && typeof value === 'string') {
        return value.trim()?.length === 0
      }

      return !value
    }

    Object.keys(formData).forEach((key) => {
      const exclude = formData[key]?.exclude
        ? excludeValue(formData[key]?.value)
        : false

      const checkIsFormItem =
        formData[key]?.isFormItem !== undefined
          ? formData[key].isFormItem
          : true

      if (!exclude && checkIsFormItem) {
        values[key] = formData[key].value
      }
    })

    return values
  }, [formData])

  const onSubmit = useCallback(
    (callback: () => void, errorCallback?: () => void) => {
      const data = validateData(formData)
      dispatch({
        type: 'DEFAULT_DATA',
        payload: data,
      })
      const isFormValid = !Object.values(data).filter((item) => !item?.isValid)
        .length

      if (isFormValid) {
        callback()
      } else {
        !!errorCallback && errorCallback()
      }
    },
    [formData, formConfigs.isFormValid]
  )

  return {
    formData,
    formValues,
    formConfigs,
    updateFormData,
    updateDefaultData,
    onSubmit,
  }
}
