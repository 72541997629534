import styled from 'styled-components'

const DeleteContainerMobile = styled.div`
  top: 0;
  right: 0;
  bottom: -100%;
  cursor: pointer;
  display: flex;
  position: absolute;
`

DeleteContainerMobile.displayName = 'DeleteContainerMobile'

export default DeleteContainerMobile
