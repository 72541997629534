import * as React from 'react'
import { Provider, useSelector } from 'react-redux'
import Storage from 'src/services/storage'

import { RouteBuilder } from 'src/helpers/routeBuilder'
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'

import theme from 'src/styles/theme'
import { routes } from 'src/pages'

// services
import { initFirebase } from 'src/services/firebase'

// styles
import { GlobalStyles } from 'src/styles/globalStyles'
/** WARN: copied part of `GlobalStyles` to avoid a bug caused by styles rerendering */
import "src/styles/globalStyles.css"
import AlertTemplate from 'src/helpers/AlertTemplate'
import SvgGradients from 'src/helpers/SvgGradients';
import { initStore } from 'src/redux/store'
import { useEffect, useState } from 'react'
import { checkAuth } from 'src/services/api'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/authorization'
import { actions as userActions } from 'src/redux/user'
import { loggedInSelector } from 'src/selectors'
import { debounce } from 'src/utils/misc';
import { KEEP_LOGGED_IN_TIME } from 'src/constants';
import moment from 'moment'
import { setUser } from "@sentry/react"

console.error = () => {}
initFirebase()
const store = initStore()
moment.locale('nl')

function App() {
  const { setIsLoggedIn, signOut } = useActions(actions)
  const { getProfile, getEmailTemplates, getMunicipalities } = useActions(userActions)
  const isLoggedIn = useSelector(loggedInSelector)
  const [isLoading, setIsLoading] = useState(true)
  const options = {
    timeout: 5000,
    offset: '5px',
    transition: transitions.FADE,
    position: positions.TOP_RIGHT,
  }

  useEffect(() => {
    const signOutIfTimedOut = async () => {
      const lastActive: number = Number(Storage.get('lastActive', 0))
      const timedOut = lastActive !== 0 && Date.now() - lastActive > KEEP_LOGGED_IN_TIME;

      if (timedOut) {
        await signOut()
      }
    }

    const signOutDebounce = debounce(signOutIfTimedOut, KEEP_LOGGED_IN_TIME)

    const onUserAction = debounce(() => {
      Storage.set('lastActive', Date.now())
      signOutDebounce()
    }, 500)

    checkAuth(
      () => {
        setIsLoggedIn(false)
        Storage.remove('isLoggedIn')
        Storage.remove('lastActive')
        document.removeEventListener('keypress', onUserAction)
        document.removeEventListener('mousemove', onUserAction)
        setIsLoading(false)
      },
      async () => {
        onUserAction()
        document.addEventListener('mousemove', onUserAction)
        document.addEventListener('keypress', onUserAction)
        Storage.set('isLoggedIn', true)
        const user = await getProfile()
        setUser(user)
        await getEmailTemplates()
        await getMunicipalities()
        setIsLoggedIn(true)
        setIsLoading(false)
      }
    )

    signOutIfTimedOut()

    return () => {
      document.removeEventListener('keypress', onUserAction)
      document.removeEventListener('mousemove', onUserAction)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <AlertProvider template={AlertTemplate} {...options}>
        <Router>
          <GlobalStyles />
          <SvgGradients />
          {!isLoading && <RouteBuilder routes={routes} isSignIn={isLoggedIn} />}
        </Router>
      </AlertProvider>
    </ThemeProvider>
  )
}

export default function Bootstrap() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}
