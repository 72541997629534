import React, { memo, useEffect, useState } from 'react'
import {
  Container,
  Text,
  TextAreaContainer,
} from 'src/styles/styled-components'
import { Button, Checkbox, FormElement, Input, Radio } from 'src/components'
import {
  ClientRequestInfo,
  EmailTemplate,
  FileData,
  MANAGER_ACTION_TYPES,
} from 'src/interfaces/app'
import { deleteFile, editClient, sendEmail } from 'src/services/api'
import { useAlert } from 'react-alert'
import { useParams } from 'react-router-dom'
import FileUploader from 'src/pages/clientDetail/components/FileUploader'
import ReactHtmlParser from 'react-html-parser'
import {
  generateQuestionsListPDF,
  generateSignatureExample,
} from 'src/services/pdfGenerator'
import { ACTIONS_TEMPLATE_MAP } from 'src/constants'
import { EMAIL_VARIABLES } from 'src/email/misc'
import { useEmailTemplate } from 'src/email'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/user'

interface CheckDocumentsProps {
  email: string
  clientInfo: ClientRequestInfo
  updateData: () => void
  templateAllFilesOk: EmailTemplate
  onRequestSent: (redirectLink: string) => void
  token: string
}

function CheckDocuments(props: CheckDocumentsProps) {
  const {
    email,
    templateAllFilesOk,
    clientInfo,
    updateData,
    onRequestSent,
    token,
  } = props

  const alert = useAlert()
  const params = useParams<{ id: string }>()
  const { showCheckModal } = useActions(actions)
  const { getTemplate } = useEmailTemplate()
  const data = [
    {
      label: 'Machtiging',
      name: 'authorization',
      value: false,
      disabled: !clientInfo.authorization,
    },
    {
      label: 'Aanslag',
      name: 'assessment',
      value: false,
      disabled: !clientInfo.assessment,
    },
    {
      label: 'Vragenlijst',
      name: 'questionsDoc',
      value: false,
      disabled: !clientInfo.questionsDoc,
    },
  ]

  // forms
  const [authorization, setAuthorization] = useState(
    clientInfo?.authorization?.status === 'approved'
  )
  const [assessment, setAssessment] = useState(
    clientInfo?.assessment?.status === 'approved'
  )
  const [questions, setQuestions] = useState(
    clientInfo?.questionsDoc?.status === 'approved'
  )

  const template = getTemplate(
    ...ACTIONS_TEMPLATE_MAP[MANAGER_ACTION_TYPES.CHECKING],
    EMAIL_VARIABLES(props.clientInfo, {
      SIGNATURE: !authorization,
      QUESTION_LIST: !questions,
      COPY_LETTER: !assessment,
    }, token)
  )

  const [isEmailSend, setIsEmailSend] = useState(true)
  const [_email, setEmail] = useState(email)
  const [subject, setSubject] = useState(template.subject)
  const [content, setContent] = useState(template.content)
  const [isPersonal, setIsPersonal] = useState(false)

  useEffect(() => {
    updateData()
  }, []);

  useEffect(() => {
    if (authorization && assessment && questions) {
      setSubject(templateAllFilesOk.subject)
      setContent(templateAllFilesOk.content)
    } else {
      setSubject(template.subject)
      setContent(template.content)
    }
  }, [authorization, assessment, token, questions])

  async function deleteDocument(name: string, fileData: FileData) {
    await editClient(params.id, {
      [name]: null,
    })
    fileData?.name && (await deleteFile(fileData?.name))
  }

  async function submit() {
    const status = authorization && assessment && questions ? 2 : 0
    const filesData: any = {}

    if (!authorization && clientInfo.authorization) {
      await deleteDocument('authorization', clientInfo.authorization)
      await deleteDocument('signature', clientInfo.signature)
    } else if (clientInfo.authorization && authorization) {
      filesData['authorization'] = {
        ...clientInfo.authorization,
        status: 'approved',
      }
    }

    if (!assessment && clientInfo.assessment) {
      await deleteDocument('assessment', clientInfo.assessment)
    } else if (clientInfo.assessment && assessment) {
      filesData['assessment'] = {
        ...clientInfo.assessment,
        status: 'approved',
      }
    }

    if (!questions && clientInfo.questionsDoc) {
      await deleteDocument('questionsDoc', clientInfo.questionsDoc)
    } else if (clientInfo.questionsDoc && questions) {
      filesData['questionsDoc'] = {
        ...clientInfo.questionsDoc,
        status: 'approved',
      }
    }

    await editClient(params.id, {
      status,
      ...filesData,
    })
    onRequestSent(params.id)
    await updateData()

    let didNotSend = true

    if (isEmailSend) {
      const attachments = []

      let generatorQuestions
      let generatorSignature
      let generatorQuestionsDOCX

      const address = `${clientInfo.buildingStreet} ${
        clientInfo.buildingHouseNumber
      }${
        clientInfo.buildingAddition ? ` ${clientInfo.buildingAddition}` : ''
      } te ${clientInfo.buildingPlace}`

      if (!authorization) {
        generatorSignature = await generateSignatureExample({
          ...clientInfo,
          signature: null,
        })
        attachments.push({
          content: generatorSignature.base64(),
          filename: 'Machtiging.pdf',
          type: 'application/pdf',
          disposition: 'attachment',
          encoding: 'base64',
        })
      }

      if (!questions) {
        generatorQuestions = await generateQuestionsListPDF(clientInfo, address)
        generatorQuestionsDOCX = await generateQuestionsListPDF(
          clientInfo,
          address,
          null,
          'DOCX'
        )

        attachments.push({
          content: generatorQuestions.base64(),
          filename: 'Vragenlijst.pdf',
          type: 'application/pdf',
          disposition: 'attachment',
          encoding: 'base64',
        })

        attachments.push({
          content: await generatorQuestionsDOCX.base64(),
          filename: 'Vragenlijst.docx',
          type:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          disposition: 'attachment',
          encoding: 'base64',
        })
      }

      const res = await sendEmail({
        to: _email,
        subject,
        body: content,
        attachments,
      })
      didNotSend = !res?.success
    }
    showCheckModal(false)
    const approvedAllFiles = authorization && assessment && questions
    if (didNotSend) {
      alert.success(approvedAllFiles
        ? 'Dossier compleet, case bijgewerkt.'
        : 'Case bijgewerkt.'
      )
    } else {
      alert.success(approvedAllFiles
        ? 'Dossier compleet, mail verstuurd.'
        : 'Herinnering ontbrekende documenten verstuurd.')
    }
  }

  function checkDocuments(value: boolean, name: string) {
    if (name === 'authorization') {
      setAuthorization(value)
    } else if (name === 'assessment') {
      setAssessment(value)
    } else {
      setQuestions(value)
    }
  }

  function getValue(name: string) {
    if (name === 'authorization') {
      return authorization
    } else if (name === 'assessment') {
      return assessment
    } else {
      return questions
    }
  }

  return (
    <Container width="100%" padding={48} height="fit-content">
      <Container margin={{ bottom: 32 }}>
        <Container>
          <Container flex margin={{ bottom: 16 }}>
            <Container width={200}>
              <Text>Aangeleverde stukken</Text>
            </Container>
            <Container flex noGrow width={200} mainAxis="center">
              <Text>Goedkeuren</Text>
            </Container>
            <Container flex noGrow width={200} mainAxis="center">
              <Text>Afkeuren</Text>
            </Container>
          </Container>
          <Container column flex margin={{ bottom: 16 }}>
            {data.map((item) => {
              const value = getValue(item.name)
              // @ts-ignore
              const name: 'assessment' | 'authorization' | 'questionsDoc' =
                item.name

              return (
                <Container flex key={item.label} margin={{ bottom: 16 }}>
                  <Container width={200}>
                    <FileUploader
                      isView
                      label={item.label}
                      fileData={clientInfo[name]}
                    />
                  </Container>
                  <Container flex noGrow width={200} mainAxis="center">
                    <Radio
                      name={item.name}
                      checked={value}
                      value={!value}
                      disabled={item.disabled}
                      onCheck={checkDocuments}
                    />
                  </Container>
                  <Container flex noGrow width={200} mainAxis="center">
                    <Radio
                      value={!value}
                      name={item.name}
                      checked={!value}
                      disabled={item.disabled}
                      onCheck={checkDocuments}
                    />
                  </Container>
                </Container>
              )
            })}
          </Container>
        </Container>
        <Container margin={{ top: 32 }}>
          <Text>E-mail versturen?</Text>
          <Container margin={{ top: 16 }} flex noGrow crossAxis="center">
            <Radio
              checked={isEmailSend}
              onCheck={setIsEmailSend}
              name="show"
              label="Ja"
              value={true}
            />
            <Container margin={{ left: 40 }}>
              <Radio
                checked={!isEmailSend}
                onCheck={setIsEmailSend}
                name="hide"
                label="Nee"
                value={false}
              />
            </Container>
          </Container>
        </Container>
      </Container>
      {isEmailSend && (
        <>
          <Container margin={{ bottom: 32 }} flex noGrow crossAxis="center">
            <Text margin={{ right: 16 }}>Email wordt verzonden naar: </Text>
            <Input
              name="email"
              size="default"
              value={_email}
              onChange={setEmail}
              disabled={!isPersonal}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Input
              name="subject"
              value={subject}
              onChange={setSubject}
              disabled={!isPersonal}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <FormElement
              isWysiwyg
              name="body"
              edit={isPersonal}
              value={content}
              formType="textarea"
              onChange={setContent}
            >
              <TextAreaContainer>{ReactHtmlParser(content)}</TextAreaContainer>
            </FormElement>
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Checkbox
              name="isPersonal"
              checked={isPersonal}
              onCheck={setIsPersonal}
              label="E-mail personaliseren"
            />
          </Container>
        </>
      )}
      <Container flex mainAxis="center">
        {/* TASK: WOZ-633 */}
        <Button xxl big onClick={submit} label={isEmailSend ? 'Versturen' : 'Opslaan'} />
      </Container>
    </Container>
  )
}

export default memo(CheckDocuments)
