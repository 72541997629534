import React, { useMemo } from 'react'
import { Text, Container } from 'src/styles/styled-components'
import { AlertComponentPropsWithStyle } from 'react-alert'

import { Icon } from 'src/components'

function AlertTemplate(props: AlertComponentPropsWithStyle) {
  const { options, style, close } = props

  const color = useMemo(() => {
    switch (options.type) {
      case 'info':
        return '#2a9bf9'
      case 'warning':
        return '#f0712c'
      case 'error':
        return '#cc2029'
      default:
        return '#2db84b'
    }
  }, [options.type])

  const renderIcon = useMemo(() => {
    const iconName =
      options.type === 'success'
        ? 'CheckCircleFill'
        : options.type === 'error'
        ? 'XCircleFill'
        : 'InfoCircleFill'

    return <Icon iconName={iconName} color="white" size={16} />
  }, [options.type])

  return (
    <Container
      flex
      radius={5}
      style={style}
      width={520}
      // TASK: WOZ-571
      mobile={{ maxWidth: 520, width: "unset" }}
      alignSelf="flex-end"
      background={color}
      mainAxis="space-between"
      padding={{ vertical: 10, horizontal: 16 }}
    >
      <Container flex crossAxis="flex-start">
        <Container margin={{ top: 3 }} flex noGrow crossAxis="flex-start">
          {renderIcon}
        </Container>
        <Text
          lineHeight={21}
          margin={{ horizontal: 14 }}
          bold
          size={14}
          color="white"
        >
          {props.message}
        </Text>
      </Container>
      <Icon
        cursor="pointer"
        iconName="X"
        color="white"
        size={24}
        onClick={close}
      />
    </Container>
  )
}

export default AlertTemplate
