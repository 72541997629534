import React, {
  memo,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'

// components
import Container from 'src/styles/styled-components/Container'

import { ContainerStyles } from 'src/interfaces/styles'

interface ItemProps extends ContainerStyles {
  data?: any
  children?: ReactNode
  onClick?: (data: any) => void
  onHover?: (isHover: boolean, data: any) => void
  onMouseLeave?: (e: SyntheticEvent) => void
}

function Item(props: ItemProps) {
  const { data, onClick, onHover, ...otherProps } = props

  const [isHover, setIsHover] = useState<boolean | null>(null)
  let hoverProps = {}

  useEffect(() => {
    if (onHover && isHover !== null) {
      onHover(isHover, data)
    }
  }, [isHover])

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.data)
    }
  }

  const onMouseEnter = useCallback(() => {
    setIsHover(true)
  }, [isHover])

  const onMouseLeave = useCallback(() => {
    setIsHover(false)
  }, [isHover])

  if (onHover) {
    hoverProps = {
      onMouseEnter,
      onMouseLeave,
    }
  }

  return (
    <Container
      cursor="pointer"
      onClick={handleClick}
      {...hoverProps}
      {...otherProps}
    >
      {props.children}
    </Container>
  )
}

export default memo(Item)
