import { saveAs } from 'file-saver'
import {
  AlignmentType,
  convertInchesToTwip,
  Document,
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalPositionAlign,
  VerticalPositionRelativeFrom,
  WidthType,
  ImageRun,
  IMediaTransformation,
} from 'docx'

import FOExport from './pdfGeneratorDataJson/FOExport.json'
import COExport from './pdfGeneratorDataJson/COExport.json'
import { getExifData, hasKey, isArray } from 'src/utils/misc'
import signatureExample from 'src/services/pdfGeneratorDataJson/signatureExample.json'
import questionsList from 'src/services/pdfGeneratorDataJson/questionsList.json'
import CopyLetter from 'src/services/pdfGeneratorDataJson/CopyLetter.json'
import IntroLetterExport from 'src/services/pdfGeneratorDataJson/IntroLetterExport.json'

import { TextBlock } from 'src/interfaces/app'
import { getMeta } from './pdfGenerator'

type NonReadonly<T> = {
  -readonly [P in keyof T]: T[P];
};

const jsonData = {
  signatureExample,
  questionsList,
  FOExport,
  COExport,
  CopyLetter,
  IntroLetterExport,
}

const DOC_NAMES: any = {
  CopyLetter: 'Voorblad',
  IntroLetterExport: 'Klantenbrief',
}

function alignment(value = 'left') {
  switch (value) {
    case 'center':
      return AlignmentType.CENTER
    case 'right':
      return AlignmentType.RIGHT
    default:
      return AlignmentType.LEFT
  }
}

function splitHtmlByTags(htmlString: string) {
  const div = document.createElement("div");
  div.insertAdjacentHTML("beforeend", htmlString);

  // @ts-ignore
  return Array.from(div.querySelectorAll(["p", 'ol', 'ul']))
    .filter(p => p.textContent !== "")
    .map(p => p.outerHTML);
}

function generateList(
  value: string,
  children: any[],
  textParser: (value: string, data: Record<string, string>) => string,
  data: Record<string, any>
) {
  const LiRegExp = new RegExp(/(<li>.*<\/li>)/, 'ig')
  const PRegExp = new RegExp(/(<p>.*<\/p>)/, 'ig')
  const ULRegExp = new RegExp(
    /\<ul\b[^>]*>([.\n\s\t\>]*<li>.*<\/li>){5}[.\n\s\t\>]*<\/ul>/,
    'ig'
  )

  const a = value.replace(ULRegExp, '')

  a.replace(PRegExp, (match: any) => {
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: textParser(match.replace(/<[^>]*>?/gm, ''), data),
            size: 24,
            font: 'Arial',
          }),
        ],
        spacing: {
          ['before']: 40 * 5,
        },
        alignment: alignment(),
      })
    )

    return a
  })

  value.replace(LiRegExp, (match: string, p1: any, p2: any) => {
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: textParser(match.replace(/<[^>]*>?/gm, ''), data) || 'Typ hier uw tekst',
            size: 24,
            font: 'Arial',
          }),
        ],
        spacing: {
          ['before']: 40 * 5,
        },
        alignment: alignment(),
        bullet: {
          level: 0,
        },
      })
    )
    return value
  })
}

function getTransformation(imageSize: [number, number, number?, boolean?]) {
  const transformation: NonReadonly<IMediaTransformation> = {
    width: imageSize[0],
    height: imageSize[1],
  }
  let isSizeCorrected = false
  if (imageSize[2]) {
    transformation.rotation = imageSize[2];
    if ((imageSize[2] / 90) % 2) {
      // NOTE: image is rotated 90 degrees (or so)
      // need to change width with height
      const correctHeight = transformation.width > 794 ? 794 : transformation.width
      const correctWidth = transformation.width > 794 ? 794 / transformation.width * transformation.height : transformation.height

      transformation.height = correctHeight
      transformation.width = correctWidth
      isSizeCorrected = true
    }
  }
  if (!isSizeCorrected && transformation.width > 794) {
    const correctWidth = transformation.width > 794 ? 794 : transformation.width
    const correctHeight = transformation.width > 794 ? 794 / transformation.width * transformation.height : transformation.height

    transformation.height = correctHeight
    transformation.width = correctWidth
  }

  return transformation
}

function readAsDataURL(photo: File) {
  return new Promise<string>(resolve => {
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      resolve(reader.result as string);
    }, false);
    reader.readAsDataURL(photo)
  });
}

export function generateDocx(
  name: string,
  data: Record<string, any> | Record<string, any>[],
  textParser: (value: string, data: Record<string, string>) => string,
  type: 'PDF' | 'WORD' | 'WORD_EXCLUDE' = 'WORD',
  _doc?: any
) {
  // Create document
  const json = hasKey(jsonData, name) ? jsonData[name] : null
  const doc = _doc || new Document({
    sections: []
  })
  let children: any[] = []
  let _fontSize = 20

  if (isArray(data)) {
    data.forEach((d) => {
      json.forEach((elem: any) => {
        if (elem.text) {
          let options = {}

          if (elem.type === 'ul') {
            options = {
              ...options,
              bullet: {
                level: elem?.level || 0,
              },
            }
          }

          children.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: textParser(elem.text, d),
                  size: 20,
                  font: 'Arial',
                  bold: !!elem?.bold,
                }),
              ],
              spacing: {
                [elem?.offset || 'before']: 40 * elem.positions[1],
              },
              alignment: alignment(elem?.align),
              ...options,
            })
          )
        } else if (elem.image) {
          if (elem.image === 'SIGNATURE') {
            children.push(
              new Paragraph({ children: [
                new ImageRun({
                  data: d[elem.image],
                  transformation: {
                    width: undefined,
                    height: undefined,
                  },
                  floating: {
                    horizontalPosition: {
                      offset: 1014400,
                    },
                    verticalPosition: {
                      align: VerticalPositionAlign.TOP,
                      relative: VerticalPositionRelativeFrom.LINE,
                    },
                  },
                })
              ]})
            )
          } else if (elem.image === 'BG' && type === 'WORD') {
            children.push(
              new Paragraph({ children: [
                new ImageRun({
                  data: d[elem.image],
                  transformation: {
                    width: 794,
                    height: 1123,
                  },
                  floating: {
                    behindDocument: true,
                    horizontalPosition: {
                      align: HorizontalPositionAlign.LEFT,
                      relative: HorizontalPositionRelativeFrom.PAGE,
                    },
                    verticalPosition: {
                      align: VerticalPositionAlign.TOP,
                      relative: VerticalPositionRelativeFrom.PAGE,
                    },
                  },
                })
              ]})
            )
          }
        } else if (elem?.section) {
          doc.addSection({
            properties: {},
            children: children,
          })
          children = []
        }
      })
      doc.addSection({
        properties: {},
        children: children,
      })
      children = []
    })
  } else {
    json.forEach((elem: any) => {
      if (elem.textBlocks) {
        if (!!data.TEXT_BLOCKS) {
          Object.values(data.TEXT_BLOCKS).forEach((block: TextBlock) => {
            children.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: textParser(block.title, data),
                    size: _fontSize,
                    font: 'Arial',
                    bold: true,
                  }),
                ],
                spacing: {
                  [elem?.offset || 'before']: 40 * 15,
                },
                alignment: alignment(elem?.align),
                bullet: {
                  level: 0,
                },
              })
            )
            splitHtmlByTags(block.content).forEach(outerHTML => {
              generateList(outerHTML, children, textParser, data)
            })
          })
        }
      } else if (elem.text) {
        let options = {}

        if (elem.type === 'ul') {
          options = {
            ...options,
            bullet: {
              level: elem?.level || 0,
            },
          }
        }
        if (textParser(elem.text, data)) {
          children.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: textParser(elem.text, data),
                  size: elem?.fontSize || _fontSize,
                  bold: !!elem?.bold,
                  font: 'Arial',
                }),
              ],
              spacing: {
                [elem?.offset || 'before']: 40 * elem.positions[1],
              },
              alignment: alignment(elem?.align),
              ...options,
            })
          )
        }
      } else if (elem.image) {
        if (elem.image === 'SIGNATURE_MANAGER') {
          children.push(
            new Paragraph({ children: [
              new ImageRun({
                data: data[elem.image],
                transformation: {
                  width: 150,
                  height: 75,
                },
                floating: {
                  horizontalPosition: {
                    align: HorizontalPositionAlign.LEFT,
                    relative: HorizontalPositionRelativeFrom.COLUMN,
                  },
                  verticalPosition: {
                    align: VerticalPositionAlign.TOP,
                    relative: VerticalPositionRelativeFrom.LINE,
                  },
                },
              })
            ]})
          )
        } else if (elem.image === 'PHOTOS') {
          doc.addSection({
            properties: {},
            children: children,
          })
          children = []

          data[elem.image].forEach((el: string, i: number) => {
            doc.addSection({
              properties: {},
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: el,
                      transformation: getTransformation(data.IMAGE_SIZE[i]),
                      floating: {
                        horizontalPosition: {
                          align: HorizontalPositionAlign.CENTER,
                        },
                        verticalPosition: {
                          align: VerticalPositionAlign.CENTER,
                        },
                      }
                    }),
                  ],
                }),
              ],
            })
          })
        } else if (elem.image === 'LOGO') {
          children.push(
            new Paragraph({ children: [
              new ImageRun({
                data: data[elem.image],
                transformation: {
                  width: 351,
                  height: 51,
                },
                floating: {
                  horizontalPosition: {
                    align: HorizontalPositionAlign.CENTER,
                  },
                  verticalPosition: {
                    align: VerticalPositionAlign.TOP,
                    relative: VerticalPositionRelativeFrom.LINE,
                  },
                },
              })
            ]})
          )
        } else if (elem.image === 'BG' && type === 'WORD') {
          children.push(
            new Paragraph({ children: [
              new ImageRun({
                data: data[elem.image],
                transformation: {
                  width: 794,
                  height: 1123,
                },
                floating: {
                  behindDocument: true,
                  horizontalPosition: {
                    align: HorizontalPositionAlign.LEFT,
                    relative: HorizontalPositionRelativeFrom.PAGE,
                  },
                  verticalPosition: {
                    align: VerticalPositionAlign.TOP,
                    relative: VerticalPositionRelativeFrom.PAGE,
                  },
                },
              })
            ]})
          )
        } else if (elem.image === 'SIGNATURE_IMAGE' && data[elem.image]) {
          doc.addSection({
            properties: {},
            children: children,
          })
          children = []
          doc.addSection({
            properties: {},
            children: [
              new Paragraph({ children: [
                new ImageRun({
                  data: data[elem.image],
                  transformation: {
                    width: 794,
                    height: 1123,
                  },
                  floating: {
                    behindDocument: true,
                    horizontalPosition: {
                      align: HorizontalPositionAlign.LEFT,
                      relative: HorizontalPositionRelativeFrom.PAGE,
                    },
                    verticalPosition: {
                      align: VerticalPositionAlign.TOP,
                      relative: VerticalPositionRelativeFrom.PAGE,
                    },
                  },
                })
              ]}),
            ],
          })
        }
      } else if (elem?.section) {
        doc.addSection({
          properties: {},
          children: children,
        })
        children = []
      } else if (elem.fontSize) {
        _fontSize = elem.fontSize * 2
      } else if (elem.table) {
        children.push(
          new Paragraph({
            children: [],
            spacing: {
              ['before']: 80,
            },
          })
        )

        const table = new Table({
          columnWidths: [310, 4300, 4300],
          rows: data.table.map((t: any, index: number) => {
            return new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `${index + 1}`,
                          font: 'Arial',
                        }),
                      ],
                    }),
                  ],
                  width: {
                    size: 310,
                    type: WidthType.DXA,
                  },
                  margins: {
                    left: convertInchesToTwip(0.1),
                  },
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: t[1].content,
                          font: 'Arial',
                        }),
                      ],
                    }),
                  ],
                  width: {
                    size: 4300,
                    type: WidthType.DXA,
                  },
                  margins: {
                    top: convertInchesToTwip(0.1),
                    bottom: convertInchesToTwip(0.1),
                    left: convertInchesToTwip(0.1),
                    right: convertInchesToTwip(0.1),
                  },
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: t[2].content || ' ',
                          font: 'Arial',
                        }),
                      ],
                    }),
                  ],
                  width: {
                    size: 4300,
                    type: WidthType.DXA,
                  },
                  margins: {
                    top: convertInchesToTwip(0.1),
                    bottom: convertInchesToTwip(0.1),
                    left: convertInchesToTwip(0.1),
                    right: convertInchesToTwip(0.1),
                  },
                }),
              ],
            })
          }),
        })
        children.push(table)
      }
    })
    if (children.length) {
      doc.addSection({
        properties: {},
        children: children,
      })
    }
  }

  return {
    getDoc: () => doc,
    download: () =>
      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `${DOC_NAMES[name] || name}.docx`)
      }),
    base64: async () => {
      return await Packer.toBase64String(doc)
    },
  }
}

export async function generateAssessmentLetter(photos: Array<File>) {
  const doc = new Document({ sections: [] })

  for (const photo of photos) {
    const [width, height] = await getMeta(URL.createObjectURL(photo));
    const photoDataURL = await readAsDataURL(photo)
    const [rotation, flipped] = getExifData(photoDataURL)
    // @ts-ignore
    doc.addSection({
      properties: {},
      children: [
        new Paragraph({
          children: [
            new ImageRun({
              data: await photo.arrayBuffer(),
              transformation: getTransformation([width, height, rotation, flipped]),
              floating: {
                horizontalPosition: {
                  align: HorizontalPositionAlign.CENTER,
                  relative: HorizontalPositionRelativeFrom.PAGE,
                },
                verticalPosition: {
                  align: VerticalPositionAlign.TOP,
                  relative: VerticalPositionRelativeFrom.LINE,
                },
              },
            })
          ]
        })
      ]
    })
  }

  const blob = await Packer.toBlob(doc)
  // saveAs(blob, 'brief_aanslag.docx')

  return new File([blob], 'brief_aanslag.docx', {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  })
}
