// TASK: WOZ-671
// TASK: WOZ-672

export const background = ({
  mediaQuery,
  background,
  overflow,
  transition,
  overflowY,
  boxShadow,
  backgroundImage,
}: any) => `
  ${mediaQuery ? mediaQuery : ''};
  ${background ? `background: ${background}` : ''};
  ${overflow ? `overflow: ${overflow}` : ''};
  ${overflowY ? `overflow-y: ${overflowY}` : ''};
  ${transition ? `transition: ${transition}` : ''};
  ${boxShadow ? `box-shadow: ${boxShadow}` : ''};
  ${backgroundImage ? `background-image: ${backgroundImage};` : ''};
`
