import React, { memo } from 'react'
import { useHistory, Link } from 'react-router-dom'

import { Icon, Select } from 'src/components'
import { Container, Image, Text } from 'src/styles/styled-components'

import logo from 'src/assets/images/logo.svg'
// TASK: WOZ-572
import avatar from 'src/assets/images/avatar.png'

import HeaderStyled from './styled/HeaderStyled'
import { useSelector } from 'react-redux'
import { profileSelector } from 'src/selectors'
import Item from 'src/helpers/Item'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/authorization'
import { actions as userActionsList } from 'src/redux/user'
import { useAuth } from 'src/hooks/useAuth'
import { useScreenSize } from 'src/hooks/useScreenSize'

interface HeaderProps {}

function Header(props: HeaderProps) {
  const profile = useSelector(profileSelector)
  const history = useHistory()
  const auth = useAuth()
  const isMobile = useScreenSize()
  const { signOut, getClients } = useActions({ ...actions, ...userActionsList })

  let menuOptions = [
    {
      label: 'Instellingen',
      value: '1',
      onClick: () => {
        history.push('/profile')
      },
    },
    {
      label: 'Uitloggen',
      value: '1',
      onClick: () => {
        signOut()
        getClients.restore()
      },
    },
  ]

  if (auth.isAdmin) {
    menuOptions = [
      {
        label: 'Instellingen',
        value: '1',
        onClick: () => {
          history.push('/profile')
        },
      },
      {
        label: 'Klant aanmelden',
        value: '1',
        onClick: () => {
          history.push('/sign_in_client')
        },
      },
      {
        label: 'Uitloggen',
        value: '1',
        onClick: () => {
          signOut()
        },
      },
    ]
  }

  return (
    <HeaderStyled>
      {isMobile ? (
        <Image id="logo" width={Math.min(231, document.body.clientWidth - 138)} src={logo} alt="logo" />
      ) : (
        <Link to="/">
          <Image id="logo" width={231} src={logo} alt="logo" />
        </Link>
      )}
      <Container>
        <Select options={menuOptions}>
          {({ showOptions }) => (
            <Item
              flex
              mainAxis="flex-end"
              crossAxis="center"
              onClick={showOptions}
              minWidth={134}
              mobile={{ padding: { right: 10 } }}
            >
              {isMobile ? (
                <Image
                  width={22}
                  src={avatar}
                  alt="avatar"
                  style={{ paddingRight: 6 }}
                />
              ) : (
                <Text margin={{ right: 5 }}>
                  {profile.payload?.first_name} {profile.payload?.last_name}
                </Text>
              )}
              <Icon
                iconPack="faSolid"
                iconName="ChevronDown"
                color="#5a5a5a"
                size={10}
              />
            </Item>
          )}
        </Select>
      </Container>
    </HeaderStyled>
  )
}

Header.defaultProps = {}

export default memo(Header)
