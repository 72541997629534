const lessThan = (breakpoint: string): (data: TemplateStringsArray) => string => {
  return (args: TemplateStringsArray) => {
    return `
      @media (max-width: ${breakpoint}) {
        ${args}
      }
    `
  }
}

const moreThan = (breakpoint: string): (data: TemplateStringsArray) => string => {
  return (args: TemplateStringsArray) => {
    return `
      @media (min-width: ${breakpoint}) {
        ${args}
      }
    `
  }
}

export default {
  lessThan,
  moreThan,
}
