import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'

interface SelectStyledProps {
  isOpen: boolean
  disabled?: boolean
}

const defaultProps = {
  theme: defaultTheme,
}

const dynamicStyled = ({ isOpen, disabled }: SelectStyledProps) => `
   ${
     !!isOpen && !disabled
       ? `
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    border: 1px solid ${defaultTheme.colors.blue0} !important;
   `
       : ''
   }
   ${
     disabled
       ? `
    border: none;
    box-shadow: none;
    background-image: none;
    background: rgba(0, 0, 0, 0.05);
    &:hover {
      background-image: none;
      border: none;
    }
   `
       : ''
   }
`

const SelectStyled = styled.div`
  width: 100%;
  min-width: 180px;
  height: 50px;
  display: flex;
  cursor: pointer;
  padding: 0 14px;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  background: ${defaultTheme.colors.white};
  border: solid 1px rgba(0, 0, 0, 0.1);

  &:hover {
    border: solid 1px #ced0da;
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  }

  ${dynamicStyled}
`

SelectStyled.displayName = 'SelectStyled'
SelectStyled.defaultProps = defaultProps

export default SelectStyled
