import JSZip from 'jszip'
import { saveAs } from 'file-saver'

type zipFile = {
  name: string
  file: any
}

export function generateZIP(files: zipFile[], zipName: string = 'documents') {
  const zip = new JSZip()

  files.forEach((f) => {
    zip.file(f.name, f.file, { base64: true })
  })

  return {
    download: () =>
      zip.generateAsync({ type: 'blob' }).then(
        function (blob) {
          saveAs(blob, `${zipName}.zip`)
        },
        function (err) {}
      ),
  }
}
