import React, { memo, ReactElement, useContext } from 'react'

// styled components
import { Container, Text } from 'src/styles/styled-components'
import CheckboxStyled from './styled/CheckboxStyled'
import CheckStyled from './styled/CheckStyled'

// components
import { ThemeContext } from 'styled-components'
import { ContainerStyles } from 'src/interfaces/styles'

type CheckboxProps = ContainerStyles & {
  name?: string
  label?: string
  value?: string
  color?: string
  checked?: boolean
  selected?: boolean
  isCircle?: boolean
  disabled?: boolean
  indeterminate?: boolean
  renderLabel?: () => ReactElement
  onCheck?: (checked: boolean, name: string) => void
}

function Checkbox(props: CheckboxProps) {
  const theme = useContext(ThemeContext)
  const {
    name,
    label,
    value,
    onCheck,
    isCircle,
    color = theme.colors.main,
    renderLabel,
    indeterminate,
    checked = false,
    disabled = false,
    selected= false,
    ...otherProps
  } = props

  function handleChecked() {
    if (onCheck && !disabled) {
      onCheck(!checked, name)
    }
  }

  function renderIcon() {
    if (indeterminate && !checked) {
      return (
        <Container
          radius={1}
          height={2}
          width={8}
          background={theme.colors.white}
        />
      )
    } else if (!checked) {
      return null
    }

    return <CheckStyled />
  }

  return (
    <Container
      flex
      noGrow
      cursor="pointer"
      crossAxis="flex-start"
      onClick={handleChecked}
      {...otherProps}
    >
      <CheckboxStyled
        isCircle={isCircle}
        color={color}
        disabled={disabled}
        checked={checked || indeterminate}
      >
        {renderIcon()}
      </CheckboxStyled>
      {Boolean(label) && (
        <Text
          size={14}
          margin={{ left: 14 }}
          color={selected ? theme.colors.main : theme.colors.gray5}
        >
          {label}
        </Text>
      )}
      {Boolean(renderLabel) && renderLabel()}
    </Container>
  )
}

Checkbox.defaultProps = {}

export default memo(Checkbox)
