import styled from 'styled-components'
import { Container } from 'src/styles/styled-components';

interface SideBarStyledProps {
  isOpen: boolean
}

const defaultProps: SideBarStyledProps = {
  isOpen: true,
}

const animation = ({ isOpen }: SideBarStyledProps) => `
  left: ${isOpen ? '0px' : '-30px'};
`

const SideBarStyled = styled(Container)`
  top: 0;
  left: 0;
  bottom: 0;
  width: 50px;
  display: flex;
  position: fixed;
  padding-top: 80px;
  flex-direction: column;
  background-color: #fafbfc;
  justify-content: space-between;
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.25);
  transition: all 0.5s linear;
  ${animation}
`

SideBarStyled.displayName = 'SideBarStyled'
SideBarStyled.defaultProps = defaultProps

export default SideBarStyled
