import { getMeasure, getFlexValue } from 'src/styles/utils'
import { ContainerStyles } from 'src/interfaces/styles'

export const flex = (styles: ContainerStyles) => `
  ${styles.flex ? `display: flex; flex-grow: 1; flex-shrink: 1;` : ''}
  ${
    styles.grow ? `display: flex; flex-grow: ${getFlexValue(styles.grow)};` : ''
  }
  ${
    styles.shrink
      ? `display: flex; flex-shrink: ${getFlexValue(styles.shrink)};`
      : ''
  }
  ${
    styles.basis
      ? `flex-basis: ${getMeasure(styles.basis, styles.measure)};`
      : ''
  }
  ${styles.crossAxis ? `align-items: ${styles.crossAxis};` : ''}
  ${styles.mainAxis ? `justify-content: ${styles.mainAxis};` : ''}
  ${styles.column ? `flex-direction: column;` : ''}
  ${styles.alignSelf ? `align-self: ${styles.alignSelf};` : ''}
  ${styles.flow ? `flex-flow: ${styles.flow};` : ''}
  ${styles.order ? `order: ${styles.order};` : ''}
  ${styles.flexWrap ? 'flex-wrap: wrap' : ''};
  ${styles.noGrow ? `flex-grow: 0;` : ''}
  ${styles.noShrink ? `flex-shrink: 0;` : ''}
`
