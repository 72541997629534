import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'

//mediaQuery
import media from 'src/styles/utils/media'
import { mobile } from 'src/styles/helpers/mobile'

interface UploaderStyledProps {
  isDragActive: boolean
}

const dynamicStyles = (props: UploaderStyledProps) => `
  ${
    props.isDragActive
      ? `
    background: #f1f4f8;
    border: 7px dashed ${defaultTheme.colors.gray1};
  `
      : ''
  }
`

const UploaderStyled = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  min-height: 170px;
  padding: 25px 38px;
  background: ${defaultTheme.colors.white};
  border: 1px solid ${defaultTheme.colors.gray1};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  ${dynamicStyles};
  ${mobile}
  ${media.lessThan(defaultTheme.breakpoints.small)`
    min-height: 64px;
    border-radius: 5px;
    margin-bottom: 64px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    padding: 20px 0;
    background: #0000ff;
  `};
`

UploaderStyled.displayName = 'UploaderStyled'

export default UploaderStyled
