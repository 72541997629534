import React, { memo, useEffect, useState } from 'react'

import { Button, Form, Input, Wrapper } from 'src/components'
import { Container, Text } from 'src/styles/styled-components'

import { useForm } from 'src/hooks/useForm'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/authorization'

// data
import { FORGOT_PASSWORD_FORM } from 'src/forms/authorization/formData'
import { useSelector } from 'react-redux'
import { resetPasswordSelector } from 'src/selectors'
import { useAlert } from 'react-alert'
import { Link } from 'react-router-dom'

interface ForgotPasswordProps {
  isRequestSent: boolean
  setIsRequestSent: (value: any) => any
}

function ForgotPassword(props: ForgotPasswordProps) {
  const alert = useAlert()
  const { setIsRequestSent, isRequestSent } = props
  const { updateFormData, formData, formConfigs, formValues } = useForm(
    FORGOT_PASSWORD_FORM
  )
  const { resetPassword } = useActions(actions)
  const { error, status } = useSelector(resetPasswordSelector)

  useEffect(() => {
    if (error) {
      alert.error(error)
      setIsRequestSent(false)
    } else if (isRequestSent && !error) {
      alert.success(
        'We hebben u een e-mail gestuurd. Controleer uw inbox om het wachtwoord te wijzigen'
      )
    }
  }, [error, isRequestSent])

  async function handleSubmit() {
    await resetPassword(formValues.email)
    setIsRequestSent(true)
  }

  return (
    <Wrapper isBusy={status}>
      <Form onSubmit={handleSubmit}>
        <Container margin={{ bottom: 15 }}>
          {isRequestSent && !error ? null : (
            <Input
              name="email"
              onChange={updateFormData}
              value={formData.email.value}
              placeholder="E-mailadres"
            />
          )}
        </Container>
        <Container flex mainAxis="center" margin={{ top: 15, bottom: 20 }}>
          {!isRequestSent ? (
            <Button
              large
              big
              type="submit"
              label="Stuur mij een link"
              disabled={!formConfigs.isFormValid}
            />
          ) : (
            <Link to="/">
              <Text>Wijziging doorvoeren</Text>
            </Link>
          )}
        </Container>
      </Form>
    </Wrapper>
  )
}

export default memo(ForgotPassword)
