import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'
import Container from 'src/styles/styled-components/Container'
import { ContainerStyles, StyledElement } from 'src/interfaces/styles'

// TASK: WOZ-574
interface ModalContainerProps {
  modalSize?: 'xs' | 'small' | 'medium' | 'large'
}

const defaultProps: StyledElement & ContainerStyles = {
  theme: defaultTheme,
}

const _size = ({ modalSize }: ModalContainerProps) => `
  ${modalSize === 'large' ? 'width: 1020px;' : ''}
  ${modalSize === 'medium' ? 'width: 768px;' : ''}
  ${modalSize === 'small' ? 'width: 480px;' : ''}
  ${modalSize === 'xs' ? 'width: 320px; min-height: 200px;' : ''}
`

const ModalContainer = styled(Container)`
  z-index: 1002;
  display: flex;
  min-height: 250px;
  position: absolute;
  flex-direction: column;
  background-color: ${defaultTheme.colors.white};

  ${_size}
`

ModalContainer.displayName = 'ModalContainer'
ModalContainer.defaultProps = defaultProps

export default ModalContainer
