import React, { memo, useEffect, useState } from 'react'
import { Container, Text, TextAreaContainer } from 'src/styles/styled-components';
import { Button, Checkbox, FormElement, Input, Radio, Textarea } from 'src/components';
import { EmailTemplate } from 'src/interfaces/app'
import { editClient, sendEmail } from 'src/services/api'
import { useAlert } from 'react-alert'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { DATE_FORMAT } from 'src/constants'
import ReactHtmlParser from 'react-html-parser';

interface FOObjectionProps {
  email: string
  updateData: () => void
  template: EmailTemplate
  onRequestSent: (redirectLink: string) => void
  token: string
}

function FOObjection(props: FOObjectionProps) {
  const { template, email, updateData, onRequestSent, token } = props

  const alert = useAlert()
  const params = useParams<{ id: string }>()

  const [isEmailSend, setIsEmailSend] = useState(true)
  const [_email, setEmail] = useState(email)
  const [subject, setSubject] = useState(template.subject)
  const [content, setContent] = useState(template.content)
  const [isPersonal, setIsPersonal] = useState(false)

  useEffect(() => {
    updateData()
  }, [])

  useEffect(() => {
    setSubject(template.subject)
    setContent(template.content)
  }, [token, JSON.stringify(template)])

  async function submit() {
    await editClient(params.id, {
      status: 3,
      foDelivery: moment().format(DATE_FORMAT),
    })
    onRequestSent(params.id)
    let didNotSend = true
    if (isEmailSend) {
      const res = await sendEmail({
        to: _email,
        subject,
        body: content,
      })
      didNotSend = !res.success
    }
    await updateData()
    if (didNotSend) {
      alert.success('Case bijgewerkt.')
    } else {
      alert.success('Klant succesvol geïnformeerd.')
    }
  }

  return (
    <Container width="100%" padding={48} height="fit-content">
      <Container margin={{ top: 32 }}>
        <Text>E-mail versturen?</Text>
        <Container margin={{ top: 16 }} flex noGrow crossAxis="center">
          <Radio
            checked={isEmailSend}
            onCheck={setIsEmailSend}
            name="show"
            label="Ja"
            value={true}
          />
          <Container margin={{ left: 40 }}>
            <Radio
              checked={!isEmailSend}
              onCheck={setIsEmailSend}
              name="hide"
              label="Nee"
              value={false}
            />
          </Container>
        </Container>
      </Container>
      {isEmailSend && (
        <>
          <Container margin={{ bottom: 32 }} flex noGrow crossAxis="center">
            <Text margin={{ right: 16 }}>Email wordt verzonden naar: </Text>
            <Input
              name="email"
              size="default"
              value={_email}
              onChange={setEmail}
              disabled={!isPersonal}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Input
              disabled={!isPersonal}
              name="subject"
              value={subject}
              onChange={setSubject}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }} minHeight={200}>
            <FormElement
              isWysiwyg
              name="body"
              edit={isPersonal}
              value={content}
              formType="textarea"
              onChange={setContent}
            >
              <TextAreaContainer>{ReactHtmlParser(content)}</TextAreaContainer>
            </FormElement>
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Checkbox
              name="isPersonal"
              checked={isPersonal}
              onCheck={setIsPersonal}
              label="E-mail personaliseren"
            />
          </Container>
        </>
      )}
      <Container flex mainAxis="center">
        {/* TASK: WOZ-633 */}
        <Button big xxl onClick={submit} label={isEmailSend ? 'Versturen' : 'Opslaan'} />
      </Container>
    </Container>
  )
}

export default memo(FOObjection)
