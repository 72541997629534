import React, { useState, useMemo, useContext } from "react";
import Item from "src/helpers/Item";
import { Container, Text } from "src/styles/styled-components";
import { ThemeContext } from "styled-components";

import type { ContainerStyles } from 'src/interfaces/styles'

type ShowMoreProps = {
    children: React.ReactNode;
    wordsToShow?: number;
} & ContainerStyles;

export default function ShowMore({ children, wordsToShow = 49, ...rest }: ShowMoreProps) {
    const [show, setShow] = useState(false);
    const theme = useContext(ThemeContext)

    const [visible, hidden] = useMemo(() => {
        const childrenArray: Array<React.ReactNode> = [];
        (Array.isArray(children) ? children : [children])
            .forEach(child => typeof child === "string" ? childrenArray.push(...child.split(" ")) : childrenArray.push(child));
        return [
            childrenArray.slice(0, wordsToShow).map(i => typeof i === "string" ? `${i} ` : i),
            childrenArray.slice(wordsToShow).map(i => typeof i === "string" ? `${i} ` : i),
        ];
    }, [wordsToShow, children.toString()]);

    return (
        <Container {...rest}>
            {visible} {!show && ". . ."}
            {show && hidden}
            <Container flex mainAxis="flex-end" padding={{ right: 32 }}>
                <Item
                    onClick={() => setShow(!show)}
                    hoverDecoration={theme.decorations.main}
                >
                    <Text color={theme.colors.main}>
                        {show ? "Minder tonen" : "Meer tonen"}
                    </Text>
                </Item>
            </Container>
        </Container>
    );
};
