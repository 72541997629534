import React, { useEffect } from 'react'
import { Loader, Spinner } from 'src/styles/styled-components'
import { useHistory, useLocation, useParams } from 'react-router'
import { getToken } from 'src/services/api'

function RedirectFromRegistration() {
  const history = useHistory()
  const location = useLocation<{ from: any }>()
  const urlParams = new URLSearchParams(location.search)
  const clientId = urlParams.get('clientId')
  const details = urlParams.get('details')

  const params = useParams<{
    token: string
    subpath?: 'documents' | 'wizard' | 'photos'
  }>()

  useEffect(() => {
    if (params.token) {
      getToken(params.token).then((result) => {
        const tokenData: any = result.docs[0] ? {
          id: result.docs[0].id,
          ...result.docs[0].data()
        } : {}
        if (details === 'true' && clientId) {
          history.replace(`/client_detail/${clientId}`)
        } else if (tokenData?.redirectLink) {
          history.replace(
            (tokenData?.subpath || params.subpath)
              ? `${tokenData?.redirectLink}/${(tokenData.subpath || params.subpath)}`
              : tokenData?.redirectLink
          )
        } else if (clientId) {
          history.replace(`/client_detail/${clientId}`)
        } else {
          history.replace('/')
        }
      })
    } else {
      history.replace('/')
    }
  }, [])

  return (
    <Loader>
      <Spinner viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="4"
        />
      </Spinner>
    </Loader>
  )
}

export default {
  component: RedirectFromRegistration,
  exact: false,
  public: false,
  path: '/registration/:token/:subpath?',
}
