import { ContainerStyles } from 'src/interfaces/styles'
import { getMeasure } from 'src/styles/utils'

export const size = (styles: ContainerStyles) => `
  ${styles.width ? `width: ${getMeasure(styles.width, styles.measure)};` : ''}
  ${
    styles.height ? `height: ${getMeasure(styles.height, styles.measure)};` : ''
  }
  ${
    styles.maxWidth
      ? `max-width: ${getMeasure(styles.maxWidth, styles.measure)};`
      : ''
  }
  ${
    styles.minWidth
      ? `min-width: ${getMeasure(styles.minWidth, styles.measure)};`
      : ''
  }
  ${
    styles.maxHeight
      ? `max-height: ${getMeasure(styles.maxHeight, styles.measure)};`
      : ''
  }
  ${
    styles.minHeight
      ? `min-height: ${getMeasure(styles.minHeight, styles.measure)};`
      : ''
  }
  ${styles.columnCount ? `column-count: ${styles.columnCount};` : ''}
`
