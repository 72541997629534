import firebase from 'firebase/app'

const isProd = false

export function initFirebase() {
  const devConfigs = {
    apiKey: 'AIzaSyCFKrwU_59kIdD4xQWT0zX4dHPEdPnFe9Q',
    authDomain: 'portal-staging-89613.firebaseapp.com',
    projectId: 'portal-staging-89613',
    storageBucket: 'portal-staging-89613.appspot.com',
    messagingSenderId: '668815907136',
    appId: '1:668815907136:web:3305e28da1a42c764b02a5',
  }

  const configs = {
    apiKey: 'AIzaSyCddjKqz0OF4bps5w05otTF8K3AqE131WE',
    authDomain: 'portal-41ffa.firebaseapp.com',
    projectId: 'portal-41ffa',
    storageBucket: 'portal-41ffa.appspot.com',
    messagingSenderId: '219837713446',
    appId: '1:219837713446:web:134e1f770de50186632ae7',
  }

  firebase.initializeApp(isProd ? configs : devConfigs)
}
