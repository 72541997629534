import styled from 'styled-components'

import { size } from 'src/styles/helpers/size'
import { flex } from 'src/styles/helpers/flex'
import { font } from 'src/styles/helpers/font'
import { margin } from 'src/styles/helpers/margin'
import { padding } from 'src/styles/helpers/padding'
import { mobile } from 'src/styles/helpers/mobile'

const listStyles = () => `
  padding-left: 24px;
  ${size}
  ${flex}
  ${font}
  ${margin}
  ${padding}
  ${mobile}
`

export const Ordered = styled.ol`${listStyles}`

export const UnOrdered = styled.ul`${listStyles}`
