import React, { memo, useContext, useEffect } from 'react'

import {
  Button,
  Radio,
  Uploader,
} from 'src/components'

import { Container, Text } from 'src/styles/styled-components'
import { ClientRequestInfo } from 'src/interfaces/app'
import { Questions } from 'src/services/questions/generateQuestions'
import Item from 'src/helpers/Item'
import { ThemeContext } from 'styled-components'
import { generateQuestionsListPDF } from 'src/services/pdfGenerator'

//mediaquery
import { step1 } from 'src/styles/mediaQuery/step1'
import { capitalize } from 'src/utils/misc';
import { useScreenSize } from 'src/hooks/useScreenSize'

interface Step1Props {
  wizard: any
  formData: any
  setQuestions: any
  setFiles: any
  questions: any
  formValues: any
  updateFormData: any
  checkboxData: any
  setCheckboxData: any
  data: ClientRequestInfo
  onSubmit: (callback: () => void, errorCallback: () => void) => void
  setQuestionsForm: (data: any) => void
}

function Step1(props: Step1Props) {
  const theme = useContext(ThemeContext)
  const isMobile = useScreenSize()

  const {
    data,
    onSubmit,
    setFiles,
    formData,
    questions,
    formValues,
    setQuestions,
    updateFormData,
    setQuestionsForm,
  } = props

  useEffect(() => {
    if (questions) {
      props.wizard.current?.updateStepValidation(2, false)
    }
  }, [questions])

  function handleSubmit() {
    onSubmit(
      () => {
        const _formData: any = {}
        Object.keys(formData).forEach(key => {
          const [_name, index, subIndex] = key.split('_')
          if (!!subIndex || index !== '0') {
            return
          }
          const name = capitalize(_name)
          _formData[name] = []
        })
        Object.keys(formValues).forEach((f) => {
          // @ts-ignore
          const name = capitalize(f.split('_')[0])

          _formData[name].push({
            question: formData[f].label,
            answer: formValues[f],
          })
        })

        setQuestionsForm(_formData)
        props.wizard.current?.updateStepValidation(1, true, true)
      },
      () => {
        props.wizard.current?.updateStepValidation(1, false)
      }
    )
  }

  function goNext() {
    if (!questions) {
      props.wizard.current?.updateStepValidation(1, true, true)
    }
  }

  function onDropFiles(file: File | File[], name: string) {
    setFiles({ [name]: file })
  }

  async function downloadQuestionsDoc() {
    const address = `${data.buildingStreet} ${data.buildingHouseNumber}${
      data.buildingAddition ? ` ${data.buildingAddition}` : ''
    } te ${data.buildingPlace}`

    await (await generateQuestionsListPDF(data, address)).download()
  }

  const listName =
    data?.property === 'bedrijfspand' ? 'none_residential' : 'residential'

  return (
    <Container
      flex
      column
      padding={16}
      // TASK: WOZ-646
      width={852}
      mediaQuery={step1.main}
      mobile={{ padding: { horizontal: "unset" } }}
    >
      <Text size={32} mediaQuery={step1.mainTitle} padding={{ bottom: 50 }}>
        Stap 1 van {questions ? 3 : 2} - Invullen vragenlijst
      </Text>
      {data?.questionsDoc ? (
        <Container flex column>
          <Text>Aangeleverde document(en):</Text>
          {/* TASK: WOZ-633 */}
          <Text target="_blank" as="a" hoverDecoration="underline" href={data?.questionsDoc.file}>
            {data?.questionsDoc.label}
          </Text>
        </Container>
      ) : (
        <Container flex={!isMobile} mediaQuery={step1.radioContainer}>
          <Radio
            value={true}
            label="Nu digitaal invullen (duurt circa 4 minuten)"
            name="question1"
            onCheck={setQuestions}
            checked={questions}
          />
          {/*<Radio*/}
          {/*  margin={{ left: 16 }}*/}
          {/*  value={null}*/}
          {/*  label="Nu uploaden"*/}
          {/*  name="question1"*/}
          {/*  disabled={data?.property !== 'bedrijfspand'}*/}
          {/*  onCheck={setQuestions}*/}
          {/*  checked={questions === null}*/}
          {/*  mediaQuery={step1.radio}*/}
          {/*/>*/}
          <Radio
            margin={{ left: 16 }}
            label="Later aanleveren"
            value={false}
            name="question1"
            onCheck={setQuestions}
            checked={questions === false}
            mediaQuery={step1.radio}
          />
        </Container>
      )}
      {questions === null && (
        <>
          <Container margin={{ vertical: 16 }}>
            <Item onClick={downloadQuestionsDoc}>
              <Text color={theme.colors.main}>Vragenlijst.pdf</Text>
            </Item>
          </Container>
          <Uploader name="questionsDoc" onDrop={onDropFiles} />
        </>
      )}
      {questions && !data?.questionsDoc ? (
        <Container>
          <Questions
            formData={formData}
            listName={listName}
            onFormSubmit={handleSubmit}
            updateFormData={updateFormData}
            checkboxData={props.checkboxData}
            setCheckboxData={props.setCheckboxData}
          />
        </Container>
      ) : (
        <Container margin={{ top: 36 }}>
          <Button
            big
            large
            // TASK: WOZ-671
            mobile={{
              boxShadow: "0 3px 6px 0 rgb(0 0 0 / 10%), 0 1px 4px 0 rgb(0 0 0 / 16%)",
              weight: "bold",
              height: 64,
              size: 16,
            }}
            onClick={goNext}
            label="Naar Stap 2 - Uploaden en versturen"
          />
        </Container>
      )}
    </Container>
  )
}

Step1.defaultProps = {}

export default memo(Step1)
