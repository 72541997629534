import React, { memo, useContext, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { useSelector } from 'react-redux'
import { stopCaseListSelector } from 'src/selectors'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/user'
import { Municipality, StopCaseItem } from 'src/interfaces/app'
import {
  createDocumentStopCase,
  deleteDocumentStopCase,
  updateDocumentStopCase,
} from 'src/services/api'
import { Button, Input, Table } from 'src/components'
import { Container, Text } from 'src/styles/styled-components'
import Item from 'src/helpers/Item'

interface StopCaseListProps {}

function StopCaseList(props: StopCaseListProps) {
  const theme = useContext(ThemeContext)
  const stopCaseList = useSelector(stopCaseListSelector)
  const { getDocumentStopCaseList } = useActions(actions)

  //form
  const [isFormShow, setIsFormShow] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [id, setId] = useState('')
  const [text, setText] = useState('')

  function showForm() {
    setIsFormShow(true)
  }

  function showEditForm({ data }: { data: StopCaseItem }) {
    setIsFormShow(true)
    setIsEdit(true)
    setId(data.id)
    setText(data.text)
  }

  function hideForm() {
    setIsFormShow(false)
    setIsEdit(false)
    setId('')
    setText('')
  }

  async function _deleteStopCase({ data }: { data: Municipality }) {
    await deleteDocumentStopCase(data.id)
    getDocumentStopCaseList()
  }

  async function _createStopCase() {
    await createDocumentStopCase({
      text,
    })
    getDocumentStopCaseList()
    hideForm()
  }

  async function _updateStopCase() {
    await updateDocumentStopCase(id, {
      text,
    })
    getDocumentStopCaseList()
    hideForm()
  }

  const renderStopCaseList = useMemo(() => {
    const columns = [
      {
        name: 'Naam',
        selector: 'text',
      },
      {
        grow: 0.4,
        name: 'Aanpassen',
        cell: (raw: Municipality) => (
          <Button
            large
            data={raw}
            height={28}
            label="Aanpassen"
            icon="PencilSquare"
            onClick={showEditForm}
            iconProps={{ size: 16, color: theme.colors.white }}
          />
        ),
      },
      {
        grow: 0.4,
        name: 'Verwijderen',
        cell: (raw: Municipality) => (
          <Button
            large
            data={raw}
            height={28}
            icon="Trash"
            styleType="remove"
            label="Verwijderen"
            onClick={_deleteStopCase}
            iconProps={{ size: 16, color: theme.colors.white }}
          />
        ),
      },
    ]
    return (
      <Container width="50%">
        {/* TASK: WOZ-640 */}
        <Table title={null} data={stopCaseList} columns={columns} highlightOnHover={false} />
      </Container>
    )
  }, [stopCaseList])

  function renderForm() {
    return (
      isFormShow && (
        <Container width={500} margin={{ top: 30 }}>
          <Container flex margin={{ bottom: 10 }} crossAxis="center">
            <Container margin={{ right: 60 }}>
              <Text color={theme.colors.gray5}>Val*</Text>
            </Container>
            <Input name="text" value={text} onChange={setText} />
          </Container>
          <Container flex crossAxis="center" mainAxis="flex-end">
            <Item margin={{ right: 16 }} onClick={hideForm} hoverColor={theme.colors.red0}>
              <Text color={theme.colors.red1} hoverColor={theme.colors.red0}>Annuleren</Text>
            </Item>
            <Button
              label="Opslaan"
              onClick={isEdit ? _updateStopCase : _createStopCase}
            />
          </Container>
        </Container>
      )
    )
  }

  return (
    <Container>
      <Container flex mainAxis="space-between" crossAxis="flex-start">
        {renderStopCaseList}
        <Button
          big
          iconProps={{ iconPack: "faSolid", color: "white", size: 16 }}
          icon="Plus"
          onClick={showForm}
          label="Nieuwe tekstblok aanmaken"
        />
      </Container>
      {renderForm()}
    </Container>
  )
}

export default memo(StopCaseList)
