import theme from 'src/styles/theme'
import { getMeasure } from 'src/styles/utils'
import { ContainerStyles } from 'src/interfaces/styles'

// TASK: WOZ-631
export const font = (styles: ContainerStyles) => `
  ${styles.cursor ? `cursor: ${styles.cursor};` : ''}
  ${styles.color ? `color: ${theme.colors[styles.color] || styles.color};` : ''}
  ${styles.font ? `font-family: ${styles.font};` : ''}
  ${styles.weight ? `font-weight: ${styles.weight};` : ''}
  ${styles.size ? `font-size: ${getMeasure(styles.size, styles.measure)};` : ''}
  ${
    styles.lineHeight
      ? `line-height: ${getMeasure(styles.lineHeight, styles.measure)};`
      : ''
  }
  ${
    styles.letterSpace
      ? `letter-spacing: ${getMeasure(styles.letterSpace, styles.measure)};`
      : ''
  }
  ${styles.decoration ? `text-decoration: ${styles.decoration};` : ''}
  ${styles.textAlign ? `text-align: ${styles.textAlign};` : ''}
  ${styles.whiteSpace ? `white-space: ${styles.whiteSpace};` : ''}
  ${styles.ellipsis ? `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
  ` : ''}
  ${
    styles.wordBreak
      ? `
    word-break: break-word
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: anywhere; /* Internet Explorer 5.5+ */
    @media all and (-ms-high-contrast: none) {
        & {
            word-break: break-all
        }
    }
  `
      : ''
  }
  &:hover {
    ${
      styles.hoverColor
        ? `color: ${theme.colors[styles.hoverColor] || styles.hoverColor};`
        : ''
    }
    ${styles.hoverDecoration ? `text-decoration: ${styles.hoverDecoration};` : ''}
  }

  ${
    styles.truncate
      ? `
    max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
      : ''
  }
`
