import React, {
  forwardRef,
  memo,
  ReactChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import Item from 'src/helpers/Item'
import { Col, Container, Row, Text } from 'src/styles/styled-components'
import { ThemeContext } from 'styled-components'

//mediaquery
import { step1 } from 'src/styles/mediaQuery/step1'

type Step = {
  title: string
  description?: string
  isValid?: boolean
}

interface SegmentProps {
  editable?: boolean
  isWizard?: boolean
  currentIndex?: number
  onChange?: (currentIndex: number, step?: Step) => void
  onNext?: (currentIndex: number, step?: Step) => void
  onPrev?: (currentIndex: number, step?: Step) => void
  steps: Step[]
  children?: ReactNode[]
}

const Segment = forwardRef((props: SegmentProps, ref) => {
  const { steps, isWizard, currentIndex = 0, editable = true, onChange } = props

  const theme = useContext(ThemeContext)
  const [currentStep, setCurrentStep] = useState(currentIndex)

  useEffect(() => {
    setCurrentStep(currentIndex)
  }, [currentIndex])

  const setupStepValidation = useMemo(() => {
    if (isWizard) {
      return Array.from(Array(steps.length), (_, index) => {
        return steps[index]?.isValid || false
      })
    }
  }, [isWizard, steps])

  const [stepValidation, setStepValidation] = useState(setupStepValidation)

  useImperativeHandle(
    ref,
    () => {
      return {
        next() {
          setCurrentStep((oldValue) => {
            if (
              steps.length - 1 === oldValue ||
              (isWizard && !stepValidation[oldValue + 1])
            ) {
              return oldValue
            }
            onChange && onChange(oldValue + 1)
            return oldValue + 1
          })
        },
        prev() {
          setCurrentStep((oldValue) => {
            if (oldValue === 0) {
              return oldValue
            }
            onChange && onChange(oldValue - 1)
            return oldValue - 1
          })
        },
        goTo(index: number) {
          if (isWizard && !stepValidation[index]) {
            return
          }
          onChange && onChange(index)
          setCurrentStep(index)
        },
        updateStepValidation(
          stepIndex: number,
          isValid: boolean,
          isNext?: boolean
        ) {
          setStepValidation((oldData) => {
            if (
              (!oldData[stepIndex] && isValid) ||
              (oldData[stepIndex] && !isValid)
            ) {
              const clone = [...oldData]
              clone[stepIndex] = isValid

              return clone
            } else {
              return oldData
            }
          })
          if (isNext && isValid) {
            setCurrentStep((oldValue) => {
              if (steps.length - 1 === oldValue) {
                return oldValue
              }
              onChange && onChange(oldValue + 1)
              return oldValue + 1
            })
          }
        },
      }
    },
    [stepValidation]
  )

  const onTabClick = useCallback(
    (item) => {
      const { index, ...data } = item

      if ((isWizard && !stepValidation[index]) || !editable) {
        return
      }

      setCurrentStep(index)
      onChange && onChange(index, data)
    },
    [stepValidation, editable]
  )

  const renderStatusBar = useMemo(() => {
    const getBackgroundColor = (active: boolean, index: number) => {
      if (active) {
        return theme.colors.main
      } else if (isWizard && index > currentIndex && !stepValidation[index]) {
        return theme.colors.white
      } else {
        return '#f3f4f5'
      }
    }

    return (
      steps?.length && (
        <Row
          noPadding
          overflow="hidden"
          radius={5}
          border="solid 1px #dededf"
          mediaQuery={step1.segmentContainer}
        >
          {steps.map((step, index) => {
            const active = currentStep === index
            const isLast = index === steps.length - 1
            const background = getBackgroundColor(active, index)
            const _isValid = !!stepValidation ? stepValidation[index] : true

            const textColor = active
              ? theme.colors.white
              : !_isValid
              ? theme.colors.gray7
              : theme.colors.black1

            return (
              <Col key={`step_${index}`} noPadding>
                <Item
                  flex
                  height={58}
                  onClick={onTabClick}
                  mainAxis="center"
                  crossAxis="center"
                  position="relative"
                  data={{ index, ...step }}
                  background={background}
                  cursor={
                    _isValid
                      ? !editable
                        ? 'default'
                        : 'pointer'
                      : 'not-allowed'
                  }
                  borderRight={!isLast && 'solid 1px #dededf'}
                  mediaQuery={step1.item}
                >
                  {isWizard && (
                    <Container
                      flex
                      noGrow
                      width={35}
                      height={35}
                      radius="50%"
                      mainAxis="center"
                      crossAxis="center"
                      margin={{ right: 15 }}
                      border={`solid 1px ${textColor}`}
                    >
                      <Text
                        color={textColor}
                        size={20}
                        font={theme.typography.openSans}
                      >
                        {index + 1}
                      </Text>
                    </Container>
                  )}
                  <Container flex column noGrow>
                    <Text
                      textAlign="center"
                      size={16}
                      color={textColor}
                      font={theme.typography.openSans}
                    >
                      {step.title}
                    </Text>
                    {!!step.description && (
                      <Text
                        textAlign="center"
                        size={13}
                        color={textColor}
                        font={theme.typography.lato}
                      >
                        {step.description}
                      </Text>
                    )}
                  </Container>
                  {!isLast && isWizard && (
                    <Container
                      right={-9}
                      width={16}
                      height={16}
                      zIndex={1}
                      rotate={45}
                      position="absolute"
                      borderTop="solid 1px #dededf"
                      borderRight="solid 1px #dededf"
                      background={background}
                      bottom="calc(50% - 8px)"
                      mediaQuery={step1.hideElement}
                    />
                  )}
                </Item>
              </Col>
            )
          })}
        </Row>
      )
    )
  }, [steps, isWizard, currentStep, stepValidation])

  return (
    <Container>
      {renderStatusBar}
      {props?.children && props?.children.filter((c) => !!c)[currentStep]}
    </Container>
  )
})

Segment.defaultProps = {}
Segment.displayName = 'Segment'

export default memo(Segment)
