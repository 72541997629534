import { ClientRequestInfo, EmailTemplate } from 'src/interfaces/app'
import { isBoolean } from 'src/utils/misc'
import { BASE_URL, TemplateKeysMap } from 'src/constants'

const getLinkFor = (path: 'documents'|'wizard'|'photos'|'portal', clientInfo: ClientRequestInfo, token: string) => {
  const allFilesOk = clientInfo?.assessment?.status === "approved" &&
    clientInfo?.authorization?.status === "approved" &&
    clientInfo?.questionsDoc?.status === "approved"

  if (!token) {
    if (path === 'portal') {
      // WOZ-692
      return `${BASE_URL}client_detail/${clientInfo.id}`
    }
    return `${BASE_URL}client_detail/${clientInfo.id}/upload_files/${path}`
  }

  if (path === 'portal') {
    return `${BASE_URL}registration/${token}?clientId=${clientInfo.id}&details=true`
  }
  return `${BASE_URL}registration/${token}/${path}?clientId=${clientInfo.id}&allFilesOk=${allFilesOk}`
}

export const EMAIL_VARIABLES = (
  clientInfo: ClientRequestInfo,
  additionalVariables: Record<string, any> = {},
  token?: string,
) =>
  clientInfo
    ? {
        USER_SEX: clientInfo?.salutation,
        USER_SURNAME: clientInfo?.lastName,
        OBJECTNUMBER: clientInfo?.objectNumber,
        EXTERNAL_CASENUMBER: clientInfo?.caseNumber,
        USER_FIRSTNAME: clientInfo?.firstName,
        USER_NAME: `${clientInfo?.firstName} ${clientInfo?.lastName}`,
        BUILDING_ADDRESS: `${clientInfo.buildingStreet} ${clientInfo.buildingHouseNumber}${clientInfo.buildingAddition} te ${clientInfo.buildingPlace}`,
        AANSLAGNR: clientInfo?.assessmentNumber,
        WOZWAARDE: clientInfo?.wozValue,
        DELIVERY_WIZARD: getLinkFor('documents', clientInfo, token),
        DELIVERY_WIZARD_FULL: getLinkFor('wizard', clientInfo, token),
        DELIVERY_WIZARD_PHOTOS: getLinkFor('photos', clientInfo, token),
        PORTAL_HREF: getLinkFor('portal', clientInfo, token),
        SIGNATURE: !clientInfo?.authorization,
        SIGNATURE_NEW: !clientInfo?.authorization,
        EMAIL_SIGNATURE: true,
        QUESTION_LIST: !clientInfo?.questionsDoc,
        COPY_LETTER: !clientInfo?.assessment,
        QUESTION_LIST_NEW: !clientInfo?.questionsDoc,
        COPY_LETTER_NEW: !clientInfo?.assessment,
        ...additionalVariables,
      }
    : null

export type ValidationData = Record<string, string | boolean | number>

function getTemplateByKey(templates: EmailTemplate[], key: string) {
  return templates?.find((template) => template.key === key)
}

export function parseTemplate(
  content: string,
  validation?: ValidationData,
  templates?: EmailTemplate[]
): string {
  let parsedData: string[] = []
  const regEXP = new RegExp(/\%[A-a,\w+]+\%/, 'g')
  const regExpKey = new RegExp(/\%/, 'g')
  if (!content) {
    return ''
  }

  if (!validation || !content.match(regEXP)) {
    return content
  }

  content.replace(regEXP, (match: string, p1: any, p2: any) => {
    const value = match.replace(regExpKey, '')
    const parsArray = p2.split(regExpKey)
    if (parsedData.length === 0) {
      const index = parsArray.indexOf(value)
      const _val = validation[value]
      parsArray[index] = isBoolean(_val)
        ? _val
          ? parseTemplate(
              getTemplateByKey(templates, TemplateKeysMap[value])?.content,
              validation,
              templates
            )
          : ''
        : _val || ''
      parsedData = parsArray
    } else {
      const index = parsedData.indexOf(value)
      const _val = validation[value]

      // @ts-ignore
      parsedData[index] = isBoolean(_val)
        ? _val
          ? parseTemplate(
              getTemplateByKey(templates, TemplateKeysMap[value])?.content,
              validation,
              templates
            )
          : ''
        : _val || ''
    }
    return content
  })

  const a = parsedData.filter((i) => !!i.replace(/\r?\n|\r/g, ''))

  return a.join('')
}
