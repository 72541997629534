import { ContainerStyles } from 'src/interfaces/styles'
import { isNumber } from 'src/utils/misc'

import { getMeasure } from '../utils'

export const padding = ({ padding = {}, center, measure }: ContainerStyles) => `
  ${center ? 'padding: auto;' : ''}
  ${
    isNumber(padding)
      ? `${padding ? `padding: ${getMeasure(padding, measure)};` : ''}`
      : `
      ${padding.top ? `padding-top: ${getMeasure(padding.top, measure)};` : ''}
      ${
        padding.left
          ? `padding-left: ${getMeasure(padding.left, measure)};`
          : ''
      }
      ${
        padding.right
          ? `padding-right: ${getMeasure(padding.right, measure)};`
          : ''
      }
      ${
        padding.bottom
          ? `padding-bottom: ${getMeasure(padding.bottom, measure)};`
          : ''
      }
      ${
        padding.vertical
          ? `
          padding-top: ${getMeasure(padding.vertical, measure)}; 
          padding-bottom: ${getMeasure(padding.vertical, measure)};
        `
          : ''
      }
      ${
        padding.horizontal
          ? `
          padding-left: ${getMeasure(padding.horizontal, measure)};
          padding-right: ${getMeasure(padding.horizontal, measure)};
        `
          : ''
      } 
      `
  }
`
