import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'src/hooks/useForm'
import MainLayout from 'src/layouts/Main'
import { Segment, Wrapper } from 'src/components'
import Step1 from 'src/forms/uploadFiles/Step1'
import Step2 from 'src/forms/uploadFiles/Step2'
import Step3 from 'src/forms/uploadFiles/Step3'
import { getClientInfoById } from 'src/services/api'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ClientRequestInfo } from 'src/interfaces/app'
import { QUESTION_LIST_FORM } from 'src/forms/questionList/formData'
import residential from 'src/services/questions/question_residential.json'
import none_residential from 'src/services/questions/question_none_residential.json'
import Step4 from 'src/forms/uploadFiles/Step4'
// TASK: WOZ-573
import SuccessPage, { SuccessPageProps } from './SuccesPage'

function UploadFiles() {
  const wizard = useRef(null)
  const location = useLocation<{ data: ClientRequestInfo }>()
  const params = useParams<{ id: string; showUploads: string }>()

  const [data, setData] = useState(location?.state?.data)
  const [questions, setQuestions] = useState(!data?.questionsDoc)
  const [_fiels, setFiles] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [_questionsForm, _setQuestionsForm] = useState({})
  const [checkboxData, setCheckboxData] = useState<any>({})
  const [successPage, setSuccessPage] = useState<SuccessPageProps["type"]>(null)

  const listName =
    data?.property === 'bedrijfspand' ? 'none_residential' : 'residential'
  const list = listName === 'residential' ? residential : none_residential
  const {
    formData,
    formValues,
    updateFormData,
    onSubmit,
    updateDefaultData,
  } = useForm(QUESTION_LIST_FORM(list))
  const history = useHistory()

  const showUploadPhoto = !!data?.photoReminder || !!data?.photoRequest

  useEffect(() => {
    ;(async function () {
      setIsLoading(true)
      await getClientInfoData()
      setIsLoading(false)
    })()
  }, [])

  let steps = [
    { title: 'Invullen vragenlijst', isValid: true },
    { title: 'Controleren' },
    { title: 'Uploaden en versturen' },
  ]

  if (!questions) {
    steps = [
      { title: 'Invullen vragenlijst', isValid: true },
      { title: 'Uploaden en versturen' },
    ]
  }

  async function getClientInfoData() {
    const response = await getClientInfoById(params.id)
    if (!response) {
      return history.replace('/')
    }
    const l =
      response?.property === 'bedrijfspand'
        ? none_residential
        : residential
    updateDefaultData(QUESTION_LIST_FORM(l))
    setData(response)
  }

  function showWizard() {
    if (params?.showUploads === 'photos') {
      return (
        <Step4
          data={data}
          showSuccesPage={() => setSuccessPage('photos')}
          wizard={wizard}
          questions={false}
          questionsListFile={_fiels}
          updateData={getClientInfoData}
          formData={_questionsForm}
        />
      )
    } else if (params?.showUploads === 'documents') {
      return (
        <Step3
          isDocuments
          data={data}
          wizard={wizard}
          questions={questions}
          showSuccesPage={() => setSuccessPage('documents')}
          questionsListFile={_fiels}
          updateData={getClientInfoData}
          formData={_questionsForm}
        />
      )
    } else if (!!data?.questionsDoc) {
      const _steps: { title: string; isValid?: boolean }[] = [
        { title: 'Uploaden en versturen', isValid: true },
      ]
      if (showUploadPhoto) {
        _steps.push({ title: "Foto's uploaden" })
      }
      return (
        <Segment isWizard steps={_steps} ref={wizard}>
          <Step3
            data={data}
            wizard={wizard}
            questions={questions}
            showSuccesPage={() => setSuccessPage('documents')}
            questionsListFile={_fiels}
            updateData={getClientInfoData}
            formData={_questionsForm}
          />
          {showUploadPhoto && (
            <Step4
              data={data}
              showSuccesPage={() => setSuccessPage('photos')}
              wizard={wizard}
              questions
              questionsListFile={_fiels}
              updateData={getClientInfoData}
              formData={_questionsForm}
            />
          )}
        </Segment>
      )
    } else {
      return (
        <>
          <Segment isWizard steps={steps} ref={wizard}>
            <Step1
              data={data}
              checkboxData={checkboxData}
              setCheckboxData={setCheckboxData}
              wizard={wizard}
              setFiles={setFiles}
              questions={questions}
              formValues={formValues}
              setQuestions={setQuestions}
              formData={formData}
              onSubmit={onSubmit}
              updateFormData={updateFormData}
              setQuestionsForm={_setQuestionsForm}
            />
            {!!questions && <Step2 wizard={wizard} formData={_questionsForm} />}
            <Step3
              isWizard
              data={data}
              wizard={wizard}
              questions={questions}
              questionsListFile={_fiels}
              updateData={getClientInfoData}
              showSuccesPage={() => setSuccessPage('documents')}
              formData={_questionsForm}
            />
            <div />
          </Segment>
        </>
      )
    }
  }

  return (
    <MainLayout hideLeftPanel>
      <Wrapper isBusy={isLoading} padding={24} flex column>
        {successPage ? (
          <SuccessPage
            type={successPage}
            onLogout={() => setSuccessPage(null)}
          />
        ) : showWizard()}
      </Wrapper>
    </MainLayout>
  )
}

UploadFiles.defaultProps = {}

export default {
  component: UploadFiles,
  exact: false,
  public: false,
  path: '/client_detail/:id/upload_files/:showUploads',
}
