import { isLocalStorageEnabled } from 'src/utils/misc'

const memoryStorage = new Map()

const getItemFromStorage = (key: string, defaultValue?: any) => {
  if (isLocalStorageEnabled) {
    return window.localStorage.getItem(key) || defaultValue
  } else {
    return memoryStorage.get(key) || defaultValue
  }
}

const setItemInStorage = (key: string, value: any) => {
  if (isLocalStorageEnabled) {
    window.localStorage.setItem(key, value)
  } else {
    memoryStorage.set(key, value)
  }
}

const removeItemInStorage = (key: string) => {
  if (isLocalStorageEnabled) {
    window.localStorage.removeItem(key)
  } else {
    memoryStorage.delete(key)
  }
}

export default {
  set: setItemInStorage,
  get: getItemFromStorage,
  remove: removeItemInStorage,
}
