import styled from 'styled-components'

import { size } from 'src/styles/helpers/size'
import { flex } from 'src/styles/helpers/flex'
import { grid } from 'src/styles/helpers/grid'
import { font } from 'src/styles/helpers/font'
import { margin } from 'src/styles/helpers/margin'
import { padding } from 'src/styles/helpers/padding'
import { position } from 'src/styles/helpers/position'
import { border } from 'src/styles/helpers/border'
// TASK: WOZ-671
// TASK: WOZ-672
import { background } from 'src/styles/helpers/background'
import { getMeasure } from 'src/styles/utils'
// TASK: WOZ-571
import { mobile } from 'src/styles/helpers/mobile'

const defaultProps = {
  grow: false,
  shrink: false,
  center: false,
  flexWrap: false,
}

const borderRadius = ({ radius, measure }: any) => `
  ${radius ? `border-radius: ${getMeasure(radius, measure)}` : ''};
`
const display = ({ none }: any) => `
  ${none ? `display: none;` : ''}
`

const Container = styled.div`
  ${size} ${flex} ${grid} ${font} ${margin} ${padding} ${position} ${background} ${borderRadius} ${border} ${display}
  ${mobile}
  box-sizing: border-box;
`

Container.displayName = 'Container'
Container.defaultProps = defaultProps

export default Container
