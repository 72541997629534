import React, { useContext } from 'react'

// questions
import residential from './question_residential.json'
import none_residential from './question_none_residential.json'

// components
import { Radio, Textarea, Input, Button, Checkbox } from 'src/components'
import { Container, Text } from 'src/styles/styled-components'
import { ThemeContext } from 'styled-components'
import { isBoolean } from 'src/utils/misc'

interface QuestionsProps {
  formData: any
  checkboxData: any
  setCheckboxData: any
  updateFormData: (value: string, name: string) => void
  listName: 'residential' | 'none_residential'
  onFormSubmit: () => void
}

export type ConditionalQuestions = {
  isNext: string
  questions: Question[]
}

export type Checkbox = {
  content: string
  name: string
  showContent?: boolean
}

export type Question = {
  question: string
  required?: boolean
  radio?: string[]
  checkbox?: Checkbox[]
  type?: 'textarea' | 'input'
  condition?: ConditionalQuestions
}

export type QuestionData = {
  title?: string
  questions?: Question[]
}

export function Questions(props: QuestionsProps) {
  const { checkboxData, setCheckboxData } = props
  const theme = useContext(ThemeContext)
  const {
    formData,
    onFormSubmit,
    updateFormData,
    listName = 'residential',
  } = props
  const list = listName === 'residential' ? residential : none_residential

  function saveCheckboxData(
    value: boolean | string,
    name: string,
    inputName: string
  ) {
    setCheckboxData((oldData: any) => {
      const _data = { ...oldData[inputName] }
      if (isBoolean(value)) {
        if (_data[name] !== undefined) {
          delete _data[name]
        } else {
          _data[name] = ''
        }
      } else {
        _data[name] = value
      }

      let _value = ''

      Object.keys(_data).length
        ? Object.keys(_data).forEach((f) => {
            _value = _value + `${f}: ${_data[f] || '-'}\n`
          })
        : null

      updateFormData(_value, inputName)

      return { ...oldData, [inputName]: _data }
    })
  }

  function renderInput(name: string, type?: 'textarea' | 'input') {
    if (type === 'textarea') {
      return (
        <Container margin={{ top: 16 }}>
          <Textarea
            name={name}
            value={formData[name]?.value}
            onChange={updateFormData}
            errorText={formData[name]?.validationText}
          />
        </Container>
      )
    }

    return (
      <Container margin={{ top: 16 }}>
        <Input
          name={name}
          onChange={updateFormData}
          value={formData[name]?.value}
          errorText={formData[name]?.validationText}
        />
      </Container>
    )
  }

  function renderConditionalQuestions(
    conditions: ConditionalQuestions,
    selectedValue: string,
    name: string
  ) {
    return conditions.questions.map((item: Question, index: number) => {
      return (
        conditions.isNext === selectedValue && (
          <Container margin={{ left: 16 }}>
            {renderQuestion(item, `${name}_${index}`)}
            {!!item?.condition &&
              renderConditionalQuestions(item?.condition, selectedValue, name)}
          </Container>
        )
      )
    })
  }

  function renderRadioButtons(radio: string[], name: string) {
    return (
      <Container margin={{ top: 16 }}>
        {!!formData[name]?.validationText ? (
          <Container margin={{ bottom: 8 }}>
            <Text size={14} lineHeight={21} color={theme.colors.red1}>
              De bovenstaande vraag is verplicht. Geef a.u.b. uw antwoord.
            </Text>
          </Container>
        ) : null}
        {radio?.map((i: string) => {
          return (
            <Radio
              key={i}
              value={i}
              label={i}
              name={name}
              margin={{ bottom: 12 }}
              onCheck={updateFormData}
              checked={formData[name]?.value === i}
            />
          )
        })}
      </Container>
    )
  }

  function renderCheckboxButtons(checkbox: Checkbox[], name: string) {
    const checkboxItemData = checkboxData[name] || {}

    return (
      <Container margin={{ top: 16 }}>
        {!!formData[name]?.validationText ? (
          <Container margin={{ bottom: 8 }}>
            <Text size={14} lineHeight={21} color={theme.colors.red1}>
              De bovenstaande vraag is verplicht. Geef a.u.b. uw antwoord.
            </Text>
          </Container>
        ) : null}
        {checkbox.map((checkboxItem: Checkbox) => {
          return (
            <>
              <Checkbox
                key={checkboxItem.name}
                label={checkboxItem.content}
                name={checkboxItem.content}
                margin={{ bottom: 12 }}
                onCheck={(checked, _name) =>
                  saveCheckboxData(checked, _name, name)
                }
                checked={checkboxItemData[checkboxItem.content] !== undefined}
              />
              {checkboxItemData[checkboxItem.content] !== undefined &&
                checkboxItem?.showContent && (
                  <Container margin={{ vertical: 8 }}>
                    <Container padding={{ bottom: 4 }}>
                      <Text color={theme.colors.red0}>Kunt u dit toelichten?*</Text>
                    </Container>
                    <Textarea
                      name={checkboxItem.content}
                      value={checkboxItemData[checkboxItem.content]}
                      onChange={(value, _name) =>
                        saveCheckboxData(value, _name, name)
                      }
                    />
                  </Container>
                )}
            </>
          )
        })}
      </Container>
    )
  }

  function renderQuestion(data: Question, name: string) {
    const showInput = !data?.radio && !data?.checkbox
    return (
      <Container margin={{ bottom: 16 }}>
        <Text weight="bold">
          {data.question}
          {data.required && '*'}
        </Text>
        {data?.radio && renderRadioButtons(data.radio, name)}
        {data?.checkbox && renderCheckboxButtons(data.checkbox, name)}
        {showInput && !data?.type && renderInput(name, 'input')}
        {showInput && data?.type && renderInput(name, data?.type)}
        {!!data?.condition &&
          renderConditionalQuestions(
            data?.condition,
            formData[name]?.value,
            name
          )}
      </Container>
    )
  }

  function renderQuestions(data: Question[], inputName: string) {
    return data?.map((d, index) => renderQuestion(d, `${inputName}_${index}`))
  }

  function generateQuestionSection(data: any[]) {
    return (
      <Container>
        {data.map((item: QuestionData, index: number) => {
          return (
            <Container key={item.title}>
              <Text as="h3" weight="bold">
                {index + 1}. {item.title}
              </Text>
              {renderQuestions(item?.questions, `${item.title.toLowerCase()}`)}
            </Container>
          )
        })}
      </Container>
    )
  }

  return (
    <Container>
      {generateQuestionSection(list)}
      <Container>
        <Button
          large
          // TASK: WOZ-671
          mobile={{
            boxShadow: "0 3px 6px 0 rgb(0 0 0 / 10%), 0 1px 4px 0 rgb(0 0 0 / 16%)",
            weight: "bold",
            height: 64,
            size: 16,
          }}
          onClick={onFormSubmit}
          label="Naar Stap 2 - Controleren"
        />
      </Container>
    </Container>
  )
}
