import { ContainerStyles } from 'src/interfaces/styles'
import { isNumber } from 'src/utils/misc'

import { getMeasure } from '../utils'

export const margin = ({ margin = {}, center, measure }: ContainerStyles) => `
  ${center ? 'margin: auto;' : ''}
  ${
    isNumber(margin)
      ? `${margin ? `margin: ${getMeasure(margin, measure)};` : ''}`
      : `
      ${margin.top ? `margin-top: ${getMeasure(margin.top, measure)};` : ''}
      ${margin.left ? `margin-left: ${getMeasure(margin.left, measure)};` : ''}
      ${
        margin.right
          ? `margin-right: ${getMeasure(margin.right, measure)};`
          : ''
      }
      ${
        margin.bottom
          ? `margin-bottom: ${getMeasure(margin.bottom, measure)};`
          : ''
      }
      ${
        margin.vertical
          ? `
          margin-top: ${getMeasure(margin.vertical, measure)}; 
          margin-bottom: ${getMeasure(margin.vertical, measure)};
        `
          : ''
      }
      ${
        margin.horizontal
          ? `
          margin-left: ${getMeasure(margin.horizontal, measure)};
          margin-right: ${getMeasure(margin.horizontal, measure)};
        `
          : ''
      } 
      `
  }
`
