import { ClientRequestInfo } from 'src/interfaces/app'

export const DELIVERY_FILES_CONTENT = (data: ClientRequestInfo) => `
<p>Beste Stan,</p>

<p>Er is/zijn zojuist één of meerdere documenten aangeleverd voor het adres ${data.buildingStreet} ${data.buildingHouseNumber} te ${data.buildingPlace}.</p>

<p>Ga naar het portal om deze direct te beoordelen.</p>

<p>Portal WOZBEZWAAR.BIZ</p>
`

export const DELIVERY_PHOTOS_CONTENT = (data: ClientRequestInfo) => `
<p>Beste beheerder,</p>

<p>Zojuist heeft de klant van het object ${data.buildingStreet} ${data.buildingHouseNumber} te ${data.buildingPlace} foto's aangeleverd.</p>

<p>Ga naar de portal om deze te bekijken en te beoordelen.</p>

<p>Groet,</p>

<p>Portal WOZBEZWAAR.BIZ</p>
`

export const DOCUMENT_NEW_USER = (data: ClientRequestInfo, url: string) => `
<p>Geachte ${data.salutation} ${data.lastName},</p>
<p>U heeft aangegeven het e-mailadres te willen wijzigen dat hoort bij de WOZ-zaak ${data.buildingStreet} ${data.buildingHouseNumber} te ${data.buildingPlace}. Het e-mailadres wordt gewijzigd naar het e-mailadres waarnaar deze e-mail gestuurd is.</p>

<p>Om de wijziging door te voeren, dient u dit te bevestigen door op onderstaande knop te klikken. U kunt dan meteen een nieuw wachtwoord opgeven.</p>

<p><a href='${url}' target='_blank'>E-mailadres wijzigen</a></p>

<p>Vriendelijke groeten,</p><p>mr. Stan Mrosek</p>
<figure style="margin-left: 0;"><img src="https://wozbezwaar.biz/wp-content/uploads/2021/02/logo-woz.png" width="250px" height="37px"/></figure>
<p>Jan Campertstraat 5<br>6416 SG Heerlen</p><p>M: 06 - 12 42 56 32<br>T: &nbsp;045 - 851 68 76</p>
`

export const UPDATE_DOCUMENT_EMAIL = (data: ClientRequestInfo, url: string) => `
<p>Geachte ${data.salutation} ${data.lastName},</p>
<p>U heeft aangegeven het emailadres, dat hoort bij de WOZ-bezwaarprocedure van het object ${data.buildingStreet} ${data.buildingHouseNumber} te ${data.buildingPlace} te willen wijzigen naar het emailadres waarnaar deze email gestuurd is.</p>

<p>Klopt het dat u het emailadres dat hoort bij bovengenoemde zaak wilt wijzigen? Zo ja, klik dan op onderstaande knop.</p>

<p><a href='${url}' target='_blank'>Emailadres wijzigen</a></p>

<p>Vriendelijke groeten,</p><p>mr. Stan Mrosek</p>
<figure style="margin-left: 0;"><img src="https://wozbezwaar.biz/wp-content/uploads/2021/02/logo-woz.png" width="250px" height="37px"/></figure>
<p>Jan Campertstraat 5<br>6416 SG Heerlen</p><p>M: 06 - 12 42 56 32<br>T: &nbsp;045 - 851 68 76</p>
`
