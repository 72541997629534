// TASK: WOZ-571
import { useEffect, useState } from "react"
import { MOBILE_MAX_SIZE } from "src/constants"

export function useScreenSize(screenSize = MOBILE_MAX_SIZE) {
    const [isMobile, setIsMobile] = useState(
        document.body.clientWidth < screenSize
    )

    useEffect(() => {
        function onResize() {
            if (document.body.clientWidth < screenSize !== isMobile) {
                setIsMobile(!isMobile)
            }
        }

        window.addEventListener("resize", onResize)

        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [isMobile])

    return isMobile
}
