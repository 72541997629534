import { createAction, createReducer } from 'redux-exodus'
import orderBy from 'lodash.orderby'
import {
  getClients,
  getDocumentStopCaseList,
  getMunicipalities,
  getProfile,
  getTemplates,
  getTextBlocks,
} from 'src/services/api'
import { hasKey } from 'src/utils/misc'
import { ClientRequestInfo, EmailTemplate } from 'src/interfaces/app'
import moment from 'moment'
import { columnsDefaultData } from 'src/pages/dashboard/components/constants'

const defaultState: any = {
  profile: {},
  filterForm: {},
  emptySearchFields: {},
  tableFilters: {},
  clients: [],
  templates: [],
  textBlocks: [],
  stopCaseList: [],
  municipalities: [],
  visibleColumns: columnsDefaultData(),
  isCheckModalShow: false,
}

const getProfileAction = createAction({
  key: 'profile',
  name: 'GET_PROFILE',
  apiCall: getProfile,
  handleResponse: (result) => {
    return { ...result.docs[0].data(), id: result.docs[0].id }
  },
})

const getEmailTemplatesAction = createAction({
  key: 'templates',
  name: 'GET_TEMPLATES',
  apiCall: getTemplates,
  handleResponse: (result) => {
    // INIT template names for correct ordering
    const data: Record<string, EmailTemplate[]> = {
      "Welkomstbericht": [],
      "Profielwijzigingen": [],
      "Verstuur herinnering": [],
      "Foto's opvragen": [],
      "Stukken controleren": [],
      "Proforma ingediend": [],
      "Inhoudelijk bezwaar ingediend": [],
      "Uitspraak ontvangen": [],
      "Dossier stopgezet": [],
    }
    result.docs.forEach((doc: any) => {
      const template = { docId: doc.id, ...doc.data() }

      if (hasKey(data, template.name)) {
        data[template.name].push(template)
      } else {
        data[template.name] = [template]
      }
    })

    Object.keys(data).forEach(key => {
      if (!data[key]?.length) {
        delete data[key]
      }
    })

    return data
  },
})

const getTextBlocksAction = createAction({
  key: 'textBlocks',
  name: 'GET_TEXT_BLOCKS',
  apiCall: getTextBlocks,
  handleResponse: (result) => {
    const data = result.docs.map((doc: any) => ({ id: doc.id, ...doc.data() }))
    return orderBy(
      data,
      (row: ClientRequestInfo) => {
        // @ts-ignore
        const value = row.short_title
        return value != null ? value.toString().toLowerCase() : ''
      },
      'asc'
    )
  },
})

const showCheckModalAction = createAction({
  key: 'isCheckModalShow',
  name: 'SHOW_CHECK_MODAL',
})

const setFilterFormAction = createAction({
  key: 'filterForm',
  name: 'SET_FILTER_FORM',
})
const setTableFiltersAction = createAction({
  key: 'tableFilters',
  name: 'SET_TABLE_FILTERS',
})

const setVisibleColumnsAction = createAction({
  key: 'visibleColumns',
  name: 'SET_VISIBLE_COLUMNS',
})

const setEmptySearchFieldsAction = createAction({
  key: 'emptySearchFields',
  name: 'SET_EMPTY_SEARCH_FIELDS',
})

const getMunicipalitiesAction = createAction({
  key: 'municipalities',
  name: 'GET_MUNICIPALITIES',
  apiCall: getMunicipalities,
  handleResponse: (result) => {
    return result.docs.map((doc: any) => ({ id: doc.id, ...doc.data() }))
  },
})
const getDocumentStopCaseListAction = createAction({
  key: 'stopCaseList',
  name: 'GET_STOP_CASE_LIST',
  apiCall: getDocumentStopCaseList,
  handleResponse: (result) => {
    return result.docs.map((doc: any) => ({ id: doc.id, ...doc.data() }))
  },
})

const getClientsAction = createAction({
  key: 'clients',
  name: 'GET_CLIENTS',
  apiCall: getClients,
  handleResponse: (result) => {
    return orderBy(
      result.docs.map((doc: any) => ({ id: doc.id, ...doc.data() })),
      (item) => {
        return moment(item.created_date).unix()
      },
      'desc'
    )
  },
})

export const actions = {
  getProfile: getProfileAction,
  getClients: getClientsAction,
  setFilterForm: setFilterFormAction,
  setTableFilters: setTableFiltersAction,
  setVisibleColumns: setVisibleColumnsAction,
  setEmptySearchFields: setEmptySearchFieldsAction,
  showCheckModal: showCheckModalAction,
  getTextBlocks: getTextBlocksAction,
  getEmailTemplates: getEmailTemplatesAction,
  getMunicipalities: getMunicipalitiesAction,
  getDocumentStopCaseList: getDocumentStopCaseListAction,
}

export default createReducer(actions, defaultState)
