import React, { memo, useEffect, useState } from 'react'
import {
  Container,
  Text,
  TextAreaContainer,
} from 'src/styles/styled-components'
import {
  Button,
  Checkbox,
  FormElement,
  Input,
  Radio,
  Select,
} from 'src/components'
import {
  ClientRequestInfo,
  EmailTemplate,
  TemplateCategoryIds,
  TemplateIds,
} from 'src/interfaces/app'
import { editClient, sendEmail } from 'src/services/api'

import { useAlert } from 'react-alert'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { stopCaseListSelector } from 'src/selectors'
import { DATE_FORMAT } from 'src/constants'
import moment from 'moment'
import { useEmailTemplate } from 'src/email'
import ReactHtmlParser from 'react-html-parser'
import { EMAIL_VARIABLES } from 'src/email/misc'

interface StopFileProps {
  email: string
  updateData: () => void
  onRequestSent: () => void
  clientInfo: ClientRequestInfo
}

function StopFile(props: StopFileProps) {
  const { email, updateData, clientInfo, onRequestSent } = props

  const alert = useAlert()
  const stopCaseList = useSelector(stopCaseListSelector)
  const { getTemplates } = useEmailTemplate()

  const params = useParams<{ id: string }>()

  const [_email, setEmail] = useState(email)
  const [reason, setReason] = useState('1')

  const templates = getTemplates(
    TemplateCategoryIds.FILE_STOPPED,
    EMAIL_VARIABLES(clientInfo)
  )
  const template = getEmailTemplateByReason(templates)
  const municipalityTemplate = getEmailTemplateForMunicipality(templates)

  const [subject, setSubject] = useState(template.subject)
  const [content, setContent] = useState(template.content)
  const [_municipalitySubject, setMunicipalitySubject] = useState(
    municipalityTemplate.subject
  )
  const [_municipalityContent, setMunicipalityContent] = useState(
    municipalityTemplate.content
  )
  const [_municipalityEmail, setMunicipalityEmail] = useState(
    clientInfo?.municipality?.email
  )

  const [isPersonal, setIsPersonal] = useState(false)
  const [isPersonalClient, setIsPersonalClient] = useState(false)

  const [sendMailType, setSendMailType] = useState(0)

  useEffect(() => {
    const _template = getEmailTemplateByReason(templates)
    setSubject(_template.subject)
    setContent(_template.content)
  }, [reason])

  const stopCaseListOptions = stopCaseList.map((i) => ({
    label: i.text,
    value: i.id,
  }))

  function getEmailTemplateByReason(templates: EmailTemplate[]) {
    return templates.find((template) => template.key === `reason${reason}`)
  }

  function getEmailTemplateForMunicipality(templates: EmailTemplate[]) {
    return templates.find(
      (template) => template.key === TemplateIds.SEND_REASON_TO_MUNICIPALITY
    )
  }

  async function submit() {
    await editClient(params.id, {
      isStop: true,
      stopReason: reason,
      decision: 'null',
      documentStoppedDate: moment().format(DATE_FORMAT),
    })
    onRequestSent()

    if (sendMailType === 0 || sendMailType === 2) {
      await sendEmail({
        to: _email,
        subject,
        body: content,
      })
    }
    if (sendMailType === 1 || sendMailType === 2) {
      await sendEmail({
        to: _municipalityEmail,
        subject: _municipalitySubject,
        body: _municipalityContent,
      })
    }

    await updateData()
    alert.warning('Document stopped')
  }

  const radioButtons = [
    { label: 'Klant', value: 0 },
    { label: 'Gemeente', value: 1 },
    { label: 'Beide', value: 2 },
    { label: 'Geen van beide', value: 3 },
  ]

  return (
    <Container width="100%" padding={48} height="fit-content">
      <Container width="40%" margin={{ vertical: 24 }}>
        <Select
          label="Noem hieronder de reden"
          options={stopCaseListOptions}
          onSelect={setReason}
          value={reason}
        />
      </Container>
      <Container margin={{ bottom: 16 }}>
        <Text>(Wie) wil je mailen?</Text>
        <Container flex>
          {radioButtons.map((radioButton) => {
            return (
              <Container margin={{ right: 16 }} key={radioButton.value}>
                <Radio
                  onCheck={setSendMailType}
                  label={radioButton.label}
                  value={radioButton.value}
                  checked={radioButton.value === sendMailType}
                />
              </Container>
            )
          })}
        </Container>
      </Container>
      {(sendMailType === 0 || sendMailType === 2) && (
        <Container margin={{ top: 16 }}>
          <Text size={18} weight="bold">
            E-mail naar de klant
          </Text>
          <Container margin={{ bottom: 32 }} flex noGrow crossAxis="center">
            <Text margin={{ right: 16 }}>Email wordt verzonden naar: </Text>
            <Input
              name="email"
              size="default"
              value={_email}
              onChange={setEmail}
              disabled={!isPersonalClient}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Input
              name="subject"
              value={subject}
              onChange={setSubject}
              disabled={!isPersonalClient}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <FormElement
              isWysiwyg
              name="body"
              edit={isPersonalClient}
              value={content}
              formType="textarea"
              onChange={setContent}
            >
              <TextAreaContainer>{ReactHtmlParser(content)}</TextAreaContainer>
            </FormElement>
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Checkbox
              name="isPersonal"
              label="E-mail personaliseren"
              checked={isPersonalClient}
              onCheck={setIsPersonalClient}
            />
          </Container>
        </Container>
      )}
      {(sendMailType === 1 || sendMailType === 2) && (
        <Container margin={{ top: 24 }}>
          <Text size={18} weight="bold">
            E-mail naar de gemeente
          </Text>
          <Container margin={{ bottom: 32 }} flex noGrow crossAxis="center">
            <Text margin={{ right: 16 }}>Email wordt verzonden naar: </Text>
            <Input
              name="email"
              size="default"
              disabled={!isPersonal}
              value={_municipalityEmail}
              onChange={setMunicipalityEmail}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Input
              name="subject"
              value={_municipalitySubject}
              onChange={setMunicipalitySubject}
              disabled={!isPersonal}
            />
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <FormElement
              isWysiwyg
              name="body"
              edit={isPersonal}
              value={_municipalityContent}
              formType="textarea"
              onChange={setMunicipalityContent}
            >
              <TextAreaContainer>
                {ReactHtmlParser(_municipalityContent)}
              </TextAreaContainer>
            </FormElement>
          </Container>
          <Container flex margin={{ bottom: 32 }}>
            <Checkbox
              name="isPersonal"
              checked={isPersonal}
              onCheck={setIsPersonal}
              label="E-mail personaliseren"
            />
          </Container>
        </Container>
      )}
      <Container flex mainAxis="center">
        {/* TASK: WOZ-633 */}
        <Button big xxl onClick={submit} label={sendMailType === 3 ? 'Opslaan' : 'Versturen'} />
      </Container>
    </Container>
  )
}

export default memo(StopFile)
