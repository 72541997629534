import React, { memo, useContext, useRef } from "react"
import { Text } from 'src/styles/styled-components'
import { Select } from "src/components"
import OptionsContainer from "src/components/select/styled/OptionsContainer"
import OptionStyled from "src/components/select/styled/OptionStyled"
import Item from "src/helpers/Item"
import { ThemeContext } from "styled-components"

type ChildrenProps = {
  name: string
  value: string
  onChange: (value: string, name: string) => void
}

type ClearableSelectProps = {
  children: (props: ChildrenProps) => React.ReactNode
  updateFilterForm: (value: string, name: string) => void
  setEmptyKeys: (emptyKeys: { [key: string]: boolean }) => void
  emptyKeys: { [key: string]: boolean }
  formData: any
  name: string
  label: string
  placeholder?: string
}

const ClearableSelect = ({
  children,
  updateFilterForm,
  setEmptyKeys,
  emptyKeys,
  formData,
  name,
  label,
  placeholder = "Make a selection",
}: ClearableSelectProps) => {
  const theme = useContext(ThemeContext)
  const selectRef = useRef({ close: () => {} })

  return (
    <Select
      ref={selectRef}
      label={label}
      placeholder={emptyKeys[name] ? 'Leeg' : placeholder}
      value={emptyKeys[name] ? 'Leeg' : formData[name]}
      renderCustomOption={() => (
        <OptionsContainer overflowY="auto">
          <Item
            data={null}
            onClick={() => {
              selectRef.current.close()
              updateFilterForm('', name)
              setEmptyKeys({ ...emptyKeys, [name]: true })
            }}
          >
            <OptionStyled>
              <Text
                flex
                size={14}
                color={theme.colors.gray5}
                font={theme.typography.lato}
              >
                Leeg
              </Text>
            </OptionStyled>
          </Item>
          <Item data={null}>
            <OptionStyled padding="6px 12px" height={64}>
              {children({
                name,
                value: formData[name],
                onChange(value) {
                  selectRef.current.close()
                  updateFilterForm(value, name)
                  setEmptyKeys({ ...emptyKeys, [name]: false })
                },
              })}
            </OptionStyled>
          </Item>
        </OptionsContainer>
      )} />
  )
}

export default memo(ClearableSelect);
