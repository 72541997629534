import React, { ReactNode, useMemo } from 'react'
import { Ordered, UnOrdered } from './styled/ListStyled'
import ListItem from './ListItem'

interface ListProps {
  ordered?: boolean
  children?: ReactNode
}

export default function List(props: ListProps) {
  const ListStyled = useMemo(
    () => props.ordered ? Ordered : UnOrdered,
    [props.ordered]
  )

  return (
    <ListStyled>
      {props.children}
    </ListStyled>
  )
}

List.defaultProps = {
  ordered: false,
}

export { ListItem }
