import { FormData, FormItem } from 'src/hooks/useForm'
import { isEmail } from 'src/utils/misc'

export const SIGN_IN_CLIENT_BUILDING_INFO: FormData = {
  addressCount: {
    value: '1',
    exclude: true,
    name: 'addressCount',
  },
  property: {
    value: 'woning in eigendom', // gehuurde woning, bedrijfspand
    name: 'property',
  },
  buildingPostCode: {
    value: '',
    name: 'buildingPostCode',
    validationType: 'required',
    validationText: 'Voer de postcode in.',
  },
  buildingHouseNumber: {
    value: '',
    name: 'buildingHouseNumber',
    validationType: 'required',
    validationText: 'Voer het huisnummer in.',
  },
  buildingAddition: {
    value: '',
    name: 'buildingAddition',
  },
  buildingStreet: {
    value: '',
    name: 'buildingStreet',
    validationType: 'required',
    validationText: 'Voer de straat in.',
  },
  buildingPlace: {
    value: '',
    name: 'buildingPlace',
    validationText: 'Voer de plaats in.',
    validationType: 'required',
  },
  town: {
    value: '',
    name: 'town',
    validationType: 'required',
    validationText: 'Voer de gemeente in.',
  },
  assessment: {
    value: true,
    isFormItem: false,
    name: 'assessment',
    dependencies: ['wozValue', 'assessmentDate', 'assessmentNumber'],
  },
  wozValue: {
    value: '',
    name: 'wozValue',
    validationText: 'Voer de WOZ-waarde in.',
    validation: step1Validation,
  },
  assessmentDate: {
    value: '',
    name: 'assessmentDate',
    validationText: 'Selecteer de dagtekening.',
    validation: step1Validation,
  },
  assessmentNumber: {
    value: '',
    name: 'assessmentNumber',
    validationText: 'Voer het aanslagnummer in.',
    validation: step1Validation,
  },
}
function step1Validation(value: string, data: FormData) {
  return data.assessment.value ? value && !!value.trim().length : true
}

function step2Validation(value: string, data: FormData) {
  return data.copyAddressFormStep1.value || !!value.trim().length
}

export const SIGN_IN_CLIENT_PERSONAL_INFO: FormData = {
  salutation: {
    value: '', // heer., mevrouw.
    name: 'salutation',
    validationText: 'Kies uw aanhef.',
    validationType: 'required',
  },
  fullName: {
    value: '',
    name: 'fullName',
  },
  firstName: {
    value: '',
    name: 'firstName',
    validationText: 'Voer de voorletters en achternaam in.',
    validationType: 'required',
  },
  lastName: {
    value: '',
    name: 'lastName',
    validationText: 'Voer de voorletters en achternaam in.',
    validationType: 'required',
  },
  copyAddressFormStep1: {
    value: true,
    exclude: true,
    isFormItem: false,
    name: 'copyAddressFormStep1',
    dependencies: [
      'personalPostCode',
      'personalHouseNumber',
      'personalAddition',
      'personalStreet',
      'personalPlace',
    ],
  },
  personalPostCode: {
    value: '',
    name: 'personalPostCode',
    exclude: true,
    validationText: 'Voer de postcode in.',
    validation: step2Validation,
  },
  personalHouseNumber: {
    value: '',
    name: 'personalHouseNumber',
    exclude: true,
    validationText: 'Voer het huisnummer in.',
    validation: step2Validation,
  },
  personalAddition: {
    value: '',
    name: 'personalAddition',
  },
  personalStreet: {
    value: '',
    name: 'personalStreet',
    exclude: true,
    validationText: 'Dit veld is verplicht.',
    validation: step2Validation,
  },
  personalPlace: {
    value: '',
    name: 'personalPlace',
    exclude: true,
    validationText: 'Voer de plaats in.',
    validation: step2Validation,
  },
  phone: {
    value: '',
    name: 'phone',
    validationText: 'Voer het telefoonnummer in.',
    validationType: 'required',
  },
  email: {
    value: '',
    name: 'email',
    validationText: 'Voer een e-mailadres in.',
    validation: (value: string, data: FormData) =>
      data.disabledEmail.value ? true : isEmail(value),
  },
  disabledEmail: {
    value: false,
    isFormItem: false,
    name: 'disabledEmail',
  },
  reasonOfObjection: {
    value: '',
    name: 'reasonOfObjection',
    validationText: 'Typ hier de reden waarom u een een bezwaar wilt indienen.',
    validationType: 'required',
  },
  howFindUs: {
    value: '', // Via een zoekmachine (bv. Google), Via een flyer, Doorverwezen door iemand, Via LinkedIn, Via WijLimburg.nl, Via onze Facebook-pagina / Facebook-advertentie, Via een advertentie in een weekblad, Wij hebben al eerder voor u bezwaar gemaakt
    name: 'howFindUs',
    validationText: 'Maak a.u.b. een keuze.',
    validationType: 'required',
  },
}
