import React, { memo, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Button, Input, Form } from 'src/components'
import { Container, Text } from 'src/styles/styled-components'

import { useForm } from 'src/hooks/useForm'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/authorization'

// data
import { AUTH_FORM } from 'src/forms/authorization/formData'
import { useSelector } from 'react-redux'
import { signInHandlerSelector } from 'src/selectors'
import { useAlert } from 'react-alert'
import { verifyEmail, getClients, getProfile } from 'src/services/api'

interface AuthFormProps {
  redirectUrl?: string
}

function AuthForm(props: AuthFormProps) {
  const alert = useAlert()
  const { updateFormData, formData, formValues, onSubmit } = useForm(AUTH_FORM)
  const { signIn, setIsLoggedIn } = useActions(actions)
  const history = useHistory<{ from?: { pathname: string } }>()
  const { error } = useSelector(signInHandlerSelector)

  useEffect(() => {
    if (error?.message) {
      alert.error(
        'Wachtwoord is onjuist of geen wachtwoord bekend bij dit e-mailadres.'
      )
    }
  }, [error])

  function handleSubmit() {
    onSubmit(() => {
      signIn(formValues.email, formValues.password).then(async (signInResult: any) => {
        if (!signInResult.user) {
          return
        }
        setIsLoggedIn(true)
        if (props.redirectUrl) {
          const url = props.redirectUrl.split('/')
          const t = url[2]
          const main = url[1]
          if (t && main === 'verify') {
            const result = await verifyEmail(t)
            if (result.error) {
              alert.error(result.error)
            } else if (result.type === 'change_email_client_info') {
              alert.success('Approved')
            }
          }
        }
        const user = await getProfile()
        if (user.size && user.docs[0].data().role === 'customer') {
          const firstTwoClient = await getClients(user.docs[0].data().email, 2)

          if (props.redirectUrl && !props.redirectUrl.includes('not_found')) {
            history.replace(props.redirectUrl)
          } else if (firstTwoClient.size === 1) {
            history.replace(`/client_detail/${firstTwoClient.docs[0].id}`)
          } else {
            history.replace('/')
          }
        }
      })
    })
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Container margin={{ bottom: 15 }}>
          <Input
            name="email"
            onChange={updateFormData}
            value={formData.email.value}
            placeholder="E-mailadres"
            errorText={formData.email.validationText}
          />
        </Container>
        <Input
          name="password"
          type="password"
          placeholder="Wachtwoord"
          onChange={updateFormData}
          value={formData.password.value}
          errorText={formData.password.validationText}
        />
        <Container flex mainAxis="center" margin={{ top: 15, bottom: 20 }}>
          <Button big type="submit" label="Inloggen" large />
        </Container>
      </Form>
      <Link to="/forgot-password">
        <Text size={12}>Wachtwoord wijzigen</Text>
      </Link>
    </>
  )
}

AuthForm.defaultProps = {}

export default memo(AuthForm)
