import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'

// TASK: WOZ-634
const DatePickerWrapper = styled.div`
  width: 100%;
  .SingleDatePicker {
    width: 100%;
    & > div {
      display: flex;
    }
  }
  & .SingleDatePicker,
  .SingleDatePickerInput {
    width: 100%;
    .DateInput {
      width: 100%;
      display: flex;
      flex-grow: 1;

      .DateInput_input {
        width: 100%;
        height: 35px;
        font-size: 16px;
        line-height: 24px;
        border-bottom: none;
        padding: 0;
        color: ${defaultTheme.colors.gray5};
        font-family: ${defaultTheme.typography.lato};
      }
    }
    .SingleDatePickerInput__withBorder {
      border-radius: 4px;
      overflow: hidden;
      border: none;
    }
  }
`

DatePickerWrapper.displayName = 'DatePickerWrapper'

export default DatePickerWrapper
