import JsPDF from 'jspdf'
import 'jspdf-autotable'
import { ClientRequestInfo } from 'src/interfaces/app'

import logo from 'src/assets/images/logo.png'
import pdfBg from 'src/assets/images/pdf_bg.png'

// pdf JSON
import signatureExample from './pdfGeneratorDataJson/signatureExample.json'
import questionsList from './pdfGeneratorDataJson/questionsList.json'
import FOExport from './pdfGeneratorDataJson/FOExport.json'
import COExport from './pdfGeneratorDataJson/COExport.json'
import EtiketExport from './pdfGeneratorDataJson/EtiketExport.json'

import { getExifData, hasKey, isArray } from 'src/utils/misc'
import moment from 'moment'

import { getFileUrl } from 'src/services/api'
import { generateDocx } from 'src/services/docxGenerator'
import {
  APP_LOGO,
  COPY_LETTER_BG,
  DIRECTOR_SIGNATURE_URL,
  DOCUMENT_BG,
} from 'src/constants'
import {
  NON_RESIDENTAL,
  RESIDENTAL,
} from 'src/services/pdfGeneratorDataJson/constnants'
import { EMAIL_VARIABLES } from 'src/email/misc'
moment.locale('de');

const jsonData = {
  signatureExample,
  questionsList,
  FOExport,
  EtiketExport,
  COExport,
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

const toDataURL = (url: string) => getFileUrl(url)

const centeredText = function (doc: JsPDF, text: string, y: number) {
  const textWidth =
    (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
    doc.internal.scaleFactor
  const textOffset = (doc.internal.pageSize.width - textWidth) / 2
  doc.text(text, textOffset, y)
}

export function pdfTextParser(value: string, data: Record<string, any>) {
  let parsedData: string[] = []
  const regEXP = new RegExp(/\%[A-a,\w+]+\%/, 'g')
  const regExpKey = new RegExp(/\%/, 'g')

  if (!value.match(regEXP)) {
    return value
  }

  value.replace(regEXP, (match: string, p1: any, p2: any) => {
    const value = match.replace(regExpKey, '')
    const parsArray = p2.split(regExpKey)

    if (parsedData.length === 0) {
      const index = parsArray.indexOf(value)

      parsArray[index] = data[value]
      parsedData = parsArray
    } else {
      const index = parsedData.indexOf(value)
      parsedData[index] = data[value]
    }
    return value
  })

  return parsedData.join('')
}

function pdfGenerator(
  name: string,
  parsedData: Record<string, any> = {},
  options: Record<string, any> = {},
  format?: any,
  _doc?: any
): JsPDF {
  const { offset = 10, orientation = 'p' } = options
  const json = hasKey(jsonData, name) ? jsonData[name] : null
  let y = 0

  if (json) {
    const doc = _doc || new JsPDF(orientation, 'mm', format || 'a4')
    const WIDTH = doc.internal.pageSize.getWidth()
    const HEIGHT = doc.internal.pageSize.getHeight()
    doc.addFont('Arial', 'normal')
    doc.setFont('Arial')

    json.forEach((item: any) => {
      if (item?.image) {
        if (item?.image === 'BG') {
          y = y + 25
          doc.addImage(pdfBg, 'PNG', 0, 0, WIDTH, HEIGHT)
        } else if (item?.image === 'LOGO') {
          y = y + 15
          doc.addImage(logo, 'PNG', WIDTH / 2 - 46, y, 92, 13)
        } else if (item?.image === 'SIGNATURE') {
          y = y + item.positions[1]
          if (parsedData['SIGNATURE']) {
            doc.addImage(parsedData['SIGNATURE'], 'PNG', 32, y, 30, 20)
          }
        } else if (item?.image === 'SIGNATURE_MANAGER') {
          y = y + item.positions[1]
          if (parsedData['SIGNATURE_MANAGER']) {
            doc.addImage(parsedData['SIGNATURE_MANAGER'], 'PNG', 32, y, 40, 20)
          }
        }
      } else if (item?.fontSize) {
        doc.setFontSize(item?.fontSize)
      } else if (item?.newDoc) {
        y = 0
        doc.addPage(format || 'a4')
      } else if (item?.text) {
        y = y + item.positions[1]

        if (item.bold) {
          doc.setFont('Helvetica', 'bold')
        } else {
          doc.setFont('Helvetica', 'normal')
        }

        if (item?.align) {
          const dim = doc.getTextDimensions(item?.text)
          if (item.align === 'center') {
            centeredText(doc, pdfTextParser(item.text, parsedData), y)
          } else if (item.align === 'right') {
            doc.text(
              pdfTextParser(item.text, parsedData),
              WIDTH - offset / 2,
              y,
              null,
              null,
              'right'
            )
          }
          y = y + dim.h
        } else {
          const splitText = doc.splitTextToSize(
            pdfTextParser(item.text, parsedData),
            WIDTH - offset
          )
          const dim = doc.getTextDimensions(splitText)

          doc.text(item.positions[0], y, splitText)
          y = y + dim.h
        }
      } else if (item?.line) {
        const [x1, y1, x2] = item.positions
        y = y + y1
        doc.line(x1, y, x2, y)
      } else if (item?.table) {
        y = y + item.y
        // @ts-ignore
        doc.autoTable({
          startY: y,
          tableWidth: WIDTH - offset,
          margin: { left: 5 },
          ...parsedData.table,
        })
      }
    })

    return doc
  }

  return null
}

export async function generateSignatureExample(clientInfo: ClientRequestInfo) {
  const {
    phone,
    firstName,
    lastName,
    signature,
    municipality,
    personalPlace,
    personalStreet,
    personalAddition,
    personalPostCode,
    assessmentNumber,
    personalHouseNumber,
  } = clientInfo

  const base64Signature = signature?.file
    ? await toDataURL(signature?.file)
    : null

  const dynamicData = {
    NAME: `${firstName} ${lastName}`,
    ADDRESS: `${personalStreet} ${personalHouseNumber}${
      personalAddition || ''
    }`,
    POSTCODE: `${personalPostCode} ${personalPlace}`,
    PHONE: phone,
    USER_PLACE: personalPlace,
    MUNICIPALITY: municipality,
    ANSLAAG: assessmentNumber,
    DATE: moment().format('DD-MM-YYYY'),
    SIGNATURE: base64Signature,
  }
  const doc = pdfGenerator('signatureExample', dynamicData)

  const blob = doc.output('blob')
  const file = new File([blob], 'Machtiging.pdf', {
    type: 'application/pdf',
  })

  return {
    file,
    base64: () => arrayBufferToBase64(doc.output('arraybuffer')),
    blob: doc.output('blob'),
    download: () => doc.save('Machtiging.pdf'),
  }
}

export async function generateFO(
  clientInfo: ClientRequestInfo,
  additionalData: Record<string, any>,
  type: 'PDF' | 'WORD' | 'WORD_EXCLUDE' = 'PDF',
  withoutSignature = false
) {
  const {
    phone,
    firstName,
    lastName,
    signature,
    personalStreet,
    personalHouseNumber,
    buildingPlace,
    authorization,
    authorizationImage,
    personalPostCode,
    personalPlace,
    personalAddition,
    assessmentNumber,
    municipality,
  } = clientInfo

  const base64SignatureClient = signature?.file
    ? await toDataURL(signature?.file)
    : null

  const base64Signature = await toDataURL(DIRECTOR_SIGNATURE_URL)
  const bg = await toDataURL(DOCUMENT_BG)
  const base64signatureImage = authorizationImage ? await toDataURL(authorizationImage.file) : null

  const dynamicData = {
    DELAY_DATE: `30 juni ${moment().format('YYYY')}`,
    NAME: `${firstName} ${lastName}`,
    CITY: `${buildingPlace},`,
    MUNICIPALITY_EMAIL: `(${
      municipality?.email || '                           '
    })`,
    PHONE: phone,
    MUNICIPALITY: `${municipality?.name || ''}`,
    POSTBUS: `${municipality ? `Postbus ${municipality?.post_bus}` : ''}`,
    PLACE: municipality?.city || '',
    AANSLAGNR: assessmentNumber,
    ADDRESS: `${personalStreet} ${personalHouseNumber}${
      personalAddition || ''
    }`,
    POSTCODE: `${personalPostCode} ${personalPlace}`,
    USER_PLACE: personalPlace,
    BUILDING_ADDRESS: `${clientInfo.buildingStreet} ${
      clientInfo.buildingHouseNumber
    }${clientInfo.buildingAddition || ''} te ${clientInfo.buildingPlace}`,
    POSTCODE_MUNICIPALITY: `${municipality?.postal_code || ''}`,
    YEAR: moment().format('YYYY'),
    DATE: moment().format('D MMMM YYYY').toLowerCase(),
    HAS_MACHTIGING: clientInfo.authorization ? 'Bijlage: machtiging' : '',
    SIGNATURE_MANAGER: base64Signature,
    SIGNATURE: base64SignatureClient,
    SIGNATURE_IMAGE: base64signatureImage,
    BG: bg,
  }
  let _doc

  if (type === 'PDF') {
    const doc = pdfGenerator('FOExport', dynamicData, { offset: 64 })
    if (authorization && authorization.status === 'approved') {
      doc.addPage('a4')

      _doc = pdfGenerator(
        'signatureExample',
        dynamicData,
        undefined,
        undefined,
        doc
      )
    }

    const document = _doc || doc
    const blob = document.output('blob')
    const file = new File([blob], `FO_export.pdf`, {
      type: 'application/pdf',
    })

    return {
      file,
      base64: () => document.output('datauristring'),
      blob: document.output('blob'),
      download: () => document.save('FO_export.pdf'),
    }
  } else {
    if (
      authorization &&
      authorization.status === 'approved' &&
      !withoutSignature
    ) {
      const document = await generateSignatureExample(clientInfo)
      document.download()
    }

    return generateDocx(`FOExport`, dynamicData, pdfTextParser, type)
  }
}

export function generateEtiket(clientInfo: ClientRequestInfo) {
  const {
    firstName,
    lastName,
    personalPlace,
    personalAddition,
    personalHouseNumber,
    personalPostCode,
    personalStreet,
  } = clientInfo

  const dynamicData = {
    NAME: `${firstName} ${lastName}`,
    STREET: `${personalStreet} ${personalHouseNumber}${personalAddition || ''}`,
    ADDRESS: `${personalPostCode} ${personalPlace}`,
  }

  const doc = pdfGenerator(
    'EtiketExport',
    dynamicData,
    { offset: 5, orientation: 'landscape' },
    [36, 89]
  )

  const blob = doc.output('blob')
  const file = new File([blob], `Print etiket.pdf`, {
    type: 'application/pdf',
  })

  return {
    file,
    base64: () => doc.output('datauristring'),
    blob: doc.output('blob'),
    download: () => doc.save('Print etiket.pdf'),
  }
}

export async function generateCopyLetter(
  clientInfo: ClientRequestInfo,
  doc?: any
) {
  const {
    town,
    phone,
    email,
    firstName,
    lastName,
    buildingPlace,
    buildingStreet,
    assessmentDate,
    buildingPostCode,
    buildingAddition,
    buildingHouseNumber,
  } = clientInfo
  const bg = await toDataURL(COPY_LETTER_BG)

  const dynamicData = {
    NAME: `${firstName} ${lastName}`,
    PHONE: phone,
    CITY: buildingPlace,
    MUNICIPALITY: town,
    ANSLAAG: assessmentDate,
    MUNICIPALITY_EMAIL: email || '',
    ADDRESS: `${buildingStreet} ${buildingHouseNumber}${
      buildingAddition || ''
    }`,
    POSTCODE: `${buildingPostCode} ${buildingPlace}`,
    BG: bg,
  }

  return generateDocx('CopyLetter', dynamicData, pdfTextParser, undefined, doc)
}

export async function generateCopyLetterBulkExport(
  clientInfo: ClientRequestInfo[]
) {
  const data: any[] = []
  const bg = await toDataURL(COPY_LETTER_BG)
  clientInfo.forEach((i: ClientRequestInfo) => {
    const {
      town,
      phone,
      email,
      firstName,
      lastName,
      buildingPlace,
      buildingStreet,
      assessmentDate,
      buildingPostCode,
      buildingAddition,
      buildingHouseNumber,
    } = i

    const dynamicData = {
      NAME: `${firstName} ${lastName}`,
      PHONE: phone,
      CITY: buildingPlace,
      MUNICIPALITY: town,
      ANSLAAG: assessmentDate,
      MUNICIPALITY_EMAIL: email || '',
      ADDRESS: `${buildingStreet} ${buildingHouseNumber}${
        buildingAddition || ''
      }`,
      POSTCODE: `${buildingPostCode} ${buildingPlace}`,
      BG: bg,
    }
    data.push(dynamicData)
  })

  return generateDocx('CopyLetter', data, pdfTextParser)
}

export async function generateIntroLetter(clientInfo: ClientRequestInfo) {
  const {
    firstName,
    lastName,
    salutation,
    buildingPlace,
    personalPlace,
  } = clientInfo

  const base64Signature = await toDataURL(DIRECTOR_SIGNATURE_URL)

  const dynamicData = {
    NAME: `${firstName} ${lastName}`,
    LASTNAME: `${lastName}`,
    SEX: `${salutation}`,
    CITY: `${buildingPlace},`,
    PLACE: `${personalPlace}`,
    PERSONAL_ADDRESS: `${clientInfo.personalStreet} ${
      clientInfo.personalHouseNumber
    }${clientInfo.personalAddition || ''}`,
    POST_CODE: `${clientInfo.personalPostCode} ${clientInfo.personalPlace}`,
    YEAR: moment().format('YYYY'),
    DATE: moment().format('D MMMM YYYY').toLowerCase(),
    SIGNATURE_MANAGER: base64Signature,
  }

  return generateDocx('IntroLetterExport', dynamicData, pdfTextParser)
}

export async function getMeta(url: string) {
  return new Promise<[number, number]>((r) => {
    const img = new Image()
    img.src = url
    img.onload = function () {
      // @ts-ignore
      r([this.width, this.height])
    }
  })
}

export async function generateCO(
  clientInfo: ClientRequestInfo,
  additionalData: Record<string, any>,
  type: 'PDF' | 'WORD' = 'WORD'
) {
  const {
    lastName,
    signature,
    firstName,
    municipality,
    buildingPlace,
    assessmentNumber,
    photos,
  } = clientInfo

  const base64SignatureClient = signature?.file
    ? await toDataURL(signature?.file)
    : null
  const base64Signature = await toDataURL(DIRECTOR_SIGNATURE_URL)
  const bg = await toDataURL(DOCUMENT_BG)
  const imageUploads: any[] = []
  const imageSize: any[] = []

  if (photos && photos.length) {
    for (const photo of photos) {
      imageUploads.push(toDataURL(photo.file))
      imageSize.push(getMeta(photo.file))
    }
  }
  const _photos = await Promise.all(imageUploads)
  const _sizes = await Promise.all(imageSize)
  _photos.forEach((data, index) => {
    _sizes[index].push(...getExifData(data))
  })

  const dynamicData = {
    DELAY_DATE: `30 juni ${moment().format('YYYY')}`,
    NAME: `${firstName} ${lastName}`,
    CITY: `${buildingPlace},`,
    MUNICIPALITY_EMAIL: `(${
      municipality?.email || '                           '
    })`,
    HAS_PHOTO: clientInfo?.photos ? 'Bijlage: Foto’s' : '',
    CASE_NUMBER: clientInfo?.caseNumber
      ? `Deze zaak is bij u geregistreerd onder dossiernummer ${clientInfo?.caseNumber}.`
      : '',
    MUNICIPALITY: `${municipality?.name || ''}`,
    POSTBUS: `${municipality ? `Postbus ${municipality?.post_bus}` : 'Adres:'}`,
    ADDRESS: municipality?.city || '',
    AANSLAGNR: assessmentNumber,
    BUILDING_ADDRESS: `${clientInfo.buildingStreet} ${
      clientInfo.buildingHouseNumber
    }${clientInfo.buildingAddition || ''} te ${clientInfo.buildingPlace}`,
    POSTCODE: `${municipality?.postal_code || ''}`,
    YEAR: moment().format('YYYY'),
    PREV_YEAR: moment().subtract(1, 'year').format('YYYY'),
    DATE: moment().format('D MMMM YYYY').toLowerCase(),
    HAS_MACHTIGING: clientInfo.authorization ? 'Bijlage: machtiging' : '',
    SIGNATURE_MANAGER: base64Signature,
    SIGNATURE: base64SignatureClient,
    BG: bg,
    TEXT_BLOCKS: additionalData?.selectedBlocks,
    PHOTOS: _photos,
    IMAGE_SIZE: _sizes,
    ...EMAIL_VARIABLES(clientInfo),
  }

  if (type === 'PDF') {
    const doc = pdfGenerator('COExport', dynamicData)

    const blob = doc.output('blob')
    const file = new File([blob], `CO_export.pdf`, {
      type: 'application/pdf',
    })

    return {
      file,
      blob: doc.output('blob'),
      download: () => doc.save('CO_export.pdf'),
    }
  } else {
    return generateDocx('COExport', dynamicData, pdfTextParser)
  }
}

export async function generateQuestionsListPDF(
  data: ClientRequestInfo,
  address: string,
  questions?: Record<string, string | any>,
  type: 'PDF' | 'DOCX' = 'PDF'
) {
  const y = 0

  const questionsList =
    data.property === 'bedrijfspand' ? RESIDENTAL : NON_RESIDENTAL

  const list = questions || questionsList

  const body = Object.keys(list).map((i, index) => {
    let val = ''
    if (isArray(list[i])) {
      list[i].forEach((q: any, index: number) => {
        let nextLine = '\n\n'

        if (list[i].length === index + 1) {
          nextLine = ''
        }

        val = val + `${q.question}\n${q.answer}${nextLine}`
      })
    }
    return [
      {
        content: index + 1,
      },
      {
        content: i,
      },
      {
        // @ts-ignore
        content: val,
      },
    ]
  })

  const dynamicData = {
    ADDRESS: address,
    TEXT:
      data.property === 'bedrijfspand'
        ? 'Uw antwoorden op onderstaande vragen helpen ons om een WOZ-bezwaarschrift op te stellen waarin alle relevante punten benoemd en gemotiveerd worden.'
        : 'Voor het opstellen van het WOZ-bezwaarschrift doen wij onderzoek naar de referentiewoningen die op het taxatieverslag vermeld staan. Verder onderzoeken we of er verkoopprijzen van andere woningen beschikbaar zijn, die het WOZ-bezwaarschrift kunnen onderbouwen. Uw antwoorden op onderstaande vragen helpen ons om tevens rekening te houden met de factoren die specifiek bij uw woning een rol spelen, waardoor we het WOZ-bezwaarschrift compleet kunnen maken.',
    TYPE: data.property === 'bedrijfspand' ? 'bedrijfspand' : 'woning',
  }

  if (type === 'PDF') {
    const table = {
      theme: 'grid',
      headStyles: { fillColor: '#0D3EAA' },
      head: [['', 'Vraag', 'Antwoord']],
      body: [...body],
      columnStyles: {
        0: {
          columnWidth: 8,
        },
        1: {
          columnWidth: 100,
        },
        2: {
          columnWidth: 'auto',
        },
      },
    }

    const doc = pdfGenerator('questionsList', { table, ...dynamicData })
    const blob = doc.output('blob')
    const file = new File([blob], `Vragenlijst.pdf`, {
      type: 'application/pdf',
    })
    return {
      file,
      blob: doc.output('blob'),
      base64: () => arrayBufferToBase64(doc.output('arraybuffer')),
      download: () => doc.save('Vragenlijst.pdf'),
    }
  } else {
    const base64Signature = await toDataURL(APP_LOGO)
    return generateDocx(
      'questionsList',
      { table: body, ...dynamicData, LOGO: base64Signature },
      pdfTextParser
    )
  }
}
