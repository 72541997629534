// @ts-ignore
import piexif from 'piexifjs'

export function removeElementInArray(array: any[], elem: any): any[] {
  return array.filter((i) => i !== elem)
}

export const isLocalStorageEnabled = () => {
  try {
    const mod = '__storage_test__'
    window.localStorage.setItem(mod, mod)
    window.localStorage.removeItem(mod)
    return true
  } catch (e) {
    return false
  }
}

export function generateString(length = 5) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

// Type checking

export function isNumber(value: unknown): value is number {
  return typeof value === 'number'
}

export function isArray(value: unknown): value is Array<unknown> {
  return Array.isArray(value)
}

export function isNull(value: unknown): value is null {
  return value === null
}

export function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && !isArray(value) && !isNull(value)
}
export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean'
}

export function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj
}

export function isFunction(value: unknown): value is Function {
  return typeof value === 'function'
}

export function throttle(fn: (...args: never[]) => void, delay: number) {
  let timer = 0

  return (...args: unknown[]) => {
    const now = new Date().getTime()

    if (now - timer < delay) {
      return
    }

    timer = now
    return fn.apply(null, ...args)
  }
}

export function debounce(func: (...args: never[]) => void, delay: number) {
  let timer: number

  return (...args: never[]) => {
    window.clearTimeout(timer)
    timer = window.setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      func.apply(null, args)
    }, delay)
  }
}

export function isEmail(email: string): boolean {
  const REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return Boolean(email.match(REGEX))
}

export function dataURItoBlob(dataurl: string, filename: string) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export function isPasswordValid(value: string): boolean {
  const regExp = new RegExp(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    'g'
  )

  return Boolean(value.match(regExp))
}

export async function createDownloader(href: any) {
  const link = document.createElement('a')
  link.href = href
  link.download = href
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function capitalize(value: string) {
  const firstLetter = value.split('')[0]
  return firstLetter.toUpperCase() + value.substring(1)
}

export function hasTableFilters(filters: Record<string, any>) {
  const isEmpty = !Object.keys(filters).length
  if (isEmpty) {
    return false
  } else {
    let hasData = false

    Object.keys(filters).forEach((key) => {
      // @ts-ignore
      if (!hasData && (filters[key].filters || filters[key].searchText)) {
        hasData = true
      }
    })

    return hasData
  }
}

export function hasColumnFilter(filters: any, column: string) {
  const isEmpty = !Object.keys(filters).length

  if (isEmpty) {
    return false
  } else {
    let hasData = false

    if (!hasData && (filters[column]?.filters || filters[column]?.searchText)) {
      hasData = true
    }

    return hasData
  }
}

export function splitNameAndExtension(name: string = "") {
  const nameArray = name.split('.')

  return [nameArray.splice(0, nameArray.length - 1).join('.'), nameArray[0]];
}

const exifOrientationMap: {
  [exifKey: number]: [
    /** rotation degrees */
    number,
    /** flipped */
    boolean?,
  ],
} = {
  1: [0, true],
  // 2: 0,
  3: [180, true],
  4: [180],
  5: [90, true],
  6: [90],
  7: [270, true],
  8: [270],
}

export function getExifData(jpeg: string = ""): [number?, boolean?] {
  if (jpeg.slice(0, 23) == "data:image/jpeg;base64,") {
    const { '0th': exifData } = piexif.load(jpeg) || {};
    /**
     * `274` is the orientation key
     * @see https://www.vcode.no/web/resource.nsf/II2LNUG/642.htm
     * @see https://sirv.com/help/articles/rotate-photos-to-be-upright/
     */
    const orientation = exifData?.['274']
    if (orientation) {
      return exifOrientationMap[orientation]
    }
  }
  return []
}
