import { ROLES } from 'src/constants'
import { useSelector } from 'react-redux'
import { profileSelector } from 'src/selectors'

export type useAuthI = {
  isAdmin: boolean
}

export function useAuth(): useAuthI {
  const profile = useSelector(profileSelector).payload

  return {
    isAdmin: ROLES.ADMIN === profile?.role,
  }
}
