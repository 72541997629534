import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import { ClientRequestInfo } from 'src/interfaces/app'
import { generateString, splitNameAndExtension } from 'src/utils/misc'

const isProd = false

const API_URL_PROD = 'https://us-central1-portal-41ffa.cloudfunctions.net/'
const API_URL_DEV =
  'https://us-central1-portal-staging-89613.cloudfunctions.net/'

const NODE_API_URL = 'https://wozbezwaar.biz/api/'
// const NODE_API_URL = 'http://localhost:3000/api/'
const API_URL = isProd ? API_URL_PROD : API_URL_DEV
//const API_URL = 'http://localhost:5001/portal-41ffa/us-central1/'
//const API_URL = 'http://localhost:5001/portal-staging-89613/us-central1/'

export async function getAddress(postCode: string, houseNumber: number) {
  const uri = `getAddressByPostCode`
  const data = {
    postCode,
    houseNumber,
  }

  const response = await fetch(`${API_URL}${uri}`, {
    method: 'POST',
    body: JSON.stringify(data),
  })

  return await response.json()
}

export const checkAuth = (onSignOut: () => void, onSignIn?: () => void) => {
  firebase.auth().onAuthStateChanged(
    (user) => {
      if (!user) {
        onSignOut()
      } else if (user && onSignIn) {
        onSignIn()
      }
    },
    (e) => {
      console.log('LOG ::::::> e <::::::', e)
    }
  )
}

export const signIn = async (email: string, password: string) => {
  return await firebase.auth().signInWithEmailAndPassword(email, password)
}

export const signUp = async (email: string, password: string) => {
  const authUserNotExist = !(await isAuthUserExist(email))
  const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password)
  const userNotExist = !(await isUserExist(email))
  if (userNotExist) {
    const client = await getClients(userCredential.user.email, 1)
    const clientInfo = client.docs[0].data()

    await createUser({
      email: clientInfo.email,
      first_name: clientInfo.firstName,
      last_name: clientInfo.lastName,
      salutation: clientInfo.salutation,
      role: 'customer',
      version: 2,
      userUid: userCredential.user.uid
    })
  } else if (authUserNotExist) {
    const user = await getProfile()

    await firebase.firestore().collection('users').doc(user.docs[0].id).update({
      version: 2,
      userUid: userCredential.user.uid
    })
  }
  return userCredential
}

export const resetPassword = async (email: string) => {
  const uri = 'resetPassword'

  const body = {
    email,
  }

  const response = await fetch(`${API_URL}${uri}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  return await response.json()
}

export const changePassword = async (email: string, password: string) => {
  const uri = 'changePassword'

  const body = {
    email,
    password,
  }

  const response = await fetch(`${API_URL}${uri}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  return await response.json()
}

export const signOut = async () => {
  return await firebase.auth().signOut()
}

export const getProfile = async (limit?: number) => {
  const user = getUser()
  const request = firebase
    .firestore()
    .collection('users')
    .where('email', '==', user.email);
  if (typeof limit === 'number') {
    request.limit(limit);
  }

  return await request.get()
}

export const isUserExist = async (email = '') => {
  if (!email) {
    return false;
  }
  const response = await firebase
    .firestore()
    .collection('users')
    .where('email', '==', email)
    .get()

  return !!response.docs.length
}

export const isAuthUserExist = async (email = '') => {
  const uri = 'isAuthUserExist'
  return (await fetch(`${API_URL}${uri}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  })).json()
}

export const createUser = async (data: any) => {
  return await firebase.firestore().collection('users').add(data)
}
export const createAuthUser = async (email: any) => {
  const uri = 'changePassword'

  const body = {
    email,
  }

  const response = await fetch(`${API_URL}${uri}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  return await response.json()
}

export const updateUserEmail = async (email: string, oldEmail: string) => {
  const uri = 'updateUser'

  const body = {
    oldEmail,
    email,
  }

  const response = await fetch(`${API_URL}${uri}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  return await response.json()
}

export const verifyEmail = async (token: string) => {
  const uri = 'verifyEmail'

  const body = {
    token,
  }

  const response = await fetch(`${API_URL}${uri}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  return await response.json()
}

export const updateUserPassword = async (password: string) => {
  const uri = 'updateUser'
  const user = getUser()

  const body = {
    uid: user.uid,
    password,
  }

  const response = await fetch(`${API_URL}${uri}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  return await response.json()
}

export const getUser = () => {
  return firebase.auth().currentUser
}

// templates
export const getTemplates = async () => {
  return await firebase.firestore().collection('templates').get()
}
export const updateTemplate = async (id: string, data: any) => {
  return await firebase.firestore().collection('templates').doc(id).update(data)
}
// text blocks
export const getTextBlocks = async () => {
  return await firebase.firestore().collection('textBlocks').get()
}
export const deleteTextBlock = async (id: string) => {
  return await firebase.firestore().collection('textBlocks').doc(id).delete()
}

export const updateTextBlock = async (id: string, data: any) => {
  return await firebase
    .firestore()
    .collection('textBlocks')
    .doc(id)
    .update(data)
}

export const createTextBlock = async (data: any) => {
  return await firebase.firestore().collection('textBlocks').add(data)
}

// Municipalities
export const getMunicipalities = async () => {
  return await firebase.firestore().collection('municipalities').get()
}

export const getMunicipality = async (id: string) => {
  return await firebase
    .firestore()
    .collection('municipalities')
    .doc(id)
    .get()
}

export const updateMunicipality = async (id: string, data: any) => {
  return await firebase
    .firestore()
    .collection('municipalities')
    .doc(id)
    .update(data)
}

export const createMunicipality = async (data: any) => {
  return await firebase.firestore().collection('municipalities').add(data)
}

export const deleteMunicipality = async (id: string) => {
  return await firebase
    .firestore()
    .collection('municipalities')
    .doc(id)
    .delete()
}
// Stop Case
export const getDocumentStopCaseList = async () => {
  return await firebase.firestore().collection('documentStopCaseList').get()
}

export const updateDocumentStopCase = async (id: string, data: any) => {
  return await firebase
    .firestore()
    .collection('documentStopCaseList')
    .doc(id)
    .update(data)
}

export const createDocumentStopCase = async (data: any) => {
  return await firebase.firestore().collection('documentStopCaseList').add(data)
}

export const deleteDocumentStopCase = async (id: string) => {
  return await firebase
    .firestore()
    .collection('documentStopCaseList')
    .doc(id)
    .delete()
}

// Clients TODO : need move to server
export const getClients = async (userId?: string, limit?: number) => {
  if (userId) {
    return await firebase
      .firestore()
      .collection('clients')
      .where('email', '==', userId)
      .get()
  } else {
    if (limit) {
      return await firebase.firestore().collection('clients').limit(limit).get()
    }
    return await firebase.firestore().collection('clients').get()
  }
}

export const getClientInfoById = async (
  id: string
): Promise<ClientRequestInfo> => {
  const response = await firebase
    .firestore()
    .collection('clients')
    .doc(id)
    .get()

  const data = response.data()
  const profile = await getProfile()
  const profileData = profile.docs[0].data()
  if (data.email !== profileData.email && profileData.role !== "admin") {
    return null
  }
  if (data?.municipality?.id) {
    const municipalityResponse = await getMunicipality(data.municipality.id)
    data.municipality = {
      id: data.municipality.id,
      ...municipalityResponse.data()
    }
  }

  return data as ClientRequestInfo
}

export const createClient = async (data: any) => {
  return await firebase.firestore().collection('clients').add(data)
}

export const editClient = async (id: string, data: any) => {
  return await firebase
    .firestore()
    .collection('clients')
    .doc(id)
    .set(data, { merge: true })
}

// Upload files
export const uploadFiles = async (
  file: File,
  name: string,
  status?: 'pending' | 'approved' | 'rejected',
  clientInfoId?: string,
  data: any = {}
) => {
  const hash = generateString(16)
  const [_name, _ext] = splitNameAndExtension(file.name);
  const fileName = _name + '_' + hash + '.' + _ext

  return firebase
    .storage()
    .ref()
    .child(`images/${fileName}`)
    .put(file)
    .then((s) => {
      return firebase
        .storage()
        .ref()
        .child(`images/${fileName}`)
        .getDownloadURL()
        .then((url) => {
          if (clientInfoId) {
            editClient(clientInfoId, {
              [name]: {
                file: url,
                name: fileName,
                label: file.name,
                status,
              },
              ...data,
            })
            return {
              status,
              file: url,
              name: fileName,
              label: file.name,
            }
          } else {
            return {
              name,
              file: {
                status,
                file: url,
                name: fileName,
                label: file.name,
              },
            }
          }
        })
    })
}

export async function getFileUrl(url: string) {
  const uri = 'convertImageToBase64'

  const data = {
    url,
  }

  const response = await fetch(`${NODE_API_URL}${uri}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.text()
}

export async function getDocumentUrl(url: string) {
  const uri = 'convertDocumentToBase64'

  const data = {
    url,
  }

  const response = await fetch(`${API_URL}${uri}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.text()
}

export const deleteFile = async (fileName: string) => {
  return firebase.storage().ref().child(`images/${fileName}`).delete()
}

// send email

export type EmailData = {
  to: string
  subject: string
  body: string
  bcc?: string
  attachments?: any
}

export async function sendEmail(data: EmailData) {
  const uri = 'sendMail'

  const response = await fetch(`${API_URL}${uri}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json()
}

// tokens
export const getToken = async (token: string) => {
  return await firebase
    .firestore()
    .collection('tokens')
    .where('token', '==', token)
    .get()
}

export const createToken = async (data: any) => {
  return await firebase.firestore().collection('tokens').add(data)
}

export const expireToken = async (token: string) => {
  return await firebase.firestore().collection('tokens').doc(token).update({ expired: true })
}

export const generateFO = async ({
  clientInfo,
  additionalData,
  type = 'PDF',
  withoutSignature = false,
  signatureUrl,
}: {
  clientInfo?: ClientRequestInfo;
  additionalData?: Record<string, any>;
  type: 'PDF' | 'WORD' | 'WORD_EXCLUDE';
  withoutSignature?: boolean;
  signatureUrl?: string;
}) => {
  const data = {
    clientInfo,
    additionalData,
    type,
    withoutSignature,
    signatureUrl,
  }

  const result = await fetch(`${NODE_API_URL}generate-fo`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  const blob = await result.blob()

  return {
    download: () => saveAs(
      blob,
      `${signatureUrl ? 'Machtiging' : 'FO_export'}.${type === 'PDF' ? 'pdf' : 'docx'}`
    )
  }
}
