// TASK: WOZ-571
import { font } from './font';
import { flex } from './flex';
import { grid } from './grid';
import { margin } from './margin';
import { padding } from './padding';
import { position } from './position';
import { size } from './size';
import { border } from './border'
import { background } from './background'
import { MOBILE_MAX_SIZE } from 'src/constants';
import { getMeasure } from '../utils';

const borderRadius = ({ radius, measure }: any) => `
  ${radius ? `border-radius: ${getMeasure(radius, measure)}` : ''};
`

export const mobile = (styles: any) => `${styles.mobile ? `
@media (max-width: ${MOBILE_MAX_SIZE}px) {
  ${font(styles.mobile)}
  ${flex(styles.mobile)}
  ${grid(styles.mobile)}
  ${margin(styles.mobile)}
  ${padding(styles.mobile)}
  ${position(styles.mobile)}
  ${size(styles.mobile)}
  ${border(styles.mobile)}
  ${borderRadius(styles.mobile)}
  ${background(styles.mobile)}
}
` : ''
}`
