// TASK: WOZ-574
import React, { memo, useContext } from 'react'
import { Container, Text } from 'src/styles/styled-components'
import { Modal } from 'src/components'
import Item from 'src/helpers/Item'
import { ThemeContext } from 'styled-components'

interface CustomAlertModalProps {
  data?: any
  text: string
  title?: string
  isModalShow: boolean
  onRemove: (data: any) => void
  onCancel: (isOpen: boolean) => void
}

function CustomAlertModal(props: CustomAlertModalProps) {
  const { text, data, onRemove, isModalShow, onCancel, title } = props
  const theme = useContext(ThemeContext)

  function hideModal() {
    onCancel(false)
  }

  function handleRemove() {
    onRemove(data)
  }

  return (
    <Modal
      modalSize="xs"
      isShow={isModalShow}
      onRequestClose={hideModal}
      background="rgb(255 255 255 / 80%)"
      boxShadow="0px 0px 9px 0px #ccc"
      title={title}
      titleProps={{
        size: 20,
        color: theme.colors.gray5,
        weight: "bold",
        textAlign: "center",
        hideClose: true,
      }}
    >
      <Container
        column
        flex
        crossAxis="center"
        mainAxis="space-between"
        padding={{ horizontal: 16 }}
      >
        <Text
          size={16}
          align="center"
          color={theme.colors.gray5}
          padding={{ horizontal: 8, top: 22 }}
          textAlign="center"
        >
          {text}
        </Text>
        <Container
          flex
          noGrow
          mainAxis="space-around"
          margin={{ bottom: 24 }}
          width="-webkit-fill-available"
        >
          {/* TASK: WOZ-673 */}
          <Container padding={{ right: 20 }}>
            <Item onClick={hideModal}>
              <Text
                size={15}
                weight="bold"
                color={theme.colors.gray5}
              >
                Annuleren
              </Text>
            </Item>
          </Container>
          <Container>
            <Item onClick={handleRemove}>
              <Text
                size={15}
                weight="bold"
                color={theme.colors.red1}
              >
                Verwijderen
              </Text>
            </Item>
          </Container>
        </Container>
      </Container>
    </Modal>
  )
}

CustomAlertModal.defaultProps = {}

export default memo(CustomAlertModal)
