import React, { memo } from 'react'

import { Text } from 'src/styles/styled-components'
import FooterStyled from 'src/layouts/components/styled/FooterStyled'
import moment from 'moment'

function Footer() {
  return (
    <FooterStyled>
      <Text color="#5C5D5D" size={11}>
        Alle rechten voorbehouden. WOZBEZWAAR.BIZ {moment().year()}
      </Text>
      <Text
        as="a"
        // TASK: WOZ-633
        hoverDecoration="underline"
        size={11}
        color="#5C5D5D"
        target="_blank"
        href="https://www.brage.nl/ "
      >
        Portal powered by Brage
      </Text>
    </FooterStyled>
  )
}

Footer.defaultProps = {}

export default memo(Footer)
