import styled from 'styled-components'
import Container from './Container'

const TextAreaContainer = styled(Container)`
  width: 100%;
  min-height: 150px;
  border-radius: 5px;
  padding: 8px 12px 0 12px;
  background: rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.1);
`

TextAreaContainer.displayName = 'TextAreaContainer'

export default TextAreaContainer
