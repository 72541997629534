import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'

interface CheckboxStyled {
  disabled: boolean
  checked?: boolean
  isCircle?: boolean
  color: string
}

const dynamicStyles = ({
  disabled,
  checked,
  color,
  isCircle,
}: CheckboxStyled) => `
  ${isCircle ? 'border-radius: 50%;' : ''}
  ${
    disabled
      ? `
      cursor: text;
    ${
      checked
        ? `
      background-color: ${defaultTheme.colors.gray2};
      border: 1px solid ${defaultTheme.colors.opacityGrey};
    `
        : `
      background-color: ${defaultTheme.colors.gray2};
      border: 1px solid ${defaultTheme.colors.opacityGrey};
    `
    }
  `
      : `
    ${
      checked
        ? `
      background-color: ${color};
      border: 1px solid ${color};
    `
        : ''
    }
    
  `
  }
`
const CheckboxStyled = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${dynamicStyles}
`

CheckboxStyled.displayName = 'CheckboxStyled'

export default CheckboxStyled
