import React, { memo, useContext } from 'react'
import Item from 'src/helpers/Item'
import { Container, Text } from 'src/styles/styled-components'
import { Button } from 'src/components'
import { ThemeContext } from 'styled-components'

interface EditButtonProps {
  data?: any
  isEdit: boolean
  onSubmit: (data: any) => void
  onCancel: (data: any) => void
  toggleEditButton: (data: any) => void
}

function EditButton(props: EditButtonProps) {
  const theme = useContext(ThemeContext)
  const { isEdit, toggleEditButton, data, onCancel, onSubmit } = props

  return (
    <Container flex crossAxis="center" mainAxis="flex-end">
      {isEdit ? (
        <>
          <Item hoverDecoration={theme.decorations.danger} margin={{ right: 16 }} data={data} onClick={onCancel}>
            <Text color={theme.colors.red1}>Annuleren</Text>
          </Item>
          <Button data={data} label="Opslaan" onClick={onSubmit} />
        </>
      ) : (
        <Item data={data} onClick={toggleEditButton}>
          <Text hoverDecoration={theme.decorations.main} color={theme.colors.main}>Wijzigen</Text>
        </Item>
      )}
    </Container>
  )
}

EditButton.defaultProps = {}

export default memo(EditButton)
