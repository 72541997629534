export const NON_RESIDENTAL = {
  'Wat is de staat van onderhoud van uw woning? Indien er sprake is van gebreken en/of achterstallig onderhoud, kunt u dan aangeven waaruit dit bestaat?':
    '',
  'Bevindt de woning zich in een gedateerde staat (bijvoorbeeld: een oude keuken, oude badkamer, oude CV-installatie, enkel glas, oud dak etc.)? Zo ja, kunt u dit toelichten?':
    '',
  'Is bij uw woning sprake van wateroverlast en/of vochtschade? Zo ja, waar en wat is de oorzaak hiervan (indien bekend)?':
    '',
  'Is er sprake van asbest? Zo ja, waar bevindt dit zich?': '',
  'Ondervindt u geluidsoverlast in uw woning? Zo ja, waardoor wordt dit veroorzaakt?':
    '',
  'Wordt het uitzicht vanuit uw woning belemmerd door de nabijheid van een ander (hoger) gebouw? Zo ja, welk gebouw en waar bevindt zich dit gebouw?':
    '',
  'Bevindt zich in de omgeving van uw woning een hoogspanningsmast? Zo ja, op hoeveel meter afstand bevindt deze zich ongeveer?':
    '',
  'Is er sprake van dat er in de toekomst bouwwerkzaamheden uitgevoerd zullen worden, of worden er bouwwerkzaamheden uitgevoerd in de directe omgeving van uw woning, die de waarde van uw woning mogelijk nadelig beïnvloeden?':
    '',
  'Heeft u hinder (bijvoorbeeld stank- en/of geluidsoverlast) van fabrieken of andere bedrijven in uw omgeving?':
    '',
  'Woont u in de omgeving van een horeca- gelegenheid, studentenhuis of coffeeshop, die (geluids-)overlast veroorzaakt?':
    '',
  "Ondervindt u overlast van geparkeerde auto's? Kunt u dit toelichten?": '',
  'Zijn (of worden) er windmolens bij u in de omgeving geplaatst?': '',
  'Heeft u de woning recent (3 jaar of korter geleden) gekocht? Zo ja, wanneer en voor welke prijs?':
    '',
  'Indien u de woning recent (3 jaar of korter geleden) gekocht heeft, kunt u aangeven of de woning na aankoop verbouwd/verbeterd is? Zo ja, hoeveel heeft dit ongeveer gekost en wat is er verbouwd/verbeterd?':
    '',
  'Zijn er verder nog aanvullende punten, waarvan u denkt dat ze van belang kunnen zijn voor het bezwaar tegen de WOZ-waarde, dan kunt u dat hiernaast vermelden.':
    '',
}

export const RESIDENTAL = {
  'Wat is de staat van onderhoud van het bedrijfspand? Indien er sprake is van gebreken en/of achterstallig onderhoud, kunt u aangeven waaruit dit bestaat?':
    '',
  'Bevindt het bedrijfspand zich in gedateerde staat (bijvoorbeeld: een verouderd interieur, een oud dak, een oude CV-installatie, enkel glas etc.)?':
    '',
  'Is bij het bedrijfspand sprake van wateroverlast en/of vochtschade? Zo ja, waar en wat is de oorzaak hiervan (indien bekend)?':
    '',
  'Is er sprake van asbest? Zo ja, waar bevindt dit zich en kunt u een inschatting maken van de totale oppervlakte hiervan?':
    '',
  'Is sprake van geluids- of stankoverlast bij het bedrijfspand? Zo ja, graag toelichten.':
    '',
  'Is sprake van parkeeroverlast of is het moeilijk om binnen een redelijke afstand van het pand te parkeren? Zo ja, graag toelichten.':
    '',
  '7 Zijn er verder nog zaken die volgens u de waarde van het bedrijfspand negatief beïnvloeden (bijvoorbeeld slechte bereikbaarheid of slechte zichtbaarheid van het pand?)':
    '',
  'Wordt het bedrijfspand verhuurd? Zo ja, hoeveel bedraagt de maandelijkse huur, ex. BTW?':
    '',
  'Indien het bedrijfspand recent (3 jaar of korter geleden) verkocht is, kunt u aangeven wat de verkoopprijs was?':
    '',
}
