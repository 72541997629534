import { Theme } from 'src/interfaces/styles'

interface Props {
  theme: Theme
}

type MediaArgument = (data: string | TemplateStringsArray) => string

export const lessThan = (breakpoint: string): MediaArgument => {
  return (args: string | TemplateStringsArray) => {
    return `
      @media (max-width: ${breakpoint}) {
        ${args}
      }
    `
  }
}

export const moreThan = (breakpoint: string): MediaArgument => {
  return (args: string | TemplateStringsArray) => {
    return `
      @media (min-width: ${breakpoint}) {
        ${args}
      }
    `
  }
}

export const getColor = (colorName: string | number) => ({ theme }: Props) =>
  `${theme.colors[colorName]}`

export const getMeasure = (
  value: number | string,
  measure?: string
): string => {
  if (typeof value === 'number') {
    return `${value}${measure || 'px'}`
  }

  return value
}

export const getFlexValue = (
  value: number | boolean | string
): string | number => {
  if (typeof value === 'boolean') {
    return '1'
  }

  return value
}
