import React, {
  forwardRef,
  ReactNode,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { ThemeContext } from 'styled-components'

// components
import { Icon } from 'src/components'
import { Text, Container } from 'src/styles/styled-components'

// helpers
import Item from 'src/helpers/Item'
import Portal from 'src/helpers/Portal'
import { generateString, isObject } from 'src/utils/misc'

// styled
import SelectStyled from './styled/SelectStyled'
import OptionsContainer from './styled/OptionsContainer'
import OptionStyled from './styled/OptionStyled'

type ChildrenProps = {
  showOptions: () => void
}

type Option = {
  value: any
  label: string
  index?: number
  disabled?: boolean
  onClick?: (value: any) => void
}

interface SelectProps {
  value?: any
  name?: string
  label?: string
  disabled?: boolean
  fullSize?: boolean
  clearable?: boolean
  errorText?: string
  valueKey?: string
  options?: Option[]
  placeholder?: string
  children?: (props: ChildrenProps) => ReactNode
  // methods
  onShow?: (isOpen: boolean) => void
  onSearch?: (text: string) => void
  renderCustomOption?: (onSelect: (option: Option) => void) => ReactNode
  onChange?: (value: any, item: Option, index?: number) => void
  onSelect?: (value: any, name: string) => void
}

const Select = forwardRef((props: SelectProps, ref) => {
  const theme = useContext(ThemeContext)
  const select = useRef(null)

  const {
    value,
    name,
    label,
    clearable,
    fullSize,
    options = [],
    errorText,
    onShow,
    disabled,
    valueKey = 'name',
    renderCustomOption,
    placeholder = 'select value',
  } = props

  const elementId = useState(generateString())
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState(getValue(value))
  const icon = isOpen ? 'CaretUpFill' : 'CaretDownFill'
  const color = isOpen ? '#2a9bf9' : '#c5d0de'

  useImperativeHandle(ref, () => ({
    close() {
      setIsOpen(false)
    },
  }), [])

  // styles
  const textStyles = {
    size: 14,
    color: theme.colors.gray5,
    fontFamily: theme.typography.lato,
  }

  function getValue(value: any) {
    if (!isObject(value) && props.options) {
      return props.options.find((i) => i.value === value)?.label
    } else if (isObject(value)) {
      return props.options.find((i) => i.value[valueKey] === value[valueKey])
        ?.label
    }
    return value
  }

  useEffect(() => {
    if (getValue(value) !== selected) {
      setSelected(getValue(value))
    }
  }, [value])

  useEffect(() => {
    if (value && options?.length) {
      const option = options.find((i) => i.value === value)
      setSelected(option?.label)
    }
  }, [])

  function showOptions() {
    if (!disabled) {
      setIsOpen(!isOpen)
      !!onShow && onShow(!isOpen)
    }
  }

  function _onSelect(option: Option) {
    if (!option) {
      props?.onSelect && props.onSelect({}, name)
      setSelected(undefined)
      setIsOpen(false)
    } else if ((props.onSelect || props.onChange) && !option.disabled) {
      props?.onChange && props.onChange(option.value, option, option?.index)
      props?.onSelect && props.onSelect(option.value, name)
      setSelected(option.label)
      setIsOpen(false)
      !!onShow && onShow(false)
    } else if (option.onClick) {
      option.onClick(option.value)
      setIsOpen(false)
      !!onShow && onShow(false)
    }
  }

  const renderOption = (option: Option, index: number) => {
    const data = { index, ...option }
    const isSelected = option.value === value
    const color = option.disabled
      ? theme.colors.gray2
      : isSelected
      ? theme.colors.coral3
      : theme.colors.gray5

    return (
      <Item key={`option_${index}`} data={data} onClick={_onSelect}>
        <OptionStyled>
          <Text flex size={14} color={color} font={theme.typography.lato}>
            {option.label}
          </Text>
        </OptionStyled>
      </Item>
    )
  }

  const renderOptions = () => {
    return (
      options?.length && (
        <OptionsContainer
          overflowY="auto"
          maxHeight={fullSize ? options.length * 37 : 150}
        >
          {clearable && (
            <Item data={null} onClick={_onSelect}>
              <OptionStyled>
                <Text
                  flex
                  size={14}
                  color={theme.colors.gray5}
                  font={theme.typography.lato}
                >
                  Leeg
                </Text>
              </OptionStyled>
            </Item>
          )}
          {options.map(renderOption)}
        </OptionsContainer>
      )
    )
  }

  return (
    <Container flex column>
      {label ? (
        <Container margin={{ bottom: 5 }}>
          <Text
            size={14}
            weight="bold"
            lineHeight={21}
            color={theme.colors.label1}
            font={theme.typography.openSans}
          >
            {label}
          </Text>
        </Container>
      ) : null}
      {props.children ? (
        <Container ref={select} flex noGrow mainAxis="flex-end">
          {props.children({
            showOptions,
          })}
        </Container>
      ) : (
        <SelectStyled
          disabled={disabled}
          ref={select}
          isOpen={isOpen}
          onClick={showOptions}
        >
          <Container flex margin={{ left: 4 }}>
            {selected ? (
              <Text truncate {...textStyles}>
                {selected}
              </Text>
            ) : (
              <Text truncate {...textStyles}>
                {placeholder}
              </Text>
            )}
          </Container>
          <Icon
            iconName={icon}
            color={!disabled ? color : '#5a5a5a'}
            size={14}
          />
        </SelectStyled>
      )}
      <Portal
        zIndex={1001}
        parent={select}
        isShow={isOpen}
        onRequestClose={(isOpen: boolean) => {
          setIsOpen(isOpen)
          !!onShow && onShow(isOpen)
        }}
        containerId={`select_options_${elementId[0]}`}
      >
        {renderCustomOption ? renderCustomOption(_onSelect) : renderOptions()}
      </Portal>
      {errorText ? (
        <Container margin={{ top: 5 }}>
          <Text size={14} lineHeight={21} color={theme.colors.red1}>
            {errorText}
          </Text>
        </Container>
      ) : null}
    </Container>
  )
})

export default Select
