import { FormData } from 'src/hooks/useForm'
import { ClientRequestInfo } from 'src/interfaces/app'

export const CLIENT_INFO_ADDITIONAL_FORM = (data: ClientRequestInfo) => ({
  objectNumber: {
    value: data?.objectNumber,
    exclude: true,
    name: 'objectNumber',
  },
  caseNumber: {
    value: data?.caseNumber,
    exclude: true,
    name: 'caseNumber',
  },
  municipality: {
    value: data?.municipality,
    exclude: true,
    name: 'municipality',
  },
  foReceived: {
    value: data?.foReceived,
    exclude: true,
    name: 'foReceived',
  },
  paymentReceived: {
    value: data?.paymentReceived,
    exclude: true,
    name: 'paymentReceived',
  },
  amount: {
    value: data?.amount,
    exclude: true,
    name: 'amount',
  },
  comment: {
    value: data?.comment,
    exclude: true,
    name: 'comment',
  },
})
