import React, {
  memo,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ThemeContext } from 'styled-components'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'

// styled components
import { Container, Text } from 'src/styles/styled-components'

// styled
import TextareaStyled from './styled/TextareaStyled'
import InputStyled from 'src/components/input/styled/InputStyled'

type InputEvent = React.ChangeEvent<HTMLTextAreaElement>

export interface TextAreaProps {
  name: string
  value: string
  label?: string
  isWysiwyg?: boolean
  width?: number | string
  height?: number | string
  errorText?: string
  disabled?: boolean
  placeholder?: string
  onBlur?: () => void
  onFocus?: () => void
  onChange?: (value: string, name: string, e?: SyntheticEvent) => void
}

function Textarea(props: TextAreaProps) {
  const theme = useContext(ThemeContext)

  const {
    name,
    width,
    height,
    label,
    value,
    isWysiwyg,
    disabled,
    onChange,
    errorText,
    ...otherProps
  } = props

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromHTML(value))
  )
  const [isFocus, setIsFocus] = useState(false)
  const [_value, _setValue] = useState(value)

  useEffect(() => {
    if (!isFocus) {
      _setValue(value)
    }
  }, [value, isFocus])

  const handleFocus = useCallback(() => {
    setIsFocus(true)
    if (otherProps.onFocus) {
      otherProps.onFocus()
    }
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocus(false)
    if (otherProps.onBlur) {
      otherProps.onBlur()
    }
  }, [])

  const handleChange = useCallback(
    (e: InputEvent) => {
      if (!disabled) {
        if (onChange) {
          onChange(e.target.value, name, e)
        }
        _setValue(e.target.value)
      }
    },
    [disabled]
  )

  const onEditorChange = useCallback(
    (editorState) => {
      if (!disabled) {
        if (onChange) {
          onChange(stateToHTML(editorState.getCurrentContent()), name, null)
        }
        setEditorState(editorState)
      }
    },
    [disabled]
  )

  return (
    <Container flex column minHeight={height}>
      {label ? (
        <Container margin={{ bottom: 5 }}>
          <Text
            size={14}
            weight="bold"
            lineHeight={21}
            color={theme.colors.label1}
            font={theme.typography.openSans}
          >
            {label}
          </Text>
        </Container>
      ) : null}
      {isWysiwyg ? (
        <Editor
          // @ts-ignore
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorChange}
          {...otherProps}
        />
      ) : (
        <TextareaStyled
          disabled={disabled}
          value={_value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          {...otherProps}
        />
      )}
      {errorText ? (
        <Container margin={{ top: 5 }}>
          <Text size={14} lineHeight={21} color={theme.colors.red1}>
            {errorText}
          </Text>
        </Container>
      ) : null}
    </Container>
  )
}

export default memo(Textarea)
