import React, { memo, ReactElement, SyntheticEvent } from 'react';

interface FormProps {
  onSubmit: () => void
  children: ReactElement | ReactElement[]
}

function Form(props: FormProps) {
  const { onSubmit, children } = props

  function handleSubmit(e: SyntheticEvent) {
    e.preventDefault()
    onSubmit && onSubmit()
  }

  return (
    <form onSubmit={handleSubmit}>
      {children}
    </form>
  )
}

Form.defaultProps = {}

export default memo(Form)
