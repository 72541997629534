import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './App'

Sentry.init({
    dsn: "https://385e97e3d15c43a997f98f86bf74c3eb@o593983.ingest.sentry.io/5741850",
    integrations: [new Integrations.BrowserTracing()],

    environment: process.env.NODE_ENV,
    enabled: !window.origin.includes("localhost"),
    tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById('app'))
