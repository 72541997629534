import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'

const defaultProps = {
  theme: defaultTheme,
}

const SpinnerStyled = styled.svg`
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;

  & .path {
    stroke: ${defaultTheme.colors.main};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

SpinnerStyled.displayName = 'SpinnerStyled'
SpinnerStyled.defaultProps = defaultProps

export default SpinnerStyled
