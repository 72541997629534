import { ContainerStyles } from 'src/interfaces/styles'
import { getMeasure } from 'src/styles/utils'

export const position = (styles: ContainerStyles) => `
  ${styles.zIndex ? `z-index: ${styles.zIndex};` : ''}
  ${styles.rotate ? `transform: rotate(${styles.rotate}deg);` : ''}
  ${styles.position ? `position: ${styles.position};` : ''}
  ${
    styles.top !== void 0
      ? `top: ${getMeasure(styles.top, styles.measure)};`
      : ''
  }
  ${
    styles.left !== void 0
      ? `left: ${getMeasure(styles.left, styles.measure)};`
      : ''
  }
  ${
    styles.right !== void 0
      ? `right: ${getMeasure(styles.right, styles.measure)};`
      : ''
  }
  ${
    styles.bottom !== void 0
      ? `bottom: ${getMeasure(styles.bottom, styles.measure)};`
      : ''
  }
`
