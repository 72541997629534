import React, { memo, useContext, useRef, useState } from 'react'
import { Container, Row, Col, Text } from 'src/styles/styled-components'
import { ThemeContext } from 'styled-components'
import { Button, DatePicker, Input, Select } from 'src/components'
import { useSelector } from 'react-redux'
import {
  filterFormSelector,
  municipalitiesSelector,
  visibleColumnsFormSelector,
  emptySearchFieldsSelector,
} from 'src/selectors'
import {
  columnsDefaultData,
  formValues,
  EMPTY_SELECT_VALUE,
} from 'src/pages/dashboard/components/constants'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/user'
import OptionsContainer from 'src/components/select/styled/OptionsContainer'
import Item from 'src/helpers/Item'
import OptionStyled from 'src/components/select/styled/OptionStyled'
import ClearableSelect from './ClearableSelect'

interface FiltersProps {
  onClose: () => void
}

function Filters(props: FiltersProps) {
  const theme = useContext(ThemeContext)
  const {
    setFilterForm,
    setVisibleColumns,
    setEmptySearchFields,
  } = useActions(actions)
  const municipalities = useSelector(municipalitiesSelector)
  const _visibleColumns = useSelector(visibleColumnsFormSelector)
  const _formData = useSelector(filterFormSelector)
  const _emptyFilters = useSelector(emptySearchFieldsSelector)
  const [formData, updateFormData] = useState(_formData)
  const [emptyKeys, setEmptyKeys] = useState(_emptyFilters)
  const amountSelectRef = useRef({ close: () => {} })

  const municipalityOptions = municipalities.map((item) => ({
    value: item.id,
    label: item.name,
  }))

  function search() {
    const _v = {}

    Object.keys(formData).forEach((key) => {
      // @ts-ignore
      _v[key] = true
    })
    setVisibleColumns({
      ..._visibleColumns,
      ..._v,
    })
    setFilterForm(formData)
    setEmptySearchFields(emptyKeys)
    props.onClose()
  }
  function reset() {
    setVisibleColumns(columnsDefaultData())
    setFilterForm({})
    setEmptySearchFields({})
    updateFormData({})
  }

  function updateFilterForm(value: string, name: string) {
    if (['decision', 'municipality'].includes(name)) {
      const byEmpty = value === EMPTY_SELECT_VALUE
      setEmptyKeys({
        ...emptyKeys,
        [name]: byEmpty,
      })
    }
    updateFormData({
      ...formData,
      [name]: value,
    })
  }

  const clearableSelectProps = {
    formData,
    emptyKeys,
    setEmptyKeys,
    updateFilterForm,
  }

  const makeSelectionText = 'Maak een selectie'

  return (
    <Container
      border={`1px solid ${theme.colors.gray6}`}
      padding={16}
      margin={{ bottom: 32, horizontal: 16 }}
    >
      <Container>
        <Row>
          <Col>
            <Container margin={{ bottom: 16 }}>
              <Select
                label="Aanslag"
                name="assessment"
                placeholder={makeSelectionText}
                value={formData.assessment}
                onSelect={updateFilterForm}
                options={formValues.assessment}
              />
            </Container>
            <Container margin={{ bottom: 16 }}>
              <Select
                name="authorization"
                label="Machtiging"
                placeholder={makeSelectionText}
                onSelect={updateFilterForm}
                value={formData.authorization}
                options={formValues.authorization}
              />
            </Container>
            <Container margin={{ bottom: 16 }}>
              <Select
                name="questionsDoc"
                label="Vragenlijst"
                placeholder={makeSelectionText}
                value={formData.questionsDoc}
                onSelect={updateFilterForm}
                options={formValues.questionsDoc}
              />
            </Container>
            <Container>
              <Select
                name="addressCount"
                label="Aantal adressen"
                onSelect={updateFilterForm}
                value={formData.addressCount}
                placeholder={makeSelectionText}
                options={formValues.addressCount}
              />
            </Container>
          </Col>
          <Col>
            <Container margin={{ bottom: 16 }}>
              <Select
                name="municipality"
                onSelect={updateFilterForm}
                label="Samenwerkingsverband"
                value={formData.municipality}
                options={[
                  { label: 'Leeg', value: EMPTY_SELECT_VALUE },
                  ...municipalityOptions
                ]}
                placeholder={makeSelectionText}
              />
            </Container>
            <Container margin={{ bottom: 16 }}>
              <Input
                name="town"
                onChange={updateFilterForm}
                label="Gemeente"
                value={formData.town}
              />
            </Container>
            <Container>
              <Select
                name="property"
                label="Soort pand"
                value={formData.property}
                onSelect={updateFilterForm}
                options={formValues.property}
                placeholder={makeSelectionText}
              />
            </Container>
          </Col>
          <Col>
            <Container margin={{ bottom: 16 }}>
              <Input
                name="objectNumber"
                label="Objectnummer"
                onChange={updateFilterForm}
                value={formData.objectNumber}
              />
            </Container>
            <Container margin={{ bottom: 16 }}>
              <Select
                name="taxDoc"
                label="Taxatieverslag"
                options={formValues.taxDoc}
                onSelect={updateFilterForm}
                placeholder={makeSelectionText}
              />
            </Container>
            <Container margin={{ bottom: 16 }}>
              <Input
                name="reasonOfObjection"
                value={formData.reasonOfObjection}
                label="Reden bezwaar"
                onChange={updateFilterForm}
              />
            </Container>
            <Container>
              <Select
                name="howFindUs"
                value={formData.howFindUs}
                onSelect={updateFilterForm}
                options={formValues.howFindUs}
                placeholder={makeSelectionText}
                label="Hoe is de klant binnengekomen?"
              />
            </Container>
          </Col>
          <Col>
            <Container margin={{ bottom: 16 }}>
              <ClearableSelect
                name="foDelivery"
                label="Pro Forma Bezwaarschrift ingediend"
                {...clearableSelectProps}
              >
                {props => (
                  <DatePicker
                    {...props}
                    openDirection="down"
                    /** WARN: this function was added to avoid a bug: remove if possible */
                    handleFocusChange={(focused, setFocused) => {
                      setTimeout(() => setFocused(focused), 200)
                    }}
                    withPortal
                  />
                )}
              </ClearableSelect>
            </Container>
            <Container margin={{ bottom: 16 }}>
              <ClearableSelect
                name="foReceived"
                label="Ontvangstbevestiging"
                {...clearableSelectProps}
              >
                {props => (
                  <DatePicker
                    {...props}
                    openDirection="down"
                    /** WARN: this function was added to avoid a bug: remove if possible */
                    handleFocusChange={(focused, setFocused) => {
                      setTimeout(() => setFocused(focused), 200)
                    }}
                    withPortal
                  />
                )}
              </ClearableSelect>
            </Container>
            <Container margin={{ bottom: 16 }}>
              <Input
                name="caseNumber"
                label="Dossiernummer"
                value={formData.caseNumber}
                onChange={updateFilterForm}
              />
            </Container>
            <Container>
              <ClearableSelect
                name="coReceived"
                label="Inhoudelijk Bezwaarschrift ingediend"
                {...clearableSelectProps}
              >
                {props => (
                  <DatePicker
                    {...props}
                    openDirection="down"
                    /** WARN: this function was added to avoid a bug: remove if possible */
                    handleFocusChange={(focused, setFocused) => {
                      setTimeout(() => setFocused(focused), 200)
                    }}
                    withPortal
                  />
                )}
              </ClearableSelect>
            </Container>
          </Col>
          <Col>
            <Container margin={{ bottom: 16 }}>
              <Select
                name="decision"
                label="Uitslag"
                value={formData.decision}
                onSelect={updateFilterForm}
                options={formValues.decision}
                placeholder={makeSelectionText}
              />
            </Container>
            <Container margin={{ bottom: 16 }}>
              <ClearableSelect
                name="paymentReceived"
                label="Betaling ontvangen"
                {...clearableSelectProps}
              >
                {props => (
                  <DatePicker
                    {...props}
                    openDirection="down"
                    /** WARN: this function was added to avoid a bug: remove if possible */
                    handleFocusChange={(focused, setFocused) => {
                      setTimeout(() => setFocused(focused), 200)
                    }}
                    withPortal
                  />
                )}
              </ClearableSelect>
            </Container>
            <Container margin={{ bottom: 16 }}>
              <Select
                ref={amountSelectRef}
                label="Ontvangen bedrag"
                placeholder={makeSelectionText}
                value={emptyKeys.amount ? 'Leeg' : formData.amount}
                renderCustomOption={() => (
                  <OptionsContainer overflowY="auto">
                    <Item
                      data={null}
                      onClick={() => {
                        amountSelectRef.current.close()
                        updateFilterForm('', 'amount')
                        setEmptyKeys({ ...emptyKeys, amount: true })
                      }}
                    >
                      <OptionStyled>
                        <Text
                          flex
                          size={14}
                          color={theme.colors.gray5}
                          font={theme.typography.lato}
                        >
                          {'Leeg'}
                        </Text>
                      </OptionStyled>
                    </Item>
                    <Item data={null}>
                      <OptionStyled padding="6px 12px" height={46}>
                        <Input
                          name="amount"
                          height={32}
                          value={formData.amount}
                          rightIcon="search"
                          rightIconProps={{ onClick: () => {
                            amountSelectRef.current.close()
                          }}}
                          onChange={(value, name) => {
                            updateFilterForm(value, name)
                            setEmptyKeys({ ...emptyKeys, amount: false })
                          }}
                        />
                      </OptionStyled>
                    </Item>
                  </OptionsContainer>
                )}
              />
            </Container>
            <Container>
              <Input
                name="comment"
                label="Opmerkingen"
                value={formData.comment}
                onChange={updateFilterForm}
              />
            </Container>
          </Col>
        </Row>
      </Container>
      <Container flex mainAxis="flex-end" margin={{ top: 24 }}>
        <Container margin={{ right: 16 }}>
          <Button
            big
            disabled={!Object.keys(_formData).length}
            label="Resetten"
            onClick={reset}
          />
        </Container>
        <Button big label="Zoeken" icon="Search" onClick={search} />
      </Container>
    </Container>
  )
}

Filters.defaultProps = {}

export default memo(Filters)
