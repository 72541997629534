import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

//styled
import MainLayout from 'src/layouts/Main'
import { Icon } from 'src/components'
import { Container, Text } from 'src/styles/styled-components'
import Item from "src/helpers/Item"

function NotFound() {
  const history = useHistory()
  const theme = useContext(ThemeContext)

  return (
    <MainLayout hideLeftPanel>
      <Item
        flex
        crossAxis="center"
        decoration={theme.decorations.main}
        onClick={() => history.goBack()} padding={16}
      >
        <Icon iconName="ChevronLeft" size={12} color={theme.colors.main} />
        <Text margin={{ left: 5 }} size={14} color={theme.colors.main}>Teruggaan</Text>
      </Item>
      <Container
        column
        flex
        mainAxis="center"
        crossAxis="center"
        height="100%"
        mobile={{ padding: { horizontal: 10 } }}
      >
        <Text size={28} weight="bold" padding={16}>404</Text>
        <Text size={24} weight="bold">Not Found</Text>
      </Container>
    </MainLayout>
  )
}

export default {
  component: NotFound,
  exact: false,
  public: false,
  path: '/not_found',
}
