import React, { memo, useState, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import ReactHtmlParser from 'react-html-parser'
import orderBy from 'lodash.orderby'

//components
import { Tab, FormElement, EditButton } from 'src/components'

//styled
import { Container, Text } from 'src/styles/styled-components'
import { useSelector } from 'react-redux'
import { templatesSelector } from 'src/selectors'
import { EmailTemplate } from 'src/interfaces/app'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/user'

// api
import { updateTemplate } from 'src/services/api'
import { TEMPLATES_TITLES_MAP } from 'src/constants'

function Email() {
  const { getEmailTemplates } = useActions(actions)
  const theme = useContext(ThemeContext)
  const [editedTemplateKey, setEditedTemplateKey] = useState(null)
  const templates = useSelector(templatesSelector)

  const [subject, setSubject] = useState('')
  const [content, setContent] = useState('')
  const [sort, setSort] = useState('')

  const tabs = Object.keys(templates).map((key) => ({ text: key }))

  function hideEditor() {
    setSubject('')
    setContent('')
    setEditedTemplateKey(null)
  }

  async function toggleEditor(template: EmailTemplate) {
    setSubject(template?.subject)
    setContent(template?.content)
    setSort(template?.sort)
    setEditedTemplateKey(template.key)
  }

  async function updateEmailTemplate({ data }: { data: EmailTemplate }) {
    const { docId, ...otherData } = data

    if (data?.label) {
      await updateTemplate(docId, { ...otherData, content, sort })
    } else {
      if (data.key === 'email_signature') {
        await updateTemplate(docId, {
          ...otherData,
          subject,
          content: content.replace(
            '<figure>',
            '<figure style="margin-left: 0px;">'
          ),
          sort,
        })
      } else {
        await updateTemplate(docId, { ...otherData, subject, content, sort })
      }
    }
    await getEmailTemplates()
    hideEditor()
  }

  function renderTemplate(template: EmailTemplate) {
    const isEdit = template.key === editedTemplateKey
    return (
      <Container
        key={template.id}
        margin={{ bottom: 24 }}
        padding={{ bottom: 10 }}
        borderBottom={`1px solid ${theme.colors.gray5}`}
      >
        {!template?.label && <Text>{TEMPLATES_TITLES_MAP[template.key]}</Text>}
        <Container flex margin={{ top: 24 }}>
          {template?.label ? (
            <Text color={theme.colors.gray5}>{template?.label}</Text>
          ) : (
            <>
              <Container margin={{ right: 50 }}>
                <Text color={theme.colors.gray5}>Onderwerp:</Text>
              </Container>
              <FormElement
                name="subject"
                edit={isEdit}
                value={subject}
                onChange={setSubject}
              >
                <Text color={theme.colors.gray5}>{template?.subject}</Text>
              </FormElement>
            </>
          )}
        </Container>
        <Container flex margin={{ top: 35 }}>
          <Container margin={{ right: 80 }}>
            <Text color={theme.colors.gray5}>Inhoud:</Text>
          </Container>
          <FormElement
            isWysiwyg
            name="content"
            edit={isEdit}
            value={content}
            formType="textarea"
            onChange={setContent}
          >
            {ReactHtmlParser(template?.content)}
          </FormElement>
        </Container>
        <Container flex crossAxis="center" width={150} margin={{ top: 35 }}>
          {isEdit && (
            <Text margin={{ right: 16 }} color={theme.colors.gray5}>
              Sort:
            </Text>
          )}
          <FormElement
            name="sort"
            edit={isEdit}
            value={sort}
            onChange={setSort}
          />
        </Container>
        <Container flex mainAxis="flex-end" margin={{ top: 32, bottom: 24 }}>
          <EditButton
            data={template}
            isEdit={isEdit}
            onCancel={hideEditor}
            onSubmit={updateEmailTemplate}
            toggleEditButton={toggleEditor}
          />
        </Container>
      </Container>
    )
  }

  return (
    <Container>
      <Container margin={{ bottom: 16 }}>
        <Text>Kies hieronder het tabblad om de desbetreffende template te wijzigen.</Text>
      </Container>
      <Tab titles={tabs} onTabChange={hideEditor}>
        {Object.keys(templates).map((key) => {
          const sortedData = orderBy(templates[key], 'sort', 'asc')
          return (
            <Container key={key}>{sortedData.map(renderTemplate)}</Container>
          )
        })}
      </Tab>
    </Container>
  )
}

export default memo(Email)
