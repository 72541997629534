import React, { memo, useContext, useEffect, useMemo, useState } from 'react'

import {
  ClientRequestInfo,
  MANAGER_ACTION_TYPES,
  TemplateCategoryIds,
  TemplateIds,
} from 'src/interfaces/app'

import { Col, Container, Row, Text } from 'src/styles/styled-components'
import { MANAGER_ACTIONS } from 'src/constants/clientInfo'
import Item from 'src/helpers/Item'
import { Modal } from 'src/components'
import RequestDocuments from 'src/pages/clientDetail/components/ActionViews/RequestDocuments'
import { ThemeContext } from 'styled-components'
import { useActions } from 'redux-exodus'
import { actions } from 'src/redux/user'
import { useSelector } from 'react-redux'
import { isCheckModalSelector, textBlocksSelector } from 'src/selectors'

// Actions View
import ExportFO from 'src/pages/clientDetail/components/ActionViews/ExportFO'
import ExportCO from 'src/pages/clientDetail/components/ActionViews/ExportCO'
import FOObjection from 'src/pages/clientDetail/components/ActionViews/FOObjection'
import SendObjection from 'src/pages/clientDetail/components/ActionViews/SendObjection'
import CheckDocuments from 'src/pages/clientDetail/components/ActionViews/CheckDocuments'
import ReceivedDecision from 'src/pages/clientDetail/components/ActionViews/ReceivedDecision'
import { useEmailTemplate } from 'src/email'
import { ACTIONS_TEMPLATE_MAP } from 'src/constants'
import { EMAIL_VARIABLES } from 'src/email/misc'
import { generateString } from 'src/utils/misc'
import { createToken } from 'src/services/api'

type ManagerAction = {
  title: string
  status?: number
  actionName: string
}

interface ManagerActionsProps {
  status: number
  email: string
  updateData: () => void
  clientInfo: ClientRequestInfo
}

function ManagerActions(props: ManagerActionsProps) {
  const theme = useContext(ThemeContext)
  const [currentAction, setCurrentAction] = useState(null)
  const [isModalShow, setIsModalShow] = useState(false)

  const { getEmailTemplates, getTextBlocks } = useActions(actions)
  const { getTemplate, getTemplates } = useEmailTemplate()
  const textBlocks = useSelector(textBlocksSelector)
  const isCheckModalShow = useSelector(isCheckModalSelector)

  useEffect(() => {
    if (isCheckModalShow) {
      setCurrentAction({
        title: 'Nu controleren',
        actionName: 'CHECKING',
        status: 1,
      })
      setIsModalShow(true)
    }
  }, [isCheckModalShow])

  useEffect(() => {
    getEmailTemplates()
    getTextBlocks()
  }, [])

  const token = useMemo(
    () => props.clientInfo?.userRegistered ? null : generateString(64),
    [props.clientInfo?.id, props.clientInfo?.userRegistered],
  )

  async function onRequestSent(redirectLink?: string) {
    if (token) {
      await createToken({
        token,
        email: props.clientInfo.email,
        type: 'create_password',
        redirectLink: redirectLink || `/client_detail/${props.clientInfo.id}/upload_files`,
        createdAt: Date.now()
      })
    }
    setIsModalShow(false)
  }

  const renderActions = useMemo(() => {
    function handleActions(action: ManagerAction) {
      if (action?.status !== undefined && action?.status > props.status) {
        return
      }

      if (action.actionName === MANAGER_ACTION_TYPES.VIEW_DECISION) {
        window.open(props.clientInfo.decisionDocument.file, '_blank')
      } else {
        setCurrentAction(action)
        setIsModalShow(true)
      }
    }

    return MANAGER_ACTIONS.map(
      (item: { actions: ManagerAction[] }, index: number) => {
        return (
          <Col key={`actions_${index}`} noPadding>
            <Container padding={{ left: 8 }}>
              {item.actions.map((action: ManagerAction) => {
                const isActive =
                  action?.status === undefined || action?.status <= props.status
                const hide =
                  (action.actionName === 'REQUEST_PHOTO' &&
                    !!props.clientInfo?.photoRequest) ||
                  (action.actionName === 'PHOTO_REMINDER' &&
                    !props.clientInfo?.photoRequest)

                return (
                  !hide && (
                    <Item
                      margin={{ bottom: 16 }}
                      key={`action_${action.actionName}`}
                      data={action}
                      onClick={handleActions}
                      // TASK: WOZ-633
                      hoverDecoration={isActive ? theme.decorations.main : "none"}
                    >
                      <Text
                        cursor={isActive ? 'pointer' : 'not-allowed'}
                        color={
                          isActive ? theme.colors.main : theme.colors.gray0
                        }
                      >
                        {action.title}
                      </Text>
                    </Item>
                  )
                )
              })}
            </Container>
          </Col>
        )
      }
    )
  }, [props.clientInfo])

  const renderActionViews = useMemo(() => {
    switch (currentAction?.actionName) {
      case MANAGER_ACTION_TYPES.SEND_REMINDER:
      case MANAGER_ACTION_TYPES.REQUEST_PHOTO:
      case MANAGER_ACTION_TYPES.PHOTO_REMINDER:
        return (
          <RequestDocuments
            clientInfo={props.clientInfo}
            email={props.email}
            updateData={props.updateData}
            onRequestSent={onRequestSent}
            actionName={currentAction?.actionName}
            token={token}
            template={getTemplate(
              ...ACTIONS_TEMPLATE_MAP[currentAction.actionName],
              EMAIL_VARIABLES(props.clientInfo, {}, token)
            )}
          />
        )
      case MANAGER_ACTION_TYPES.CHECKING:
        return (
          <CheckDocuments
            clientInfo={props.clientInfo}
            email={props.email}
            updateData={props.updateData}
            onRequestSent={onRequestSent}
            token={token}
            templateAllFilesOk={getTemplate(
              TemplateCategoryIds.CHECKING,
              TemplateIds.ALL_FILES_OK,
              EMAIL_VARIABLES(props.clientInfo, {}, token)
            )}
          />
        )
      case MANAGER_ACTION_TYPES.EXPORT_FO:
        return (
          <ExportFO
            data={props.clientInfo}
            updateData={props.updateData}
            onRequestSent={onRequestSent}
          />
        )
      case MANAGER_ACTION_TYPES.FO_OBJECTION:
        return (
          <FOObjection
            email={props.email}
            updateData={props.updateData}
            onRequestSent={onRequestSent}
            token={token}
            template={getTemplate(
              ...ACTIONS_TEMPLATE_MAP[currentAction.actionName],
              EMAIL_VARIABLES(props.clientInfo, {}, token)
            )}
          />
        )
      case MANAGER_ACTION_TYPES.EXPORT_CO:
        return (
          <ExportCO
            data={textBlocks.filter((i) => i.category === 'FOExport')}
            clientInfo={props.clientInfo}
            updateData={props.updateData}
            onRequestSent={onRequestSent}
          />
        )
      case MANAGER_ACTION_TYPES.CO_OBJECTION:
        return (
          <SendObjection
            email={props.email}
            clientInfo={props.clientInfo}
            updateData={props.updateData}
            onRequestSent={onRequestSent}
            token={token}
            templates={getTemplates(
              TemplateCategoryIds.CO_OBJECTION,
              EMAIL_VARIABLES(props.clientInfo, {}, token)
            )}
          />
        )
      case MANAGER_ACTION_TYPES.RECEIVED_DECISION:
        return (
          <ReceivedDecision
            data={textBlocks.filter((i) => i.category === 'COExport')}
            email={props.email}
            clientInfo={props.clientInfo}
            updateData={props.updateData}
            onRequestSent={onRequestSent}
            template={getTemplate(
              ...ACTIONS_TEMPLATE_MAP[currentAction.actionName],
              EMAIL_VARIABLES(props.clientInfo)
            )}
          />
        )
      default:
        return null
    }
  }, [currentAction, isModalShow, token])

  return (
    <Container margin={{ top: 16 }}>
      <Row noPadding>{renderActions}</Row>
      <Modal
        isShow={isModalShow}
        title={currentAction?.title}
        // TASK: WOZ-633
        titleProps={{ weight: "bold" }}
        onRequestClose={setIsModalShow}
      >
        {renderActionViews}
      </Modal>
    </Container>
  )
}

ManagerActions.defaultProps = {}

export default memo(ManagerActions)
