import React, {
  memo,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ThemeContext } from 'styled-components'

// styled components
import { Container, Text } from 'src/styles/styled-components'

// components
import Icon from './../icon'

type InputEvent = React.ChangeEvent<HTMLInputElement>

export interface InputProps {
  name: string
  value: string
  type?: string
  label?: string
  flex?: boolean
  width?: number | string
  leftIcon?: string
  rightIcon?: string
  errorText?: string
  disabled?: boolean
  placeholder?: string
  color?: string
  onBlur?: () => void
  onFocus?: () => void
  leftIconProps?: StyledIconProps
  rightIconProps?: StyledIconProps
  size?: 'default' | 'large' | 'full'
  height?: number | string
  onChange?: (value: string, name: string, e?: SyntheticEvent) => void
  onClick?: () => void
}

// styled
import InputStyled from './styled/InputStyled'
import InputContainer from './styled/InputContainer'
import { StyledIconProps } from '@styled-icons/styled-icon'

function Input(props: InputProps) {
  const theme = useContext(ThemeContext)

  const {
    name,
    width,
    label,
    value,
    disabled,
    onChange,
    type,
    leftIcon,
    rightIcon,
    errorText,
    flex = true,
    size = 'full',
    height,
    onClick,
    leftIconProps = {
      size: 16,
      color: theme.colors.gray0,
    },
    rightIconProps = {
      size: 14,
      color: theme.colors.gray0,
    },
    ...otherProps
  } = props

  const input = useRef<HTMLInputElement>(null)

  const [_value, _setValue] = useState(value)
  const [isFocus, setIsFocus] = useState(false)

  useEffect(() => {
    if (!isFocus) {
      _setValue(value || '')
    }
  }, [value, isFocus])

  const handleChange = (e: InputEvent) => {
    if (!disabled) {
      if (onChange) {
        onChange(e.target.value, name, e)
      }
      _setValue(e.target.value)
    }
  }

  const handleFocus = useCallback(() => {
    setIsFocus(true)
    if (otherProps.onFocus) {
      otherProps.onFocus()
    }
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocus(false)
    if (otherProps.onBlur) {
      otherProps.onBlur()
    }
  }, [])

  const renderLeftIcon = useMemo(() => {
    const disabledStyles = disabled
      ? {
          color: 'rgba(0, 0, 0, 0.1)',
        }
      : {}

    return (
      Boolean(leftIcon) && (
        <Icon iconName={leftIcon} {...leftIconProps} {...disabledStyles} />
      )
    )
  }, [leftIcon, leftIconProps, disabled])

  const renderRightIcon = useMemo(() => {
    const disabledStyles = disabled
      ? {
          color: 'rgba(0, 0, 0, 0.1)',
        }
      : {}
    return (
      Boolean(rightIcon) && (
        <Container margin={{ left: 9 }} flex={1} noGrow mainAxis="center">
          <Icon iconName={rightIcon} {...rightIconProps} {...disabledStyles} />
        </Container>
      )
    )
  }, [rightIcon, rightIconProps, disabled])

  return (
    <Container flex={flex} column onClick={onClick}>
      {label ? (
        <Container margin={{ bottom: 5 }}>
          <Text
            size={14}
            weight="bold"
            lineHeight={21}
            color={theme.colors.label1}
            font={theme.typography.openSans}
          >
            {label}
          </Text>
        </Container>
      ) : null}
      <InputContainer disabled={disabled} height={height} isFocus={isFocus} size={size}>
        {renderLeftIcon}
        <InputStyled
          disabled={disabled}
          value={_value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type={type}
          height={height ? +height - 2 : null}
          {...otherProps}
        />
        {renderRightIcon}
      </InputContainer>
      {errorText ? (
        <Container margin={{ top: 5 }}>
          <Text size={14} lineHeight={21} color={theme.colors.red1}>
            {errorText}
          </Text>
        </Container>
      ) : null}
    </Container>
  )
}

export default memo(Input)
