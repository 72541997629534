import React, { useEffect } from 'react'
import { getToken } from 'src/services/api'
import { useHistory, useParams } from 'react-router-dom'
import {
  generateQuestionsListPDF,
  generateSignatureExample,
} from 'src/services/pdfGenerator'
import { generateZIP } from 'src/services/generateZip'

function DownloadDocuments() {
  const params = useParams<{ token: string }>()
  const history = useHistory()

  useEffect(() => {
    getToken(params.token).then(async (r) => {
      if (!r.docs.length) {
        history.replace('/')
      } else {
        const tokenData: any = { id: r.docs[0].id, ...r.docs[0].data() }

        if (tokenData.type !== 'download_documents') {
          history.replace('/')
        } else {
          const clientInfo = tokenData.document

          const address = `${clientInfo.buildingStreet} ${
            clientInfo.buildingHouseNumber
          }${
            clientInfo.buildingAddition ? ` ${clientInfo.buildingAddition}` : ''
          } te ${clientInfo.buildingPlace}`

          const generatorQuestions = await generateQuestionsListPDF(
            clientInfo,
            address
          )
          const generatorQuestionsDOCX = await generateQuestionsListPDF(
            clientInfo,
            address,
            null,
            'DOCX'
          )
          const generatorSignature = await generateSignatureExample(clientInfo)

          const zip = []

          if (generatorQuestions) {
            zip.push({
              name: 'Vragenlijst.pdf',
              file: await generatorQuestions.base64(),
            })
            zip.push({
              name: 'Vragenlijst.docx',
              file: await generatorQuestionsDOCX.base64(),
            })
          }

          if (generatorSignature) {
            zip.push({
              name: 'Machtiging.pdf',
              file: generatorSignature.base64(),
            })
          }
          //await deleteToken(tokenData.id)
          await generateZIP(zip, address).download()
          history.replace('/')
        }
      }
    })
  }, [])

  return <div />
}

DownloadDocuments.defaultProps = {}

export default {
  component: DownloadDocuments,
  exact: false,
  public: null,
  path: '/download-documents/:token',
}
