import React, { memo, useCallback, useContext, useEffect } from 'react'

import {
  Button,
  Checkbox,
  Input,
  Radio,
  Select,
  Textarea,
} from 'src/components'

import { Col, Container, Row, Text } from 'src/styles/styled-components'
import { ThemeContext } from 'styled-components'
import { getAddress } from 'src/services/api'
import { SELECT_OPTIONS } from 'src/constants'

interface Step2Props {
  formData: any
  wizard: any
  isValid: boolean
  updateFormData: (value: any, name?: string) => void
  onSubmit: (callback: () => void, errorCallback: () => void) => void
}

function Step2(props: Step2Props) {
  const theme = useContext(ThemeContext)
  const { formData, updateFormData, isValid, onSubmit } = props

  useEffect(() => {
    if (!isValid) {
      props.wizard.current?.updateStepValidation(2, isValid)
      props.wizard.current?.updateStepValidation(3, isValid)
    }
  }, [isValid, props.wizard])

  const updateAddressData = useCallback(
    async (value: string, name: string) => {
      const postCode =
        name === 'personalPostCode' ? value : formData.personalPostCode.value
      const houseNumber =
        name === 'personalHouseNumber'
          ? value
          : formData.personalHouseNumber.value

      if (!!postCode && !!houseNumber && !formData.copyAddressFormStep1.value) {
        const data = await getAddress(postCode, houseNumber)
        if (data?.street) {
          updateFormData(data.street, 'personalStreet')
          updateFormData(data.city, 'personalPlace')
        }
      }
      updateFormData(value, name)
    },
    [formData.personalPostCode.value, formData.personalHouseNumber.value]
  )

  function submit() {
    onSubmit(
      () => {
        props.wizard.current?.updateStepValidation(2, true, true)
      },
      () => {
        props.wizard.current?.updateStepValidation(2, false)
        props.wizard.current?.updateStepValidation(3, false)
      }
    )
  }

  return (
    <Container padding={16}>
      <Text size={32}>Stap 2 van 4 - Gegevens belastingplichtige</Text>
      {/* TASK: WOZ-648 */}
      <Container maxWidth={820}>
        <Container margin={{ bottom: 32 }}>
          <Container margin={{ bottom: 10, top: 32 }}>
            <Text>Aanhef*</Text>
          </Container>
          <Select
            name="salutation"
            onSelect={updateFormData}
            options={SELECT_OPTIONS.salutation}
            placeholder="Maak uw keuze..."
            value={formData.salutation.value}
            errorText={formData.salutation.validationText}
          />
        </Container>
        <Container margin={{ bottom: 32 }}>
          <Input
            name="firstName"
            onChange={updateFormData}
            value={formData.firstName.value}
            placeholder="Voorletters*"
            errorText={formData.firstName.validationText}
          />
        </Container>
        <Container margin={{ bottom: 32 }}>
          <Input
            name="lastName"
            onChange={updateFormData}
            value={formData.lastName.value}
            placeholder="Achternaam (incl. tussenvoegsels)*"
            errorText={formData.lastName.validationText}
          />
        </Container>
        <Container margin={{ bottom: 32 }}>
          <Text>
            Is uw eigen adres hetzelfde als het adres, zoals ingevuld bij Stap
            1? *
          </Text>
          <Container flex margin={{ top: 10 }}>
            <Container margin={{ right: 25 }}>
              <Radio
                value={true}
                label="Ja"
                name="copyAddressFormStep1"
                onCheck={updateFormData}
                checked={formData.copyAddressFormStep1.value}
              />
            </Container>
            <Radio
              label="Nee"
              value={false}
              onCheck={updateFormData}
              name="copyAddressFormStep1"
              checked={!formData.copyAddressFormStep1.value}
            />
          </Container>
        </Container>
        {!formData.copyAddressFormStep1.value && (
          <>
            <Container margin={{ bottom: 32 }}>
              <Row>
                <Col>
                  <Input
                    name="personalPostCode"
                    placeholder="Postcode*"
                    onChange={updateAddressData}
                    value={formData.personalPostCode.value}
                    errorText={formData.personalPostCode.validationText}
                  />
                </Col>
                <Col>
                  <Input
                    name="personalHouseNumber"
                    onChange={updateAddressData}
                    placeholder="Huisnummer*"
                    value={formData.personalHouseNumber.value}
                    errorText={formData.personalHouseNumber.validationText}
                  />
                </Col>
                <Col>
                  <Input
                    name="personalAddition"
                    placeholder="Toevoeging"
                    onChange={updateFormData}
                    value={formData.personalAddition.value}
                    errorText={formData.personalAddition.validationText}
                  />
                </Col>
              </Row>
            </Container>
            <Container margin={{ bottom: 32 }}>
              <Input
                name="personalStreet"
                placeholder="Straat*"
                onChange={updateFormData}
                value={formData.personalStreet.value}
                errorText={formData.personalStreet.validationText}
              />
            </Container>
            <Container margin={{ bottom: 32 }}>
              <Input
                name="personalPlace"
                placeholder="Plaats*"
                onChange={updateFormData}
                value={formData.personalPlace.value}
                errorText={formData.personalPlace.validationText}
              />
            </Container>
          </>
        )}
        <Container margin={{ bottom: 32 }}>
          <Input
            name="phone"
            onChange={updateFormData}
            placeholder="Telefoonnummer *"
            value={formData.phone.value}
            errorText={formData.phone.validationText}
          />
        </Container>
        <Container margin={{ bottom: 32 }}>
          <Input
            name="email"
            onChange={updateFormData}
            placeholder="E-mailadres *"
            value={formData.email.value}
            disabled={formData.disabledEmail.value}
            errorText={formData.email.validationText}
          />
        </Container>
        <Container flex noGrow crossAxis="center" margin={{ bottom: 32 }}>
          <Checkbox
            name="disabledEmail"
            onCheck={(value: boolean) => {
              updateFormData(value, 'disabledEmail')
              updateFormData('', 'email')
            }}
            checked={formData.disabledEmail.value}
            renderLabel={() => (
              <Text color={theme.colors.gray5} size={14} margin={{ left: 14 }}>
                Ik heb{' '}
                <Text
                  color={
                    formData.disabledEmail.value ? theme.colors.red1 : 'inherit'
                  }
                >
                  geen e-mailadres
                </Text>
              </Text>
            )}
          />
        </Container>
        <Container margin={{ bottom: 32 }}>
          <Textarea
            name="reasonOfObjection"
            placeholder="Reden bezwaar *"
            onChange={updateFormData}
            value={formData.reasonOfObjection.value}
            errorText={formData.reasonOfObjection.validationText}
          />
        </Container>
        <Container margin={{ bottom: 32 }}>
          <Container margin={{ bottom: 10 }}>
            <Text>Hoe bent u bij ons terecht gekomen?</Text>
          </Container>
          <Select
            name="howFindUs"
            onSelect={updateFormData}
            placeholder="Maak uw keuze..."
            value={formData.howFindUs.value}
            options={SELECT_OPTIONS.howFindUs}
            errorText={formData.howFindUs.validationText}
          />
        </Container>
        <Container>
          <Button large onClick={submit} label="Naar Stap 3 - Controleren" />
        </Container>
      </Container>
    </Container>
  )
}

Step2.defaultProps = {}

export default memo(Step2)
