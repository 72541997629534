import React, { memo } from 'react'
import ReactTooltip, { TooltipProps } from 'react-tooltip'

// styled
import { Container } from 'src/styles/styled-components'

interface ITooltipProps extends TooltipProps {
  description: string
  tooltipId?: string
}

function Tooltip(props: ITooltipProps) {
  const { description, tooltipId = 'tooltip', ...otherProps } = props

  return (
    <Container>
      <ReactTooltip
        multiline
        place="top"
        id={tooltipId}
        effect="float"
        {...otherProps}
      />
      <p data-tip={description} data-for={tooltipId}>
        {props.children}
      </p>
    </Container>
  )
}

export default memo(Tooltip)
