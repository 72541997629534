import styled from 'styled-components'
import defaultTheme from 'src/styles/theme'

interface SwitchStyledProps {
  disabled: boolean
  checked: boolean
}

const dynamicStyles = ({ disabled, checked }: SwitchStyledProps) => `
  ${
    disabled
      ? `
    cursor: not-allowed;
    ${
      !checked
        ? `
      background-color: ${defaultTheme.colors.gray2};
      &:after { 
        background-color: ${defaultTheme.colors.white};
      }
    `
        : `
      background-color: ${defaultTheme.colors.gray2};
      &:after {
        left: 18px;
        background-color: ${defaultTheme.colors.white};
      }
    `
    }
  `
      : `
    ${
      checked
        ? `
      background-color: ${defaultTheme.colors.main};
      &:after {
        background-color: ${defaultTheme.colors.white};
        left: 18px;
      }
    `
        : ''
    }
    
  `
  }
`

const SwitchStyled = styled.div`
  width: 36px;
  height: 20px;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  background-color: ${defaultTheme.colors.gray0};
  transition: all 0.25s linear;

  &:after {
    content: '';
    width: 16px;
    height: 16px;
    left: 2px;
    top: 2px;
    border-radius: 8px;
    background-color: ${defaultTheme.colors.white};
    position: absolute;
    transition: all 0.25s linear;
  }

  ${dynamicStyles}
`

SwitchStyled.displayName = 'SwitchStyled'

export default SwitchStyled
