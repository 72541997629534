// pages
import Login from './login'
import Profile from './profile'
import Dashboard from './dashboard'
import UploadFiles from './uploadFiles'
import Registration, { RedirectFromRegistration } from './registration'
import ClientDetail from './clientDetail'
import SignInClient from './signInClient'
import ResetPassword from './resetPassword'
import ForgotPassword from './forgotPassword'
import DownloadDocuments from './downloadDocuments'
import NotFound from './notFound'

export const routes = [
  Login,
  Profile,
  Dashboard,
  UploadFiles,
  Registration,
  RedirectFromRegistration,
  ClientDetail,
  SignInClient,
  ResetPassword,
  ForgotPassword,
  DownloadDocuments,
  NotFound,
]
