export type CLIENT_INFO_DATA_MAP_TYPE = {
  buildingInfo: Record<string, unknown>
  personalInfo: Record<string, unknown>
}

export type CLIENT_INFO_KEY_MAP = {
  realEstateData: Record<string, unknown>
  taxpayerDetails: Record<string, unknown>
}

export const CUSTOMER_ACTIONS = [
  {
    actions: [{ title: 'Stukken aanleveren', actionName: 'DELIVER_DOCUMENTS' }],
  },
  {
    actions: [
      {
        title: 'Inhoudelijk bezwaar inzien',
        actionName: 'SHOW_CO',
        status: 4,
      },
    ],
  },
  {
    actions: [
      { title: 'Beslissing inzien', actionName: 'SHOW_DECISION', status: 5 },
    ],
  },
]

export const MANAGER_ACTIONS = [
  {
    actions: [
      {
        title: 'Verstuur herinnering',
        actionName: 'SEND_REMINDER',
      },
      {
        title: "Foto's opvragen",
        actionName: 'REQUEST_PHOTO',
      },
      {
        title: "Verstuur herinnering foto's",
        actionName: 'PHOTO_REMINDER',
      },
    ],
  },
  {
    actions: [
      {
        title: 'Nu controleren',
        actionName: 'CHECKING',
        status: 1,
      },
    ],
  },
  {
    actions: [
      {
        title: 'Exporteer Proforma bezwaar',
        actionName: 'EXPORT_FO',
      },
      {
        title: 'Proforma bezwaar ingediend',
        actionName: 'FO_OBJECTION',
      },
    ],
  },
  {
    actions: [
      {
        title: 'Exporteer inhoudelijk bezwaar',
        actionName: 'EXPORT_CO',
        status: 3,
      },
      {
        title: 'Verstuur bezwaar naar gemeente',
        actionName: 'CO_OBJECTION',
        status: 3,
      },
    ],
  },
  {
    actions: [
      {
        title: 'Beslissing ontvangen',
        actionName: 'RECEIVED_DECISION',
        status: 4,
      },
    ],
  },
  {
    actions: [
      { title: 'Beslissing inzien', actionName: 'VIEW_DECISION', status: 5 },
    ],
  },
]

export const CLIENT_INFO_KEY_MAP: CLIENT_INFO_KEY_MAP = {
  realEstateData: {
    property: 'Soort pand',
    buildingStreet: 'Adres',
    buildingHouseNumber: 'Huisnummer',
    buildingAddition: 'Toevoeging',
    buildingPostCode: 'Postcode',
    buildingPlace: 'Plaats',
    town: 'Gemeente',
    wozValue: 'WOZ-waarde (x 1000)',
    assessmentDate: 'Dagtekening',
    assessmentNumber: 'Aanslagnummer',
  },
  taxpayerDetails: {
    salutation: 'Aanhef',
    firstName: 'Voorletters',
    lastName: 'Achternaam',
    personalStreet: 'Adres Klant',
    personalHouseNumber: 'Huisnummer',
    personalAddition: 'Toevoeging',
    personalPostCode: 'Postcode Klant',
    personalPlace: 'Plaats Klant',
    phone: 'Telefoonnummer',
    email: 'E-mailadres',
    reasonOfObjection: 'Reden bezwaar',
    howFindUs: 'Hoe bent u bij ons terecht gekomen',
  },
}

export const CLIENT_INFO_DATA_MAP: CLIENT_INFO_DATA_MAP_TYPE = {
  buildingInfo: {
    property: true,
    buildingPostCode: true,
    buildingHouseNumber: true,
    buildingAddition: true,
    buildingStreet: true,
    buildingPlace: true,
    municipality: true,
    wozValue: true,
    assessmentDate: true,
    assessmentNumber: true,
    //Aamgemeld op
  },
  personalInfo: {
    salutation: true,
    fullName: true,
    personalPostCode: true,
    personalHouseNumber: true,
    personalAddition: true,
    personalStreet: true,
    personalPlace: true,
    phone: true,
    email: true,
    reasonOfObjection: true,
    howFindUs: true,
  },
}

export const STATUSES = [
  { title: 'In afwachting van de stukken' },
  { title: 'Stukken worden gecontroleerd' },
  { title: 'In behandeling' },
  { title: 'Proforma bezwaar ingediend' },
  { title: 'Inhoudelijk bezwaar ingediend' },
  { title: 'Beslissing ontvangen' },
]
